import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { FacilityManagementService, PhysicianService, StoreService } from '@app/services';
import { PatientService } from '@app/services/patient.service';
import { PatientState } from '@app/store/store.interface';
import { filter, debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { Physician } from '@app/interfaces';
import { ROUTER_UTILS } from '@app/helpers';
import { Router } from '@angular/router';
// Clinical categories
import { SlpComorbidityService } from '@app/services';
import { SLPComorbidity } from '@app/store/store.interface';
import { ToasterService } from '@app/services/toaster.service';
import { cloneDeep } from 'lodash';
@Component({
    selector: 'app-search-bar',
    templateUrl: './search-bar.component.html',
    styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements AfterViewInit, OnChanges, OnDestroy {
    @Input() type: 'filter' | 'search' | 'category' | 'physician' | 'basic' | 'multi' | 'multi-pill' = 'search';
    @Input() placeholder = '';
    @Input() searchMethod: any;
    @Input() loading: boolean;
    @Input() disabled: boolean;
    @Input() focusEvent$: Observable<boolean>;
    @Input() dataGridSearch = false;

    @Output() isLoading$ = new EventEmitter<boolean>();
    @Output() searching$ = new EventEmitter<string>();
    @Output() physiciansRecords = new EventEmitter<Physician[]>();
    @Output() searchEvent: EventEmitter<string> = new EventEmitter<string>();
    @Output() removeEvent: EventEmitter<any> = new EventEmitter<any>();
    @Output() xEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChild('diagnosisSearch') diagnosisSearch: ElementRef;
    @ViewChild('searchFilter') searchFilter: ElementRef;
    @ViewChild('search') search: ElementRef;
    @ViewChild('physicianSearch') physicianSearch: ElementRef;
    @ViewChild('multiSearchField') public multiSearchField: ElementRef<HTMLInputElement>;
    @ViewChild('badgesInput') badgesInput: ElementRef;
    // Added to clear results on clicking outside
    patientSearchResults: PatientState[] = [];
    showResults: boolean;
    allSLPs: SLPComorbidity[] = [];
    // Multiselect input data
    @Input() selectedData: any[] = [];
    @Input() objectKey!: string;
    @Input() objectSubKey: string;

    subscriptions: Subscription[] = [];

    visibleItems = [];
    remainingCount = 0;
    showAllPills = false;
    dataChanged = false;

    constructor(
        private facilityManagementService: FacilityManagementService,
        private patientService: PatientService,
        private physicianService: PhysicianService,
        private router: Router,
        private storeService: StoreService,
        private toasterService: ToasterService,
        // Clinical categories
        private slpComorbidityService: SlpComorbidityService,
        private elRef: ElementRef,
        private CDR: ChangeDetectorRef
    ) {
        this.showResults = true;
        // this.renderer.listen('window', 'click', (e: Event) => {
        //   if (e.target !== this.search?.nativeElement && e.target !== this.searchFilter?.nativeElement) {
        //     console.log('Clicked outside of the search filter');
        //     this.patientSearchResults = [];
        //   }
        // });
    }

    clearResults() {
        this.patientSearchResults = [];
    }

    ngAfterViewInit() {
        if (this.diagnosisSearch && this.diagnosisSearch.nativeElement) {
            fromEvent(this.diagnosisSearch.nativeElement, 'keydown')
                .pipe(
                    filter(Boolean),
                    debounceTime(200),
                    distinctUntilChanged(),
                    tap(() => {
                        const value = this.diagnosisSearch?.nativeElement?.value;
                        if (value.length > 0) {
                            this.searching$.emit(value);
                        } else {
                            this.searching$.emit(undefined);
                        }
                    })
                )
                .subscribe();
        }

        if (this.search && this.search.nativeElement) {
            fromEvent(this.search?.nativeElement, 'keyup')
                .pipe(
                    filter(Boolean),
                    debounceTime(200),
                    distinctUntilChanged(),
                    tap(() => {
                        this.searchPatient(this.search?.nativeElement?.value);
                    })
                )
                .subscribe();
        }

        if (this.searchFilter && this.searchFilter.nativeElement) {
            fromEvent(this.searchFilter?.nativeElement, 'keyup')
                .pipe(
                    filter(Boolean),
                    debounceTime(200),
                    distinctUntilChanged(),
                    tap(() => {
                        this.searchPatient(this.searchFilter?.nativeElement?.value);
                    })
                )
                .subscribe();
        }

        if (this.physicianSearch && this.physicianSearch.nativeElement) {
            fromEvent(this.physicianSearch?.nativeElement, 'keyup')
                .pipe(
                    filter(Boolean),
                    debounceTime(200),
                    distinctUntilChanged(),
                    tap(() => {
                        this.searchPhysician(this.physicianSearch?.nativeElement?.value);
                    })
                )
                .subscribe();
        }
        // clinical categories search event
        if (this.multiSearchField && this.multiSearchField.nativeElement && !this.physicianSearch) {
            fromEvent(this.multiSearchField?.nativeElement, 'keyup')
                .pipe(
                    filter(() =>
                        this.type === 'physician'
                            ? this.multiSearchField.nativeElement.value.length >= 0
                            : this.multiSearchField.nativeElement.value.length >= 3
                    ),
                    debounceTime(this.type === 'physician' ? 200 : 1000),
                    distinctUntilChanged(),
                    tap(() => {
                        this.searchEvent.emit(this.multiSearchField?.nativeElement?.value);
                    })
                )
                .subscribe();
        }

        this.subscriptions.push(
            this.focusEvent$?.subscribe((action) => {
                if (action) {
                    setTimeout(() => this.multiSearchField?.nativeElement?.focus(), 500);
                }
            })
        );

        this.calculateVisibleItems();
        window.addEventListener('resize', this.calculateVisibleItems.bind(this));
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.selectedData && changes.selectedData.previousValue !== changes.selectedData.currentValue) {
            this.calculateVisibleItems();
        }
    }

    ngOnDestroy() {
        window.removeEventListener('resize', this.calculateVisibleItems.bind(this));
    }

    calculateVisibleItems(): void {
        // if (!this.showAllPills) {
        const parent = this.elRef.nativeElement.querySelector('.pills-holder');

        this.visibleItems = cloneDeep(this.selectedData);

        setTimeout(() => {
            const child = this.elRef.nativeElement.querySelector('.badge');

            if (parent && child) {
                const parentWidth = parent.clientWidth;
                const childWidth = child.clientWidth;
                const maxVisibleItems = Math.floor(parentWidth / (childWidth + 8)); // 8 is gap
                if (!this.showAllPills) {
                    this.visibleItems = this.selectedData.slice(0, maxVisibleItems);
                }

                this.remainingCount = this.selectedData.length - maxVisibleItems;
                // If there are no child, make remainingCount 0
            }
        }, 0);
        // } else {
        //     this.visibleItems = this.selectedData;
        // }
    }

    toggleAllPills(): void {
        if (this.showAllPills) {
            this.calculateVisibleItems();
            this.showAllPills = false;
        } else {
            this.visibleItems = this.selectedData;
            this.showAllPills = true;
        }
    }

    clearSelection(): void {
        this.removeEvent.emit(null);
    }

    searchPatient(queryString) {
        if (queryString.length > 0) {
            this.loading = true;
            this.isLoading$.emit(true);
            this.patientService.searchPatient(queryString).subscribe(
                (data) => {
                    queryString;
                    this.patientSearchResults = data;
                    this.isLoading$.emit(false);
                    this.loading = false;
                },
                () => {
                    // this.patientSearchResults = [];
                    this.isLoading$.emit(false);
                    this.loading = false;
                }
            );
        } else {
            this.patientSearchResults = [];
        }
    }
    getPatient(id) {
        // if (ssn.length == 11) {
        this.loading = true;
        this.isLoading$.emit(true);
        this.storeService.initializePatientData(id, this.facilityManagementService._currentFacility.id).subscribe(
            (data) => {
                if (data) {
                    this.toasterService.show({
                        title: 'Success',
                        body: 'Patient Selected',
                        type: 'success',
                    });
                    //this.alertService.success('Patient Selected');
                    if (this.router.url.split('/')[1] == ROUTER_UTILS.config.addPatients.root) {
                        this.router.navigate([ROUTER_UTILS.config.addPatients.editAdmission], {
                            skipLocationChange: true,
                        });
                        this.storeService.admissionStepper = ROUTER_UTILS.config.addPatients.personalInformation;
                    }
                }
                this.patientSearchResults = [];
                this.isLoading$.emit(false);
                this.loading = false;
            },
            (err) => {
                // this.alertService.error(err);
                this.toasterService.show({
                    title: 'Error',
                    body: err,
                    type: 'error',
                });
                this.isLoading$.emit(false);
                this.loading = false;
            }
        );
        // }
    }
    searchPhysician(queryString) {
        if (queryString.length > 0) {
            this.loading = true;
            this.isLoading$.emit(true);
            this.physicianService.getPhysicianByOrganization().subscribe(
                (data) => {
                    if (data) {
                        this.isLoading$.emit(false);
                        this.physiciansRecords.emit(queryString);
                        this.loading = false;
                    }
                },
                (err) => {
                    // this.alertService.error(err);
                    this.toasterService.show({
                        title: 'Error',
                        body: err,
                        type: 'error',
                    });
                    this.isLoading$.emit(false);
                    this.loading = false;
                }
            );
        } else {
            this.physiciansRecords.emit([]);
        }
    }
    // To empty the patient results
    setShowResults(value: boolean) {
        this.badgesInput.nativeElement.scrollLeft += this.badgesInput.nativeElement?.clientWidth;
        this.showResults = value;
    }
    // Multi select
    removeSelectedItemEvent(value: any) {
        this.removeEvent.emit(value);
        // this.calculateVisibleItems();
        if (this.showAllPills) {
            this.visibleItems = this.selectedData;
        }
        // this.visibleItems = this.selectedData;
    }
    inputChanged(value: string) {
        // to handle reset case when input 'x' clicked
        if (!value || !value.length) {
            this.removeEvent.emit(null);
        }
    }
    xClicked(): void {
        this.xEvent.emit(true);
    }
}
