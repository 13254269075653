/* eslint-disable spellcheck/spell-checker */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { NavigationExtras, Router } from '@angular/router';
import { ROUTER_UTILS } from '@app/helpers/utils/router.utils';
import { ModalService, OrganizationManagementService, ToasterService } from '@app/services';
import { DeletionConfirmationDialogComponent } from '@app/shared/deletion-confirmation-dialog/deletion-confirmation-dialog.component';
import { take } from 'rxjs';
import { cloneDeep } from 'lodash';
import { uuidv4 } from '@app/helpers/utils/charts/goals.utils';

@Component({
    selector: 'app-non-patient-care',
    templateUrl: './non-patient-care.component.html',
    styleUrls: ['./non-patient-care.component.scss'],
})
export class NonPatientCareComponent implements OnInit {
    @Output() goBack = new EventEmitter<boolean>();
    @Input() title = 'Other Services';
    @Input() backTitle = 'Go Back';
    @Input() isRehabCenter = false;
    @Input() hideTitle = false;
    @Input() editingOrgId = '';
    @Input() npcList = [];

    activities = false;
    isEditMode = false;
    loading = true;
    isDataSaving = false;
    // eslint-disable-next-line spellcheck/spell-checker
    addIcon = './../../../../../assets/icons/add-icon.svg';
    // eslint-disable-next-line spellcheck/spell-checker
    editIcon = './../../../../../assets/icons/edit-icon.svg';
    data: any[] = [];
    headerSizes = [
        { title: 'Activity Name', size: '22vw', padding: '2vw' },
        { title: 'Unit', size: '12vw', padding: '2vw' },
        { title: 'Billable', size: '10vw', padding: '2vw' },
        { title: 'Rate', size: '12vw', padding: '2vw' },
        { title: '', size: '5vw', padding: '2vw' },
    ];

    public SKELETON_CSS = {
        CELL: {
            width: '96%',
            height: '3.4em',
            margin: '0 2em 0.1em 2em',
        },
    };
    items = { unit: null };

    units = ['By hour', 'By mile', 'By session'];

    buttons: { text: string; isSelected: boolean; isDisabled: boolean }[];
    id = '';

    organizationId = '';

    form: FormGroup;
    conceptRehabURL = '';
    isEditing = false;
    facilitiesOfOrganization: string[];

    constructor(
        private router: Router,
        private fb: FormBuilder,
        private _organizationService: OrganizationManagementService,
        private toasterService: ToasterService,
        private modalService: ModalService,
    ) {}

    ngOnInit(): void {
        this.isEditMode = !this.isRehabCenter;

        this.form = this.fb.group({
            rows: this.fb.array([]),
        });

        const url = window.location.href;
        this.isEditing = url.includes('edit');
        if (this.isRehabCenter) {
            const url = window.location.href;
            const segments = url.split('/');
            this.id = segments.pop();
        } else {
            this.id = this.editingOrgId;
        }

        this.conceptRehabURL = `/${ROUTER_UTILS.config.admin.organization.root}/${ROUTER_UTILS.config.admin.organization.conceptRehabPartners}/${this.id}`;

        this._organizationService.getOrganizationFacilitiesById(this.id).subscribe((res) => {
            this.facilitiesOfOrganization = [];
            res?.data?.facility?.forEach((facility) => {
                this.facilitiesOfOrganization.push(facility?._id);
            });
            this.npcList = res?.data?.npc.filter(item => !item?.isDeleted);
            this.loading = false;
            if (this.npcList?.length) {
                this.populateFormWithNPCList();
            } else {
                this.addNPC();
            }
        });
    }

    addActivities() {
        this.activities = true;
        this.isEditMode = true;
        const allDeleted = this.rowForms.value.every((item) => item.isDeleted);
        if (!this.rowForms?.controls?.length || allDeleted) {
            this.addNPC();
        }
    }
    populateFormWithNPCList(): void {
        this.npcList.forEach((item) => {
            if (!item?.isDeleted) {
                this.addRow(item);
            }
        });
    }

    get rowForms() {
        return this.form.get('rows') as FormArray;
    }

    createRow(data?: any): FormGroup {
        return this.fb.group({
            activity: data?.activity ? data.activity : '',
            unit: data?.unit ? data.unit : '',
            billable: data?.billable,
            rate: data?.rate ? data.rate : '',
            activityId: data?.activityId ? data.activityId : uuidv4(),
            isDeleted: data?.isDeleted || false,
            _id: data?._id ? data?._id : ''
        });
    }

    addRow(data?: any): void {
        const row = this.createRow(data);
        this.rowForms.push(row);
    }

    addNPC() {
        if (this.isEditMode || !this.rowForms.value?.length) {
            this.rowForms.push(this.createRow());
        } else {
            this.isEditMode = !this.isEditMode;
        }
    }

    deleteNPC(index: number) {
        const control = this.rowForms.at(index);
        if (control) {
            control.patchValue({ isDeleted: true });
        }

        if (!this.rowForms.value?.some((form: any) => !form.isDeleted)) {
            this.activities = false;
        }
    }

    toggleBillable(row: FormGroup) {
        const currentBillable = row.get('billable').value;
        row.get('billable').patchValue(!currentBillable);
    }

    isMandatoryFieldsFilled() {
        const npcData = this.form.controls.rows.value;
        const isValid = this.npcValidation(npcData);
        return !isValid;
    }

    isDataSame() {
        const npcData = this.form.controls.rows.value;
        const nonDeletedFormNpc = npcData?.filter((act)=> !act?.isDeleted);
        const nonDeletedNpc = this.npcList?.filter((act)=> !act.isDeleted);
        for (let i = 0; i < this.npcList.length; i++) {
            const npcItem = this.npcList[i];
            const rowFormItem = npcData[i];
            if(rowFormItem.rate == 0 && rowFormItem.billable){
                return true;
            }

            if (
                npcItem.activity !== rowFormItem.activity ||
                npcItem.billable !== rowFormItem.billable ||
                npcItem.rate !== rowFormItem.rate ||
                npcItem.unit !== rowFormItem.unit ||
                npcItem.isDeleted !== rowFormItem.isDeleted ||
                nonDeletedNpc?.length !== nonDeletedFormNpc?.length
            ) {
                return false;
            }
        }
        return true;
    }

    openConfirmationModal() {
        const npcData = this.form.controls.rows.value;
        const isValid = this.npcValidation(npcData);
        const isUnique = this.checkDuplicatedData(npcData);

        if (!isUnique) {
            this.toasterService.show({ title: 'Failure', body: 'Duplicated Activity Names', type: 'error' });

            return;
        }

        if (isValid) {
            if (!this.facilitiesOfOrganization?.length) {
                this.saveUpdateNPC(npcData);
                return;
            }
            const dialogRef = this.modalService.open(DeletionConfirmationDialogComponent, {
                data: {
                    heading: 'Confirmation',
                    message:
                        'By changing data, it will also change the data in all the facilities associated with this organization.',
                    button1Text: 'Confirm',
                },
            });
            dialogRef.afterClosed().subscribe((data) => {
                if (data) {
                    this.saveUpdateNPC(npcData);
                }
            });
            return;
        } else {
            this.toasterService.show({ title: 'Failure', body: 'Please Fill Mandatory Fields', type: 'error' });
            return;
        }
    }
    checkDuplicatedData(npcData: any) {
        let isValid = true;
        const activitiesSet = new Set<string>();
        npcData.forEach((element) => {
            if (!element.isDeleted) {
                if (activitiesSet.has(element.activity?.toLowerCase())) {
                    isValid = false;
                    return isValid;
                }
                activitiesSet.add(element.activity?.toLowerCase());
            }
        });
        return isValid;
    }

    saveUpdateNPC(data) {
        data = this.enableNPC(data);
        const payload = {
            data,
            organizationId: this.id,
            facilitiesOfOrganization: this.facilitiesOfOrganization,
        };
        this.deleteId(data);
        this.isDataSaving = true;
        this._organizationService
            .saveNonPatientCare(payload)
            .pipe(take(1))
            .subscribe(
                (result: any) => {
                    this.isDataSaving = false;

                    if (result.success) {
                        this.toasterService.show({ title: 'Success', body: result.message, type: 'success' });
                        this.npcList = cloneDeep(result.updatedData.result);
                        this.activities = this.npcList.length ? true : false;
                    } else {
                        this.toasterService.show({ title: 'Failure', body: result.message, type: 'error' });
                    }
                    this.isEditMode = false;
                },
                (error) => {
                    this.toasterService.show({ title: 'Failure', body: error, type: 'error' });
                }
            );
        const navigationExtras: NavigationExtras = {
            state: {
                organizationId: this._organizationService.organizationId,
            },
        };
        this.router.navigateByUrl(this.conceptRehabURL, navigationExtras);
    }

    /**
     * This function is deleting _id as its not necessary in the payload
     * @param data
     */
    deleteId(data: any) {
        data.forEach(element => {
            delete element?._id;
        });
    }

    /**
     * 1- This function will add enable as true key while adding NPC to Facility and Organization
     * 2- Also as we are deleting ativities only from frontend
     * if the activity is saved already it will check that by _id as the saved activity will have _id
     * if an activity has isDeleted true and _id then it will only update its isDeleted property
     */
    enableNPC(data: any) {
        data = data.filter((item) => {

        
            item.enable = true;

            if (item.isDeleted && item._id.length) {
                return item;
            }

            if (!item.isDeleted) {
                return item;
            }
         

           
        })
        return data;
    }

    npcValidation(data): boolean {
        let isValid = true;
        data?.forEach((item) => {
            if (!item.isDeleted) {
                const { activity, unit, billable, rate } = item;
                if (!activity || !unit) {
                    isValid = false;
                    return;
                }
                if (billable && !rate) {
                    isValid = false;
                    return;
                }
            }
        });
        return isValid;
    }

    validateInput(event: any) {
        const input = event.target.value;
        const regex = /^[0-9]*$/;
        if (!regex.test(input)) {
            event.target.value = input.slice(0, -1);
        }
    }
    handleCancel() {
        //temp fix
        let length = this.rowForms?.value?.length;
        let index = 0;
        while (index < length) {
            let found = false;
            this.npcList.forEach((npc) => {
                if (npc.activity === this.rowForms.value[index]?.activity) {
                    found = true;
                }
            });

            if (!found) {
                this.rowForms.removeAt(index);
                length = this.rowForms?.value?.length;
            } else {
                index++;
            }
        }

        if (this.isRehabCenter) {
            this.isEditMode = false;
        } else {
            this.goBack.emit(true);
        }
    }

    skipNow() {
        this.router.navigateByUrl(this.conceptRehabURL);
    }

    backButton() {
        this.goBack.emit(true);
    }

    getUnit(unit: string): string {
        const words = unit.split(' ');

        if (words.length === 1) {
            return 'per ' + unit;
        }
        return unit.replace('By', 'per');
    }

    getPlaceholderText(item): string {
        return item.get('billable').value ? 'Enter Rate' : 'Non-Billable';
    }

    resetInputValue(item) {
        if (!item.get('billable').value) {
            // Reset the value of the input field
            item.get('rate').setValue('');
        }
    }
}
