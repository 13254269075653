import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DIALOG_DATA } from '@app/helpers/dialog-tokens';
import { DailyNote, DetailedCptCodes, Exercises, TherapyAdmission } from '@app/interfaces';
import { DialogRef, CptCodesService, DailyNotesService, TherapyAdmissionService } from '@app/services';
import { Button } from '@app/shared/toggle/toggle.component';
import { GridApi, GridOptions } from 'ag-grid-community';
import { RowClickedEvent } from 'ag-grid-community';
import { catchError, debounceTime, of, Subject, Subscription } from 'rxjs';
import { ToasterService } from '@app/services';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import * as uuid from 'uuid';
import IMask from 'imask';
import { ADD_CPT_STYLES } from '@app/helpers/constants/documentation-report';

@Component({
    selector: 'app-add-cpt',
    templateUrl: './add-cpt.component.html',
    styleUrls: ['./add-cpt.component.scss'],
})
export class AddCptComponent implements OnInit, OnDestroy, AfterViewInit {
    private gridApi!: GridApi;

    public cptMinutes: number;
    public justification: string;
    tooltip: boolean;
    tooltipMsg: string;
    timeMasks: {
        minutes: Pick<IMask.MaskedNumber, 'min'> & { mask: NumberConstructor };
    } = {
        minutes: {
            mask: Number,
            min: 0,
        },
    };
    units: number;
    loading = false;
    heading: string;
    options: string[];
    selectedOption: any;
    procedureView = 'Procedures';
    gridOptions: GridOptions;
    searchLoading = false;
    selectedData = [];
    disableSaveButton: boolean;
    cptCols = [
        {
            field: 'code',
            headerName: 'Code',
            maxWidth: 120,
            cellRenderer: this.customCheckboxRenderer,
        },
        {
            field: 'description',
            headerName: 'Description',
            cellRenderer: (params) => {
                let badge = '';
                if (params.data.isTeleHealthEligible && this.cptCodeService.getTeleHealthVisit()) {
                    badge += `<span style='${ADD_CPT_STYLES.BADGE}'>
                        <img style="${ADD_CPT_STYLES.IMG}" src="assets/icons/tele-health.svg"/>
                        <label style="${ADD_CPT_STYLES.LABEL}">Telehealth Code</label>
                    </span>`;
                    return `<span style="${ADD_CPT_STYLES.BLOCK}">${params.data.description}${badge}</span>`;
                }
                return `<span style="${ADD_CPT_STYLES.BLOCK} opacity:${params.data.isDisabled ? 0.7 : 1}">${
                    params.data.description
                }</span>`;
            },
        },
    ];
    customCheckboxRenderer(params: any) {
        const inputTypeAndClass = 'class="checkbox" type="checkbox"';
        let checkboxHtml;
        if (params.data.isSelected && params.data.isDisabled) {
            checkboxHtml = `<input ${inputTypeAndClass} checked="true" disabled="true">`;
        } else if (params.data.isSelected && !params.data.isDisabled) {
            checkboxHtml = `<input ${inputTypeAndClass} checked="true">`;
        } else if (!params.data.isSelected && params.data.isDisabled) {
            checkboxHtml = `<input ${inputTypeAndClass} disabled="true">`;
        } else {
            checkboxHtml = `<input ${inputTypeAndClass}>`;
        }
        return `<div style="opacity:${
            params.data.isDisabled ? 0.7 : 1
        };" (click)="stopPropagation($event)">${checkboxHtml} <span class="ml-3">${params.data.code}</span></div>`;
    }

    stopPropagation(event: any) {
        // Prevent propagation to avoid selection when clicking on the checkbox
        event.stopPropagation();
    }
    cptData = []; // can contain few or full cpt data
    allCptData = []; // contains all cpt data
    buttons: Button[] = [
        { text: 'Procedures', isSelected: true },
        { text: 'Custom Procedures', isSelected: false },
    ];
    goals = [];
    seeMoreProcedures = [];

    // observables
    private queryChanged$: Subject<string> = new Subject<string>();

    private proceduresQuery: string;
    private subscriptions: Subscription[] = [];
    private DELAY = 500;
    private routeData: { doc: string; discipline: string } = {
        doc: null,
        discipline: null,
    };
    private currentTA: TherapyAdmission;
    private currentDN: DailyNote;
    private currentAdmissions: TherapyAdmission[];
    customProcedure: string;
    underSearchMsg: string;
    selectedProcedures = [];
    searchedProcedures = [];
    saveBtnStatus = false;
    goalsSelected = false;
    SEARCH_CHUNK_SIZE = 10;
    @ViewChild('timeDiv') timeDiv: ElementRef;
    @ViewChild('minutesInput') minutesInput: ElementRef;
    @ViewChild('seeMoreBtn') seeMoreBtn: ElementRef;

    isEditMode: boolean;
    allTherapyAdmissions: TherapyAdmission[];
    dailyNoteIndex: number;

    constructor(
        @Inject(DIALOG_DATA) private data,
        private ref: DialogRef,
        private cptCodesService: CptCodesService,
        private route: ActivatedRoute,
        private dailyNotesService: DailyNotesService,
        private toaster: ToasterService,
        private store: Store<{
            therapyAdmission: TherapyAdmission[];
        }>,
        private cptCodeService: CptCodesService,
        private therapyService: TherapyAdmissionService
    ) {
        this.isEditMode = data.isEditMode;
        this.gridOptions = {
            context: {
                componentParent: this,
            },
        };

        this.subscriptions.push(
            this.queryChanged$.pipe(debounceTime(this.DELAY)).subscribe((query) => {
                this.fetchProceduresCall(query);
            })
        );

        this.route.queryParamMap.subscribe({
            next: (params) => {
                this.routeData.doc = params.get('doc');
                this.routeData.discipline = params.get('discipline');
            },
        });

        this.subscriptions.push(
            this.store.select('therapyAdmission').subscribe((admissions: TherapyAdmission[]) => {
                if (!admissions || !admissions.length) {
                    return;
                }

                this.allTherapyAdmissions = JSON.parse(JSON.stringify(admissions));

                this.currentAdmissions = admissions;
                this.currentTA = this.therapyService.initCurrentTherapyAdmissionByIdQueryString(admissions);
                this.currentDN = this.currentTA.documentation.dailyNote?.find((elem) => elem.id === this.routeData.doc);
                this.dailyNoteIndex = this.currentTA.documentation.dailyNote?.findIndex(
                    (elem) => elem.id === this.routeData.doc
                );

                this.loadCPTData();
            })
        );
    }

    onRowClicked(event: any) {
        if (!event.node.data.isDisabled) {
            const node = event.node;
            const data = node.data;

            // Update the isSelected property on row click
            data.isSelected = !data.isSelected;

            if (data.isSelected) {
                this.selectedData.push(data);
            } else {
                this.selectedData = this.selectedData.filter((data) => data.isSelected);
            }

            // Get the grid's API and refresh the displayed data
            const api = event.api;
            api.refreshCells({ rowNodes: [node], force: true });
        }
    }

    // Disabled rows (Non TeleHealth) should not get selected
    getRowStyle(params: any) {
        return params.data.isDisabled ? { 'background-color': '#fff' } : null;
    }

    loadCPTData(): void {
        if (this.currentDN.objectiveAssessment?.skilledServices) {
            const { action } = this.data;
            const isTeleHealthVisit = this.cptCodeService.getTeleHealthVisit();

            this.cptData = this.currentDN.objectiveAssessment.skilledServices;

            if (action !== 'edit') {
                const services = this.cptData.map((all: any) => {
                    const test = this.currentDN.objectiveAssessment?.detailedCptCodes?.find(
                        (added) => added && added.code === all.cptCode
                    );
                    if (test?.code) {
                        return {
                            code: all.cptCode,
                            isSelected: true,
                            isDisabled: true,
                            description: all.description,
                            isTeleHealthEligible: all.isTeleHealthEligible,
                            isMds: all.isMds,
                        };
                    } else {
                        return {
                            code: all.cptCode,
                            isSelected: false,
                            isDisabled: isTeleHealthVisit && !all.isTeleHealthEligible,
                            description: all.description,
                            isTeleHealthEligible: all.isTeleHealthEligible,
                            isMds: all.isMds,
                        };
                    }
                });

                const teleHealth = services.filter((cpt) => cpt.isTeleHealthEligible);
                const nonTeleHealth = services.filter((cpt) => !cpt.isTeleHealthEligible);

                // sorting data, putting telehealth codes above and non telehealth down
                this.cptData = [...teleHealth, ...nonTeleHealth];
            }

            this.allCptData = [...this.cptData];
        }
    }

    loadGoals(): void {
        if (this.currentDN.objectiveAssessment?.goals?.length) {
            const goals = this.currentDN.objectiveAssessment.goals;

            this.goals = goals.map((item: any) => {
                const mappedGoal: any = {
                    isCollapsed: true,
                    isSelected: false,
                };

                mappedGoal.title = item.goal;
                mappedGoal.clof = item.clof.levelOfFunction;
                mappedGoal.plof = item.plof.levelOfFunction;
                mappedGoal.stg = [];

                // Will always have stg at no more than first level of obj
                if (item.stgs && item.stgs.length) {
                    mappedGoal.stg = item.stgs.map((stage) => {
                        return {
                            title: stage.goal,
                            clof: stage.clof.levelOfFunction,
                            plof: stage.plof.levelOfFunction,

                            isSelected: false,
                        };
                    });
                }

                return mappedGoal;
            });
        }
    }

    ngOnInit(): void {
        this.heading = this.data.heading;
        this.options = this.data.options ?? ['Progress', 'UPOC', 'Re-Cert', 'Discharge', 'Addendum'];

        const { action, code } = this.data;
        if (action === 'edit') {
            this.populateData(code);
        }
    }

    ngAfterViewInit() {
        this.scrollIfNeeded();
    }

    onClose() {
        this.ref.close();
    }
    onSave() {
        console.log('Saving...');
        this.onClose();
    }

    scrollIfNeeded() {
        if (this.data.scrollTo && this.data.scrollTo === 'time') {
            this.timeDiv.nativeElement.scrollIntoView({
                behavior: 'smooth',
            });
            setTimeout(() => {
                this.minutesInput.nativeElement.focus();
            }, 500);
        }
    }

    populateData(code) {
        const currentData = this.currentDN.objectiveAssessment.detailedCptCodes.find((elem) => elem.code === code);
        this.selectedData = [{ code: currentData.code, isSelected: true }]; // selecting the current cptCode
        this.selectedProcedures = [...currentData.exercises];
        this.justification = currentData.justification;
        this.cptMinutes = currentData.cptMinutes === 0 ? null : currentData.cptMinutes;

        this.markSelectedGoals(currentData);
        this.evaluateBtnState();
    }

    markSelectedGoals(currentData) {
        const goalsIndex = {};
        currentData.goals.forEach((elem) => {
            goalsIndex[elem.goal] = true;
        });

        this.goals.forEach((goal) => {
            if (goalsIndex[goal.title]) {
                goal.isSelected = true;
            }

            if (goal?.stg?.length) {
                goal.stg.forEach((elem) => {
                    if (goalsIndex[elem.title]) {
                        elem.isSelected = true;
                        goal.isCollapsed = false;
                    }
                });
            }
        });
    }

    setSelected(event: RowClickedEvent) {
        try {
            if (!event || !event.data) {
                console.log('event undefined');
                return;
            }

            const { data } = event;
            if (!data) {
                console.log('data undefined');
                return;
            }

            this.selectedOption = { ...data };
            this.selectedData = [this.selectedOption];
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { code: updatedCode, isSelected } = data;

            /**
             * Set all to false except selected option.
             * Need to do in both arrays because when resetting,
             * the selected option will be different
             */
            this.cptData = this.cptData.map((item) => {
                item.isSelected = item.code === updatedCode;
                return item;
            });
            this.allCptData = this.allCptData.map((item) => {
                item.isSelected = item.code === updatedCode;
                return item;
            });
        } catch (error) {
            console.log('ERR: setSelected', error);
        }
    }

    onFirstDataRendered() {
        // console.log('params', params);
    }

    searchEvent(value: string) {
        if (!value) {
            this.cptData = [...this.allCptData];
            return;
        }

        const matchingResults = this.allCptData.filter(
            (row) => row.code.includes(value) || row.description?.toLowerCase()?.includes(value.toLowerCase())
        );

        this.cptData = [...matchingResults];
    }

    resetSelectedData() {
        // on resetting cptCode
        this.cptData = [...this.allCptData];
        this.selectedOption = null;
        this.selectedData = [];
        this.proceduresQuery = '';
        this.selectedProcedures = [];
        this.searchedProcedures = [];
        this.saveBtnStatus = false;
        this.unSelectGoals();
        this.customProcedure = null;
        this.justification = null;
        this.seeMoreProcedures = [];
        this.cptMinutes = null;
        this.units = 0;
    }

    searchProcedures(): void {
        this.seeMoreProcedures = [];
        this.queryChanged$.next(this.proceduresQuery);
    }

    fetchProceduresCall(query: string) {
        this.underSearchMsg = 'searching...';
        const cptCodeObj = this.selectedData[0];
        if (!cptCodeObj || !cptCodeObj.code) {
            return;
        }

        const cptCode = cptCodeObj.code;

        this.cptCodesService
            .searchExercisesInCptCode(query, cptCode)
            .pipe(catchError((err) => of({ error: err })))
            .subscribe((results) => {
                if (results.error) {
                    this.underSearchMsg = 'Failed to fetch records!';
                    this.searchedProcedures = [];
                    return;
                }

                if (!results) {
                    this.searchedProcedures = [];
                    return;
                }

                if (results.message === 'type more chars') {
                    this.underSearchMsg = 'Please type more characters...';
                    this.searchedProcedures = [];
                    return;
                }

                // checks search results if they were already selected
                results?.forEach((result) => {
                    const exists = this.selectedProcedures.find((elem) => elem._id === result._id);
                    result.checked = exists ? true : false;
                });

                this.seeMoreProcedures = [...results];
                this.searchedProcedures = [];
                this.applySeeMoreFilter();
                if (results.length === 0) {
                    this.underSearchMsg = `No Records found for '${query}' in code '${this.selectedData[0].code}'`;
                }
            });
    }

    applySeeMoreFilter(): void {
        if (!this.seeMoreProcedures.length) {
            return;
        }
        if (this.seeMoreBtn?.nativeElement) {
            this.seeMoreBtn.nativeElement.scrollIntoView({
                behavior: 'smooth',
            });
        }
        this.searchedProcedures = [
            ...this.searchedProcedures,
            ...this.seeMoreProcedures.slice(0, this.SEARCH_CHUNK_SIZE),
        ];
        this.seeMoreProcedures = this.seeMoreProcedures.slice(this.SEARCH_CHUNK_SIZE, this.seeMoreProcedures.length);
    }

    procedureClicked(item: Exercises, event: any) {
        if (!event || !event.target) {
            console.log('ERR: failed to get event');
            return;
        }

        this.selectedProcedures = event.target.checked
            ? [...this.selectedProcedures, item]
            : [...this.selectedProcedures.filter((elem) => elem._id !== item._id)];

        // update checked in results
        const existsInResults = this.searchedProcedures.find((result) => result._id === item._id);
        if (existsInResults) {
            // intentional update by ref
            existsInResults.checked = event.target.checked;
        }

        this.evaluateBtnState();
    }

    ngOnDestroy(): void {
        this.subscriptions.map((sub: Subscription) => sub.unsubscribe());
    }

    evaluateBtnState() {
        const requiredFields =
            !!this.selectedProcedures.length &&
            this.selectedData[0] &&
            this.selectedData[0].code &&
            !!this.getSelectedGoals().length &&
            !!this.justification?.length &&
            this.units !== 0;

        this.saveBtnStatus = requiredFields;
    }

    saveClicked(): void {
        // Disable the save button to prevent multiple clicks
        this.disableSaveButton = true;

        const procedures: Exercises[] = this.removeChecked(this.selectedProcedures);

        const payload: any = cloneDeep(this.currentDN);
        payload.documentation_id = this.currentTA.documentation.id;

        if (!payload) {
            console.log('ERR: unable to get current Daily Note');
            this.disableSaveButton = false; // Enable the save button again
            return;
        }

        if (!payload.objectiveAssessment) {
            payload.objectiveAssessment = {};
        }

        this.selectedData.forEach((item) => {
            const newRecord = {
                code: item.code,
                description: item.description,
                exercises: procedures,
                goals: this.getSelectedGoals(),
                cptMinutes: this.cptMinutes,
                justification: this.justification,
                isTeleHealthEligible: item.isTeleHealthEligible,
                isMds: item.isMds,
            };

            if (!payload.objectiveAssessment.detailedCptCodes) {
                payload.objectiveAssessment.detailedCptCodes = [];
            }

            const foundIndex = payload.objectiveAssessment.detailedCptCodes.findIndex(
                (record: DetailedCptCodes) => record.code === item.code
            );

            if (foundIndex !== -1) {
                payload.objectiveAssessment.detailedCptCodes[foundIndex] = newRecord;
            } else {
                payload.objectiveAssessment.detailedCptCodes.push(newRecord);
            }
        });

        if (this.isEditMode) {
            const currentTA = JSON.parse(JSON.stringify(this.currentTA));
            currentTA.documentation.dailyNote[this.dailyNoteIndex].objectiveAssessment.detailedCptCodes =
                payload['objectiveAssessment']['detailedCptCodes'];
            this.dailyNotesService.prepareDataForEdit(
                'objectiveAssessment',
                'detailedCptCodes',
                payload.objectiveAssessment.detailedCptCodes,
                'array',
                this.allTherapyAdmissions,
                currentTA,
                this.dailyNoteIndex
            );
            this.onClose();
        } else {
            this.dailyNotesService.updateDailyNote(payload, this.currentAdmissions).subscribe((result) => {
                if (!result) {
                    this.disableSaveButton = false; // Enable the save button again
                    return;
                }
                this.toaster.show({
                    title: 'Successful',
                    body: 'CPT Codes added to list.',
                    type: 'success',
                });
                this.onClose();
                this.disableSaveButton = false; // Enable the save button again
            });
        }
    }

    removeChecked = (arr: any[]): Exercises[] =>
        arr.map((elem) => {
            delete elem['checked'];
            return elem;
        });

    getSelectedGoals = () => {
        const selectedGoals = [];

        this.goals.forEach((elem) => {
            if (elem.isSelected) {
                selectedGoals.push({
                    goal: elem.title,
                    clof: elem.clof,
                    plof: elem.plof,
                    type: 'LTG',
                });
            }

            if (elem.stg.length) {
                elem.stg.forEach((elem) => {
                    if (elem.isSelected) {
                        selectedGoals.push({
                            goal: elem.title,
                            clof: elem.clof,
                            plof: elem.plof,
                            type: 'STG',
                        });
                    }
                });
            }
        });
        return selectedGoals;
    };

    goalsChanged(item: any, event: boolean) {
        item.isSelected = event;
        this.evaluateBtnState();
        this.goalsSelected = !!this.getSelectedGoals().length;
    }

    unSelectGoals() {
        this.goals.map((goal) => {
            goal.isSelected = false;

            if (goal?.stg?.length) {
                goal.stg.forEach((elem) => {
                    elem.isSelected = false;
                });
            }
        });
    }

    addCustomProcedure() {
        const containsOnlySpaces = /^\s*$/.test(this.customProcedure);

        if (this.customProcedure && !containsOnlySpaces) {
            this.selectedProcedures.push({
                _id: uuid.v4(),
                description: this.customProcedure,
                frequency: 0,
                checked: true,
            });
        }

        this.customProcedure = null;
        this.evaluateBtnState();
    }

    justificationChanged(event) {
        this.justification = event;
        this.evaluateBtnState();
    }

    /**
     * 1) apply appropriate mask
     * 2) update ts variable
     * 3) update units
     */
    onTimeChanged(event: any, attribute: string) {
        const mask: IMask.InputMask<any> = IMask(event.target, this.timeMasks[attribute]);

        // to avoid 'NaN' console warning
        const value = mask.value ? mask.value : '0';

        this.cptMinutes = parseInt(value, 10);

        // show '00' placeholder if '0' is input
        this.cptMinutes = this.cptMinutes === 0 ? null : this.cptMinutes;

        this.setTooltipIfNeeded();

        this.evaluateBtnState();
    }

    setTooltipIfNeeded() {
        this.tooltip = false;

        if (this.units === 0) {
            this.tooltip = true;
            this.tooltipMsg = 'At least a single unit is required to save. Please increase minutes.';
        }
    }
}
