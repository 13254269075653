import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { SocketEvents } from '@app/helpers/constants';
import { ToasterService } from './toaster.service';

@Injectable({
    providedIn: 'root',
})
export class SocketService {
    constructor(private socket: Socket, private authService: AuthService, private toasterService: ToasterService) {
        this.sendMessage(SocketEvents.IDENTITY, this.authService.accountValue?.user?.id);
        this.getEvent(SocketEvents.FORCE_LOGOUT).subscribe((event) => {
            if (event && event.message) {
                this.toasterService.show({
                    title: 'Session terminated',
                    body: event.message,
                    type: 'info',
                });
            }
            this.authService.logout();
        });
        this.socket.on('connect', () => {
            console.log('Socket connected ...');
        });

        this.socket.on('disconnect', (reason: string) => {
            console.log(`Socket disconnected,,,: ${reason}`);
        });

        this.socket.on('reconnect_attempt', () => {
            console.log('Attempting to reconnect,,,');
        });

        this.socket.on('reconnect....', (attemptNumber: number) => {
            console.log(`Reconnected after ${attemptNumber} attempts`);
        });
    }
    sendMessage(event: string, message: any) {
        this.socket.emit(event, message);
    }
    getEvent(eventName: string) {
        return this.socket.fromEvent(eventName).pipe(map((data: any) => data));
    }
    removeAllListeners(eventName: string) {
        this.socket.removeAllListeners(eventName);
    }
}
