export enum TIME {
    hours = 6,
    minutes = 0,
    seconds = 0,
}

export function setHours(date: string | Date): Date | null {
    if (date) {
        return new Date(new Date(date).setHours(TIME.hours, TIME.minutes, TIME.seconds));
    } else return null;
}