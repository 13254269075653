<div class="sticky-top" style="top: 76px !important; z-index: 1">
    <span class="body-medium-semibold text-secondary mb-5">Quick Links</span>
    <nav class="mt-3">
        <div
            *ngFor="let link of links"
            [ngClass]="{ selected: link == selectedLink, required: validityTest(link), valid: validClass(link) }"
            class="py-2 px-3 body-small-semibold link-item text-secondary"
        >
            <a (click)="jumpLink(link); scroll(link.el)">
                {{ link.label }}
                <span *ngIf="link?.required && !validClass(link)"> *</span>
            </a>
        </div>
    </nav>
</div>
