export class User {
    id: string;
    therapist?: Therapist;
    firstName: string;
    lastName: string;
    email: string;
    title: string;
    policy: string;
    roles: any[];
    rightPolicies: string[];
    facility: string;
    selectedFacility: string;
    organization: string;
    createdAt?: Date;
    employeeId?: string;
    userRoles?:  any[];
    _id?: string;
}

export interface Therapist {
    id: string;
    discipline: string;
    therapistType: string;
    licensureNumber: string;
    signCredentials: string;
}

export interface ActivateOrDeactivate {
    id: string;
    isActive: boolean;
}

export enum USER_TYPES {
    PHYSICIAN = 'PHYSICIAN',
    THERAPIST = 'THERAPIST',
    NONE = 'NONE',
    OTHER = 'OTHER'
}
