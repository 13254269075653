<!-- <div id="rounded-modal" class="card text-secondary body-medium" [ngStyle]="{ 'min-height': minHeight }">
    <div class="d-flex mt-2">
        <p
            style="position: absolute; left: 24px; top: 8px"
            class="col-11 pl-0 h5-semibold"
            [ngbTooltip]="showTooltip ? heading : false"
        >
            {{ showTooltip ? (heading | stringLength: 30:30) : heading }}
        </p>
        <button
            style="position: absolute; right: 0; top: 0; height: 3.5em; width: 4em"
            class="btn btn-primary d-flex flex-row align-items-center justify-content-center modal-close-button"
            (click)="onClose()"
        >
            <span class="material-icons-outlined text-white-100" style="vertical-align: middle"> close </span>
        </button>
    </div>
    <hr class="horizontal-line" />
    <div class="mt-3 px-4 py-5" style="overflow-x: hidden; overflow-y: hidden; padding-bottom: 2vh !important">
        <ng-content></ng-content>
    </div>
</div> -->
<div class="modal-content custom-card text-secondary {{ wrapperClass }}" style="max-height: 90vh !important">
    <div class="modal-header header align-items-center">
        <p *ngIf="!isHeaderEditable" [ngbTooltip]="showTooltip ? heading : false" [ngClass]="headingClasses">
            {{ showTooltip ? (heading | stringLength: 30:30) : heading }}
        </p>
        <div *ngIf="isHeaderEditable">
            <input
                class="editable-header"
                [placeholder]="placeholder"
                [value]="heading"
                (keyup)="updateTitle($event)"
            />
        </div>
        <button
            *ngIf="showCross"
            class="btn btn-close d-flex flex-row align-items-center justify-content-center modal-close-button"
            [ngClass]="crossIconClasses"
            (click)="onClose()"
        >
            <span class="material-icons-outlined text-white-100" style="vertical-align: middle"> close </span>
        </button>
    </div>
    <div class="modal-body {{ paddingZero }}" [style]="inputStyle">
        <ng-content select="[body]"></ng-content>
    </div>
    <div
        *ngIf="showFooter"
        class="footer px-3 py-3 d-flex justify-content-end"
        [ngClass]="{ 'footer-border': showFooterDivider === true }"
    >
        <ng-content select="[footer]"></ng-content>
    </div>
</div>
