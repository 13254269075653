import { tabsReducer } from './tabs.reducer';
import { scheduleReducer, schedulerCalendarReducer, schedulerReducer } from './schedule.reducer';
import { userReducer } from './user.reducer';
import { caseloadCalendarReducer, patientReducer } from './patient.reducer';
import {
    cachedFacilityAdmissionReducer,
    facilityAdmissionReducer,
    pastFacilityAdmissionsReducer,
} from './facilityAdmission.reducer';
import { cachedTherapyadmissionReducer, therapyadmissionReducer } from './therapyAdmission.reducer';
import { plannerTherapyAdmissionsReducer } from './plannerTherapyAdmissions.reducer';
import { SLPReducer } from './slpComorbidity.reducer';
import { therapyDisciplineReducer } from './therapyDiscipline.reducer';
import { plannerStatesReducer } from './planner-state.reducer';
import { plannerPatientReducer } from './planner-patient.reducer';
import { payorInformationReducer } from './payorInformation.reducer';
import { payorRoutingReducer } from './payor-routing.reducer';
import { spinnerDocumentStatesReducer, spinnerStatesReducer, spinnerTherapyStatesReducer } from './spinner.reducer';
export const RootReducer = {
    addPatient: tabsReducer,
    schedule: scheduleReducer,
    scheduler: schedulerReducer,
    schedulerCalendar: schedulerCalendarReducer,
    user: userReducer,
    patient: patientReducer,
    caseloadCalendar: caseloadCalendarReducer,
    facilityAdmission: facilityAdmissionReducer,
    cachedFacilityAdmission: cachedFacilityAdmissionReducer,
    therapyAdmission: therapyadmissionReducer,
    cachedTherapyAdmission: cachedTherapyadmissionReducer,
    slpComorbidity: SLPReducer,
    therapyDiscipline: therapyDisciplineReducer,
    plannerState: plannerStatesReducer,
    plannerPatientState: plannerPatientReducer,
    payorInformation: payorInformationReducer,
    payorRouting: payorRoutingReducer,
    loadingSpinner: spinnerStatesReducer,
    documentLoadingSpinner: spinnerDocumentStatesReducer,
    therapyLoadingSpinner: spinnerTherapyStatesReducer,
    plannerTherapyAdmissions: plannerTherapyAdmissionsReducer,
    pastFacilityAdmissions: pastFacilityAdmissionsReducer,
};
