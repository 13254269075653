<div class="d-inline-flex flex-row justify-content-between align-items-center paddings">
    <app-go-back [title]="orgName"></app-go-back>
</div>

<div class="paddings">
    <div>
        <form [formGroup]="organizationForm">
            <div class="custom-card">
                <div class="header custom-header">
                    <p>General Information</p>

                    <img
                        loading="lazy"
                        class="cursor-pointer edit-icon"
                        [src]="isGeneralEditMode ? crossIcon : editIcon"
                        (click)="updateEditMode()"
                    />
                </div>
                <div class="body card-padding">
                    <div class="row justify-content-start">
                        <div class="col-5">
                            <div class="d-flex">
                                <div class="col-md-6 company-code" [ngClass]="{ 'non-edit-mode': !isGeneralEditMode }">
                                    <app-input
                                        placeholder="Type Here"
                                        type="text"
                                        label="Company Code"
                                        formControlName="companyCode"
                                    >
                                    </app-input>
                                </div>

                                <div
                                    class="col-md-6 status"
                                    [ngClass]="{
                                        'non-edit-mode': !isGeneralEditMode,
                                        'disable-select': !isGeneralEditMode
                                    }"
                                >
                                    <app-select
                                        formControlName="status"
                                        [options]="units"
                                        [label]="'Status'"
                                        [placeholder]="'Choose an Option'"
                                        [selectedValue]="status"
                                    ></app-select>
                                </div>

                                <div class="col-md-12 d-flex" formGroupName="address">
                                    <div
                                        class="col-md-6"
                                        [ngClass]="{
                                            'non-edit-mode': !isGeneralEditMode,
                                            'disable-select': !isGeneralEditMode
                                        }"
                                    >
                                        <app-input
                                            placeholder="Enter Zip Code"
                                            type="number"
                                            label="Zip Code"
                                            mask="00000"
                                            formControlName="zipCode"
                                        >
                                        </app-input>
                                    </div>

                                    <div
                                        class="organization-select col-md-6"
                                        [ngClass]="{
                                            'non-edit-mode': !isGeneralEditMode,
                                            'disable-select': !isGeneralEditMode
                                        }"
                                    >
                                        <app-select
                                            class="col-md-6 col-lg-4 col-xl-3"
                                            label="State"
                                            placeholder="Choose an Option"
                                            [options]="filteredStates"
                                            (change)="updateCounties($event.target.value, true)"
                                            formControlName="state"
                                        >
                                        </app-select>
                                    </div>

                                    <div
                                        class="organization-select col-md-5"
                                        [ngClass]="{
                                            'non-edit-mode': !isGeneralEditMode,
                                            'disable-select': !isGeneralEditMode
                                        }"
                                    >
                                        <app-select
                                            class="col-md-6 col-lg-4 col-xl-3"
                                            label="County"
                                            placeholder="Choose an option"
                                            [options]="mailingCounties"
                                            formControlName="county"
                                        >
                                        </app-select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-start">
                        <div class="second-row" >
                            <div
                                formGroupName="address"
                                class="d-flex align-items-center justify-content-around"
                                [ngClass]="{ 'non-edit-mode': !isGeneralEditMode }"
                            >
                                <div  class="phone" [ngClass]="{ 'non-edit-mode': !isGeneralEditMode }">
                                    <app-input
                                        placeholder="Enter Phone Number"
                                        type="text"
                                        label="Phone"
                                        formControlName="phone"
                                    >
                                    </app-input>
                                </div>

                                <div  class="fax" [ngClass]="{ 'non-edit-mode': !isGeneralEditMode }">
                                    <app-input placeholder="Enter Fax" type="text" label="Fax" formControlName="fax">
                                    </app-input>
                                </div>

                                <div
                                    class="company-code address ml-14"
                                    [ngClass]="{ 'non-edit-mode': !isGeneralEditMode }"
                                >
                                    <app-input
                                        placeholder="Enter Address"
                                        type="text"
                                        label="Address"
                                        formControlName="address"
                                    >
                                    </app-input>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>

        <div *ngIf="isGeneralEditMode" class="row p-3 justify-content-end">
            <button class="innova-btn btn-danger" (click)="isGeneralEditMode = false">Cancel</button>
            <button
                class="innova-btn btn-secondary ml-1"
                [disabled]="this.organizationForm?.invalid"
                (click)="saveUpdateOrganization()"
            >
                Save
            </button>
        </div>
    </div>
</div>

<div class="row toggle-btns paddings">
    <app-toggle [buttons]="buttons" (buttonChanged)="jumpToSection($event)"> </app-toggle>
</div>

<div class="row p-0 m-0 bg-white p-3 mt-2 px-4 npc-paddings">
    <div class="w-100">
        <div class="table-data" *ngIf="isFacilitiesTabSelected; else nonPatientCare">
            <ng-container *ngIf="!facilityListData.length && isListLoading; else facilityList">
                <div>
                    <br />
                    <ngx-skeleton-loader appearance="line" [theme]="SKELETON_CSS.CELL"></ngx-skeleton-loader>
                    <ngx-skeleton-loader appearance="line" [theme]="SKELETON_CSS.CELL"></ngx-skeleton-loader>
                    <ngx-skeleton-loader appearance="line" [theme]="SKELETON_CSS.CELL"></ngx-skeleton-loader>
                    <ngx-skeleton-loader appearance="line" [theme]="SKELETON_CSS.CELL"></ngx-skeleton-loader>
                    <ngx-skeleton-loader appearance="line" [theme]="SKELETON_CSS.CELL"></ngx-skeleton-loader>
                    <ngx-skeleton-loader appearance="line" [theme]="SKELETON_CSS.CELL"></ngx-skeleton-loader>
                    <ngx-skeleton-loader appearance="line" [theme]="SKELETON_CSS.CELL"></ngx-skeleton-loader>
                    <ngx-skeleton-loader appearance="line" [theme]="SKELETON_CSS.CELL"></ngx-skeleton-loader>
                    <ngx-skeleton-loader appearance="line" [theme]="SKELETON_CSS.CELL"></ngx-skeleton-loader>
                    <ngx-skeleton-loader appearance="line" [theme]="SKELETON_CSS.CELL"></ngx-skeleton-loader>
                    <ngx-skeleton-loader appearance="line" [theme]="SKELETON_CSS.CELL"></ngx-skeleton-loader>
                    <hr class="table-footer" />
                    <div class="pagination-loader">
                        <ngx-skeleton-loader [theme]="{ width: '10em', 'margin-right': '0' }"></ngx-skeleton-loader>
                        <ngx-skeleton-loader [theme]="{ width: '10em', 'margin-left': '5em' }"></ngx-skeleton-loader>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>

<ng-template #nonPatientCare>
    <app-non-patient-care
        [isRehabCenter]="true"
        [backTitle]="'Cancel'"
        [hideTitle]="true"
        [npcList]="NPCList"
    ></app-non-patient-care>
</ng-template>

<ng-template #facilityList>
    <div class="facility-header">
        <div class="content">List of Facilities</div>
        <div *ngIf="facilityListData.length" class="add-facility-icon">
            <button class="btn pt-0 mt-n2 shadow-none btn-sm" (click)="addNewFacility()">
                <span
                    class="material-icons-outlined text-secondary add-facility-icon"
                    ngbTooltip="Add New Facility"
                    placement="left"
                >
                    add_circle
                </span>
            </button>
        </div>
    </div>
    <div *ngIf="facilityListData.length; else noData">
        <app-facility-list [facilities]="facilityListData"></app-facility-list>
    </div>
</ng-template>

<ng-template #noData>
    <div class="d-flex flex-column align-items-center justify-content-between no-data-section">
        <div class="no-data">
            <h5 class="h5-semibold text-secondary no-facilities">No Facilities as of now</h5>
            <button (click)="addNewFacility()" class="innova-btn innova-btn-lg btn-secondary add-facility">
                Add Facilities
            </button>
        </div>
    </div>
</ng-template>
