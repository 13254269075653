import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
const baseUrl = environment.apiUrl + '/facility-admission';

@Injectable({
    providedIn: 'root',
})
export class ReportService {
    constructor(private http: HttpClient) { }

    getTripleCheck(payload: any): Observable<any[]> {
        const url = `${baseUrl}/triple-check-report`;
        return this.http.post<any>(url, payload).pipe(map((v) => v.data));
    }
    getMOP(payload: any): Observable<any[]> {
        const url = `${environment.apiUrl}/payor/mbp-report`;
        return this.http.post<any>(url, payload).pipe(map((v) => v.data));
    }
    getEOMReport(payload: any): Observable<any[]> {
        const url = `${baseUrl}/eom-report`;
        return this.http.post<any>(url, payload).pipe(map((v) => v.data));
    }
    getTripleCheckDetails(payload: any): Observable<any[]> {
        const url = `${baseUrl}/triple-check-report-details`;
        return this.http.post<any>(url, payload).pipe(map((v) => v.data));
    }
    getEOMDetails(payload: any): Observable<any[]> {
        const url = `${baseUrl}/eom-report-details`;
        return this.http.post<any>(url, payload).pipe(map((v) => v.data));
    }
}
