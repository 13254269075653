<div class="d-flex flex-row mt-3">
    <div class="col-6">
        <div class="custom-card">
            <div class="header">
                <p>Select Facilities</p>
            </div>
            <div class="body">
                <app-search-bar
                    type="multi"
                    (searchEvent)="facilitySearch($event)"
                    (removeEvent)="redrawRows($event)"
                    placeholder="Search"
                    id="facility-search-for-recipient"
                    style="width: 98%; display: block; margin: 0 auto"
                ></app-search-bar>
                <div style="height: calc(100vh - 520px)">
                    <app-data-grid
                        [columnDefs]="facilityCols"
                        [rowData]="facilityList"
                        [showCount]="false"
                        rowSelection="single"
                        (rowClicked)="facilitySelectionChanged($event)"
                        (selectedRowsEmitter)="rowSelection($event)"
                        (gridReady)="onGridReady($event)"
                    >
                    </app-data-grid>
                </div>
            </div>
        </div>
    </div>

    <div class="col-6">
        <div class="custom-card h-100">
            <div class="header">
                <p>Selected Facilities</p>
            </div>
            <div class="body pt-3">
                <div style="height: calc(100vh - 470px)">
                    <div *ngIf="selectedFacilities.length > 0" class="my-3 p-3 bg-white">
                        <div class="overflow-auto" style="max-height: calc(100vh - 514px)">
                            <span
                                *ngFor="let facility of selectedFacilities; let i = index"
                                class="badge rounded-pill bg-primary pl-3 pr-2 py-2 text-white mr-2 mb-2"
                            >
                                {{ facility?.name ? facility.name : facility?.facilityName }}
                                <span
                                    (click)="removeSelectedFacility(facility, i)"
                                    class="material-symbols-outlined font-16 align-middle cursor-pointer"
                                    >close</span
                                >
                            </span>
                            <div class="d-flex">
                                <div class="d-flex align-items-center" (click)="removeAll()">
                                    <span class="font-16 text-secondary clear-all-button">Clear All</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
