<div class="d-flex flex-row">
    <div class="align-self-center">
        <app-go-back title="Recipient management"></app-go-back>
    </div>
</div>
<div class="row p-3">
    <form class="w-100" [formGroup]="newUserForm">
        <div class="custom-card">
            <div class="header justify-content-between">
                <p>Name and Contact</p>
            </div>
            <div class="body">
                <div class="row p-3 input-field">
                    <div class="col-3">
                        <app-input
                            placeholder="Type here"
                            type="text"
                            label="First Name"
                            formControlName="firstName"
                        ></app-input>
                    </div>
                    <div class="col-3">
                        <app-input
                            placeholder="Type here"
                            type="text"
                            label="Last Name"
                            formControlName="lastName"
                        ></app-input>
                    </div>
                    <div class="col-3">
                        <app-input
                            placeholder="Type here"
                            type="text"
                            label="Title"
                            formControlName="title"
                        ></app-input>
                    </div>
                    <div class="col-3">
                        <app-input placeholder="Type here" label="Email" formControlName="email"></app-input>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

<div class="row p-3">
    <form class="w-100" [formGroup]="reportingPreferences">
        <div class="custom-card">
            <div class="header justify-content-between">
                <p>Reporting Preferences</p>
            </div>
            <div class="body">
                <div class="row p-3 input-field">
                    <div class="col-6">
                        <span class="align-items-center d-flex flex-row font-weight-bold text-secondary mb-1"
                            >Service log and reports</span
                        >
                        <div class="ml-n3">
                            <div class="d-flex">
                                <app-switch formControlName="serviceLogs"></app-switch>
                                <span>Service Log</span>
                            </div>
                            <div class="d-flex">
                                <app-switch formControlName="BillingExport"></app-switch>
                                <span>Billing Export</span>
                            </div>
                            <div class="d-flex">
                                <app-switch formControlName="UBO4Reports"></app-switch>
                                <span>UB-04 Report</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <span class="align-items-center d-flex flex-row font-weight-bold text-secondary mb-1"
                            >Invoice</span
                        >
                        <div class="ml-n3">
                            <div class="d-flex">
                                <app-switch formControlName="invoiceForSelectedFacility"></app-switch>
                                <span>Include invoice for selected facility</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

<div footer class="d-flex justify-content-end">
    <button class="innova-btn innova-btn-sm btn-danger" (click)="goBack()">Cancel</button>
    <button class="ml-1 innova-btn innova-btn-sm btn-secondary" (click)="AddNewRecipient()">
        {{ editMode ? 'Update' : 'Save' }}
    </button>
</div>
