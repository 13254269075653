import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
const baseUrl = environment.apiUrl + '/billing/adjustment';
@Injectable({
    providedIn: 'root',
})
export class AdjustmentService {
    constructor(private http: HttpClient) {}
    getRecipientById(id: string): Observable<any> {
        return this.http.get<any>(`${baseUrl}/${id}`);
    }
    createManualAdjustment(payload: any) {
        return this.http.post(`${baseUrl}/create`, payload, {
            withCredentials: true,
        });
    }
    getAdjustmentsByInvoiceNumber(invoiceNumber: any) {
        return this.http.get(`${baseUrl}/invoice/${invoiceNumber}`);
    }
}
