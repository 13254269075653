import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { FacilityManagementService } from './facilityManagement.service';
const baseUrl = environment.apiUrl + '/billing/recipient';
@Injectable({
    providedIn: 'root',
})
export class RecipientService {
    constructor(private http: HttpClient,private facilityService: FacilityManagementService,) {}

    updateRecipientInFacilities(selectedFacilities: any, removedFacilities: any, currentRecipient: any) {
        let facilities = [];
        this.facilityService._Facilities.subscribe((result) => {
            if (result.length > 0) {
                facilities = result;
            }
        });
        selectedFacilities?.forEach((facility) => {
            const found = facilities.findIndex((data) => data._id === facility.id);
            if (found != -1) {
                const index = facilities[found].recipients?.findIndex((recipient: any) => recipient._id == currentRecipient._id);
            if (index === -1 || index == undefined) {
                // If not present, push the recipient to the recipients array
                if(facilities[found].recipients === null) {
                    facilities[found].recipients = [];
                }
                facilities[found].recipients.push(currentRecipient);
            } 
            }
        });
        removedFacilities?.forEach((facility) => {
            const found = facilities.findIndex((data) => data._id === facility.id);
            if (found != -1) {
                const index = facilities[found].recipients?.findIndex((recipient: any) => recipient._id == currentRecipient._id);
                if (index > -1) {
                    facilities[found].recipients.splice(index, 1);
                }
            }
        });
        this.facilityService.updateFacilities(facilities);
        this.facilityService.mapFacilitiesIntoDropdown();
    }

    updateRecipientInFacility(data) {
        let facilities = [];
        this.facilityService._Facilities.subscribe((result) => {
            if (result.length > 0) {
                facilities = result;
            }
        });
        data?.forEach((facility) => {
            const found = facilities.findIndex((data) => data.id === facility._id);
            if (found != -1) {
                facilities[found].recipients = facility.recipients;
            }
        });
        this.facilityService.updateFacilities(facilities);
        this.facilityService.mapFacilitiesIntoDropdown();
    }



    getRecipientById(id: string): Observable<any> {
        return this.http.get<any>(`${baseUrl}/${id}`);
    }
    createRecipientProfile(payload: any) {
        return this.http.post(`${baseUrl}/create-recipient-profile`, payload, {
            withCredentials: true,
        });
    }
    updateRecipientProfileStatus(id: string, recipient: any) {
        return this.http.put(
            `${baseUrl}/update-recipient-profile-status/${id}`,
            { recipient },
            {
                withCredentials: true,
            }
        );
    }
    updateRecipientProfile(id: string, recipient: any) {
        return this.http.put(
            `${baseUrl}/update-recipient-profile/${id}`,
            { recipient },
            {
                withCredentials: true,
            }
        );
    }

    getRecipientByOrganizationId(organizationId: string) {
        return this.http.get(`${baseUrl}/get-recipient-by-organization/${organizationId}`);
    }
    deleteAndUnLinkRecipient(_id: any) {
        // eslint-disable-next-line spellcheck/spell-checker
        return this.http.post(`${baseUrl}/facility/remove-unlink-recipient/${_id}`,
            { _id },
            {
                withCredentials: true,
            }
        )
        
    }
}
