<div class="add-note-wrapper d-flex align-items-center">
    <div class="add-note-wrapper-inner d-flex {{ widthLarge }} overflow-hidden">
        <div class="day-box body-medium-semibold text-secondary">{{ day | titlecase }}</div>
        <textarea
            *ngIf="!editIcon"
            class="w-100 bg-body-background body-small note"
            type="text"
            [(ngModel)]="note"
            placeholder="Type notes here"
            row="1"
            maxlength="1000"
        ></textarea>
        <span *ngIf="editIcon" class="w-100 bg-body-background body-small note">
            {{ note }}
        </span>
    </div>
    <div class="ml-3 d-flex align-items-start {{ widthSmall }}">
        <span class="mr-2 cursor-pointer" (click)="deleteDayNote()">
            <img width="26px" height="26px" src="/assets/icons/close-icon.svg" alt="" />
        </span>
        <span *ngIf="!editIcon" class="save-comment-img cursor-pointer" (click)="saveDayNote()">
            <img
                [ngClass]="note ? 'enable' : 'disable'"
                width="26px"
                height="26px"
                src="/assets/icons/check-circle-icon.svg"
                alt=""
            />
        </span>
        <span *ngIf="editIcon" class="cursor-pointer" (click)="editIcon = false">
            <img width="26px" height="26px" src="/assets/icons/edit-icon.svg" alt=""
        /></span>
    </div>
</div>
