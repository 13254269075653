<div class="mt-3">
    <div class="custom-card bg-white" [class.top-card]="state === 'one'">
        <div class="header" *ngIf="!withOutHeader">
            <div class="row">
                <div class="col-md-6 d-flex">
                    <p class="text-secondary">{{ heading }}</p>
                    &nbsp;&nbsp;<span
                        *ngIf="additionalInfo"
                        class="material-symbols-outlined align-self-center text-secondary cursor-default"
                        ngbTooltip="{{ additionalInfo }}"
                    >
                        help
                    </span>
                </div>
                <div class="col-md-6">
                    <div #searchBar *ngIf="state === 'one'" class="d-flex align-items-center justify-content-end">
                        <div ngbDropdown class="d-inline-block" placement="bottom-right" *ngIf="isDone">
                            <button
                                class="innova-btn btn-secondary btn-sm d-flex flex-row justify-content-center align-items-center p-2 btn-circle"
                                id="dd-toggle"
                                placement="left"
                                ngbDropdownToggle
                                [disabled]="loadingObs"
                            >
                                <span class="material-symbols-outlined"> menu </span>
                            </button>
                            <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-list-cont">
                                <button
                                    [disabled]="
                                        (isDone === false && isReadOnly === true) ||
                                        disableAdd ||
                                        normalSelectedRows.length > 0
                                    "
                                    ngbDropdownItem
                                    class="nav-item nav-link"
                                    (click)="toggleIsDone()"
                                >
                                    Add Medical Diagnosis
                                </button>
                                <button
                                    [disabled]="normalSelectedRows.length <= 0"
                                    ngbDropdownItem
                                    class="nav-item nav-link"
                                    (click)="deleteSelectedData()"
                                >
                                    Delete Medical Diagnosis
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="body">
            <div *ngIf="state == 'zero'" class="d-flex flex-row justify-content-center align-items-center my-3">
                <div class="d-flex flex-column align-items-center justify-content-center body-small-semibold">
                    <p class="text-secondary">{{ heading ? 'No ' + heading + ' yet' : 'No Added Items' }}</p>
                    <app-button
                        *ngIf="isReadOnly === false"
                        (click)="setState('one')"
                        type="secondary"
                        [text]="'Add New'"
                        icon="add"
                        [disabled]="disableAdd"
                    ></app-button>
                </div>
            </div>
            <div *ngIf="state == 'one'">
                <app-search-bar
                    *ngIf="isDone === false && isReadOnly === false"
                    type="multi-pill"
                    placeholder="Search code"
                    (searchEvent)="searched($event)"
                    (removeEvent)="removeSelectedItem($event); isDone === false ? redrawRows() : null"
                    [selectedData]="allRecords"
                    [loading]="loading"
                    [disabled]="isDone"
                    [objectKey]="name === SEARCHABLE_LIST_NAMES.CPT_CODES ? 'cptCode' : 'code'"
                    [focusEvent$]="focusEvent.asObservable()"
                    (xEvent)="resetEverything()"
                    [dataGridSearch]="true"
                ></app-search-bar>
                <!-- ag grid table -->
                <div *ngIf="!isReadOnly">
                    <app-data-grid
                        domLayout="autoHeight"
                        [context]="{ componentParent: this }"
                        [columnDefs]="isDone && !disableAdd ? cols[name].recordCols : cols[name].editCols"
                        [rowData]="isDone === true ? selectedData : data"
                        [showCount]="false"
                        [rowClassRules]="isDone === false ? rowClassRules : null"
                        (rowSelected)="onRowSelected($event)"
                        (gridReady)="initGrid($event)"
                        [isSearchable]="true"
                        [loading]="true"
                        rowSelection="multiple"
                        (selectedRowsEmitter)="
                            isDone === false ? rowSelection($event) + redrawRows() : normalModeSelection($event)
                        "
                        (rowDataChanged)="onRowDataChanged($event)"
                        [overlayNoRowsTemplate]="true"
                    ></app-data-grid>
                    <div
                        *ngIf="data && data.length < paginate.total"
                        class="see-more-container d-flex justify-content-space-between align-items-center"
                    >
                        <div
                            #seeMoreBtn
                            class="pt-2 see-more-btn d-flex justify-content-center align-items-center"
                            style="margin: 0 auto"
                            (click)="fetchMoreRecords()"
                        >
                            <span class="material-symbols-outlined solid"> expand_more </span>
                            <button class="btn orange-color">See More ({{ data.length }}/{{ paginate.total }})</button>
                        </div>

                        <div class="d-flex mr-2" (click)="scrollToBar()">
                            <span class="material-symbols-outlined solid text-success cursor-pointer">
                                arrow_upward
                            </span>
                            <span class="ml-1 cursor-pointer">Top</span>
                        </div>
                    </div>
                </div>
                <div class="p-3 text-right" *ngIf="!isReadOnly && isDone === false && isReadOnly === false">
                    <app-button type="outline-secondary mr-3" text="Cancel" (click)="cancelAddition()"></app-button>
                    <app-button
                        type="secondary"
                        text="Save"
                        [disabled]="selectedData.length <= 0 || (isDone === true && isReadOnly === true)"
                        (click)="toggleIsDone()"
                    >
                    </app-button>
                </div>
                <div class="col-12" *ngIf="isReadOnly && recordsData.length > 0">
                    <app-data-grid
                        domLayout="autoHeight"
                        [columnDefs]="cols[name].readOnlyCols"
                        [showCount]="false"
                        [rowData]="recordsData"
                        (gridReady)="initGrid($event)"
                    >
                    </app-data-grid>
                </div>
            </div>
        </div>
    </div>
</div>
