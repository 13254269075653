import { DatePipe } from '@angular/common';
import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CheckIconCellRendererComponent, DiffCellRendererComponent } from '@app/data-grid/cell-renderers';
import { FacilityAdmission, PNChangeHistory, ProgressNote, TherapyAdmission } from '@app/interfaces';
import { DOCUMENTATION_CONSTANTS, Doc_Notes } from '@app/interfaces/documentation';
import { PrintData } from '@app/interfaces/print';
import { Account } from '@app/models';
import { SignNoteComponent } from '@app/modules/patients/PCC/pcc-tabs/pcc-documentation/modals/sign-note/sign-note.component';
import {
    AuthService,
    GoalService,
    ModalService,
    ProgressNoteService,
    StoreService,
    ToasterService,
} from '@app/services';
import { select, Store } from '@ngrx/store';
import { ColDef } from 'ag-grid-community';
import { format, parse } from 'date-fns';
import { cloneDeep } from 'lodash';
import { Subject, takeUntil } from 'rxjs';
import { prepareSimpleTextChangeLog } from '@app/helpers/utils';
import { scrollToElementWithOffset } from '@app/helpers/utils/scroll.helper';
import { isAllowedToCoSign, isUserTherapistAssistant } from '@app/helpers';
import { CoSignNoteComponent } from '@app/modules/patients/PCC/pcc-tabs/pcc-documentation/modals/co-sign-note/co-sign-note.component';
import { findTherapyCaseByParamId } from '@app/helpers/utils/therapy-case.utils';
import { TimeConfirmationDialogComponent } from '@app/shared/time-confirmation-dialog/time-confirmation-dialog.component';

@Component({
    selector: 'app-view-mod-progress-note',
    templateUrl: './view-mod-progress-note.component.html',
    styleUrls: ['./view-mod-progress-note.component.scss'],
})
export class ViewModProgressNoteComponent implements OnInit, OnDestroy {
    @Input() printMode = false;
    @Input() printData: PrintData;
    noteTitle = 'Progress Note';
    DOCUMENTATION_CONSTANTS = DOCUMENTATION_CONSTANTS;
    evalHistorySide: boolean;
    showCoSign = false;
    contentEl = document.getElementById('content');
    enableCoSignBtn = false;
    medicalDiagnosis: {
        title: string;
        cols: ColDef[];
        data: Array<object>;
    } = {
        title: 'Medical Diagnosis',
        cols: [
            { field: 'no', headerName: 'No.', width: 60, valueGetter: 'node.rowIndex + 1' },
            { field: 'code', headerName: 'Code', width: 100 },
            { field: 'description', headerName: 'Description', width: 300, wrapText: true, autoHeight: true },
            {
                field: 'pdpmClinicalCategory',
                headerName: 'PDPM Clinical Category',
                width: 300,
                wrapText: true,
                autoHeight: true,
            },
            {
                field: 'onsetDate',
                headerName: 'Onset Date',
                cellRenderer: (params) => {
                    return params.data.onsetDate ? this.transformDate(new Date(params.data.onsetDate)) : '-----';
                },
            },
            { field: 'slp', headerName: 'SLP Comorbidity' },
            { headerName: 'Primary DX', field: 'isPrimary', cellRenderer: CheckIconCellRendererComponent },
        ],
        data: [],
    };
    treatmentDiagnosis: {
        title: string;
        cols: ColDef[];
        data: Array<object>;
    } = {
        title: 'Treatment Diagnosis',
        cols: [
            { field: 'no', headerName: 'No.', width: 30, valueGetter: 'node.rowIndex + 1' },
            { field: 'code', headerName: 'Code', width: 100 },
            {
                field: 'description',
                headerName: 'Description',
                cellClass: ['body-medium', 'line-space'],
                autoHeight: true,
            },
        ],
        data: [],
    };
    precautions: { title: string; cols: ColDef[]; data: Array<object> } = {
        title: 'Precautions',
        cols: [
            { field: 'no', headerName: 'No.', width: 30, valueGetter: 'node.rowIndex + 1' },
            {
                field: 'description',
                headerName: 'Description',
                cellClass: ['body-medium', 'line-space'],
                autoHeight: true,
                cellRenderer: DiffCellRendererComponent,
            },
        ],
        data: [],
    };
    contraindications: {
        title: string;
        cols: ColDef[];
        data: Array<object>;
    } = {
        title: 'Contraindications',
        cols: [
            { field: 'no', headerName: 'No.', width: 30, valueGetter: 'node.rowIndex + 1' },
            {
                field: 'description',
                headerName: 'Description',
                cellClass: ['body-medium', 'line-space'],
                autoHeight: true,
                cellRenderer: DiffCellRendererComponent,
            },
        ],
        data: [],
    };
    assessmentLastWeek = {
        title: "Patient's self-report of their current status",
        paragraph: '',
    };
    goal: any = [];
    clinicalImpression: { title: string; paragraph: string } = {
        title: 'Clinical Impression',
        paragraph: '',
    };

    currentUpdatesTreatment: { title: string; paragraph: string } = {
        title: 'Overall plan and updates to treatment',
        paragraph: '',
    };

    addendum: { title: string; cols: ColDef[]; data: Array<object> } = {
        title: 'Addendum',
        cols: [
            {
                field: 'description',
                width: 900,
                headerName: 'Description',
                autoHeight: true,
                wrapText: true,
            },
            {
                field: 'userNameRole',
                headerName: 'User name & Role',
                cellRenderer: (params) => {
                    const keyData = params.data?.signed.signedBy[0];
                    return this.userNameFormatter(keyData);
                },
                autoHeight: true,
                width: 250,
            },
            {
                field: 'date',
                headerName: 'Date & Time',
                valueFormatter: (params) => {
                    const completionDate = params.data?.signed?.signDate;
                    const completionTime =
                        ('0' + new Date(completionDate).getHours()).slice(-2) +
                        ':' +
                        ('0' + new Date(completionDate).getMinutes()).slice(-2);
                    return completionDate
                        ? this.transformDate(new Date(completionDate)) + ' ' + completionTime
                        : '-------';
                },
            },
        ],
        data: [],
    };

    dateFormat(date): string {
        const d = parse(date, 'yyyy-MM-dd', new Date());
        return format(d, 'MMMM, dd h:mm a');
    }

    private transformDate = (date) => {
        if (!date) {
            return '';
        }
        return this.datePipe.transform(new Date(date), 'MM/dd/yyyy');
    };
    userNameFormatter(user: any) {
        return user?.roles
            ? `<p class="name mb-0" >${user.lastName + ', ' + user.firstName}</p><span>${user.roles.map(
                  (role) => role.name
              )}</span>`
            : `<p class="name mb-0" >${
                  this.user.user.lastName + ', ' + this.user.user.firstName
              }</p><span>${this.user.user.roles.map((role) => role.name)}</span>`;
    }
    private readonly onDestroy = new Subject<void>();
    ngOnDestroy(): void {
        this.onDestroy.next();
        this.contentEl.removeEventListener('scroll', this.divScroll, true);
    }
    currentAdmission: TherapyAdmission;
    therapyAdmissions: TherapyAdmission[];
    therapyDiscipline: string;
    medicalDiagnosisData: any[] = [];
    progressNoteId: string;
    progressNote: ProgressNote;
    admissionDischarged = false;
    loadingObs;
    user: any;
    currentAddendum: string;
    changeHistory: PNChangeHistory[];
    selectedChangeInstance = 0;
    selectedLabel: string;
    therapistDiscipline: any;
    isDifferentDisciplineTherapist = false;
    currentDocSigned = false;
    constructor(
        private route: ActivatedRoute,
        private storeService: StoreService,
        private datePipe: DatePipe,
        private modalService: ModalService,
        private authService: AuthService,
        private toasterService: ToasterService,
        private store: Store<{
            therapyAdmission: TherapyAdmission[];
            facilityAdmission: FacilityAdmission;
            user: Account;
        }>,
        private goalService: GoalService,
        private PNService: ProgressNoteService
    ) {
        this.loadingObs = this.storeService.getLoadingState();
        this.store
            .select('facilityAdmission')
            .pipe(takeUntil(this.onDestroy))
            .subscribe((data) => {
                if (data.patientDischarge) {
                    this.admissionDischarged = true;
                }
            });
        this.route.queryParamMap.subscribe({
            next: (params) => {
                this.therapyDiscipline = params.get('discipline');
                this.progressNoteId = params.get('doc');
            },
        });
        this.store.pipe(select('user')).subscribe((user) => {
            if (user) {
                this.user = user;
            }
        });
        this.PNService.getPNEditHistory(this.progressNoteId).subscribe({
            next: (result) => {
                if (result) {
                    this.changeHistory = result;
                }
            },
            complete: () => {
                this.prepareChangeInstanceData(0);
            },
        });
    }

    ngOnInit(): void {
        this.contentEl.addEventListener('scroll', this.divScroll, true);
        if (this.printMode) {
            const { discipline, id } = this.printData;
            this.therapyDiscipline = discipline;
            this.progressNoteId = id;
        }
        this.store
            .select('therapyAdmission')
            .pipe(takeUntil(this.onDestroy))
            .subscribe((data) => {
                this.currentAddendum = '';
                this.therapyAdmissions = data;

                const queryString = this.route.snapshot.queryParams;
                this.currentAdmission = findTherapyCaseByParamId(data, queryString['therapy-case']);
                this.therapyDiscipline ||= this.currentAdmission?.therapyDiscipline;
                this.therapyDiscipline ||= 'PT';
                this.currentAdmission = cloneDeep(this.currentAdmission);


                if (this.currentAdmission) {
                    this.progressNote = this.currentAdmission.documentation.progressNote.find(
                        (progressNote) => progressNote.id == this.progressNoteId
                    );
                    this.currentDocSigned = !!this.progressNote?.signed?.signDate;
                    this.showCoSign = isAllowedToCoSign(this.currentAdmission, this.user, this.progressNote.coSigned);
                    this.addendum.data = this.progressNote.addendum;
                }
            });
        if (this.authService.isTherapist) {
            this.therapistDiscipline = this.authService.accountValue.user.therapist.discipline;
            if (this.therapistDiscipline.replace('A', '') !== this.therapyDiscipline) {
                this.isDifferentDisciplineTherapist = true;
            }
        } else if (this.authService.isManager || this.authService.isSuperAdmin) this.isDifferentDisciplineTherapist = true;
    }

    onCoSignClicked() {
        if (this.admissionDischarged) {
            const data = {
                heading: 'Confirmation',
                icon: '',
                btn1Text: 'Cancel',
                btn2Text: 'Continue',
                lowerBodyValue: '',
                iconFilled: true,
                bodyText: `This patient has a facility discharge, would you like to continue?`,
            };
            const dialogRef = this.modalService.open(TimeConfirmationDialogComponent, {
                data,
            });
            dialogRef.afterClosed().subscribe((res) => {
                if (res) {
                    this.modalService.open(CoSignNoteComponent, {
                        data: {
                            documentType: Doc_Notes.Progress,
                            heading: 'Progress note',
                            document: this.progressNote,
                            changeHistoryCount: this.changeHistory.length,
                        },
                    });
                }
            });
        } else {
            this.modalService.open(CoSignNoteComponent, {
                data: {
                    documentType: Doc_Notes.Progress,
                    heading: 'Progress note',
                    document: this.progressNote,
                    changeHistoryCount: this.changeHistory.length,
                },
            });
        }
        
    }
    divScroll = (event): void => {
        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight - 500)
            this.enableCoSignBtn = true;
    };

    prepareChangeInstanceData(item: number) {
        this.selectedLabel = '';
        this.treatmentDiagnosis.data = this.changeHistory?.length
            ? this.changeHistory[item].patientHistory?.treatmentDiagnosis
            : [];
        this.medicalDiagnosis.data = this.changeHistory[item].patientHistory.medicalDiagnosis;

        this.precautions.data = this.prepareDynamicArrayChangeLog(item, 'patientHistory', 'precautions', 'id');
        this.contraindications.data = this.prepareDynamicArrayChangeLog(
            item,
            'patientHistory',
            'contraindications',
            'id'
        );
        this.clinicalImpression.paragraph = prepareSimpleTextChangeLog(
            this.changeHistory,
            item,
            'assessment',
            'clinicalImpression'
        );
        this.assessmentLastWeek.paragraph = prepareSimpleTextChangeLog(
            this.changeHistory,
            item,
            'subjectiveAssessment',
            'patientAssessmentSinceLastTreatment'
        );
        this.currentUpdatesTreatment.paragraph = prepareSimpleTextChangeLog(
            this.changeHistory,
            item,
            'plan',
            'overallPlanAndUpdates'
        );
        this.populateGoalsData(item);
        this.selectedChangeInstance = item;
    }

    prepareDynamicArrayChangeLog(changeInstance: number, section: string, key: string, uniqueKeyMatcher = '_id') {
        const arrayData = this.changeHistory[changeInstance]?.changeHistory?.changedFields?.filter(
            (x) => x.fieldKey === key
        );
        if (arrayData && arrayData.length > 0) {
            const temp = [];
            this.changeHistory[changeInstance]?.changeHistory.changedFields
                .filter((x) => x.fieldKey === key)
                .forEach((y) => {
                    y['currentValue' as any].forEach((cv) => {
                        const found = y['previousValue' as any].find(
                            (pv) => pv[uniqueKeyMatcher] === cv[uniqueKeyMatcher]
                        );
                        if (found) {
                            const fullBody = {};
                            //delta
                            Object.keys(y['currentValue'][0]).forEach((key) => {
                                fullBody[key] = {
                                    currentValue: cv[key] ?? undefined,
                                    previousValue: found[key] ?? undefined,
                                };
                            });
                            temp.push(fullBody);
                        } else {
                            const fullBody = {};
                            //new value addition
                            Object.keys(y['currentValue'][0]).forEach((key) => {
                                fullBody[key] = { currentValue: cv[key], previousValue: undefined };
                            });
                            temp.push(fullBody);
                        }
                    });

                    y['previousValue' as any].forEach((pv) => {
                        const found = y['currentValue' as any].find(
                            (cv) => cv[uniqueKeyMatcher] === pv[uniqueKeyMatcher]
                        );
                        if (!found) {
                            const fullBody = {};
                            Object.keys(y['previousValue'][0]).forEach((key) => {
                                fullBody[key] = { currentValue: undefined, previousValue: pv[key] };
                            });
                            // value deletion
                            temp.push(fullBody);
                        }
                    });
                });
            return temp;
        } else return this.changeHistory[changeInstance][section]?.[key];
    }

    /**
     * Function to scroll to a specific section when user clicks item on edit history section
     * @param id - Id of the section that user clicked
     * @param index - Index of the currently selected history (there can be multiple histories)
     */
    scroll(id: string, index: number): void {
        event.stopPropagation();
        if (index === this.selectedChangeInstance) {
            this.selectedLabel = id;
            id = this.generateSectionId(id);
            const sectionElement: ElementRef = new ElementRef(document.getElementById(id));
            scrollToElementWithOffset(sectionElement, 'start');
        } else {
            // If user is clicking on other history Instance, then select that history instance
            this.prepareChangeInstanceData(index);
        }
    }

    /**
     * Function to generate ID to move to that section in HTML
     * @param id - Current ID coming as label
     * @returns - ID according to sections
     */
    generateSectionId(id: string): string {
        // As there are some label that have dots so we need the later part
        const str = id.includes('.') ? id.split('.')[1] : id.split('.')[0];
        return str + 'Section';
    }

    updateAddendum(description) {
        this.currentAddendum = description;
    }

    padTo2Digits(number: number) {
        return number.toString().padStart(2, '0');
    }
    addAddendumNote() {
        if (this.authService.isManager || this.authService.isTherapist) {
            if (this.currentAddendum) {
                const addendumNote = {
                    description: this.currentAddendum,
                };
                this.modalService.open(SignNoteComponent, {
                    data: {
                        documentType: 'progressNote',
                        addendumNote,
                        heading: 'Addendum Note',
                        progressNote: this.progressNoteId,
                    },
                });
            } else {
                this.toasterService.show({ title: 'Failure', body: 'Add description', type: 'error' });
            }
        }
    }

    tooltipCheck = () => {
        if (this.admissionDischarged) {
            return 'Patient Discharged';
        } else if (!this.authService.isManager && !this.authService.isTherapist) {
            return 'You are not authorized to add addendum';
        } else if (this.isDifferentDisciplineTherapist) {
            return '';
        } else {
            return 'Add Addendum Note';
        }
    };
    openEvalHistory() {
        this.evalHistorySide = true;
        this.prepareChangeInstanceData(this.changeHistory?.length == 1 ? 0 : 1);
    }

    closeHistorySideBar(event: boolean) {
        this.evalHistorySide = event;
        this.prepareChangeInstanceData(0);
    }

    populateGoalsData(item: number) {
        const goals = this.prepareDynamicGoalsArrayChangeLog(item, 'objectiveAssessment', 'goals', 'id');
        if (goals[0].goal?.currentValue) {
            this.goal = this.goalService.buildGoalsWithDiffView(goals);
        } else {
            this.goal = this.goalService.buildGoal(goals);
        }
    }

    prepareDynamicGoalsArrayChangeLog(changeInstance: number, section: string, key: string, uniqueKeyMatcher = '_id') {
        const arrayData = this.changeHistory[changeInstance]?.changeHistory?.changedFields?.filter(
            (x) => x.fieldKey === key
        );
        if (arrayData && arrayData.length > 0) {
            const temp = [];
            this.changeHistory[changeInstance]?.changeHistory.changedFields
                .filter((x) => x.fieldKey === key)
                .forEach((y) => {
                    y['currentValue' as any].forEach((cv) => {
                        const found = y['previousValue' as any].find(
                            (pv) => pv[uniqueKeyMatcher] === cv[uniqueKeyMatcher]
                        );
                        if (found) {
                            const fullBody = {};
                            //delta
                            Object.keys(y['currentValue'][0]).forEach((key) => {
                                fullBody[key] = { currentValue: cv[key], previousValue: found[key] };
                            });

                            let finalStgs = [];
                            // do in one loop outside this foreach. Remember if chunk
                            cv.stgs.forEach((stg) => {
                                const prevStg = found.stgs.find((prevStg) => prevStg._id === stg._id);
                                const stgToPush = {};
                                if (prevStg) {
                                    Object.keys(stg).forEach((key) => {
                                        stgToPush[key] = { currentValue: stg[key], previousValue: prevStg[key] };
                                    });
                                } else {
                                    Object.keys(stg).forEach((key) => {
                                        stgToPush[key] = { currentValue: stg[key], previousValue: undefined };
                                    });
                                }
                                finalStgs.push(stgToPush);
                            });
                            fullBody['stgs'].currentValue = finalStgs;

                            // currentV = finalStgs ?
                            temp.push(fullBody);
                            finalStgs = [];
                        } else {
                            const fullBody = {};
                            //new value addition
                            Object.keys(y['currentValue'][0]).forEach((key) => {
                                fullBody[key] = { currentValue: cv[key], previousValue: undefined };
                            });
                            temp.push(fullBody);
                        }
                    });

                    y['previousValue' as any].forEach((pv) => {
                        const found = y['currentValue' as any].find(
                            (cv) => cv[uniqueKeyMatcher] === pv[uniqueKeyMatcher]
                        );
                        if (!found) {
                            const fullBody = {};
                            Object.keys(y['previousValue'][0]).forEach((key) => {
                                fullBody[key] = { currentValue: undefined, previousValue: pv[key] };
                            });
                            // value deletion
                            temp.push(fullBody);
                        }
                    });
                });
            return temp;
        } else return this.changeHistory[changeInstance][section]?.[key];
    }
    canEdit() {
        // Assistant Therapist cannot edit notes except for daily note.
        if (isUserTherapistAssistant(this.user.user)) return false;
        else return true;
    }
}
