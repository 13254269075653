import { Injectable, OnDestroy } from '@angular/core';
import { FacilityManagementService } from './facilityManagement.service';
import { Store } from '@ngrx/store';
import { TherapyAdmission } from '@app/interfaces';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class QuickLinksService implements OnDestroy {
    private _quickLinks;
    private _isGoLiveEnabled: any;
    therapyAdmissions$: any;
    evalOnly: boolean;
    subscription: Subscription[] = [];

    constructor(
        private facilityManagementService: FacilityManagementService,
        private store: Store<{
            therapyAdmission: TherapyAdmission[];
        }>,
        private route: ActivatedRoute
    ) {
        this._isGoLiveEnabled = !this.facilityManagementService._currentFacility.goLiveEnabled;
        this.therapyAdmissions$ = this.store.select('therapyAdmission');
        this.setQuickLinks();
        this.subscription.push(
            this.therapyAdmissions$.subscribe((data) => {
                this.subscription.push(
                    this.route.queryParams.subscribe((q) => {
                        const currentEvalId = q.doc;
                        for (const admission of data) {
                            const evaluation = admission?.documentation?.evaluation;
                            if (evaluation.id === currentEvalId) {
                                this.evalOnly = evaluation.evaluationType.evalOnly;
                                this.setQuickLinks();
                                break;
                            }
                        }
                    })
                );
            })
        );
    }

    applyRequiredFilter(data, path): any {
        let links = this._quickLinks[0];
        for (let i = 0; i < path.length; i++) {
            links = links[path[i]];
        }

        const onlyRequiredFields = Object.keys(data).reduce((accumulator, key) => {
            const value = data[key];
            return links.find((item) => item.storeValName === key)?.required
                ? { ...accumulator, [key]: value }
                : accumulator;
        }, {});

        return onlyRequiredFields;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setIsAnIntegratedUser(userStatus: boolean): void {
        this._isGoLiveEnabled = !this.facilityManagementService._currentFacility.goLiveEnabled;
        this.setQuickLinks();
    }
    setQuickLinks() {
        this._quickLinks = [
            {
                evaluation: {
                    medicalReview: [
                        {
                            el: '',
                            label: 'Medical Diagnosis',
                            required: true,
                            storeValName: 'icd10Codes',
                            ownPath: ['facilityAdmission', 'medicalDiagnosis'],
                        },
                        {
                            el: '',
                            label: 'Treatment Diagnosis',
                            required: true,
                            storeValName: 'treatmentDiagnosis',
                            // ownPath: ['therapyAdmission'],
                        },
                        {
                            label: 'Prior Hospitalization Dates',
                            storeValName: 'priorHospitalizationDates',
                            required: false,
                        },
                        {
                            label: 'Past Medical History',
                            required: this._isGoLiveEnabled ? false : true,
                            storeValName: 'pastMedicalHistory',
                        },
                        {
                            el: '',
                            label: 'Reason For Referral',
                            required: this._isGoLiveEnabled ? false : true,
                            storeValName: 'reasonForReferral',
                        },
                        {
                            el: '',
                            label: 'Chief Complaint',
                            required: this._isGoLiveEnabled ? false : true,
                            storeValName: 'chiefComplaint',
                        },
                        {
                            el: '',
                            label: 'Precautions',
                            required: true,
                            storeValName: 'precautions',
                        },
                        {
                            el: '',
                            label: 'Contraindications',
                            required: true,
                            storeValName: 'contraindications',
                        },
                        {
                            el: '',
                            label: 'Medications',
                            required: this._isGoLiveEnabled ? false : true,
                            storeValName: 'medications',
                        },
                    ],
                    backgroundAssessment: [
                        {
                            el: '',
                            label: 'Patients Goals',
                            required: this._isGoLiveEnabled ? false : true,
                            storeValName: 'patientGoals',
                        },
                        {
                            el: '',
                            label: 'Social Determinants of Health',
                            required: this._isGoLiveEnabled ? false : true,
                            storeValName: 'socialDeterminantsOfHealth',
                        },
                        {
                            el: '',
                            label: 'Environmental Factors',
                            required: this._isGoLiveEnabled ? false : true,
                            storeValName: 'environmentalFactors',
                        },
                        {
                            el: '',
                            label: 'Prior Level of function',
                            required: this._isGoLiveEnabled ? false : true,
                            storeValName: 'priorLevelOfFunction',
                        },
                        {
                            el: '',
                            label: 'Previous Therapy',
                            required: this._isGoLiveEnabled ? false : true,
                            storeValName: 'previousTherapy',
                        },
                        {
                            el: '',
                            label: 'Care Giver Education',
                            required: this._isGoLiveEnabled ? false : true,
                            storeValName: 'careGiverEducation',
                        },
                    ],
                    patientAssessment: [
                        {
                            el: '',
                            label: 'Impairments',
                            required: this._isGoLiveEnabled ? false : true,
                            storeValName: 'impairments',
                        },
                        {
                            el: '',
                            label: 'Functional Deficits',
                            required: this._isGoLiveEnabled ? false : true,
                            storeValName: 'functionalDeficits',
                        },
                        {
                            el: '',
                            label: 'Standardized Tests',
                            required: this._isGoLiveEnabled ? false : true,
                            storeValName: 'standardizedTests',
                        },
                    ],
                    medicalNecessity: [
                        {
                            el: '',
                            label: 'Therapy Necessity',
                            required: this._isGoLiveEnabled ? false : true,
                            storeValName: 'therapyNecessity',
                        },
                        {
                            el: '',
                            label: 'Intervention Approaches',
                            required: this._isGoLiveEnabled ? false : true,
                            storeValName: 'interventionApproaches',
                        },
                    ],
                    planOfCare: [
                        {
                            el: '',
                            label: 'Frequency & Duration',
                            required: true,
                            storeValName: 'frequencyAndDuration',
                            child: [
                                // {
                                //     el: 'amountCap',
                                //     label: 'Frequency & Duration',
                                //     required: true,
                                //     storeValName: 'range',
                                // },
                                {
                                    el: 'frequencyFrom',
                                    label: 'Frequency',
                                    required: true,
                                    storeValName: 'frequencyFrom',
                                },
                                // {
                                //     el: 'frequencyTo',
                                //     label: 'Frequency',
                                //     required: false,
                                //     storeValName: 'frequencyTo',
                                // },
                                {
                                    el: 'duration',
                                    label: 'Frequency',
                                    required: true,
                                    storeValName: 'duration',
                                },
                                {
                                    el: 'durationType',
                                    label: 'Frequency',
                                    required: true,
                                    storeValName: 'durationType',
                                },
                                // {
                                //     el: 'intensity',
                                //     label: 'Frequency',
                                //     required: true,
                                //     storeValName: 'intensity',
                                // },
                            ],
                        },
                        {
                            el: 'payorAuthorization',
                            label: 'Certification',
                            required: true,
                            storeValName: 'certification',
                            child: [
                                {
                                    el: 'payorAuthorization',
                                    label: 'Certification',
                                    required: true,
                                    storeValName: 'fromDate',
                                },
                                {
                                    el: 'payorAuthorization',
                                    label: 'Certification',
                                    required: true,
                                    storeValName: 'throughDate',
                                },
                            ],
                        },
                        {
                            el: '',
                            label: 'Goals',
                            required: true,
                            storeValName: 'goals',
                        },
                        {
                            el: '',
                            label: 'Skilled Services (CPT Codes)',
                            required: true,
                            storeValName: 'skilledServices',
                        },

                        {
                            el: 'visits',
                            label: 'Prognosis for Achieving Rehab Goals',
                            required: this._isGoLiveEnabled ? false : true,
                            storeValName: 'goalPurpose',
                        },
                        {
                            el: 'amountCap',
                            label: 'Anticipated Discharge Plans',
                            required: this._isGoLiveEnabled ? false : true,
                            storeValName: 'anticipatedDischarge',
                            child: [
                                {
                                    el: 'amountCap',
                                    label: 'Anticipated Discharge Plans',
                                    required: this._isGoLiveEnabled ? false : true,
                                    storeValName: 'dischargePlans',
                                },
                                {
                                    el: 'amountCap',
                                    label: 'Anticipated Discharge Plans',
                                    required: this._isGoLiveEnabled ? false : true,
                                    storeValName: 'dischargeDestination',
                                },
                            ],
                        },
                    ],
                    planOfCareOnlyEval: [
                        {
                            el: 'evaluationType',
                            label: 'Reason for not including POC',
                            required: true,
                            storeValName: 'evalOnlyReason',
                        },
                    ],
                    evaluationType: [
                        {
                            el: 'evalType',
                            label: 'Eval Type',
                            required: (this._isGoLiveEnabled ? false : true) || this.evalOnly,
                            child: [
                                {
                                    el: 'evalType',
                                    label: 'Eval Type',
                                    required: this._isGoLiveEnabled ? false : true,
                                    storeValName: 'evalType',
                                },
                                {
                                    el: 'evalTypeDescription',
                                    label: 'Description',
                                    required: this._isGoLiveEnabled ? false : true,
                                    storeValName: 'description',
                                },
                                {
                                    el: 'evalTypeMinutes',
                                    label: 'Number of Minutes',
                                    required: this.evalOnly,
                                    storeValName: 'minutes',
                                },
                            ],
                        },
                    ],
                },
                daily: [
                    {
                        el: '',
                        label: 'Patient Demographics',
                        required: true,
                        storeValName: 'patientHistory',
                        child: [
                            {
                                el: '',
                                label: 'Medical Diagnosis',
                                required: true,
                                storeValName: 'medicalDiagnosis',
                            },
                            {
                                el: '',
                                label: 'Treatment Diagnosis',
                                required: true,
                                storeValName: 'treatmentDiagnosis',
                            },
                            {
                                el: '',
                                label: 'Precautions',
                                required: true,
                                storeValName: 'precautions',
                            },
                            {
                                el: '',
                                label: 'Contraindications',
                                required: true,
                                storeValName: 'contraindications',
                            },
                        ],
                    },
                    {
                        el: '',
                        label: 'Subjective Assessment',
                        required: true,
                        storeValName: 'subjectiveAssessment',
                        child: [
                            {
                                el: '',
                                label: "Patient's self-report of their current status",
                                required: true,
                                storeValName: 'patientAssessmentSinceLastTreatment',
                            },
                        ],
                    },
                    {
                        el: '',
                        label: 'Vitals',
                        storeValName: 'objectiveAssessment.vitals',
                    },
                    {
                        el: '',
                        label: 'Treatment',
                        required: true,
                        storeValName: 'objectiveAssessment.detailedCptCodes',
                        child: [
                            {
                                el: '',
                                label: 'Skilled Services (CPT Codes)',
                                required: true,
                                storeValName: 'detailedCptCodes.skilledServices',
                                customConditions: ['individualMinutesValid', 'otherTxValid'],
                            },
                        ],
                    },
                    {
                        el: '',
                        label: 'Patient Goals',
                        required: false,
                        storeValName: 'patientGoal',
                    },
                    {
                        el: '',
                        label: 'Response To Treatment',
                        required: true,
                        storeValName: 'assessment',
                        child: [
                            {
                                el: '',
                                label: 'Response To Treatment',
                                required: true,
                                storeValName: 'responseToTreatment',
                            },
                        ],
                    },
                    {
                        el: '',
                        label: 'Plan For Next Treatment',
                        required: true,
                        storeValName: 'plan',
                        child: [
                            {
                                el: '',
                                label: 'Plan For Next Treatment',
                                required: true,
                                storeValName: 'planForNextTreatment',
                            },
                        ],
                    },
                    // {
                    //     el: '',
                    //     label: 'Anticipated Discharge Date',
                    //     required: false,
                    //     storeValName: 'AnticipatedDischargeDate',
                    // },
                ],
                progress: [
                    {
                        el: '',
                        label: 'Patient History',
                        required: false,
                        storeValName: 'patientHistory',
                    },
                    {
                        el: '',
                        label: 'Subjective Assessment',
                        required: true,
                        storeValName: 'subjectiveAssessment',
                        child: [
                            {
                                el: '',
                                label: "Patient's self-report of their current status",
                                required: true,
                                storeValName: 'patientAssessmentSinceLastTreatment',
                            },
                        ],
                    },
                    {
                        el: '',
                        label: 'Objective Assessment',
                        required: false,
                        storeValName: 'objectiveAssessment',
                    },
                    {
                        el: '',
                        label: 'Assessment',
                        required: true,
                        storeValName: 'assessment',
                        child: [
                            {
                                el: '',
                                label: 'Clinical Impression',
                                required: true,
                                storeValName: 'clinicalImpression',
                            },
                        ],
                    },
                    {
                        el: '',
                        label: 'Plan',
                        required: true,
                        storeValName: 'plan',
                        child: [
                            {
                                el: '',
                                label: 'Overall Plan and Updates to Treatment',
                                required: true,
                                storeValName: 'overallPlanAndUpdates',
                            },
                        ],
                    },
                ],
                discharge: [
                    {
                        el: '',
                        label: 'Patient History',
                        required: false,
                        storeValName: 'patientHistory',
                    },
                    {
                        el: '',
                        label: 'Subjective Assessment',
                        required: true,
                        storeValName: 'subjectiveAssessment',
                        child: [
                            {
                                el: '',
                                label: 'Patient Assessment Since Treatment Started',
                                required: true,
                                storeValName: 'patientAssessmentSinceTreatmentStarted',
                            },
                        ],
                    },
                    { el: '', label: 'Objective Assessment', required: false },
                    {
                        el: '',
                        label: 'Assessment',
                        required: true,
                        storeValName: 'assessment',
                        child: [
                            {
                                el: '',
                                label: 'Clinical Impression',
                                required: true,
                                storeValName: 'clinicalImpression',
                            },
                            {
                                el: '',
                                label: 'End of Care Date',
                                required: true,
                                storeValName: 'endOfCareDate',
                            },
                            {
                                el: '',
                                label: 'End of Care Reason',
                                required: true,
                                storeValName: 'endOfCareReason',
                            },
                        ],
                    },
                    {
                        el: '',
                        label: 'Plan',
                        required: true,
                        storeValName: 'plan',
                        child: [
                            {
                                el: '',
                                label: 'Discharge Instructions',
                                required: true,
                                storeValName: 'dischargeInstructions',
                            },
                            {
                                el: '',
                                label: 'Anticipated Discharge Destination',
                                required: true,
                                storeValName: 'dischargeDestination',
                            },
                            {
                                el: '',
                                label: 'Caregiver Education',
                                required: true,
                                storeValName: 'caregiverEducation',
                            },
                        ],
                    },
                ],

                recertification: [
                    {
                        el: '',
                        label: 'Patient History',
                        storeValName: 'patientHistory',
                        required: true,
                        child: [
                            {
                                el: '',
                                label: 'Medical Diagnosis',
                                required: true,
                                storeValName: 'medicalDiagnosis',
                                customConditions: ['primaryDX'],
                            },
                            {
                                el: '',
                                label: 'Treatment Diagnosis',
                                required: true,
                                storeValName: 'treatmentDiagnosis',
                            },
                        ],
                    },
                    {
                        el: '',
                        label: 'Subjective Assessment',
                        required: true,
                        storeValName: 'subjectiveAssessment',
                        child: [
                            {
                                el: '',
                                label: 'Patient Assessment Since Treatment Started',
                                required: true,
                                storeValName: 'patientAssessmentSinceTreatmentStarted',
                            },
                        ],
                    },
                    {
                        el: '',
                        label: 'Objective Assessment',
                        required: false,
                        storeValName: 'objectiveAssessment',
                        child: [
                            {
                                el: '',
                                label: 'Impairments',
                                required: false,
                                storeValName: 'impairments',
                            },
                            {
                                el: '',
                                label: 'Functional Deficits',
                                required: false,
                                storeValName: 'functionalDeficits',
                            },
                            {
                                el: '',
                                label: 'Standardized Tests',
                                required: false,
                                storeValName: 'standardizedTests',
                            },
                        ],
                    },
                    {
                        el: '',
                        label: 'Assessment',
                        required: true,
                        storeValName: 'assessment',
                        child: [
                            {
                                el: '',
                                label: 'Clinical Impression',
                                required: true,
                                storeValName: 'clinicalImpression',
                            },
                            {
                                el: '',
                                label: 'Therapy Necessity',
                                required: true,
                                storeValName: 'therapyNecessity',
                            },
                            {
                                el: '',
                                label: 'Prognosis for Achieving Rehab Goals',
                                required: true,
                                storeValName: 'prognosisForAchievingRehabGoals',
                            },
                            {
                                el: '',
                                label: 'Reason for Continuing Treatment',
                                required: true,
                                storeValName: 'reasonForContinuingTreatment',
                            },
                            {
                                el: '',
                                label: 'Updates to Treatment',
                                required: true,
                                storeValName: 'updateToTreatment',
                            },
                        ],
                    },
                    {
                        el: '',
                        label: 'Plan',
                        required: true,
                        storeValName: 'plan',
                        child: [
                            {
                                el: '',
                                label: 'Goals',
                                required: true,
                                storeValName: 'goals',
                            },
                            {
                                el: '',
                                label: 'Skilled Services',
                                required: true,
                                storeValName: 'skilledServices',
                            },
                            {
                                el: '',
                                label: 'Frequency and Duration',
                                required: true,
                                storeValName: 'frequencyAndDuration',
                                subChild: [
                                    // {
                                    //     el: 'amountCap',
                                    //     label: 'Frequency & Duration',
                                    //     required: true,
                                    //     storeValName: 'range',
                                    // },
                                    {
                                        el: 'frequencyFrom',
                                        label: 'Frequency',
                                        required: true,
                                        storeValName: 'frequencyFrom',
                                    },
                                    // {
                                    //     el: 'frequencyTo',
                                    //     label: 'Frequency',
                                    //     required: false,
                                    //     storeValName: 'frequencyTo',
                                    // },
                                    {
                                        el: 'duration',
                                        label: 'Frequency',
                                        required: true,
                                        storeValName: 'duration',
                                    },
                                    {
                                        el: 'durationType',
                                        label: 'Frequency',
                                        required: true,
                                        storeValName: 'durationType',
                                    },
                                    // {
                                    //     el: 'intensity',
                                    //     label: 'Frequency',
                                    //     required: true,
                                    //     storeValName: 'intensity',
                                    // },
                                ],
                            },
                        ],
                    },
                ],
                upoc: [
                    {
                        el: '',
                        label: 'Patient History',
                        storeValName: 'patientHistory',
                        required: true,
                        child: [
                            {
                                el: '',
                                label: 'Medical Diagnosis',
                                required: true,
                                storeValName: 'medicalDiagnosis',
                                customConditions: ['primaryDX'],
                            },
                            {
                                el: '',
                                label: 'Treatment Diagnosis',
                                required: true,
                                storeValName: 'treatmentDiagnosis',
                            },
                        ],
                    },
                    {
                        el: '',
                        label: 'Objective Assessment',
                        required: false,
                        storeValName: 'objectiveAssessment',
                        child: [
                            {
                                el: '',
                                label: 'Impairments',
                                required: false,
                                storeValName: 'impairments',
                            },
                            {
                                el: '',
                                label: 'Functional Deficits',
                                required: false,
                                storeValName: 'functionalDeficits',
                            },
                            {
                                el: '',
                                label: 'Standardized Tests',
                                required: false,
                                storeValName: 'standardizedTests',
                            },
                        ],
                    },
                    {
                        el: '',
                        label: 'Assessment',
                        storeValName: 'assessment',
                        required: true,
                        child: [
                            {
                                el: '',
                                label: 'Reason for Update',
                                required: true,
                                storeValName: 'reasonForUpdate',
                            },
                        ],
                    },
                    {
                        el: '',
                        label: 'Plan',
                        required: true,
                        storeValName: 'plan',
                        child: [
                            {
                                el: '',
                                label: 'Goals',
                                required: true,
                                storeValName: 'goals',
                            },
                            {
                                el: '',
                                label: 'Skilled Services',
                                required: true,
                                storeValName: 'skilledServices',
                            },
                            {
                                el: '',
                                label: 'Frequency and Duration',
                                required: true,
                                storeValName: 'frequencyAndDuration',
                                subChild: [
                                    // {
                                    //     el: 'amountCap',
                                    //     label: 'Frequency & Duration',
                                    //     required: true,
                                    //     storeValName: 'range',
                                    // },
                                    {
                                        el: 'frequencyFrom',
                                        label: 'Frequency',
                                        required: true,
                                        storeValName: 'frequencyFrom',
                                    },
                                    // {
                                    //     el: 'frequencyTo',
                                    //     label: 'Frequency',
                                    //     required: false,
                                    //     storeValName: 'frequencyTo',
                                    // },
                                    {
                                        el: 'duration',
                                        label: 'Frequency',
                                        required: true,
                                        storeValName: 'duration',
                                    },
                                    {
                                        el: 'durationType',
                                        label: 'Frequency',
                                        required: true,
                                        storeValName: 'durationType',
                                    },
                                    // {
                                    //     el: 'intensity',
                                    //     label: 'Frequency',
                                    //     required: true,
                                    //     storeValName: 'intensity',
                                    // },
                                ],
                            },
                        ],
                    },
                ],
            },
        ];
    }

    getQuickLinks(note: 'evaluation' | 'progress' | 'daily' | 'upoc' | 'discharge' | 'recertification', object?: any) {
        return object ? this._quickLinks[0][note][object] : this._quickLinks[0][note];
    }
    getRequiredProperties(element) {
        const requiredKeys = [];
        if (Array.isArray(element) && element.length) {
            for (const key in element) {
                if (
                    element &&
                    Object.prototype.hasOwnProperty.call(element, key) &&
                    element[key].required &&
                    element[key].storeValName &&
                    !Object.prototype.hasOwnProperty.call(element[key], 'child')
                ) {
                    requiredKeys.push(element[key].storeValName);
                } else if (element) {
                    requiredKeys.push(...this.getRequiredProperties(element[key]['child']));
                }
            }
        } else if (element && element.required) {
            if (Object.prototype.hasOwnProperty.call(element, 'child')) {
                requiredKeys.push(...this.getRequiredProperties(element['child']));
            } else {
                requiredKeys.push(element.storeValName);
            }
        }
        return requiredKeys;
    }
    findValueByKey(key, payLoad) {
        let result = null;
        if (payLoad instanceof Array) {
            for (let i = 0; i < payLoad.length; i++) {
                result = this.findValueByKey(key, payLoad[i]);
                if (result) {
                    break;
                }
            }
        } else {
            for (const prop in payLoad) {
                if (prop == key) {
                    return payLoad[prop];
                }
                if (payLoad[prop] instanceof Object || payLoad[prop] instanceof Array) {
                    result = this.findValueByKey(key, payLoad[prop]);
                    if (result) {
                        break;
                    }
                }
            }
        }
        return result;
    }
    /**
     * compares if the 'key' lies under parent path
     */
    findValueByKeyParent(key, payLoad, parent, path = []) {
        let result = null;
        if (payLoad instanceof Array) {
            for (let i = 0; i < payLoad.length; i++) {
                path.push(payLoad[i]);
                result = this.findValueByKeyParent(key, payLoad[i], parent, path);
                path.pop();
                if (result) {
                    break;
                }
            }
        } else {
            for (const prop in payLoad) {
                if (prop == key) {
                    if (path && path.includes(parent)) {
                        return payLoad[prop];
                    }
                }
                if (payLoad[prop] instanceof Object || payLoad[prop] instanceof Array) {
                    path.push(prop);
                    result = this.findValueByKeyParent(key, payLoad[prop], parent, path);
                    path.pop();
                    if (result) {
                        break;
                    }
                }
            }
        }
        return result;
    }
    ngOnDestroy(): void {
        this.subscription.forEach((listener) => listener.unsubscribe());
    }
}
