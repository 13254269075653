import { DatePipe } from '@angular/common';
import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CheckIconCellRendererComponent, DiffCellRendererComponent } from '@app/data-grid/cell-renderers';
import { isAllowedToCoSign } from '@app/helpers';
import { prepareSimpleTextChangeLog } from '@app/helpers/utils';
import { scrollToElementWithOffset } from '@app/helpers/utils/scroll.helper';
import { findTherapyCaseByParamId } from '@app/helpers/utils/therapy-case.utils';
import { DNChangeHistory, DailyNote, FacilityAdmission, TherapyAdmission } from '@app/interfaces';
import { DOCUMENTATION_CONSTANTS, Doc_Notes } from '@app/interfaces/documentation';
import { PrintData } from '@app/interfaces/print';
import { Account } from '@app/models';
import { CoSignNoteComponent } from '@app/modules/patients/PCC/pcc-tabs/pcc-documentation/modals/co-sign-note/co-sign-note.component';
import { SignNoteComponent } from '@app/modules/patients/PCC/pcc-tabs/pcc-documentation/modals/sign-note/sign-note.component';
import {
    GoalService,
    AuthService,
    ModalService,
    StoreService,
    ToasterService,
    DailyNotesService,
    CptCodesService,
} from '@app/services';
import { TimeConfirmationDialogComponent } from '@app/shared/time-confirmation-dialog/time-confirmation-dialog.component';
import { select, Store } from '@ngrx/store';
import { CellStyle, ColDef } from 'ag-grid-community';
import { format, parse } from 'date-fns';
import { cloneDeep } from 'lodash';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'app-daily-notes',
    templateUrl: './daily-notes.component.html',
    styleUrls: ['./daily-notes.component.scss'],
})
export class DailyNotesComponent implements OnDestroy, OnInit {
    contentEl = document.getElementById('content');
    @Input() printMode = false;
    @Input() printData: PrintData;
    noteTitle = 'Daily Note';
    hideBtn = true;
    evalHistorySide: boolean;
    admissionDischarged = false;
    DOCUMENTATION_CONSTANTS = DOCUMENTATION_CONSTANTS;
    DEEP_CHANGE_KEYS = ['isTeleHealthEligible'];
    showCoSign = false;
    enableCoSignBtn = false;

    private readonly onDestroy = new Subject<void>();

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.contentEl.removeEventListener('scroll', this.divScroll, true);
    }
    defaultCellStyle: CellStyle = {
        height: '100%',
        display: 'flex ',
        'align-items': 'center',
        'word-break': 'normal',
    };
    defaultColDef: ColDef = {
        flex: 1,
        filter: true,
        resizable: true,
        suppressMovable: true,
        suppressMenu: true,
        cellStyle: this.defaultCellStyle,
        wrapText: true,
        autoHeight: true,
    };
    pinnedRow = [];
    medicalDiagnosis: { title: string; cols: ColDef[]; data: Array<object> } = {
        title: 'Medical Diagnosis',
        cols: [
            { field: 'no', headerName: 'No.', minWidth: 60, maxWidth: 100, valueGetter: 'node.rowIndex + 1' },
            { field: 'code', headerName: 'Code', minWidth: 60, maxWidth: 100 },
            { field: 'description', headerName: 'Description', width: 300, wrapText: true, autoHeight: true },
            {
                field: 'pdpmClinicalCategory',
                headerName: 'PDPM Clinical Category',
                width: 300,
                wrapText: true,
                autoHeight: true,
            },
            {
                field: 'onsetDate',
                headerName: 'Onset Date',
                cellRenderer: (params) => {
                    return params.data.onsetDate ? this.transformDate(new Date(params.data.onsetDate)) : '-----';
                },
            },
            { field: 'slp', headerName: 'SLP Comorbidity' },
            { headerName: 'Primary DX', field: 'isPrimary', cellRenderer: CheckIconCellRendererComponent },
        ],
        data: [],
    };
    treatmentDiagnosis: { title: string; cols: ColDef[]; data: Array<object> } = {
        title: 'Treatment Diagnosis',
        cols: [
            { field: 'no', headerName: 'No.', minWidth: 60, maxWidth: 100, valueGetter: 'node.rowIndex + 1' },
            { field: 'code', headerName: 'Code', minWidth: 60, maxWidth: 100 },
            {
                field: 'description',
                headerName: 'Description',
                cellClass: ['body-medium', 'line-space'],
                autoHeight: true,
            },
        ],
        data: [],
    };
    precautions: { title: string; cols: ColDef[]; data: Array<object> } = {
        title: 'Precautions',
        cols: [
            { field: 'no', headerName: 'No.', minWidth: 60, maxWidth: 100, valueGetter: 'node.rowIndex + 1' },
            {
                field: 'description',
                headerName: 'Description',
                cellClass: ['body-medium', 'line-space'],
                autoHeight: true,
                cellRenderer: DiffCellRendererComponent,
            },
        ],
        data: [],
    };
    contraindications: { title: string; cols: ColDef[]; data: Array<object> } = {
        title: 'Contraindications',
        cols: [
            { field: 'no', headerName: 'No.', minWidth: 60, maxWidth: 100, valueGetter: 'node.rowIndex + 1' },
            {
                field: 'description',
                headerName: 'Description',
                cellClass: ['body-medium', 'line-space'],
                autoHeight: true,
                cellRenderer: DiffCellRendererComponent,
            },
        ],
        data: [],
    };
    patientsSelfReport = {
        title: "Patient's self-report of their current status",
        paragraph: '',
    };
    vitals: { title: string; cols: ColDef[]; data: Array<object> } = {
        title: 'Vitals',
        cols: [
            { field: 'respiratoryRate', headerName: 'Respiratory Rate', cellRenderer: DiffCellRendererComponent },
            { field: 'bloodPressure', headerName: 'Blood Pressure', cellRenderer: DiffCellRendererComponent },
            { field: 'temperature', headerName: 'Temperature', cellRenderer: DiffCellRendererComponent },
            { field: 'pulseRate', headerName: 'Pulse Rate', cellRenderer: DiffCellRendererComponent },
            { field: 'o2Saturation', headerName: 'O2 Saturation', cellRenderer: DiffCellRendererComponent },
            {
                field: 'note',
                width: 400,
                headerName: 'Note',
                autoHeight: true,
                wrapText: true,
                cellRenderer: DiffCellRendererComponent,
            },
        ],
        data: [],
    };
    cptCodes: { title: string; cols: ColDef[]; data: Array<object> } = {
        title: 'Treatment',
        cols: [
            {
                field: 'code',
                headerName: 'Code',
                minWidth: 60,
                maxWidth: 100,
                // suppressSizeToFit: true
                //     if (params.node.rowPinned) {
                //         return params.data.code;
                //     } else {
                //         return {
                //             component: CptCellRendererComponent,
                //             params: { gridName: 'skilledServicesDN' },
                //         };
                //     }
                // },
                cellStyle: {
                    ...this.defaultCellStyle,
                    'padding-left': '1vw',
                },
                cellRenderer: DiffCellRendererComponent,
            },
            {
                field: 'description',
                headerName: 'Description',
                cellClass: 'text-secondary',
                // width: 130,
                autoHeight: true,
                wrapText: true,
                cellStyle: { ...this.defaultCellStyle },
                cellRenderer: DiffCellRendererComponent,
            },
            {
                field: 'time',
                headerName: 'Time',
                cellClass: 'text-secondary',
                minWidth: 60,
                cellStyle: { ...this.defaultCellStyle },
                wrapText: true,
                autoHeight: true,
                cellRenderer: DiffCellRendererComponent,
            },
            {
                field: 'goal',
                headerName: 'Goals Addressed',
                cellClass: 'text-secondary',
                autoHeight: true,
                wrapText: true,
                // width: 100,
                cellStyle: {
                    ...this.defaultCellStyle,
                    'font-weight': '700',
                    'work-break': 'normal',
                },
                cellRenderer: DiffCellRendererComponent,
                cellRendererParams: { key: 'nestedArray' },
            },
            // {
            //     field: 'exercise',
            //     headerName: 'Exercises',
            //     cellRenderer: (params: any) => {
            //         let result = ``;
            //         params.data?.exercise?.forEach((elem: string) => {
            //             result = result + `<div> ${elem} </div>`;
            //         });
            //         return result;
            //     },
            // },
            {
                field: 'justification',
                headerName: 'Skilled Services & Justification for Tx',
                cellClass: ['body-medium', 'line-space'],
                autoHeight: true,
                wrapText: true,
                cellRenderer: DiffCellRendererComponent,
            },
        ],
        data: [],
    };
    otherTxMinutes: { title: string; cols: ColDef[]; data: Array<object> } = {
        title: 'Other Tx Minutes',
        cols: [
            { field: 'mode', headerName: 'Mode', width: 120, cellStyle: { 'white-space': 'normal' }, autoHeight: true },
            { field: 'minutes', headerName: 'Minutes', cellRenderer: DiffCellRendererComponent },
            {
                field: 'disciplines',
                headerName: 'Disciplines',
                cellRenderer: DiffCellRendererComponent,
            },
            {
                field: 'justification',
                headerName: 'Justification',
                cellClass: ['body-medium', 'line-space'],
                autoHeight: true,
                cellRenderer: DiffCellRendererComponent,
            },
        ],
        data: [],
    };
    goal: any = [];
    responseToTreatment: { title: string; paragraph: string } = {
        title: 'Response to treatment',
        paragraph: '',
    };
    planTreatment: { title: string; paragraph: string } = {
        title: 'Plan for next treatment',
        paragraph: '',
    };
    anticipatedDischargeDate: { title: string; date: string } = {
        title: 'Anticipated Discharge Date',
        date: '',
    };

    addendum: { title: string; cols: ColDef[]; data: Array<object> } = {
        title: 'Addendum',
        cols: [
            {
                field: 'description',
                width: 900,
                headerName: 'Description',
                autoHeight: true,
                wrapText: true,
            },
            {
                field: 'userNameRole',
                headerName: 'User name & Role',
                cellRenderer: (params) => {
                    const keyData = params.data?.signed.signedBy[0];
                    return this.userNameFormatter(keyData);
                },
                autoHeight: true,
                width: 250,
            },
            {
                field: 'date',
                headerName: 'Date & Time',
                valueFormatter: (params) => {
                    const completionDate = params.data?.signed?.signDate;
                    const completionTime =
                        ('0' + new Date(completionDate).getHours()).slice(-2) +
                        ':' +
                        ('0' + new Date(completionDate).getMinutes()).slice(-2);
                    return completionDate
                        ? this.transformDate(new Date(completionDate)) + ' ' + completionTime
                        : '-------';
                },
            },
        ],
        data: [],
    };

    dateFormat(date): string {
        const d = parse(date, 'yyyy-MM-dd', new Date());
        return format(d, 'MMMM, dd h:mm a');
    }
    private transformDate = (date) => {
        if (!date) {
            return '';
        }
        return this.datePipe.transform(new Date(date), 'MM/dd/yyyy');
    };
    userNameFormatter(user: any) {
        return user?.roles
            ? `<p class="name mb-0" >${user.lastName + ', ' + user.firstName}</p><span>${user.roles.map(
                  (role) => role.name
              )}</span>`
            : `<p class="name mb-0" >${
                  this.user.user.lastName + ', ' + this.user.user.firstName
              }</p><span>${this.user.user.roles.map((role) => role.name)}</span>`;
    }
    currentAdmission: TherapyAdmission;
    therapyAdmissions: TherapyAdmission[];
    therapyDiscipline: string;
    medicalDiagnosisData: any[] = [];
    dailyNoteId: string;
    dailyNote: DailyNote;
    loadingObs: any;
    user: any;
    currentAddendum: string;
    cptData = [];
    UNDEFINED_TIME_MSG = 'NA';

    changeHistory: DNChangeHistory[];
    selectedChangeInstance = 0;
    selectedLabel = '';
    therapistDiscipline: any;
    isDifferentDisciplineTherapist = false;
    currentDocSigned = false;

    constructor(
        private route: ActivatedRoute,
        private datePipe: DatePipe,
        private modalService: ModalService,
        private toasterService: ToasterService,
        private authService: AuthService,
        private storeService: StoreService,
        private cptCodeService: CptCodesService,
        private store: Store<{
            therapyAdmission: TherapyAdmission[];
            facilityAdmission: FacilityAdmission;
            user: Account;
        }>,
        private goalService: GoalService,
        private DNService: DailyNotesService
    ) {
        this.loadingObs = this.storeService.getLoadingState();
        this.store
            .select('facilityAdmission')
            .pipe(takeUntil(this.onDestroy))
            .subscribe((data) => {
                if (data.patientDischarge) {
                    this.admissionDischarged = true;
                }
            });
        this.route.queryParamMap.subscribe({
            next: (params) => {
                this.therapyDiscipline = params.get('discipline');
                this.dailyNoteId = params.get('doc');
            },
        });
        this.store.pipe(select('user')).subscribe((user) => {
            if (user) {
                this.user = user;
            }
        });
        this.DNService.getDNEditHistory(this.dailyNoteId).subscribe({
            next: (result) => {
                if (result) {
                    this.changeHistory = result;
                }
            },
            complete: () => {
                this.prepareChangeInstanceData(0);
            },
        });
    }

    divScroll = (event): void => {
        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight - 500)
            this.enableCoSignBtn = true;
    };

    ngOnInit(): void {
        this.contentEl.addEventListener('scroll', this.divScroll, true);

        if (this.printMode) {
            const { discipline, id } = this.printData;
            this.therapyDiscipline = discipline;
            this.dailyNoteId = id;
        }
        this.store
            .select('therapyAdmission')
            .pipe(takeUntil(this.onDestroy))
            .subscribe((data) => {
                this.currentAddendum = '';
                this.therapyAdmissions = data;

                const queryString = this.route.snapshot.queryParams;
                this.currentAdmission = findTherapyCaseByParamId(data, queryString['therapy-case']);
                this.therapyDiscipline ||= this.currentAdmission?.therapyDiscipline;
                this.therapyDiscipline ||= 'PT';
                this.currentAdmission = cloneDeep(this.currentAdmission);

                if (this.currentAdmission) {
                    this.dailyNote = this.currentAdmission?.documentation.dailyNote.find(
                        (dailyNote) => dailyNote.id == this.dailyNoteId
                    );
                    // if(!this.dailyNote.objectiveAssessment.anticipatedDischargeDate) {
                    //     this.anticipatedDischargeDate.date = this.currentAdmission.anticipatedDischargeDate;
                    // } else {
                    //     this.anticipatedDischargeDate.date = this.dailyNote.objectiveAssessment.anticipatedDischargeDate;
                    // }
                    this.showCoSign = isAllowedToCoSign(this.currentAdmission, this.user, this.dailyNote.coSigned);
                    this.addendum.data = this.dailyNote.addendum;
                    this.currentDocSigned = !!this.dailyNote?.signed?.signDate;
                }
            });
        if (this.authService.isTherapist) {
            this.therapistDiscipline = this.authService.accountValue.user.therapist.discipline;
            if (this.therapistDiscipline.replace('A', '') !== this.therapyDiscipline) {
                this.isDifferentDisciplineTherapist = true;
            }
        } else if (this.authService.isManager || this.authService.isSuperAdmin) this.isDifferentDisciplineTherapist = true;
    }

    onCoSignClicked() {
        if (this.admissionDischarged) {
            const data = {
                heading: 'Confirmation',
                icon: '',
                btn1Text: 'Cancel',
                btn2Text: 'Continue',
                lowerBodyValue: '',
                iconFilled: true,
                bodyText: `This patient has a facility discharge, would you like to continue?`,
            };
            const dialogRef = this.modalService.open(TimeConfirmationDialogComponent, {
                data,
            });
            dialogRef.afterClosed().subscribe((res) => {
                if (res) {
                    this.modalService.open(CoSignNoteComponent, {
                        data: {
                            documentType: Doc_Notes.Daily_note,
                            heading: 'Daily note',
                            document: this.dailyNote,
                            changeHistoryCount: this.changeHistory.length,
                        },
                    });
                }
            });
        } else {
            this.modalService.open(CoSignNoteComponent, {
                data: {
                    documentType: Doc_Notes.Daily_note,
                    heading: 'Daily note',
                    document: this.dailyNote,
                    changeHistoryCount: this.changeHistory.length,
                },
            });
        }
    }

    prepareChangeInstanceData(item: number) {
        if (this.changeHistory) {
            this.selectedLabel = '';
            this.treatmentDiagnosis.data = this.changeHistory[item].patientHistory?.treatmentDiagnosis ?? [];
            this.medicalDiagnosis.data = this.changeHistory[item].patientHistory.medicalDiagnosis;

            this.precautions.data = this.prepareDynamicArrayChangeLog(item, 'patientHistory', 'precautions', 'id');
            this.contraindications.data = this.prepareDynamicArrayChangeLog(
                item,
                'patientHistory',
                'contraindications',
                'id'
            );
            this.patientsSelfReport.paragraph = prepareSimpleTextChangeLog(
                this.changeHistory,
                item,
                'subjectiveAssessment',
                'patientAssessmentSinceLastTreatment'
            );
            this.populateVitals(item);
            this.populateCptData(item);
            this.populateOtherTxMinutes(item);
            this.responseToTreatment.paragraph = prepareSimpleTextChangeLog(
                this.changeHistory,
                item,
                'assessment',
                'responseToTreatment'
            );
            this.planTreatment.paragraph = prepareSimpleTextChangeLog(
                this.changeHistory,
                item,
                'plan',
                'planForNextTreatment'
            );
            this.populateGoalsData(item);
            this.selectedChangeInstance = item;
        }
    }

    prepareDynamicArrayChangeLog(changeInstance: number, section: string, key: string, uniqueKeyMatcher = '_id') {
        const arrayData = this.changeHistory[changeInstance]?.changeHistory?.changedFields?.filter(
            (x) => x.fieldKey === key
        );
        if (arrayData && arrayData.length > 0) {
            const temp = [];
            this.changeHistory[changeInstance]?.changeHistory.changedFields
                .filter((x) => x.fieldKey === key)
                .forEach((y) => {
                    y['currentValue' as any].forEach((cv) => {
                        const found = y['previousValue' as any].find(
                            (pv) => pv[uniqueKeyMatcher] === cv[uniqueKeyMatcher]
                        );
                        if (found) {
                            const fullBody = {};
                            //delta
                            Object.keys(y['currentValue'][0]).forEach((key) => {
                                fullBody[key] = {
                                    currentValue: cv[key] ?? undefined,
                                    previousValue: found[key] ?? undefined,
                                };
                            });
                            temp.push(fullBody);
                        } else {
                            const fullBody = {};
                            //new value addition
                            Object.keys(y['currentValue'][0]).forEach((key) => {
                                fullBody[key] = { currentValue: cv[key], previousValue: undefined };
                            });
                            temp.push(fullBody);
                        }
                    });

                    y['previousValue' as any].forEach((pv) => {
                        const found = y['currentValue' as any].find(
                            (cv) => cv[uniqueKeyMatcher] === pv[uniqueKeyMatcher]
                        );
                        if (!found) {
                            const fullBody = {};
                            Object.keys(y['previousValue'][0]).forEach((key) => {
                                fullBody[key] = { currentValue: undefined, previousValue: pv[key] };
                            });
                            // value deletion
                            temp.push(fullBody);
                        }
                    });
                });
            return temp;
        } else return this.changeHistory[changeInstance][section]?.[key];
    }

    prepareDynamicGoalsArrayChangeLog(changeInstance: number, section: string, key: string, uniqueKeyMatcher = '_id') {
        const arrayData = this.changeHistory[changeInstance]?.changeHistory?.changedFields?.filter(
            (x) => x.fieldKey === key
        );
        if (arrayData && arrayData.length > 0) {
            const temp = [];
            this.changeHistory[changeInstance]?.changeHistory.changedFields
                .filter((x) => x.fieldKey === key)
                .forEach((y) => {
                    y['currentValue' as any].forEach((cv) => {
                        const found = y['previousValue' as any].find(
                            (pv) => pv[uniqueKeyMatcher] === cv[uniqueKeyMatcher]
                        );
                        if (found) {
                            const fullBody = {};
                            //delta
                            Object.keys(y['currentValue'][0]).forEach((key) => {
                                fullBody[key] = { currentValue: cv[key], previousValue: found[key] };
                            });

                            let finalStgs = [];
                            // do in one loop outside this foreach. Remember if chunk
                            cv.stgs.forEach((stg) => {
                                const prevStg = found.stgs.find((prevStg) => prevStg.id === stg.id);
                                const stgToPush = {};
                                if (prevStg) {
                                    Object.keys(stg).forEach((key) => {
                                        stgToPush[key] = { currentValue: stg[key], previousValue: prevStg[key] };
                                    });
                                } else {
                                    Object.keys(stg).forEach((key) => {
                                        stgToPush[key] = { currentValue: stg[key], previousValue: undefined };
                                    });
                                }
                                finalStgs.push(stgToPush);
                            });
                            fullBody['stgs'].currentValue = finalStgs;

                            // currentV = finalStgs ?
                            temp.push(fullBody);
                            finalStgs = [];
                        } else {
                            const fullBody = {};
                            //new value addition
                            Object.keys(y['currentValue'][0]).forEach((key) => {
                                fullBody[key] = { currentValue: cv[key], previousValue: undefined };
                            });
                            temp.push(fullBody);
                        }
                    });

                    y['previousValue' as any].forEach((pv) => {
                        const found = y['currentValue' as any].find(
                            (cv) => cv[uniqueKeyMatcher] === pv[uniqueKeyMatcher]
                        );
                        if (!found) {
                            const fullBody = {};
                            Object.keys(y['previousValue'][0]).forEach((key) => {
                                fullBody[key] = { currentValue: undefined, previousValue: pv[key] };
                            });
                            // value deletion
                            temp.push(fullBody);
                        }
                    });
                });
            return temp;
        } else return this.changeHistory[changeInstance][section]?.[key];
    }

    prepareDynamicOtherModeMinutesChangeLog(
        changeInstance: number,
        section: string,
        key: string,
        uniqueKeyMatcher = '_id'
    ) {
        const arrayData = this.changeHistory[changeInstance]?.changeHistory?.changedFields
            ?.filter((x) => x.fieldKey === key)
            .map((y) => {
                if (!Array.isArray(y.currentValue)) y.currentValue = [y.currentValue];
                if (!Array.isArray(y.previousValue)) y.previousValue = [y.previousValue];
                return y;
            });
        if (arrayData && arrayData.length > 0) {
            const temp = [];
            this.changeHistory[changeInstance]?.changeHistory.changedFields
                .filter((x) => x.fieldKey === key)
                .forEach((y) => {
                    y['currentValue' as any].forEach((cv) => {
                        const found = y['previousValue' as any].find(
                            (pv) => pv?.[uniqueKeyMatcher] === cv?.[uniqueKeyMatcher]
                        );
                        if (found) {
                            const fullBody = {};
                            //delta
                            Object.keys(y['currentValue'][0]).forEach((key) => {
                                fullBody[key] = { currentValue: cv[key], previousValue: found[key] };
                            });
                            temp.push(fullBody);
                        } else {
                            const fullBody = {};
                            //new value addition
                            Object.keys(y['currentValue'][0]).forEach((key) => {
                                fullBody[key] = { currentValue: cv[key], previousValue: undefined };
                            });
                            temp.push(fullBody);
                        }
                    });

                    y['previousValue' as any].forEach((pv) => {
                        const found = y['currentValue' as any].find(
                            (cv) => cv?.[uniqueKeyMatcher] === pv?.[uniqueKeyMatcher]
                        );
                        if (!found) {
                            const fullBody = {};
                            Object.keys(y['previousValue'][0]).forEach((key) => {
                                fullBody[key] = { currentValue: undefined, previousValue: pv[key] };
                            });
                            // value deletion
                            temp.push(fullBody);
                        }
                    });
                });
            return temp;
        } else {
            return this.changeHistory[changeInstance][section]?.[key];
        }
    }

    populateVitals(changeInstance: number) {
        this.vitals.data = this.prepareDynamicArrayChangeLog(changeInstance, 'objectiveAssessment', 'vitals');
        if (!Array.isArray(this.vitals.data)) this.vitals.data = [this.vitals.data];
        if (
            !this.vitals ||
            !this.vitals.data ||
            !this.vitals.data[0] ||
            Object.keys(this.vitals?.data[0])?.every((v) => this.vitals.data[0][v] === '' || !this.vitals.data[0][v])
        ) {
            this.vitals.data = [];
        }
    }

    populateCptData(changeInstance: number) {
        const cptData = this.prepareDynamicArrayChangeLog(
            changeInstance,
            'objectiveAssessment',
            'detailedCptCodes',
            'code'
        );
        if (cptData[0]?.code.currentValue) {
            this.cptCodes.data = cptData.map((row) => {
                return {
                    code: { currentValue: row.code.currentValue, previousValue: row.code.previousValue },
                    time: {
                        currentValue:
                            this.getFormattedTime(row.cptMinutes.currentValue) === 'NA'
                                ? undefined
                                : this.getFormattedTime(row.cptMinutes.currentValue),
                        previousValue:
                            this.getFormattedTime(row.cptMinutes.previousValue) === 'NA'
                                ? undefined
                                : this.getFormattedTime(row.cptMinutes.previousValue),
                    },
                    description: {
                        currentValue: this.changeHistory[changeInstance].objectiveAssessment.skilledServices?.find(
                            (item) => item && item?.cptCode === row.code.currentValue
                        )?.description,
                        previousValue: this.changeHistory[changeInstance].objectiveAssessment.skilledServices?.find(
                            (item) => item && item?.cptCode === row.code.previousValue
                        )?.description,
                    },
                    goal: {
                        currentValue: row.goals.currentValue
                            ? row.goals.currentValue.map((current) => current.goal)
                            : undefined,
                        previousValue: row.goals.previousValue
                            ? row.goals.previousValue.map((current) => current.goal)
                            : undefined,
                    },
                    exercise: {
                        currentValue:
                            (row.exercises.currentValue &&
                                row.exercises.currentValue.map((current) => current.description)) ??
                            [],
                        previousValue:
                            (row.exercises.previousValue &&
                                row.exercises.previousValue.map((current) => current.description)) ??
                            [],
                    },
                    justification: {
                        currentValue: row.justification?.currentValue ? row.justification.currentValue : undefined,
                        previousValue: row.justification?.previousValue ? row.justification.previousValue : undefined,
                    },
                    isTeleHealthEligible: {
                        currentValue: row.isTeleHealthEligible?.currentValue
                            ? row.isTeleHealthEligible.currentValue
                            : undefined,
                        previousValue: row.isTeleHealthEligible?.previousValue
                            ? row.isTeleHealthEligible.previousValue
                            : undefined,
                    },
                };
            });
        } else {
            this.cptCodes.data = cptData?.map((row) => {
                return {
                    code: row.code,
                    time: this.getFormattedTime(row.cptMinutes),
                    description: this.changeHistory[changeInstance].objectiveAssessment.skilledServices?.find(
                        (item) => item && item?.cptCode === row.code
                    )?.description,
                    goal: (row.goals && row.goals.map((current) => current.goal)) ?? [],
                    exercise: (row.exercises && row.exercises.map((current) => current.description)) ?? [],
                    justification: row.justification ?? 'NA',
                    isTeleHealthEligible: this.dailyNote?.isTeleHealthEligible,
                };
            });
        }
        // grand total row
        if (this.cptCodes.data.length) {
            const onlyCPTMinutesArr = cptData
                .map((elem) => {
                    if (elem.cptMinutes.currentValue) {
                        return { code: elem.code.currentValue, cptMinutes: elem.cptMinutes.currentValue };
                    }

                    if (!elem.code.currentValue && elem.code.previousValue) {
                        return;
                    }

                    if (elem.cptMinutes) {
                        return { code: elem.code, cptMinutes: elem.cptMinutes };
                    }

                    return { code: elem.code, cptMinutes: 0 };
                })
                .filter((elem) => elem?.code);

            const totalUnits = this.cptCodeService.calculateTotalUnits(onlyCPTMinutesArr);

            const unitsString = `(${totalUnits} ${totalUnits > 1 ? 'units' : 'unit'})`;

            this.cptCodes.data.push({
                code: 'Total',
                description: null,
                time: this.cptCodeService.grandTotalMinutes(onlyCPTMinutesArr) + ' min ' + unitsString,
                goal: null,
                exercise: null,
                justification: '',
            });
        }
    }

    populateGoalsData(item: number) {
        const goals = this.prepareDynamicGoalsArrayChangeLog(item, 'objectiveAssessment', 'goals', 'id');
        if (goals?.length && goals[0]?.goal?.currentValue) {
            this.goal = this.goalService.buildGoalsWithDiffView(goals);
        } else {
            this.goal = this.goalService.buildGoal(goals);
        }
    }

    /**
     * Function to scroll to a specific section when user clicks item on edit history section
     * @param id - Id of the section that user clicked
     * @param index - Index of the currently selected history (there can be multiple histories)
     */
    scroll(id: string, index: number, key?: string): void {
        event.stopPropagation();
        if (this.DEEP_CHANGE_KEYS.includes(key)) return;
        if (index === this.selectedChangeInstance) {
            this.selectedLabel = id;
            id = this.generateSectionId(id);
            const sectionElement: ElementRef = new ElementRef(document.getElementById(id));
            scrollToElementWithOffset(sectionElement, 'start');
        } else {
            // If user is clicking on other history Instance, then select that history instance
            this.prepareChangeInstanceData(index);
        }
    }

    /**
     * Function to generate ID to move to that section in HTML
     * @param id - Current ID coming as label
     * @returns - ID according to sections
     */
    generateSectionId(id): string {
        // As there are some label that have dots so we need the later part
        const str = id.includes('.') ? id.split('.')[1] : id.split('.')[0];
        return str + 'Section';
    }

    getFormattedTime(minutes) {
        if (!minutes) {
            return this.UNDEFINED_TIME_MSG;
        }

        const totalMinutesFormatted: string = '' + minutes + ' min';

        return totalMinutesFormatted === '0 min' ? this.UNDEFINED_TIME_MSG : totalMinutesFormatted;
    }

    populateOtherTxMinutes(changeInstance: number): void {
        const otherModeMinutes = this.changeHistory[changeInstance].objectiveAssessment.otherModeMinutes;

        this.otherTxMinutes.data = [];

        if (!otherModeMinutes) {
            return;
        }

        const otherCoTreatMinutes = this.prepareDynamicOtherModeMinutesChangeLog(
            changeInstance,
            'objectiveAssessment',
            'otherModeMinutes.coTreat'
        );
        const otherConcurrentMinutes = this.prepareDynamicOtherModeMinutesChangeLog(
            changeInstance,
            'objectiveAssessment',
            'otherModeMinutes.concurrent'
        );

        this.otherTxMinutes.data = Object.keys(otherModeMinutes).map((item) => {
            const disciplines = otherModeMinutes[item]?.disciplines?.filter(
                (elem) => elem.discipline !== this.therapyDiscipline
            );

            return {
                mode: item,
                minutes: otherModeMinutes[item]?.minutes,
                disciplines:
                    disciplines?.reduce(
                        (accumulator, current, index) =>
                            accumulator +
                            ' ' +
                            `${current.therapistId?.user?.lastName}, ${current.therapistId?.user?.firstName}(${
                                current.discipline
                            })${index !== disciplines.length - 1 ? ' ,' : ''}`,
                        ''
                    ) ?? [],
                justification: otherModeMinutes[item]?.justification,
            };
        });
        if (otherCoTreatMinutes && Array.isArray(otherCoTreatMinutes)) {
            const foundIndex = this.otherTxMinutes.data.findIndex((x) => x['mode'] === 'coTreat');
            if (foundIndex !== -1)
                this.otherTxMinutes.data[foundIndex] = otherCoTreatMinutes
                    .map((item) => {
                        return {
                            mode: 'coTreat',
                            minutes: {
                                currentValue: item.minutes?.currentValue ? item.minutes.currentValue : undefined,
                                previousValue: item.minutes?.previousValue ? item.minutes.previousValue : undefined,
                            },
                            disciplines: {
                                currentValue: this.getChangedCoTreatDisciplines(
                                    item.disciplines?.currentValue,
                                    item.disciplines?.previousValue,
                                    'current'
                                ),
                                previousValue: this.getChangedCoTreatDisciplines(
                                    item.disciplines?.currentValue,
                                    item.disciplines?.previousValue,
                                    'previous'
                                ),
                            },
                            justification: {
                                currentValue: item.justification?.currentValue
                                    ? item.justification.currentValue
                                    : undefined,
                                previousValue: item.justification?.previousValue
                                    ? item.justification.previousValue
                                    : undefined,
                            },
                        };
                    })
                    .pop();
        }
        if (otherConcurrentMinutes && Array.isArray(otherConcurrentMinutes)) {
            const foundIndex = this.otherTxMinutes.data.findIndex((x) => x['mode'] === 'concurrent');
            if (foundIndex !== -1)
                this.otherTxMinutes.data[foundIndex] = otherConcurrentMinutes
                    .map((item) => {
                        return {
                            mode: 'concurrent',
                            minutes: {
                                currentValue: item.minutes?.currentValue ? item.minutes.currentValue : undefined,
                                previousValue: item.minutes?.previousValue ? item.minutes.previousValue : undefined,
                            },
                        };
                    })
                    .pop();
        }
        // Filter modes whose minutes don't exist
        this.otherTxMinutes.data = this.otherTxMinutes.data
            .map((x) => {
                if (!x['minutes']) return null;
                else return x;
            })
            .filter((y) => y);
    }

    /**
     * This function will check the difference in disciplines and return the value.
     * @param { any } currentDisciplines - value of current Disciplines
     * @param { any } previousDisciplines - value of previous Disciplines
     * @returns { string | undefined }
     */
    getChangedCoTreatDisciplines(currentDisciplines: any[], previousDisciplines: any[], get: string): string {
        const disciplines: any[] = [];

        if (get === 'current') {
            currentDisciplines?.forEach((currentDiscipline) => {
                const foundIndex = previousDisciplines?.findIndex(
                    (previousDiscipline) =>
                        previousDiscipline.discipline === this.therapyDiscipline &&
                        previousDiscipline.discipline === currentDiscipline.discipline &&
                        previousDiscipline.therapistId.user.firstName ===
                            currentDiscipline.therapistId.user.firstName &&
                        previousDiscipline.therapistId.user.lastName === currentDiscipline.therapistId.user.lastName
                );

                if (foundIndex === -1) {
                    disciplines.push(currentDiscipline);
                }
            });
        }

        if (get === 'previous') {
            previousDisciplines?.forEach((previousDiscipline) => {
                const foundIndex = currentDisciplines?.findIndex(
                    (currentDiscipline) =>
                        currentDiscipline.discipline === this.therapyDiscipline &&
                        currentDiscipline.discipline === previousDiscipline.discipline &&
                        currentDiscipline.therapistId.user.firstName ===
                            previousDiscipline.therapistId.user.firstName &&
                        currentDiscipline.therapistId.user.lastName === previousDiscipline.therapistId.user.lastName
                );

                if (foundIndex === -1) {
                    disciplines.push(previousDiscipline);
                }
            });
        }

        const disciplinesAsString = disciplines.reduce(
            (accumulator: any, current: any) =>
                accumulator +
                ` ${current.therapistId.user.lastName}, ${current.therapistId.user.firstName} (${current.discipline}) `,
            ''
        );
        return disciplinesAsString || undefined;
    }

    updateAddendum(description) {
        this.currentAddendum = description;
    }

    padTo2Digits(number: number) {
        return number.toString().padStart(2, '0');
    }
    addAddendumNote() {
        if (this.authService.isManager || this.authService.isTherapist) {
            if (this.currentAddendum) {
                const addendumNote = {
                    description: this.currentAddendum,
                };
                this.modalService.open(SignNoteComponent, {
                    data: {
                        documentType: 'dailyNote',
                        addendumNote,
                        dailyNote: this.dailyNoteId,
                        heading: 'Addendum Note',
                    },
                });
            } else {
                this.toasterService.show({ title: 'Failure', body: 'Add description', type: 'error' });
            }
        }
    }

    public tooltipCheck = () => {
        if (this.admissionDischarged) {
            return 'Patient Discharged';
        } else if (!this.authService.isManager && !this.authService.isTherapist) {
            return 'You are not authorized to add addendum';
        } else if (this.isDifferentDisciplineTherapist) {
            return '';
        } else {
            return 'Add Addendum Note';
        }
    };

    openEvalHistory() {
        this.evalHistorySide = true;
        this.hideBtn = false;
        this.prepareChangeInstanceData(this.changeHistory?.length == 1 ? 0 : 1);
    }

    closeHistorySideBar(event: boolean) {
        this.evalHistorySide = event;
        this.prepareChangeInstanceData(0);
    }
}
