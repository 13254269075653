import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GridActionsService } from '@app/data-grid/services/grid-actions.service';
import { BtnCellRenderer, ROUTER_UTILS } from '@app/helpers';
import { OrganizationManagementService, StoreService, ToasterService } from '@app/services';
import { ColDef, GridApi } from 'ag-grid-community';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'app-organization-list',
    templateUrl: './organization-list.component.html',
    styleUrls: ['./organization-list.component.scss'],
})
export class OrganizationListComponent implements OnInit, OnDestroy {
    organizations: any[];
    private readonly onDestroy = new Subject<void>();
    columnDefs: ColDef[] = [
        {
            headerName: 'No',
            field: 'no',
            valueGetter: 'node.rowIndex + 1',
            width: 80,
        },
        {
            headerName: 'Organization Name',
            field: 'name',
            width: 300,
        },
        {
            headerName: 'Facility Count',
            field: 'facilityCount',
            valueGetter: (v) => {
                return v.data.facility.length > 0 ? v.data.facility.length : 'N/A';
            },
            width: 300,
        },
        {
            headerName: 'Company Code',
            field: 'companyCode',
            width: 200,
        },
        {
            headerName: 'Actions',
            field: 'type',
            type: 'rightAligned',
            cellRenderer: BtnCellRenderer,
            cellRendererParams: {
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                clicked: () => {},
            },
            width: 200,
        },
    ];
    gridApi: GridApi;
    public SKELETON_CSS = {
        CELL: {
            width: '96%',
            height: '3.4em',
            margin: '0 2em 0.1em 2em',
        },
    };
    loadingObj: any;
    constructor(
        private router: Router,
        private toasterService: ToasterService,
        private gridActionService: GridActionsService,
        private storeService: StoreService,
        private organizationService: OrganizationManagementService
    ) {
        this.gridActionService.action.pipe(takeUntil(this.onDestroy)).subscribe((val) => {
            if (val.gridName === 'Organizations') this.openViewMode(val);
        });
        this.storeService.setLoadingState(true);
        this.loadingObj = this.storeService.getLoadingState();
    }

    ngOnInit(): void {
        this.organizationService.__Organizations.subscribe((res) => {
            this.organizations = res;
            this.storeService.setLoadingState(false);
        });
    }

    initGrid(event: any) {
        this.gridApi = event.api;
    }

    openViewMode(event) {
        const rootURL = ROUTER_UTILS.config.admin.root + '/' + ROUTER_UTILS.config.admin.organization.root;
        switch (event.actionType) {
            case 'View Facilities':
                if (event.data.facility.length > 0) {
                    const url = ROUTER_UTILS.config.admin.organization.viewOrganization;
                    this.router.navigate([`${rootURL}/${url}`], { queryParams: { organization: event.data.id } });
                } else {
                    this.toasterService.show({
                        title: '',
                        body: 'No Facility Associated with this organization',
                        type: 'warning',
                    });
                }
                break;
            case 'Edit Organization':
                this.router.navigate([`${rootURL}/${ROUTER_UTILS.config.admin.organization.editOrganization}`], {
                    state: {
                        data: { id: event.data.id, name: event.data.name, companyCode: event.data.companyCode },
                    },
                });
        }
    }
    ngOnDestroy(): void {
        this.onDestroy.next();
    }

    onRowClicked(event) {
        const orgId = event?.data?.id;
        const conceptRehabURL = `/${ROUTER_UTILS.config.admin.organization.root}/${ROUTER_UTILS.config.admin.organization.conceptRehabPartners}/${orgId}`;
        this.router.navigateByUrl(conceptRehabURL);
    }
}
