<button
    [placement]="tooltipPlacement"
    [ngbTooltip]="tooltipText"
    [class]="generateClass()"
    [disabled]="disabled"
    [style]="style"
    (click)="onClick.emit()"
>
    <ng-container *ngIf="icon !== ''">
        <span
            [ngClass]="{
                disabled: disabled,
                enabled: !disabled,
                'material-icons': !filled,
                'material-icons-outlined': filled
            }"
            *ngIf="rightIcon !== true"
            [class]="iconClass"
            [style]="iconStyle"
        >
            {{ icon }}
        </span>
    </ng-container>
    <span [ngClass]="{ disabled: disabled, enabled: !disabled }">{{ text | titlecase }}</span>
    <ng-container *ngIf="icon !== ''">
        <span
            [ngClass]="{
                disabled: disabled,
                enabled: !disabled,
                'material-icons': !filled,
                'material-icons-outlined': filled
            }"
            *ngIf="rightIcon === true"
            [class]="iconClass"
            [style]="iconStyle"
        >
            {{ icon }}
        </span>
    </ng-container>
</button>
