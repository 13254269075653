import { REPORT_GRIDS } from '@app/helpers/constants/documentation-report';
import { Exercises } from './cpt-codes';
import { MedicalDiagnosis, TherapyDiscipline, TreatmentDiagnosis } from './therapyAdmission';
export interface Documentation {
    evaluation: EvaluationDocument;
    dailyNote: DailyNote[];
    progressNote: ProgressNote[];
    updatedPlanOfCareNote: UpdatedPlanOfCareNote[];
    dischargeNote: DischargeNote;
    recertificationNote: RecertificationNote[];
    addendum: Addendum[];
    auditLog: any;
    id?: string;
}

//eval start
export interface EvaluationDocument {
    medicalReview: MedicalReview;
    backgroundAssessment: BackgroundAssessment;
    medicalNecessity: MedicalNecessity;
    patientAssessment: PatientAssessment;
    planOfCare: PlanOfCare;
    evaluationType: {
        minutes?: number;
        evalType: string;
        evalOnly: boolean;
        description: string;
        evalOnlyReason?: string;
    };
    status: string;
    addendum: Addendum[];
    changeHistoryPrint: ChangeHistoryPrint[];
    id?: string;
    day: string;
    signed?: {
        signDate: string;
        signedBy: any;
        therapistType: string;
    };
    coSigned?: { signedDate: string | null; signedBy: string };
    caseload?: any;
    isJDoc: boolean;
}

export interface MedicalReview {
    //medical and treatment diagnosis are two steps above
    reasonForReferral: string;
    chiefComplaint: string;
    precautions: { number: number; description: string }[];
    contraindications: { number: number; description: string }[];
    medications: { number: number; description: string }[];
    treatmentDiagnosis: { code: string; description: string; date: string }[];
    medicalDiagnosis: {
        code: string;
        description: string;
        clinicalCategory: string;
        majorProcedure: string;
        comorbidity: string;
    }[];
    priorHospitalizationDates: PriorHospitalizationDate[];
    pastMedicalHistory: PastMedicalEvent[];
}
export interface PastMedicalEvent {
    _id?: string;
    description: string;
}
export interface PriorHospitalizationDate {
    admission: Date;
    discharge: Date;
    description: string;
    id: string;
}

export interface BackgroundAssessment {
    patientGoals: { number: number; description: string }[];
    priorLevelOfFunction: string;
    socialDeterminantsOfHealth: { number: number; description: string }[];
    environmentalFactors: { number: number; description: string }[];
    previousTherapy: string;
    careGiverEducation: string;
}
export interface MedicalNecessity {
    therapyNecessity: string;
    interventionApproaches: string;
}

export interface CLOF_PLOF {
    ggScore: string;
    assistiveDevice: string;
    levelOfFunction: string;
}

export type CLOF = CLOF_PLOF;

export type PLOF = CLOF_PLOF;

export interface FunctionalDeficits {
    name: string;
    type: string;
    isGG: boolean;
    hasAssistiveDevice?: boolean;
    clof: CLOF;
    plof: PLOF;
    isAGoal: boolean;
    _id: string;
}

export interface Impairments {
    [key: string]: any;
}

export interface StandardizedTests {
    name: string;
    score: number;
    interpretation: string;
    note: string;
    questionnaire: any;
    isCompleted?: boolean;
    isAGoal: boolean;
}
export interface PatientAssessment {
    functionalDeficits: FunctionalDeficits[];
    impairments: Impairments[];
    standardizedTests: StandardizedTests[];
}
export interface PlanOfCare {
    goal: string;
    frequencyAndDuration: FrequencyAndDuration[];

    skilledServices: TreatmentDiagnosis[];

    certification: { fromDate: string; throughDate: string };

    goalPurpose: string;

    anticipatedDischarge: {
        dischargePlans: string;
        dischargeDestination: string;
        anticipatedDischargePlans: string;
        anticipatedDischargeDestination: string;
    };

    temp: string;

    goals: Goal[];
}

export interface Goal {
    id?: number | string;
    goalName?: string;
    goal: string;
    plof: {
        levelOfFunction: string;
        assistiveDevice: string;
    };
    clof: {
        levelOfFunction: string;
        assistiveDevice: string;
    };
    assistiveDevice: string;
    target: string;
    targetDate: string;
    updatedTargetDate: string;
    goalPurpose: string;
    stgs: Goal[];
    scale?: {
        scaleType: string;
        assessmentType: string;
        assessmentId?: string;
        scaleDetails: {
            name: string;
            minValue: number;
            maxValue: number;
            placeholder: string;
            options?: any;
            scaleInterpretation: string;
        };
    };
    status: { description: string; reason: string; dateOfCompletion?: string };
    minTargetDate?: Date | string;
    maxTargetDate?: Date | string;
}

//eval finish

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface DailyNote {
    id: string;
    patientHistory: PatientHistory;
    subjectiveAssessment: { patientAssessmentSinceLastTreatment: string };
    objectiveAssessment: {
        vitals: {
            respiratoryRate: string;
            temperature: string;
            pulseRate: string;
            bloodPressure: string;
            o2Saturation: string;
        };
        careGiverEducation: string;
        goals: Goal[];
        skilledServices: SkilledServices[];
        detailedCptCodes: DetailedCptCodes[];
        otherModeMinutes: OtherModeMinutes;
        anticipatedDischargeDate: string;
    };
    assessment: { responseToTreatment: string };
    plan: {
        planForNextTreatment: string;
    };
    status: string;
    reason: string;
    signed: { signDate: string; signedBy: string; therapistType: string };
    coSigned?: { signedDate: string | null; signedBy: string };
    day: string;
    addendum: Addendum[];
    changeHistoryPrint: ChangeHistoryPrint[];
    plannedDay: string;
    isTeleHealthEligible?: boolean;
    isSplit: boolean;
    evalRelated: boolean;
    splitTx: number;
    withheldOrRefusedAt?: Date;
}

export interface OtherModeMinutes {
    coTreat: CoTreat;
    concurrent: Concurrent;
}

export interface Concurrent {
    minutes: number;
}
export interface CoTreat {
    minutes: number;
    disciplines: {
        discipline: string;
        therapistId: string;
    }[];
    justification: string;
}

export interface DetailedCptCodes {
    code: string;
    goals: Goal[];
    exercises: Exercises[];
    cptMinutes: number;
    justification: string;
    isMds: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ProgressNote {
    id: string;
    patientHistory: PatientHistory;
    subjectiveAssessment: {
        patientAssessmentSinceLastTreatment: string;
    };
    objectiveAssessment: {
        goals: Goal[];
    };
    assessment: {
        clinicalImpression: string;
    };
    plan: {
        overallPlanAndUpdates: string;
    };
    status: string;
    signed: { signDate: string; signedBy: string; therapistType: string };
    coSigned?: { signedDate: string | null; signedBy: string };
    day: string;
    addendum: Addendum[];
    changeHistoryPrint: ChangeHistoryPrint[];
    re_signed: any;
}
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UpdatedPlanOfCareNote {
    id: string;
    patientHistory: PatientHistory;
    subjectiveAssessment: { reason: string };
    objectiveAssessment: PatientAssessment;
    assessment: {
        reasonForUpdate: string;
    };
    plan: {
        goals: Goal[];
        skilledServices: SkilledServices[];
        frequencyAndDuration: FrequencyAndDuration[];
        certification: { fromDate: string; throughDate: string };
    };
    status: string;
    signed: { signDate: string; signedBy: string; therapistType: string };
    coSigned?: { signedDate: string | null; signedBy: string };
    day: string;
    type: string;
    addendum: Addendum[];
}
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface DischargeNote {
    id: string;
    patientHistory: {
        medicalDiagnosis: MedicalDiagnosis[];
        treatmentDiagnosis: TreatmentDiagnosis[];
    };
    subjectiveAssessment: {
        patientAssessmentSinceTreatmentStarted: string;
    };
    goalsStatus: Goal[];
    objectiveAssessment: {
        functionalDeficits: [
            {
                name: string;
                clof: CLOF_PLOF;
            }
        ];
        ggItems: [
            {
                name: string;
                clof: {
                    levelOfFunction: string;
                };
            }
        ];
    };
    assessment: {
        clinicalImpression: string;
        endOfCareDate: string;
        endOfCareReason: string;
    };
    plan: {
        dischargeInstructions: string;
        dischargeDestination: string;
        caregiverEducation: string;
    };
    status: string;
    signed: { signDate: string; signedBy: string; therapistType: string };
    coSigned?: { signedDate: string | null; signedBy: string };
    day: string;
    addendum: Addendum[];
    changeHistoryPrint: ChangeHistoryPrint[];
    type: string;
}
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface RecertificationNote {
    addendum: Addendum[];
    id: string;
    patientHistory: PatientHistory;
    subjectiveAssessment: {
        patientAssessmentSinceTreatmentStarted: string;
    };
    objectiveAssessment: {
        impairments: Impairments[];
        functionalDeficits: FunctionalDeficits[];
        standardizedTests: StandardizedTests[];
    };
    assessment: {
        clinicalImpression: string;
        therapyNecessity: string;
        prognosisForAchievingRehabGoals: string;
        reasonForContinuingTreatment: string;
        updateToTreatment: string;
        anticipatedDischarge: {
            dischargeDestination: string;
            dischargePlan: string;
        };
    };
    plan: {
        goals: Goal[];
        skilledServices: SkilledServices[];
        frequencyAndDuration: FrequencyAndDuration[];
        certification: { fromDate: string; throughDate: string };
    };
    status: string;
    signed: { signDate: string; signedBy: string; therapistType: string };
    coSigned?: { signedDate: string | null; signedBy: string };
    day: string;
}

export interface Addendum {
    id: string;
    description: string;
    required: boolean;
    signed?: { signDate: string; signedBy: string; therapistType: string };
}

export interface ChangeHistoryPrint {
    changedFields: string[];
    therapist: { name: string; type: string };
    timestamp: string;
    signedAt?: string;
    signedBy?: { firstName?: string; lastName?: string };
}

export interface FrequencyAndDuration {
    range: boolean;
    frequencyFrom: string;
    frequencyTo: string;
    duration: string;
    intensity: string;
}

export interface DynamicFields {
    label?: string;
    key: string;
    required?: boolean;
    controlType: string;
    validationType?: string;
    value?: any;
    minValue?: number;
    maxValue?: number;
    dependsOn?: string;
    placeholder?: string;
    canBeAGoal?: boolean;
    scaleInterpretation?: string;
    note?: { label: string; key: string; controlType: string; value?: string };
    isRegularAlike?: boolean;
}

export interface Precautions {
    description: string;
}

export interface Contraindications {
    description: string;
}
export interface PatientHistory {
    medicalDiagnosis: MedicalDiagnosis[];
    treatmentDiagnosis: TreatmentDiagnosis[];
    precautions: Precautions[];
    contraindications: Contraindications[];
}

export interface SkilledServices {
    code: string;
    description: string;
    exercises: [];
    cptCode?: string;
    isTeleHealthEligible?: boolean;
}
export interface ClinicalSummary {
    chiefComplaint: string;
    contraindications: [];
    day: string;
    frequencyAndDuration: FrequencyAndDuration;
    medications: any;
    precautions: any;
    reasonForReferral: string;
    type: string;
    _id: string;
    skilledServices: any;
    priorHospitalizationDates: PriorHospitalizationDate[];
    medicalDiagnosis: any;
}

export interface changeHistory {
    editedAt: string;
    signedAt: string;
    signedBy: string;
    changedFields: [
        {
            section: string;
            fieldName: string;
            fieldKey: string;
            previousValue: string | object;
            currentValue: string | object;
        }
    ];
}
export interface DNChangeHistory extends DailyNote {
    changeHistory: changeHistory;
}

export interface PNChangeHistory extends ProgressNote {
    changeHistory: changeHistory;
}

export interface DCChangeHistory extends DischargeNote {
    changeHistory: changeHistory;
}

export interface DailyNoteReport {
    header: ReportHeader;
    patientAssessmentSinceLastTreatment: string;
    vitals: VitalsReport;
    treatment: DetailedCptCodes[];
    responseToTreatment: string;
    addendum: GridLayoutReport;
    planForNextTreatment: string;
    //anticipatedDischargeDate: string;
    signature: ReportSignature;
    coTreatment?: GridLayoutReport;
    changeHistoryPrint?: GridLayoutReport;
}

export interface ProgressNoteReport {
    header: ReportHeader;
    patientAssessmentSinceLastTreatment: string;
    clinicalImpression: string;
    medicalDiagnosis: GridLayoutReport;
    treatmentDiagnosis: GridLayoutReport;
    contraindications: GridLayoutReport;
    precautions: GridLayoutReport;
    goals?: GridLayoutReport;
    overallPlanAndUpdates: string;
    addendum: GridLayoutReport;
    signature: ReportSignature;
    changeHistoryPrint?: GridLayoutReport;
}

export interface RecertificationNoteReport {
    header: ReportHeader;
    patientAssessmentSinceLastTreatment: string;
    therapyNecessity: string;
    updateToTreatment: string;
    dischargeDestination: string;
    dischargePlan: string;
    clinicalImpression: string;
    prognosisForAchievingRehabGoals: string;
    medicalDiagnosis: GridLayoutReport;
    treatmentDiagnosis: GridLayoutReport;
    contraindications: GridLayoutReport;
    precautions: GridLayoutReport;
    goals?: GridLayoutReport;
    impairments?: GridLayoutReport;
    functionalDeficits?: GridLayoutReport;
    standardizedTests?: GridLayoutReport;
    skilledServices?: GridLayoutReport;
    frequencyAndDuration?: GridLayoutReport;
    reasonForContinuingTreatment: string;
    addendum: GridLayoutReport;
    signature: ReportSignature;
}

export interface UpdatedPlanOfCareNoteReport {
    header: ReportHeader;
    reasonForUpdate: string;
    medicalDiagnosis: GridLayoutReport;
    treatmentDiagnosis: GridLayoutReport;
    contraindications: GridLayoutReport;
    precautions: GridLayoutReport;
    goals?: GridLayoutReport;
    impairments?: GridLayoutReport;
    functionalDeficits?: GridLayoutReport;
    standardizedTests?: GridLayoutReport;
    skilledServices?: GridLayoutReport;
    frequencyAndDuration?: GridLayoutReport;
    addendum: GridLayoutReport;
    signature: ReportSignature;
}

export interface EvaluationNoteReport {
    header: ReportHeader;
    therapyNecessity: string;
    dischargeDestination: string;
    dischargePlan: string;
    reasonForReferral: string;
    chiefComplaint: string;
    priorLevelOfFunction: string;
    previousTherapy: string;
    caregiverEducation: string;
    prognosisForAchievingRehabGoals: string;
    interventionApproach: string;
    medicalDiagnosis: GridLayoutReport;
    treatmentDiagnosis: GridLayoutReport;
    pastMedicalHistory: GridLayoutReport;
    priorHospitalizationDates: GridLayoutReport;
    contraindications: GridLayoutReport;
    precautions: GridLayoutReport;
    reasonForNotCreatingPoc?: string;
    goals?: GridLayoutReport;
    impairments?: GridLayoutReport;
    functionalDeficits?: GridLayoutReport;
    standardizedTests?: GridLayoutReport;
    skilledServices?: GridLayoutReport;
    frequencyAndDuration?: GridLayoutReport;
    medications?: GridLayoutReport;
    patientGoals?: GridLayoutReport;
    socialDeterminantsOfHealth?: GridLayoutReport;
    environmentalFactors?: GridLayoutReport;
    evaluationType?: GridLayoutReport;
    addendum: GridLayoutReport;
    changeHistoryPrint?: GridLayoutReport;
    signature: ReportSignature;
}

export interface DischargeNoteReport {
    header: ReportHeader;
    patientAssessmentSinceLastTreatment: string;
    dischargeDestination: string;
    dischargePlan: string;
    caregiverEducation: string;
    medicalDiagnosis: GridLayoutReport;
    treatmentDiagnosis: GridLayoutReport;
    goals?: GridLayoutReport;
    functionalDeficits?: GridLayoutReport;
    clinicalImpression: string;
    endOfCareReason: string;
    sectionGg: GridLayoutReport;
    addendum: GridLayoutReport;
    changeHistoryPrint?: GridLayoutReport;
    signature: ReportSignature;
}

export interface AddendumNoteReport {
    header: ReportHeader;
    description: string;
    signature: ReportSignature;
}

export class VitalsReport {
    respiratoryRate: string;
    temperature: string;
    pulseRate: string;
    bloodPressure: string;
    o2Saturation: string;
    note?: string;

    constructor() {
        this.respiratoryRate = '';
        this.temperature = '';
        this.pulseRate = '';
        this.bloodPressure = '';
        this.o2Saturation = '';
        this.note = '';
    }
}

export class ReportHeader {
    patientName: string;
    note: string;
    dateOfService: string;
    discipline: TherapyDiscipline | null;
    mrn: string;
    dob: string;
    therapist: string;
    totalVisits: string;
    range: string;
    endCareDate: string;
    integration?: boolean;
    attendingPhysician?: string;

    constructor() {
        this.patientName = '';
        this.note = '';
        this.dateOfService = '';
        this.discipline = null;
        this.mrn = '';
        this.therapist = '';
        this.totalVisits = '';
        this.range = '';
        this.endCareDate = '';
        this.dob = '';
        this.integration = false;
        this.attendingPhysician = '';
    }
}

export class ReportSignature {
    physician?: string;
    physicianNpi?: string;
    physicianSign?: string;
    therapist?: string;
    therapistLicense?: string;
    therapistType: string;
    therapistSign?: string | null;
    therapistDiscipline?: string | null;
    signCredentials?: string | null;
    integration?: boolean;
    coSignTherapist?: string;
    coSignTherapistDiscipline?: string | null;
    coSignTherapistLicense?: string;
    coSignTherapistSign?: string | null;
    coSignTherapistSignCredentials?: string | null;
    noteDate?: string | null;
    status?: string | null;

    constructor() {
        this.physician = '';
        this.physicianNpi = '';
        this.physicianSign = '';
        this.therapist = '';
        this.therapistLicense = '';
        this.therapistSign = null;
        this.therapistDiscipline = '';
        this.signCredentials = '';
        this.integration = false;
        this.coSignTherapist = '';
        this.coSignTherapistDiscipline = '';
        this.coSignTherapistLicense = '';
        this.coSignTherapistSign = '';
        this.coSignTherapistSignCredentials = '';
        this.noteDate = '';
        this.status = '';
    }
}

export class AddendumReport {
    description: string;
    usernameWithRole: string;
    date: string;
    time: string;

    constructor() {
        this.description = '';
        this.usernameWithRole = '';
        this.date = '';
        this.time = '';
    }
}

export class DiagnosisReport {
    description: string;
    no?: string;
    admissionDate?: string;
    dischargeDate?: string;
    code?: string;
    onsetDate?: string;

    constructor() {
        this.no = '';
        this.admissionDate = '';
        this.dischargeDate = '';
        this.description = '';
        this.code = '';
        this.onsetDate = '';
    }
}

export type ReportGridTypes = REPORT_GRIDS.HEADER | REPORT_GRIDS.NON_HEADER | REPORT_GRIDS.TREE | REPORT_GRIDS.DYNAMIC;

export interface TableDataBlock {
    key?: string;
    value: string;
    width: string;
    isBold?: boolean;
    isRightBordered?: boolean;
    paddingLeft?: string;
}

export interface TreeGrid {
    data: TableDataBlock[];
    children?: TableDataBlock[][];
}

export class GridLayoutReport {
    header?: TableDataBlock[];
    data: string[] | AddendumReport[] | DiagnosisReport[] | TreeGrid[] | TreeGrid[][];

    constructor() {
        this.header = [];
        this.data = [];
    }
}

export class Credentials {
    userId: string;
    therapistNpi: string;
    license: string;
    signCredentials?: string;
    therapistType?: string;

    constructor() {
        this.userId = '';
        this.therapistNpi = '';
        this.license = '';
        this.signCredentials = '';
    }
}
