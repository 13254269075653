<div class="d-flex flex-row paddings">
    <div class="align-self-center">
        <app-go-back title="{{ title }}"></app-go-back>
    </div>
</div>

<div class="mt-1 paddings">
    <div class="mb-4">
        <app-organization-nav-bar [tabs]="tabs" (tabIndex)="selectedTab($event)"></app-organization-nav-bar>
    </div>
</div>
<div *ngIf="showOrganizationalInfo">
    <div class="paddings">
        <form [formGroup]="organizationForm">
            <!-- Organization Info -->
            <div class="custom-card">
              <div class="header">
                <p>{{ title }}</p>
              </div>
              <div class="body card-padding">
                <div class="row justify-content-start">
                  <div class="col-lg-8 col">
                    <div class="row">
                      <div class="col-md-6">
                        <app-input
                          placeholder="Type here"
                          type="text"
                          label="Organization Name"
                          formControlName="name">
                        </app-input>
                      </div>
                      <div class="col-md-6">
                        <app-input
                          placeholder="Type here"
                          type="text"
                          label="Company Code"
                          formControlName="companyCode">
                        </app-input>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          
            <!-- Organization Address -->
            <div class="address-card custom-card">
              <div class="header">
                <p>Address</p>
              </div>
              <div class="body card-padding">
                <div class="row justify-content-start">
                  <div class="col-lg-8 col">
                    <div formGroupName="address">
                      <div class="d-flex">

                        <div class="col-md-8">
                          <app-input
                            placeholder="Enter Address"
                            type="text"
                            label="Address"
                            formControlName="address">
                          </app-input>
                        </div>

                        <div class="col-md-4">
                          <app-input
                            placeholder="Enter Zip Code"
                            type="number"
                            label="Zip Code"
                            mask="00000"
                            formControlName="zipCode">
                          </app-input>
                        </div>

                        <div class=" organization-select col-md-4">
                          <app-select
                            class="col-md-6 col-lg-4 col-xl-3"
                            label="State"
                            placeholder="Choose an Option"
                            [options]="filteredStates"
                            (change)="updateCounties($event.target.value)"
                            formControlName="state">
                          </app-select>
                        </div>


                       
                      </div>
                      <div class="d-flex">
                        <div class="organization-select col-md-5">
                          <app-select
                            class="col-md-6 col-lg-4 col-xl-3"
                            label="County"
                            placeholder="Choose an option"
                            [options]="mailingCounties"
                            formControlName="county">
                          </app-select>
                        </div>

                        <div class=" col-md-5">
                          <app-input
                            placeholder="Enter Phone Number"
                            type="text"
                            label="Phone"
                            formControlName="phone">
                          </app-input>
                        </div>
                        
                        <div class="col-md-6">
                          <app-input
                            placeholder="Enter Fax"
                            type="text"
                            label="Fax"
                            formControlName="fax">
                          </app-input>
                        </div>
                       
                      </div>
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
          
          </form>
        <!-- Footer  -->
        <div class="row p-3 justify-content-end">
            <button class="innova-btn btn-danger" (click)="onCancel()">Cancel</button>
            <button [disabled]="this.organizationForm.invalid" class="innova-btn btn-secondary ml-1" (click)="saveUpdateOrganization()">
                {{ getStatus() }}
            </button>
        </div>
    </div>
</div>

<div class="paddings" *ngIf="!showOrganizationalInfo">
    <app-non-patient-care
        (goBack)="showOrganizationalInfo = $event"
        [editingOrgId]="EditingData?.id ? EditingData?.id : id"
    ></app-non-patient-care>
</div>
