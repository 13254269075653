import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthService } from '@app/services';

@Injectable({ providedIn: 'root' })
export class AuthenticationGuard implements CanActivate {
    constructor(private router: Router, private AuthService: AuthService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const account = this.AuthService.accountValue;
        if (account) {
            // check if route is restricted by role
            // if (route.data.roles && !route.data.roles.includes(account.user.role)) {
            //   // role not authorized so redirect to home page
            //   this.router.navigate(['/']);
            //   return false;
            // }

            // authorized so return true
            return true;
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/account/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}
