<div class="time-block">
    <div class="comment-box-wrapper">
        <div class="icon-box">
            <span *ngIf="timeBlockIcon" class="material-symbols-outlined"> schedule </span>
            <span *ngIf="commentIcon" class="material-symbols-outlined"> chat </span>
        </div>
        <div
            container="body"
            class="comment-box-inner text-secondary"
            #note
            [ngbTooltip]="isOverflow(note) ? description : ''"
            placement="bottom-right"
            tooltipClass="comments-tooltip"
        >
            <span class="font-weight-bold">{{ heading }}</span> {{ description }}
        </div>
    </div>
</div>
