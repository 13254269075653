import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject, takeUntil } from 'rxjs';
import { PatientState } from '../../../../store/store.interface';
import { FacilityAdmission } from '../../../../models';
import { PayorInformation, TherapyAdmission, TherapyDiscipline } from '../../../../interfaces';
import { select, Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { ROUTER_UTILS } from '@app/helpers';

@Component({
    selector: 'app-view-mode-layout',
    templateUrl: './view-mode-layout.component.html',
    styleUrls: ['./view-mode-layout.component.scss'],
})
export class ViewModeLayoutComponent implements OnInit, OnDestroy {
    private readonly onDestroy = new Subject<void>();

    public mappedData: any;
    public patientData$: Observable<PatientState>;
    public facilityAdmission$: Observable<FacilityAdmission>;
    public therapyAdmission$: Observable<TherapyAdmission[]>;
    public therapyDiscipline$: Observable<TherapyDiscipline>;
    public payorInformation$: Observable<PayorInformation>;
    public therapists: any[];

    pccUrl = '/' + ROUTER_UTILS.config.patients.pcc.root;
    therapyAdmissionUrl = '/' + ROUTER_UTILS.config.patients.pcc.therapyCase.root + '/' + ROUTER_UTILS.config.patients.pcc.therapyCase.therapyAdmission;
    therapyAdmissionObs$: any;
    pageTitle = '';

    rootPath: string;
    pathParams: any;

    constructor(
        private store: Store<{
            patient: PatientState;
            therapyAdmission: TherapyAdmission[];
            therapyDiscipline: TherapyDiscipline;
            facilityAdmission: FacilityAdmission;
            payorInformation: PayorInformation;
        }>,
        private route: ActivatedRoute
    ) {
        this.patientData$ = store.pipe(select('patient'));
        this.therapyDiscipline$ = store.pipe(select('therapyDiscipline'));
        this.therapyAdmission$ = store.pipe(select('therapyAdmission'));
        this.facilityAdmission$ = store.pipe(select('facilityAdmission'));
        this.payorInformation$ = store.pipe(select('payorInformation'));
    }

    ngOnInit(): void {
        this.updateTherapyAdmission();
        const queryString = this.route.snapshot.queryParams;
        this.rootPath = this.pccUrl + this.therapyAdmissionUrl;
        this.pathParams = { patient: queryString['patient'], tab: 'documentation', 'therapy-case': queryString['therapy-case'], 'facilityAd': queryString['facilityAd'],'isFromEOM': queryString['isFromEOM'] };

        this.route.queryParamMap.subscribe({
            next: (params) => {
                if (params.get('caseLoads')) {
                    this.rootPath = '/therapist-caseload';
                } else if (params.get('therapistDashboard')) {
                    this.rootPath = '/therapist-co-sign';
                    this.pathParams = null;
                } else if (params.get('dorDashboard')) {
                    this.rootPath = '/dor-co-sign';
                    this.pathParams = null;
                } else if (params.get('pendingDoc')) {
                    this.rootPath = '/therapist-pending-documents';
                }
            },
        });
    }

    onOutletLoaded(component) {
        this.pageTitle = component.noteTitle;
    }

    updateTherapyAdmission() {
        this.therapyAdmissionObs$ = this.therapyAdmission$.pipe(takeUntil(this.onDestroy)).subscribe((data) => {
            if (data) {
                this.mappedData = data.map((admission) => {
                    const _data: any = {};
                    _data.discipline = admission.therapyDiscipline;
                    _data.users = admission.attendingPhysicians?.map(
                        (_item) => `${_item?.user?.firstName} ${_item?.user?.lastName}`
                    );
                    return _data;
                });
                this.therapists = data.map((admission) => {
                    const _data: any = {};
                    _data.discipline = admission.therapyDiscipline;
                    _data.therapists = admission.assignedTherapists?.map((_item) => {
                        const _data: any = {};
                        _data.therapistType = _item.role;
                        _data.role = _item.therapist.discipline;
                        _data.name = `${_item.therapist?.user?.lastName} ${_item.therapist?.user?.firstName}`;
                        return _data;
                    });
                    return _data;
                });
            }
        });
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
    }
}
