<div class="input-group parent flex-nowrap">
    <span [ngClass]="{'not-enabled' : !params.enabled}" *ngIf="params.data.billable" class="currency" id="addon-wrapping">{{params?.preFix ? params?.preFix : ''}}</span>

    <input
        [placeholder]="params.placeholder"
        (keyup)="inputChanged($event)"
        [type]="params.type"
        [value]="params.data.rate"
        class="form-control rate-input"
        [ngClass]="params.className"
        aria-label="Username"
        aria-describedby="addon-wrapping"
    />
    <span [ngClass]="{'not-enabled' : !params.enabled, 'adjust-Width': params.adjustWidth}" *ngIf="params.data.billable" class="currency-unit" id="addon-wrapping">{{params?.postFix ? params?.postFix : ''}}</span>
</div>

