import { AuthPolicies } from './policies.utils';

export const GroupedPolicies = {
    AdmissionPolicies: [
        AuthPolicies.Patient.View,
        AuthPolicies.Patient.Add,
        AuthPolicies.Patient.Edit,
        AuthPolicies.Patient.All,
    ],
    PatientPolicies: [
        AuthPolicies.Patient.View,
        AuthPolicies.PatientBoard.View,
        AuthPolicies.PDPMBoard.View,
    ],
    PlanPatientPolicies: [
        AuthPolicies.Planner.View,
        AuthPolicies.Scheduler.View,
    ],
    AdminPolicies: [
        AuthPolicies.Facilities.View,
        AuthPolicies.Payors.View,
        AuthPolicies.Roles.View,
        AuthPolicies.Users.View
    ],
    ReportsPolicies: [
        AuthPolicies.ServiceLog.View,
        AuthPolicies.BillingExport.View,
        AuthPolicies.TripleCheckReport.View,
        AuthPolicies.EOMReport.View,
        AuthPolicies.MinutesByPayor.View
    ],
    BillingPolicies: [
        AuthPolicies.EOM.View,
        AuthPolicies.Invoicing.View
    ],
};
