<div
    [ngClass]="{ 'report-container': !signature?.integration, 'report-container-integration': signature?.integration }"
>
    <div class="block left">
        <div *ngIf="signature.therapist" class="row">
            <div class="col p-0 m-0">
                <span class="bold">Therapist: </span>
                <span
                    >&nbsp;{{ signature.therapist }}
                    {{
                        signature.signCredentials
                            ? signature.therapistType + ' (' + signature.signCredentials + ')'
                            : signature.therapistType
                    }}</span
                >
            </div>
        </div>
        <div *ngIf="signature.therapistLicense" class="row">
            <span class="bold">License #: </span>
            <span>&nbsp;{{ signature.therapistLicense }}</span>
        </div>
        <div *ngIf="signature.therapistSign" class="row">
            <span class="bold">Electronically Signed: </span>
            <span>&nbsp;{{ signature.therapistSign }}</span>
        </div>
        <div *ngIf="!signature.therapistSign && signature.noteDate" class="row">
            <span class="bold">{{signature.status}} At: </span>
            <span>&nbsp;{{ signature.noteDate }}</span>
        </div>
        <div *ngIf="!signature.therapistSign" class="row">
            <div class="col p-0 m-0">
                <div class="signature">
                    <span class="date">Date: </span>
                </div>
            </div>
        </div>
    </div>
    <div class="block right" *ngIf="toShowPhysician">
        <div class="row">
            <div class="col p-0 m-0">
                <span class="bold">Physician name: </span>
                <span>&nbsp;{{ signature.physician }}</span>
            </div>
        </div>
        <div *ngIf="signature.physicianNpi" class="row">
            <span class="bold">NPI #: </span>
            <span>&nbsp;{{ signature.physicianNpi }}</span>
        </div>
        <div *ngIf="signature.physicianSign" class="row">
            <span class="bold">Electronically Signed: </span>
            <span>&nbsp;{{ signature.physicianSign }}</span>
        </div>
        <div *ngIf="!signature.physicianSign" class="row">
            <div class="signature">
                <span class="date">Date: </span>
            </div>
        </div>
    </div>
</div>
<div class="report-container">
    <div class="block left" *ngIf="signature.coSignTherapistSign">
        <div *ngIf="signature.coSignTherapist" class="row">
            <div class="col p-0 m-0">
                <span class="bold">Therapist: </span>
                <span
                    >&nbsp;{{ signature.coSignTherapist }}
                    {{
                        signature.coSignTherapistSignCredentials && signature.coSignTherapistDiscipline === 'OT'
                            ? signature.coSignTherapistSignCredentials
                            : signature.coSignTherapistDiscipline
                    }}</span
                >
            </div>
        </div>
        <div *ngIf="signature.coSignTherapistLicense" class="row">
            <span class="bold">License #: </span>
            <span>&nbsp;{{ signature.coSignTherapistLicense }}</span>
        </div>
        <div *ngIf="signature.coSignTherapistSign" class="row">
            <span class="bold">Electronically Co Signed: </span>
            <span>&nbsp;{{ signature.coSignTherapistSign }}</span>
        </div>
    </div>
</div>
