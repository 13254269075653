import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable, map } from 'rxjs';
import { DatePipe } from '@angular/common';

const baseUrl = `${environment.apiUrl}/dashboard`;

@Injectable({
    providedIn: 'root',
})
export class DashboardService {
    constructor(private http: HttpClient, private datePipe: DatePipe) {}

    public getMinutesPercentages(query: string): Observable<any> {
        return this.http.get<any>(`${baseUrl}/minutes?${query}`, {
            withCredentials: true,
        });
    }
    getCaseLoadsByTherapistId(dates: any): Observable<any> {
        return this.http.post<any>(`${baseUrl}/caseloads`, dates).pipe(map((v) => v.data));
    }
    getCaseLoadListByTherapistId(dates: any): Observable<any> {
        return this.http.post<any>(`${baseUrl}/caseloads/list`, dates).pipe(map((v) => v.data));
    }
    getCaseloadsCommentsByTherapistId(dates: any): Observable<any> {
        return this.http.post<any>(`${baseUrl}/caseloads/comments`, dates).pipe(map((v) => v.data));
    }
    getTherapistAuthorizations(): Observable<any> {
        return this.http.post<any>(`${baseUrl}/authorizations`, {}).pipe(map((v) => v.data));
    }
    updateVisitStatus(data: any): Observable<any> {
        return this.http.put<any>(`${baseUrl}/update-visit-status`, data).pipe(map((v) => v.data));
    }
    getPatientsWithMinutesByFacilityId(
        facilityId: string,
        queryString = '',
        fromDate = '',
        toDate = ''
    ): Observable<any> {
        return this.http
            .get<any>(
                `${baseUrl}/patients-minutes-summary?facilityId=${facilityId}${queryString}&fromDate=${fromDate}&toDate=${toDate}`
            )
            .pipe(map((v) => v.data));
    }
    getPendingDocumentsByFacilityId(facilityId: string, therapistId = ''): Observable<any> {
        let url = '';
        if (therapistId == '') {
            url = `${baseUrl}/dor-pending-documents-count?facilityId=${facilityId}`;
        } else {
            url = `${baseUrl}/pending-documents?facilityId=${facilityId}&therapistId=${therapistId}`;
        }
        return this.http.get<any>(url).pipe(map((v) => v.data));
    }
    // If count is true, it will fetch just patient count otherwise it will fetch patient details
    getExpiringCertifications(facilityId: string, therapistId: string, isCount = false): Observable<any> {
        const url = `${baseUrl}/expiring-certifications?facilityId=${facilityId}&therapistId=${therapistId}&isCount=${isCount}`;
        return this.http.get<any>(url).pipe(map((v) => v.data));
    }
    // If count is true, it will fetch just patient count otherwise it will fetch patient details
    getExpiringCertificationByDOR(facilityId: string, isCount = false) {
        const url = `${baseUrl}/dor-expiring-certifications?facilityId=${facilityId}&isCount=${isCount}`;
        return this.http.get<any>(url).pipe(map((v) => v.data));
    }
    getCoSignPendingDocuments(facilityId: string, therapistId = ''): Observable<any> {
        const url = `${baseUrl}/get-pending-co-sign-docs?facilityId=${facilityId}&supervisingTherapist=${therapistId}`;
        return this.http.get<any>(url).pipe(
            map((v) => {
                v.data.map((data) => {
                    data.patient.patientName =
                        data.patient.lastName[0].toUpperCase() +
                        data.patient.lastName.slice(1).toLowerCase() +
                        ', ' +
                        data.patient.firstName[0].toUpperCase() +
                        data.patient.firstName.slice(1).toLowerCase();
                });
                return v.data;
            })
        );
    }
    getPendingCoSignCount(facilityId: string, therapistId = ''): Observable<any> {
        let url = '';
        if (therapistId == 'Manager') {
            url = `${baseUrl}/get-pending-co-sign-docs-count?facilityId=${facilityId}`;
        } else {
            url = `${baseUrl}/get-pending-co-sign-docs-count?facilityId=${facilityId}&supervisingTherapist=${therapistId}`;
        }
        return this.http.get<any>(url).pipe(map((v) => v.data[0]));
    }
    getPendingDocumentsListByFacilityId(facilityId: string, therapistId = ''): Observable<any> {
        let url = '';
        if (therapistId == '') {
            url = `${baseUrl}/pending-document-list?facilityId=${facilityId}`;
        } else {
            url = `${baseUrl}/pending-document-list?facilityId=${facilityId}&therapistId=${therapistId}`;
        }
        return this.http.get<any>(url).pipe(
            map((v) => {
                const pendingDocsList = [];
                v.data.forEach((item) => {
                    let obj = {};
                    if (therapistId == '') {
                        obj = {
                            name: item.therapist.user.lastName + ', ' + item.therapist.user.firstName,
                            docType: item.notes.documentType,
                            date: this.datePipe.transform(item.notes.day, 'MM/dd/yyyy'),
                            discipline: item.notes.documentation?.therapyAdmission?.therapyDiscipline,
                            patientName:
                                item.notes.documentation?.therapyAdmission?.facilityAdmission?.patient.lastName[0].toUpperCase() +
                                item.notes.documentation?.therapyAdmission?.facilityAdmission?.patient.lastName
                                    .slice(1)
                                    .toLowerCase() +
                                ', ' +
                                item.notes.documentation?.therapyAdmission?.facilityAdmission?.patient.firstName[0].toUpperCase() +
                                item.notes.documentation?.therapyAdmission?.facilityAdmission?.patient.firstName
                                    .slice(1)
                                    .toLowerCase(),
                            status: item.notes.status,
                            evaluation: item.notes.documentation?.evaluation,
                            patientId: item.notes.documentation?.therapyAdmission?.facilityAdmission?.patient._id,
                            comment: item.notes.comment,
                            facilityAdmissionId: item.notes.documentation?.therapyAdmission?.facilityAdmission?._id,
                            therapyAdmissionId: item.notes.documentation?.therapyAdmission?._id,
                            dueDate: new Date(item.notes.day).toISOString(),
                        };
                    } else {
                        obj = {
                            name:
                                item.notes.documentation?.therapyAdmission?.facilityAdmission?.patient.lastName[0].toUpperCase() +
                                item.notes.documentation?.therapyAdmission?.facilityAdmission?.patient.lastName
                                    .slice(1)
                                    .toLowerCase() +
                                ', ' +
                                item.notes.documentation?.therapyAdmission?.facilityAdmission?.patient.firstName[0].toUpperCase() +
                                item.notes.documentation?.therapyAdmission?.facilityAdmission?.patient.firstName
                                    .slice(1)
                                    .toLowerCase(),
                            docType: item.notes.documentType,
                            date: this.datePipe.transform(item.notes.day, 'MM/dd/yyyy'),
                            status: item.notes.status,
                            comment: item.notes.comment,
                            discipline: item.notes.documentation?.therapyAdmission?.therapyDiscipline,
                            documentationId: item.notes.documentation?._id,
                            id: item.notes._id,
                            _id: item._id,
                            evaluation: item.notes.documentation?.evaluation,
                            patientId: item.notes.documentation?.therapyAdmission?.facilityAdmission?.patient?._id,
                            facilityAdmissionId: item.notes.documentation?.therapyAdmission?.facilityAdmission?._id,
                            therapyAdmissionId: item.notes.documentation?.therapyAdmission?._id,
                            dueDate: new Date(item.notes.day).toISOString(),
                        };
                    }
                    pendingDocsList.push(obj);
                });
                return pendingDocsList;
            })
        );
    }

    getDOR_Documents(facilityId: string, status: any): Observable<any> {
        let url = '';
        url = `${baseUrl}/dor-documents?facilityId=${facilityId}`;
        return this.http
            .post<any>(url, {
                status: status,
            })
            .pipe(
                map((v) => {
                    return v.data;
                })
            );
    }
    //dor-upcoming-documents
    getDOR_upcomingDocuments(facilityId: string, status: any): Observable<any> {
        let url = '';
        url = `${baseUrl}/dor-upcoming-documents?facilityId=${facilityId}`;
        return this.http
            .post<any>(url, {
                status: status,
            })
            .pipe(
                map((v) => {
                    return v.data;
                })
            );
    }
    initialSort(patients) {
        return patients.sort((a, b) => {
            if (a.status === 'Expired' && b.status !== 'Expired') {
                return -1;
            }
            if (a.status !== 'Expired' && b.status === 'Expired') {
                return 1;
            }
            if (a.status === 'Expiring' && b.status !== 'Expiring') {
                return -1;
            }
            if (a.status !== 'Expiring' && b.status === 'Expiring') {
                return 1;
            }
            return 0;
        });
    }
}
