import {
    FilesAndDocuments,
    IPlannerPlannedResult,
    IPlannerUnplannedResult,
    PayorInformation,
    Scheduler,
    TherapyAdmission,
    TherapyDiscipline,
} from '@app/interfaces';

import { Account, FacilityAdmission } from '@app/models';
import {
    CaseloadCalendarState,
    loadingSpinnerState,
    PatientState,
    PlannerState,
    SchedulerCalendarState,
    SLPComorbidity,
    tabState,
} from '../store.interface';

import { ROUTER_UTILS } from '@app/helpers';

export const scheduleTabInitialState: tabState = { selectedTab: ROUTER_UTILS.config.scheduling.planner };
export const addPatientTabInitialState: tabState = { selectedTab: ROUTER_UTILS.config.addPatients.personalInformation };
export const payorRoutingInitialState: tabState = { selectedTab: ROUTER_UTILS.config.payorInformation.root };

export const initialLoginState: Account = {
    accessToken: null,
    refreshToken: null,
    user: null,
};

export const initialPatient: PatientState = {
    id: null,
    integrationEntity: {
        name: null,
        patientId: null,
    },
    firstName: null,
    middleName: null,
    lastName: null,
    gender: 'male',
    SSN: null,
    DOB: null,
    isAnIntegratedPatient: false,
    // medicareNo: null,
    // medicaidNo: null,
    // medicareIdentifier: null,
    filesAndDocuments: [
        {
            name: null,
            date: null,
            facilityAdmission: null,
            therapyAdmission: null,
            type: null,
            description: null,
            files: [],
        },
    ],
};

export const initialPlannerPatient: IPlannerUnplannedResult | IPlannerPlannedResult = null;
export const initialFacilityAdmission: FacilityAdmission = {
    id: null,
    admitDate: null,
    admittedFrom: null,
    admissionType: 'Inpatient',
    lastAcute: null,
    MRN: null,
    admissionID: null,
    roomNumber: null,
    capOT: null,
    capPT: null,
    therapyTeam: null,
    filesAndDocuments: [],
    isAnIntegratedPatient: false,
    attendingPhysician: null,
};
export const initialPastFacilityAdmissions: FacilityAdmission[] = [];
export const initialFilesAndDocuments: FilesAndDocuments[] = [];
export const initialPlannerTherapyAdmissions: TherapyAdmission[] = [];
export const initialTherapyAdmission: TherapyAdmission[] = [];
export const initialCurrentTherapyAdmission: TherapyAdmission | null = null;
export const initialPlannerState: PlannerState = 'unplanned';
export const initialLoadingSpinnerState: loadingSpinnerState = { isLoading: false };
export const initialDocumentLoadingSpinnerState: loadingSpinnerState = { isLoading: false };
export const initialTherapyLoadingSpinnerState: loadingSpinnerState = { isLoading: false };

export const initialTherapyDiscipline: TherapyDiscipline = null;

export const slpComorbidityInitialState: SLPComorbidity[] = [];

export const initialPayorInformation: PayorInformation = {
    id: '',
    medicareNumber: '',
    medicaidNumber: '',
    medicareBeneficiaryIdentifier: '',
    payor: '',
    insuranceCoverage: [''],
    patient: '',
};
export const initialScheduler: Scheduler = {
    patients: [],
    therapists: [],
};
export const initialSchedulerCalendarState: SchedulerCalendarState = { selectedDate: null };
export const initialCaseloadCalendarState: CaseloadCalendarState = { selectedDate: null };
