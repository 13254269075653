import { Component } from '@angular/core';

import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
    selector: 'app-checkbox-cell-renderer',
    templateUrl: './checkbox-cell-renderer.component.html',
    styleUrls: ['./checkbox-cell-renderer.component.scss'],
})
export class CheckBoxCellRendererComponent implements ICellRendererAngularComp {
    switchValue: boolean;
    params;
    radioId: string;

    agInit(params): void {
        this.params = params;
    }

    refresh(): boolean {
        return true;
    }
    onChange(event: any) {
        this.params.onChange(event.target.checked);
        event.stopPropagation();
    }
}
