import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, map } from 'rxjs';
import { PNChangeHistory, ProgressNote, TherapyAdmission } from '@app/interfaces';
import { _compareArrays, getNestedObjectValue } from '@app/helpers/utils';
import { therapyadmissionChangedAction } from '@app/store';
import { Store } from '@ngrx/store';
const baseUrl = `${environment.apiUrl}/progressNote`;

@Injectable({
    providedIn: 'root',
})
export class ProgressNoteService {
    dataForEdit = [];
    therapyAdmissionsSnapshot: TherapyAdmission[];
    documentEditedSuccessfully = false;
    isEditModeActive = false;
    progressNotePDF = '';
    public loadedProgressNote: Subject<number> = new Subject<number>();

    additionToGoals = { noteType: null, noteId: null, note: null };

    constructor(
        private http: HttpClient,
        private store: Store<{
            therapyAdmission: TherapyAdmission[];
        }>
    ) { }

    prepareDataForEdit(
        section: string,
        fieldKey: string,
        fieldValue: string | object,
        type: string,
        allTherapyAdmissions: TherapyAdmission[],
        currentAdmission: TherapyAdmission,
        noteIndex: number
    ) {
        const foundIndex = allTherapyAdmissions.findIndex(
            (ta) => ta._id === currentAdmission._id
        );
        let currentValue;
        if (type == 'textBox') {
            currentValue = fieldValue;
        } else {
            const arr = currentAdmission.documentation.progressNote[noteIndex][section][fieldKey];
            currentValue = arr;
        }
        let previousValue;
        let obj;
        const pathList = fieldKey.split('.');
        if (pathList && pathList.length > 1) {
            for (let i = 0; i < pathList.length; i++) {
                if (i === 0) {
                    obj =
                        this.therapyAdmissionsSnapshot[foundIndex].documentation.progressNote[noteIndex][section][
                        pathList[i]
                        ];
                } else if (i === pathList.length - 1) {
                    previousValue = getNestedObjectValue(obj, pathList[i]);
                }
            }
        } else {
            previousValue =
                this.therapyAdmissionsSnapshot[foundIndex].documentation.progressNote[noteIndex][section][fieldKey];
        }
        const changeInstance = {
            section,
            fieldName: '',
            fieldKey,
            currentValue: currentValue,
            previousValue,
        };
        const foundExisting = this.dataForEdit.findIndex((x) => x.fieldKey == fieldKey);
        if (foundExisting !== -1) {
            // following check is for maintaining the LTG & STG edits together in same edit history
            if(fieldKey == 'goals') {
                changeInstance.previousValue = this.dataForEdit[foundExisting].previousValue;
            }
            this.dataForEdit[foundExisting] = changeInstance;
            if (
                !Array.isArray(this.dataForEdit[foundExisting].currentValue) &&
                this.dataForEdit[foundExisting].currentValue === this.dataForEdit[foundExisting].previousValue
            ) {
                this.dataForEdit.splice(foundExisting, 1);
            } else if (Array.isArray(this.dataForEdit[foundExisting].currentValue)) {
                const comparison = _compareArrays(
                    this.dataForEdit[foundExisting].currentValue,
                    this.dataForEdit[foundExisting].previousValue
                );
                if (fieldKey === 'goals') {
                    if (
                        Object.keys(comparison).every(
                            (x) =>
                                comparison[x].clof.levelOfFunction &&
                                comparison[x].clof.assistiveDevice &&
                                comparison[x].assistiveDevice &&
                                comparison[x].target &&
                                comparison[x].targetDate &&
                                comparison[x].updatedTargetDate &&
                                comparison[x].status.description &&
                                comparison[x].goalPurpose &&
                                Object.keys(comparison[x].stgs).every(
                                    (y) =>
                                        comparison[x].stgs[y].clof.levelOfFunction &&
                                        comparison[x].stgs[y].target &&
                                        comparison[x].stgs[y].targetDate &&
                                        comparison[x].stgs[y].updatedTargetDate &&
                                        comparison[x].stgs[y].status.description &&
                                        comparison[x].stgs[y].goalPurpose
                                )
                        )
                    )
                        this.dataForEdit.splice(foundExisting, 1);
                } else if (Object.keys(comparison).every((x) => comparison[x].description))
                    this.dataForEdit.splice(foundExisting, 1);
            }
        } else if (fieldKey === 'goals') {
            const comparison = _compareArrays(currentValue, previousValue);
            if (
                !Object.keys(comparison).every(
                    (x) =>
                        comparison[x].clof.levelOfFunction &&
                        comparison[x].clof.assistiveDevice &&
                        comparison[x].assistiveDevice &&
                        comparison[x].target &&
                        comparison[x].targetDate &&
                        comparison[x].updatedTargetDate &&
                        comparison[x].status.description &&
                        comparison[x].goalPurpose &&
                        Object.keys(comparison[x].stgs).every(
                            (y) =>
                                comparison[x].stgs[y].clof.levelOfFunction &&
                                comparison[x].stgs[y].target &&
                                comparison[x].stgs[y].targetDate &&
                                comparison[x].stgs[y].updatedTargetDate &&
                                comparison[x].stgs[y].status.description &&
                                comparison[x].stgs[y].goalPurpose
                        )
                )
            )
                this.dataForEdit.push(changeInstance);
        } else {
            if (changeInstance.currentValue !== changeInstance.previousValue) this.dataForEdit.push(changeInstance);
        }
        allTherapyAdmissions[foundIndex] = currentAdmission;
        this.store.dispatch(
            therapyadmissionChangedAction({
                therapyadmission: allTherapyAdmissions,
            })
        );
        console.log('pushing the editor data...', this.dataForEdit);
    }

    editPN(id, body): Observable<ProgressNote> {
        return this.http.patch(`${baseUrl}/edit/${id}`, body) as Observable<ProgressNote>;
    }

    getPlannedDayNotes(plannedDayId) {
        return this.http.get(`${baseUrl}/plannedDay/${plannedDayId}`, {
            withCredentials: true,
        });
    }

    getPNEditHistory(id: string): Observable<PNChangeHistory[]> {
        return this.http.get(`${baseUrl}/history/${id}`).pipe(
            map((x: any) => {
                return x.data;
            })
        ) as Observable<PNChangeHistory[]>;
    }

    getProgressNotePDF(): any {
        return this.progressNotePDF;
    }

    setProgressNotePDF(value): any {
        this.progressNotePDF = value;
    }
    delete(id: string) {
        return this.http.delete(`${baseUrl}/delete/with_cleanup/${id}`, {
            withCredentials: true,
        });
    }
}
