import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Store } from '@ngrx/store';
import { DNChangeHistory, DailyNote, TherapyAdmission } from '@app/interfaces';
import { Subject, map, Observable, of, switchMap, BehaviorSubject } from 'rxjs';
import { StoreService } from './store.service';
import { cloneDeep } from 'lodash';
import { spinnerDocumentStateChangeAction, therapyadmissionChangedAction } from '@app/store/actions';
import { _compareArrays, getNestedObjectValue } from '@app/helpers/utils';
const baseUrl = `${environment.apiUrl}/dailyNote`;
const apiUrl = `${environment.apiUrl}`;

@Injectable({
    providedIn: 'root',
})
export class DailyNotesService {
    dataForEdit = [];
    therapyAdmissionsSnapshot: TherapyAdmission[];
    documentEditedSuccessfully = false;
    isEditModeActive = false;
    dailyNotePDF = '';
    private trigger = new BehaviorSubject<any>('');
    private trigger2 = new Subject();
    public loadedDailyNote: Subject<number> = new Subject<number>();
    public suggestionBoxValues = {
        responseToTreatment: [
            'Caregiver reports___________.',
            'Change in ________ status has resulted in setback in_______due to ________, requiring patient to need more assist for __________.   Treatment plan adjustments to be made include________.  Progress towards goals is expected to continue due to_________.',
            'Decreased pain in __________ to [LEVEL] in response to [MODALITY/TREATMENT] allows for improvement in _________.',
            "Functional gains in _______ have impacted the patient's ability to perform_________ with a reduction in assist levels to_________.",
            'Functional progress this week has been significant due to__________.',
            "Gains in ________ have improved the patient's ability to perform ______with decreased levels of assist to___________.",
            'Improvement in ________allows patient to tolerate higher levels of challenges in_________.',
            'Pain in [AREA] has decreased to [LEVEL] in response to [TREATMENT/MODALITY], allowing fore ease in completing__________.',
            'Patient can now tolerate higher level challenges in [ACTIVITY/TASK] demonstrating an improvement in[IMPAIRMENT].',
            'Patient is now able to perform______with fewer episodes of [BARRIER] allowing for improved [TASK/FUNCTION].',
            'Patient progressed from using [DEVICE] to using [DEVICE] for [FUNCTIONAL ACTIVITY].',
            "Patient reports [PATIENT'S CONCERNS AND SELF REPORT OF TREATMENT RESPONSE]",
            'Patient requires fewer [VERBAL, VISUAL OR TACTILE] cues to complete [ACTIVITY] with [ASSIST LEVEL].',
            'Patient shows improvement in [TASK] with fewer episodes of [IMPAIRMENT] allowing for improved quality and safety of [FUNCTIONAL ACTIVITY]',
            "Patient's consistent use of [STRATEGY] has improved [FUNCTIONAL IMPAIRMENT] which resulted in decreased assist to [ASSIST LEVEL]",
            "Patient's consistent use of [STRATEGY] has yielded overall improvements in [FUNCTION]. Patient now requiring a reduction in [CUES/ASSIST] to (LEVEL]",
            "Patient's setback in [AREA] due to [REASON], has resulted in the patient requiring more assist for [TASK].  Adjustments in treatment this week included [ADJUSTMENTS] because of the change in status.  However, because of [REASON], it is expected that progress towards goals should resume within approaching week.",
            "Patient's functional progress gains due to _________.",
            'The patient did not make significant progress this week toward goals. Adjustment will be made to the treatment plan in order to improve response to treatment. See update to plan indicated below.',
            'The patient did not make significant progress toward goals. Treatment plan adjustments to be made include [ADJUSTMENTS].',
            'Patient lack of progress due to____________.',
        ],
        planForNextTreatment: [''],
    };

    constructor(
        private http: HttpClient,
        private store: Store<{
            therapyAdmission: TherapyAdmission[];
        }>,
        private storeService: StoreService
    ) {
        // This handles the dynamic timing for debounce
        this.trigger
            .pipe(
                switchMap(({ dailyNote, admissions }: any) => {
                    this.setLoader(true);

                    if (!dailyNote) {
                        this.setLoader(false);
                        this.trigger2.next(null);
                        return of(null);
                    }

                    if (dailyNote.id) {
                        dailyNote._id = dailyNote.id;
                        delete dailyNote['id'];
                    }

                    return this.http.put(`${baseUrl}/${dailyNote._id}`, dailyNote).pipe(
                        map((result: any) => {
                            if (!result) {
                                this.setLoader(false);
                                this.trigger2.next(null);
                                return null;
                            }

                            if (!admissions) {
                                this.setLoader(false);
                                this.trigger2.next(result);
                                return result;
                            }

                            // to mutate read only properties
                            admissions = cloneDeep(admissions);

                            admissions.forEach((admission: TherapyAdmission) => {
                                if (admission?.documentation?.dailyNote?.length) {
                                    const docIndex = admission.documentation.dailyNote.findIndex(
                                        (note) => note.id === result.data.note.id
                                    );
                                    if (docIndex !== -1) {
                                        admission.documentation.dailyNote[docIndex] = result.data.note;
                                    }
                                }
                            });

                            this.storeService.setTherapyAdmission(admissions);

                            this.setLoader(false);
                            this.trigger2.next(result);
                            return result;
                        })
                    );
                })
            )
            .subscribe();

        // needed because 'updateDocumentationV2' in documentation.service.ts changes the order
        // of therapy admissions in array and wrong index is accessed in "therapyAdmissionsSnapshot"
        this.store.select('therapyAdmission').subscribe((data) => {
            if (data && data.length) {
                this.therapyAdmissionsSnapshot = JSON.parse(JSON.stringify(data));
            }
        });
    }
    updateStatus(notId: string, note: any) {
        return this.http.put(`${baseUrl}/update-status/${notId}`, note);
    }
    updateDailyNote(dailyNote: any, admissions: TherapyAdmission[] = null): Observable<any> {
        this.setLoader(true);

        if (!dailyNote) {
            this.setLoader(false);
            return of(null);
        }

        if (dailyNote.id) {
            dailyNote._id = dailyNote.id;
            delete dailyNote['id'];
        }

        return this.http.put(`${baseUrl}/${dailyNote._id}`, dailyNote).pipe(
            map((result: any) => {
                if (!result) {
                    this.setLoader(false);
                    return null;
                }

                if (!admissions) {
                    this.setLoader(false);
                    return result;
                }

                // to mutate read only properties
                admissions = cloneDeep(admissions);

                admissions.forEach((admission: TherapyAdmission) => {
                    if (admission?.documentation?.dailyNote?.length) {
                        const docIndex = admission.documentation.dailyNote.findIndex(
                            (note) => note.id === result.data.note.id
                        );
                        if (docIndex !== -1) {
                            admission.documentation.dailyNote[docIndex] = result.data.note;
                        }
                    }
                });

                this.storeService.setTherapyAdmission(admissions);

                this.setLoader(false);

                return result;
            })
        );
    }

    updateDailyNoteMinutes(dailyNote: any, admissions: TherapyAdmission[] = null): Observable<any> {
        this.trigger.next({ dailyNote, admissions });
        return this.trigger2;
    }
    // Function to update given note comment
    updateNoteComment(notePath, note: any): Observable<any> {
        return this.http.put(`${apiUrl}${notePath}/update/${note._id}`, note);
    }
    splitNote(data) {
        return this.http.post(`${baseUrl}/split`, data, {
            withCredentials: true,
        });
    }
    splitNotes(plannedDayId) {
        return this.http.get(`${baseUrl}/split/${plannedDayId}`, {
            withCredentials: true,
        });
    }
    mergeNotes(splitNotes: any) {
        return this.http.post(
            `${baseUrl}/merge`,
            { splitNotes },
            {
                withCredentials: true,
            }
        );
    }
    getPlannedDayNotes(plannedDayId) {
        return this.http.get(`${baseUrl}/plannedDayNotes/${plannedDayId}`, {
            withCredentials: true,
        });
    }
    unSplitNote(data) {
        return this.http.post(`${baseUrl}/un-split`, data, {
            withCredentials: true,
        });
    }
    setLoader(value: boolean) {
        this.store.dispatch(
            spinnerDocumentStateChangeAction({
                selectedLoadingSpinnerState: { isLoading: value },
            })
        );
    }

    prepareDataForEdit(
        section: string,
        fieldKey: string,
        fieldValue: string | object | boolean,
        type: string,
        allTherapyAdmissions: TherapyAdmission[],
        currentAdmission: TherapyAdmission,
        dailyNoteIndex: number
    ) {
        // Always consult before making a change in this function
        const foundIndex = allTherapyAdmissions.findIndex(
            (ta) => ta._id === currentAdmission._id
        );
        let currentValue;
        if (type == 'textBox') {
            currentValue = fieldValue;
        } else if (section && section.length) {
            const arr = currentAdmission.documentation.dailyNote[dailyNoteIndex][section][fieldKey];
            currentValue = cloneDeep(arr);
        }
        let previousValue;
        let obj;
        const pathList = fieldKey.split('.');
        if (pathList && pathList.length > 1) {
            for (let i = 0; i < pathList.length; i++) {
                if (i === 0) {
                    obj =
                        this.therapyAdmissionsSnapshot[foundIndex].documentation.dailyNote[dailyNoteIndex][section][
                            pathList[i]
                        ];
                } else if (i === pathList.length - 1) {
                    previousValue = getNestedObjectValue(obj, pathList[i]);
                }
            }
        } else if (section && section.length) {
            previousValue = JSON.parse(
                JSON.stringify(
                    this.therapyAdmissionsSnapshot[foundIndex].documentation.dailyNote[dailyNoteIndex][section][
                        fieldKey
                    ] || ''
                )
            );
        }
        if (fieldKey === 'vitals') {
            Array.isArray(previousValue) ? (previousValue = previousValue.pop()) : previousValue;
            currentValue.o2Saturation = currentValue.o2Saturation ? String(currentValue.o2Saturation) : null;
            currentValue.pulseRate = currentValue.pulseRate ? String(currentValue.pulseRate) : null;
            currentValue.respiratoryRate = currentValue.respiratoryRate ? String(currentValue.respiratoryRate) : null;
            currentValue.temperature =
                currentValue.temperature &&
                currentValue.temperature.length > 1 &&
                !currentValue.temperature.includes('NaN')
                    ? String(currentValue.temperature)
                    : null;
            currentValue.bloodPressure = currentValue.bloodPressure ? String(currentValue.bloodPressure) : null;
            currentValue.note = currentValue.note ? currentValue.note : null;
            previousValue.o2Saturation = previousValue.o2Saturation ? String(previousValue.o2Saturation) : null;
            previousValue.pulseRate = previousValue.pulseRate ? String(previousValue.pulseRate) : null;
            previousValue.respiratoryRate = previousValue.respiratoryRate
                ? String(previousValue.respiratoryRate)
                : null;
            previousValue.temperature = previousValue.temperature ? previousValue.temperature : null;
            previousValue.bloodPressure = previousValue.bloodPressure ? previousValue.bloodPressure : null;
            previousValue.note = previousValue.note ? previousValue.note : null;
            currentValue = [currentValue];
            previousValue = [previousValue];
        } else if (fieldKey === 'detailedCptCodes') {
            currentValue = (fieldValue as any[]).map((cv) => ({
                ...cv,
                cptMinutes: cv.cptMinutes ? String(cv.cptMinutes) : undefined,
            }));
            previousValue = previousValue.map((pv) => ({
                ...pv,
                cptMinutes: pv.cptMinutes ? String(pv.cptMinutes) : undefined,
            }));
        } else if (fieldKey === 'otherModeMinutes.coTreat' || fieldKey === 'otherModeMinutes.concurrent') {
            const tempCurrentValue = fieldValue ? JSON.parse(JSON.stringify(fieldValue)) : undefined;
            let pv = undefined;
            let cv = undefined;
            if (tempCurrentValue) {
                cv = Object.keys(tempCurrentValue)
                    .map((x) => {
                        if (!Array.isArray(tempCurrentValue[x])) tempCurrentValue[x] = String(tempCurrentValue[x]);
                        return tempCurrentValue;
                    })
                    .pop();
            }
            if (previousValue) {
                pv = Object.keys(previousValue)
                    .map((x) => {
                        if (!Array.isArray(previousValue[x])) previousValue[x] = String(previousValue[x]);
                        return previousValue;
                    })
                    .pop();
            }
            previousValue = pv;
            currentValue = cv;

            if (fieldKey === 'otherModeMinutes.coTreat') {
                previousValue = cloneDeep(previousValue);
                previousValue?.disciplines?.forEach((elem) => {
                    if (elem && elem.therapistId && typeof elem.therapistId === 'object') {
                        elem.therapistId = elem.therapistId._id;
                    }
                });
            }
        } else currentValue = fieldValue;
        const changeInstance = {
            section,
            fieldName: '',
            fieldKey,
            currentValue: currentValue,
            previousValue,
        };
        const foundExisting = this.dataForEdit.findIndex((x) => x.fieldKey == fieldKey);
        if (foundExisting !== -1) {
            this.dataForEdit[foundExisting] = changeInstance;
            if (
                !Array.isArray(this.dataForEdit[foundExisting].currentValue) &&
                typeof this.dataForEdit[foundExisting].currentValue !== 'object' &&
                this.dataForEdit[foundExisting].currentValue === this.dataForEdit[foundExisting].previousValue
            ) {
                this.dataForEdit.splice(foundExisting, 1);
            } else if (Array.isArray(this.dataForEdit[foundExisting].currentValue)) {
                const comparison = _compareArrays(
                    this.dataForEdit[foundExisting].currentValue,
                    this.dataForEdit[foundExisting].previousValue
                );
                if (fieldKey === 'vitals') {
                    if (
                        Object.keys(comparison).every(
                            (x) =>
                                comparison[x].bloodPressure &&
                                comparison[x].note &&
                                comparison[x].o2Saturation &&
                                comparison[x].pulseRate &&
                                comparison[x].respiratoryRate &&
                                comparison[x].temperature
                        )
                    )
                        this.dataForEdit.splice(foundExisting, 1);
                } else if (fieldKey === 'detailedCptCodes') {
                    if (
                        Object.keys(comparison).every(
                            (x) =>
                                comparison[x].code &&
                                comparison[x].cptMinutes &&
                                comparison[x].exercises &&
                                comparison[x].justification &&
                                Object.keys(comparison[x].goals).every((y) =>
                                    Object.keys(comparison[x].goals[y]).every(() => comparison[x].goals[y].goal)
                                )
                        )
                    )
                        this.dataForEdit.splice(foundExisting, 1);
                } else if (fieldKey === 'goals') {
                    if (
                        Object.keys(comparison).every(
                            (x) => comparison[x].clof.levelOfFunction && comparison[x].clof.assistiveDevice
                        )
                    )
                        this.dataForEdit.splice(foundExisting, 1);
                } else if (Object.keys(comparison).every((x) => comparison[x].description)) {
                    this.dataForEdit.splice(foundExisting, 1);
                }
            } else if (typeof this.dataForEdit[foundExisting].currentValue == 'object') {
                if (fieldKey === 'otherModeMinutes.coTreat' || fieldKey === 'otherModeMinutes.concurrent') {
                    const comparison = _compareArrays([currentValue], [previousValue]);
                    if (fieldKey === 'otherModeMinutes.concurrent') {
                        if (Object.keys(comparison).every((x) => comparison[x].minutes))
                            this.dataForEdit.splice(foundExisting, 1);
                    } else if (fieldKey === 'otherModeMinutes.coTreat') {
                        if (
                            Object.keys(comparison).every(
                                (x) =>
                                    comparison[x].minutes &&
                                    comparison[x].justification &&
                                    Object.keys(comparison[x].disciplines).every(
                                        (y) =>
                                            comparison[x].disciplines[y].discipline &&
                                            comparison[x].disciplines[y].therapistId
                                    )
                            )
                        )
                            this.dataForEdit.splice(foundExisting, 1);
                    }
                }
            } else if (fieldKey === 'isTeleHealthEligible') {
                currentAdmission.documentation.dailyNote[dailyNoteIndex].isTeleHealthEligible =
                    changeInstance.currentValue;
                this.dataForEdit.push(changeInstance);
            }
        } else {
            if (fieldKey === 'vitals') {
                const comparison = _compareArrays(currentValue, previousValue);
                if (
                    !Object.keys(comparison).every(
                        (x) =>
                            comparison[x].bloodPressure &&
                            comparison[x].note &&
                            comparison[x].o2Saturation &&
                            comparison[x].pulseRate &&
                            comparison[x].respiratoryRate &&
                            comparison[x].temperature
                    )
                )
                    this.dataForEdit.push(changeInstance);
            } else if (fieldKey === 'otherModeMinutes.coTreat' || fieldKey === 'otherModeMinutes.concurrent') {
                const comparison = _compareArrays([currentValue], [previousValue]);
                if (fieldKey === 'otherModeMinutes.concurrent') {
                    if (!Object.keys(comparison).every((x) => comparison[x].minutes))
                        this.dataForEdit.push(changeInstance);
                } else if (fieldKey === 'otherModeMinutes.coTreat') {
                    if (
                        !Object.keys(comparison).every(
                            (x) =>
                                comparison[x].minutes &&
                                comparison[x].justification &&
                                Object.keys(comparison[x].disciplines).every(
                                    (y) =>
                                        comparison[x].disciplines[y].discipline &&
                                        comparison[x].disciplines[y].therapistId
                                )
                        )
                    )
                        this.dataForEdit.push(changeInstance);
                } else if (!Object.keys(comparison).every((x) => comparison[x].minutes && comparison[x].justification))
                    this.dataForEdit.push(changeInstance);
            } else if (fieldKey === 'detailedCptCodes') {
                const comparison = _compareArrays(currentValue, previousValue);
                if (
                    !Object.keys(comparison).every(
                        (x) =>
                            comparison[x].cptMinutes &&
                            comparison[x].justification &&
                            Object.keys(comparison[x].goals).every((y) =>
                                Object.keys(comparison[x].goals[y]).every(() => comparison[x].goals[y].goal)
                            )
                    )
                )
                    this.dataForEdit.push(changeInstance);
            } else if (fieldKey === 'isTeleHealthEligible') {
                currentAdmission.documentation.dailyNote[dailyNoteIndex].isTeleHealthEligible =
                    changeInstance.currentValue;
                this.dataForEdit.push(changeInstance);
            } else if (changeInstance.currentValue !== changeInstance.previousValue) {
                this.dataForEdit.push(changeInstance);
            }
        }
        allTherapyAdmissions[foundIndex] = currentAdmission;
        this.store.dispatch(
            therapyadmissionChangedAction({
                therapyadmission: allTherapyAdmissions,
            })
        );
        console.log('pushing the editor data...', this.dataForEdit);
    }

    editDN(id, body): Observable<DailyNote> {
        return this.http.patch(`${baseUrl}/edit/${id}`, body) as Observable<DailyNote>;
    }

    getDNEditHistory(id: string): Observable<DNChangeHistory[]> {
        return this.http.get(`${baseUrl}/history/${id}`).pipe(
            map((x: any) => {
                return x.data;
            })
        ) as Observable<DNChangeHistory[]>;
    }

    getDailyNotePDF(): any {
        return this.dailyNotePDF;
    }

    setDailyNotePDF(value): any {
        this.dailyNotePDF = value;
    }
    delete(id: string) {
        return this.http.delete(`${baseUrl}/delete/with_cleanup/${id}`, {
            withCredentials: true,
        });
    }
    deleteAddendum(id: string) {
        const addendumUrl = `${environment.apiUrl}/addendum`;
        return this.http.delete(`${addendumUrl}/delete/with_cleanup/${id}`, {
            withCredentials: true,
        });
    }
}
