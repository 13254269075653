import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { Component, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TIME } from '../constants';

@Component({
    selector: 'medal-component',
    template: `<app-date-picker
        [container]="'body'"
        [disabled]="disabled"
        [(ngModel)]="params.data.onsetDate"
        (ngModelChange)="invokeSetRendererDate($event)"
        placeholder="MM/DD/YYYY"
        [applyBackground]="false"
        [maxDate]="params.data.maxOnSetDate"
        inputClass="p-0"
    ></app-date-picker> `,
})
export class OnSetDateCellRenderer implements ICellRendererAngularComp {
    constructor(private renderer: Renderer2, private elementRef: ElementRef) {}
    dateStringControl = new FormControl('2020-09-28');
    public params: any;
    disabled = false;
    private valueType: 'value' | 'valueAsDate' = 'value';

    agInit(params: ICellRendererParams): void {
        this.params = params;
        const ngbDateStruct = {
            day: new Date(params.data.onsetDate).getUTCDate(),
            month: new Date(params.data.onsetDate).getUTCMonth() + 1,
            year: new Date(params.data.onsetDate).getUTCFullYear(),
        };
        this.disabled = this.params.disabled;
        this.dateStringControl = new FormControl(ngbDateStruct);
    }

    refresh() {
        return false;
    }
    // Use this function in the template on date field change event
    invokeSetRendererDate(event: any) {
        // Create setRendererDate function in parent component and it will be called here on date changed
        //this.params.context.componentParent.setRendererDate({ data: this.params.data, value: this.toDateJs(event) });
        this.params.context.componentParent.setRendererDate({
            data: this.params.data,
            value: new Date(new Date(event).setHours(TIME.hours, TIME.minutes, TIME.seconds)).toISOString(),
        });
    }
    // For date input
    @HostListener('blur', []) onTouched: any;
    @HostListener('input', ['$event']) onChange: any;

    registerOnTouched(fn: () => void) {
        this.onTouched = fn;
    }

    registerOnChange(fn: (value: any) => void) {
        this.onChange = (event: any) => fn(event.target[this.valueType]);
    }

    writeValue(value: Date | string) {
        this.valueType = typeof value === 'string' ? 'value' : 'valueAsDate';
        this.renderer.setProperty(this.elementRef.nativeElement, this.valueType, value);
    }
    toDateJs(date: any): Date {
        return date
            ? new Date(
                  new Date(date).getUTCFullYear(),
                  new Date(date).getUTCMonth(),
                  new Date(date).getUTCDate()

                  //   Date.UTC(
                  //       new Date(date).getUTCFullYear(),
                  //       new Date(date).getUTCMonth(),
                  //       new Date(date).getUTCDate() + 1
                  //   )
              )
            : null;
    }
}
