import { Injectable } from '@angular/core';
import * as xml2js from 'xml2js';
import { FacilityManagementService } from './facilityManagement.service';

@Injectable({ providedIn: 'root' })
export class ExportService {
    constructor(private facilityManagementService: FacilityManagementService) {}

    public generateXml(obj: any): void {
        const builder = new xml2js.Builder({
            rootName: 'ChargeBatch',
        });
        const xml = builder.buildObject(obj);
        this.writeContents(xml.toString(), `${obj.FacilityName}.txt`, 'text/plain');
    }

    writeContents(content, fileName, contentType) {
        const block: any = document.createElement('a');
        const file = new Blob([content], { type: contentType });
        block.href = URL.createObjectURL(file);
        block.download = fileName;
        block.click();
    }

    generateCsv(data) {
        const keys = Array.isArray(data) && data.length ? Object.keys(data[0]) : [];
        let csvData = '';
        for (let index = 0; index < keys.length; index++) {
            if (keys[index] === 'Modifiers') {
                csvData += ',';
            }
            csvData += keys[index].split('_').join(' ') + ',';
        }
        csvData += '\r\n';
        for (const d of data) {
            for (let index = 0; index < keys.length; index++) {
                if (keys[index] === 'Modifiers') {
                    csvData += ',';
                }
                csvData += d[keys[index]] + ',';
            }
            csvData += '\r\n';
        }
        this.writeContents(
            csvData.toString(),
            `${this.facilityManagementService?._currentFacility?.facilityName ?? 'file'}.csv`,
            'text/csv'
        );
    }
}
