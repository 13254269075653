export const CONSTANTS = {
    IMPAIRMENTS: 'Impairments',
    FUNCTIONAL_DEFICITS: 'Functional Deficits',
    STANDARDIZED_TESTS: 'Standardized Tests',
    MOBILITY: 'Mobility',
    ADL_SELF_CARE: 'ADLs/Selfcare',
    COGNITIVE_LANGUAGE_MEMORY: 'Cognitive/Language/Memory',
    CARDIOPULMONARY: 'Cardiopulmonary',
    NEURO_MUSCULO_SKELETAL: 'Neuro, Musculo & Skeletal',
    RANGE_OF_MOTION_AND_STRENGTH: 'Range of Motion & Strength',
    PAIN: 'Pain',
    COGNITION: 'Cognition',
    SENSORY: 'Sensory',
    VISION_AND_PERCEPTION: 'Vision & Perception',
    ORAL_MOTOR_EXAMINATION: 'Oral Motor Examination',
    AUDITORY_COMPREHENSION: 'Auditory Comprehension',
    READING_COMPREHENSION: 'Reading Comprehension',
    WRITTEN_LANGUAGE: 'Written Language',
    VERBAL_EXPRESSION: 'Verbal Expression',
    MOTOR_SPEECH: 'Motor Speech',
    VOICE_EVALUATION: 'Voice Evaluation',
    GENERAL_PROCESSING: 'General Processing',
    MEMORY: 'Memory',
    EXECUTIVE_FUNCTIONING: 'Executive Functioning',
    PRAGMATICS: 'Pragmatics',
    OTHER_COGNITIVE_SKILLS: 'Other Cognitive Skills',
    SWALLOWING: 'Swallowing',
    Doc_Notes: 'Notes Documents',
    MORE_IS_BEST: 'More is best',
    LESS_IS_BEST: 'Less is best',
};

export const DOCUMENT_CONSTANTS = {
    EVALUATION: 'Evaluation',
    DAILY_NOTE: 'Daily Note',
    PROGRESS_NOTE: 'Progress Note',
    UPOC: 'Updated Plan of Care',
    RECERTIFICATION: 'Recertification',
    DISCHARGE_NOTE: 'Discharge Note',
};

export const DOCUMENTATION_CONSTANTS = {
    EVALUATION: 'Evaluation',
    DAILY: 'Daily',
    DAILY_NOTE: 'Daily Note',
    PROGRESS: 'Progress',
    UPOC: 'UPOC',
    UPDATED_PLAN_OF_CARE: 'Updated Plan of Care',
    RECERTIFICATION: 'Recertification',
    DISCHARGE: 'Discharge',
    DISCHARGE_NOTE: 'Discharge Note',
    ADDENDUM: 'Addendum',
    ALL: 'All',
    COMPLETED: 'Completed',
    EDITED: 'Edited',
    INCOMPLETE: 'Incomplete',
    DUE: 'Due',
    PAST_DUE: 'Past Due',
    UPCOMING: 'Upcoming',
    WITHHELD: 'Withheld',
    REFUSED: 'Refused',
    REQUESTED: 'Requested',
    NOT_REQUESTED: 'Not Requested',
    REQUEST_DC_NOTE: 'Request Discharge Note',
    REQUESTED_DC_NOTE: 'Discharge Note Requested',
    NO_ADMISSION: 'No admission',
    PRIMARY_DX: 'Primary D/X',
    PRIMARY_DX_REQUIRED: 'Primary D/X *',
    COSIGNED: 'Co-sign',
    CLOSED: 'Closed',
};

export const MAPPED_DOCUMENTATION_CONSTANTS_AS_KEYS = {
    EVALUATION: 'evaluation',
    DAILY: 'dailyNote',
    PROGRESS: 'progressNote',
    RECERTIFICATION: 'recertificationNote',
    UPOC: 'updatedPlanOfCareNote',
    DISCHARGE: 'dischargeNote',
    ADDENDUM: 'addendum',
};

export const GOAL_CONSTANTS = {
    ON_GOING: 'On Going',
    ACHIEVED: 'Achieved',
    DISCONTINUED: 'Discontinued',
    NOT_ACHIEVED: 'Not Achieved',
};

export interface FunctionalDeficits {
    text: string;
    hasGGScore: boolean;
    isCompleted?: boolean;
    assistiveDevices?: string[];
}

export enum DEFICITS_SCALE {
    UNKNOWN = '0 - Unknown',
    PATIENT_REFUSED = '0 - Patient Refused',
    NOT_APPLICABLE = '0 - Not Applicable',
    NOT_ATTEMPTED_DUE_TO_ENVIRONMENTAL_LIMITATION = '0 - Not attempted due to environmental limitation',
    NOT_ATTEMPTED_DUE_TO_MEDICAL_CONDITION_OR_SAFETY_CONCERNS = '0 - Not attempted due to medical condition or safety concerns',
    DEPENDENT_TOTAL_ASSISTANCE_D = '1 - Dependent/Total Assistance (D)',
    MAXIMAL_ASSISTANCE_MAX_A = '2 - Maximal Assistance (Max A)',
    MODERATE_ASSISTANCE_MOD_A = '3 - Moderate Assistance (Mod A)',
    MINIMAL_ASSISTANCE_MIN_A = '4 - Minimal Assistance (Min A)',
    CONTACT_GUARD_ASSISTANCE_CGA = '5 - Contact Guard Assistance (CGA)',
    STANDBY_ASSIST_SBA = '6 - Standby Assist (SBA)',
    SUPERVISED_S = '7 - Supervised (S)',
    MODIFIED_I = '8 - Modified Independent (Mod I)',
    INDEPENDENT_I = '9 - Independent (I)',
}

export enum GG_SCORE {
    DEPENDENT = '1. Dependent',
    SUBSTANTIAL_MAXIMAL_ASSISTANCE = '2. Substantial/Maximal assistance',
    PARTIAL_MODERATE_ASSISTANCE = '3. Partial/Moderate assistance',
    SUPERVISION_OR_TOUCHING_ASSISTANCE = '4. Supervision or touching assistance',
    SETUP_OR_CLEAN_UP_ASSISTANCE = '5. Setup or clean-up assistance',
    INDEPENDENT = '6. Independent',
    PATIENT_REFUSED = '7. Patient refused',
    NOT_APPLICABLE = '9. Not applicable',
    NOT_ATTEMPTED_DUE_TO_ENVIRONMENTAL_CONSTRAINTS = '10. Not attempted due to environmental constraints',
    NOT_ATTEMPTED_DUE_TO_MEDICAL_CONDITION_OR_SAFETY_CONCERNS = '88. Not attempted due to medical condition or safety concerns',
}
export enum NOTE_STATUS {
    UPCOMING = 'Upcoming',
    WITHHELD = 'Withheld',
    REFUSED = 'Refused',
}
export enum ADLs_SELF_CARE {
    COOKING_AND_MEAL_PREPARATION = 'Cooking and Meal Preparation',
    EATING = 'Eating',
    GROOMING_PERSONAL_HYGIENE = 'Personal hygiene',
    HOUSE_CLEANING = 'House Cleaning',
    LAUNDRY = 'Laundry',
    SHOWER_BATHE_SELF = 'Shower/Bathe self',
    WASH_UPPER_BODY = 'Wash upper body',
    WRITING = 'Writing',
    TOILETING_HYGIENE = 'Toileting hygiene',
    UPPER_BODY_DRESSING = 'Upper body dressing',
    LOWER_BODY_DRESSING = 'Lower body dressing',
    PUT_ON_TAKE_OFF_FOOTWEAR = 'Put on/take off footwear',
    ORAL_HYGIENE = 'Oral hygiene',
}

export enum FUNCTIONAL_DEFICITS {
    SIT_TO_LYING = 'Sit to lying while in bed',
    WALK_50_FEET_WITH_2_TURNS = 'Walk 50 feet with two turns',
    WALK_150_FEET = 'Walk 150 feet',
    WALK_10_FEET = 'Walk 10 feet',
    LYING_TO_SITTING_ON_SIDE_OF_BED = 'Lying to sitting on side of bed',
    SIT_TO_STAND = 'Sit to stand',
    WHEEL_50_FEET_WITH_2_Turns = 'Wheel 50 feet with two turns',
    WHEEL_150_FEET = 'Wheel 150 feet',
    CHAIR_BED_TO_CHAIR_TRANSFER = 'Chair/bed to chair transfer',
    TOILET_TRANSFER = 'Toilet transfer',
    LOWER_BODY_DRESSING = 'Lower body dressing',
    UPPER_BODY_DRESSING = 'Upper body dressing',
    SHOWER_BATHE_SELF = 'Shower/Bathe self',
    WASH_UPPER_BODY = 'Wash upper body',
    TOILETING_HYGIENE = 'Toileting hygiene',
    ORAL_HYGIENE = 'Oral hygiene',
    EATING = 'Eating',
}

export enum COGNITIVE_LANGUAGE_MEMORY {
    ATTENTION = 'Attention',
    COGNITION = 'Cognition',
    MEMORY = 'Memory',
    MOTOR_SPEECH = 'Motor Speech',
    READING = 'Reading',
    SPOKEN_LANGUAGE_COMPREHENSION = 'Spoken Language Comprehension',
    SPOKEN_LANGUAGE_EXPRESSION = 'Spoken Language Expression',
    VOICE = 'Voice',
}

export enum STANDARDIZED_TESTS {
    WALK_TEST_10_METER = '10-Meter Walk Test',
    WALK_TEST_2_MINUTE = '2-Minute Walk Test',
    STEP_TEST_2_MINUTE = '2-Minute Step Test',
    WALK_TEST_6_MINUTE = '6-Minute Walk Test',
    HOLE_PEG_TEST = '9-Hole Peg Test',
    ACTIVITY_SPECIFIC_BALANCE_CONFIDENCE_SCALE = 'Activity Specific Balance Confidence Scale (ABC Scale)',
    ALLEN_COGNITIVE_LEVELS = 'Allen Cognitive Levels',
    AMPAC = 'AM-PAC',
    ARIZONA_BATTERY_FOR_COMMUNICATION_DISORDERS_OF_DEMENTIA = 'Arizona Battery for Communication Disorders of Dementia (ABCD)',
    ASSESSMENT_OF_LANGUAGE_RELATED_FUNCTIONAL_ACTIVITIES = 'Assessment of Language Related Functional Activities (ALFA)',
    BARTHEL_INDEX = 'Barthel Index',
    BRIEF_INTERVIEW_FOR_MENTAL_STATUS = 'Brief Interview for Mental Status (BIMS)',
    BLADDER_ASSESSMENT_SCORE = 'Bladder Assessment Score',
    BORG_CR_10 = 'Borg CR 10',
    BORG_SCALE_OF_PERCEIVED_EXERTION = 'Borg Scale of Perceived Exertion',
    BOSTON_DIAGNOSTIC_APHASIA_EXAMINATION = 'Boston Diagnostic Aphasia Examination (BDAE)',
    BOSTON_NAMING_TEST = 'Boston Naming Test',
    BRADEN_SCALE = 'Braden Scale',
    BCAT = 'Brief Cognitive Assessment Tool (BCAT)',
    BCRS = 'Brief Cognitive Rating Scale (BCRS)',
    CLQT = 'Cognitive Linguistic Test',
    DASH = 'Disabilities of the Arm, Shoulder, and Hand (DASH)',
    DGI = 'Dynamic Gait Index (DGI)',
    DYNAMOMETER = 'Dynamometer/Grip Strength',
    FALLS_EFFICACY_SCALE = 'Falls Efficacy Scale',
    FEES = 'Fiberoptic Endoscopic Evaluation of Swallowing (FEES)',
    FRENCHAY_DYSARTHRIA_ASSESSMENT = 'Frenchay Dysarthria Assessment',
    FROMAJE = 'FROMAJE',
    FUGL_MEYER_ASSESSMENT_OF_MOTOR_PERFORMANCE = 'Fugl-Meyer Assessment of Motor Performance',
    FUNCTIONAL_KNEE_EVALUATION = 'Functional Knee Evaluation',
    FLCI = 'Functional Linguistic Communication Inventory (FLCI)',
    FUNCTIONAL_STAGING_ASSESSMENT = 'Functional Staging Assessment',
    GARS_M = 'Gait Assessment Rating Score Modified (GARS-M)',
    GAIT_SPEED = 'Gait Speed',
    GLOBAL_DETERIORATION_SCALE = 'Global Deterioration Scale',
    HARRIS_HIP_SCORE = 'Harris Hip Score',
    HOOS = 'Hip Dysfunction and Osteoarthritis Outcome Score (HOOS)',
    KNEE_RATING_SCALE = 'Cincinnati Knee Rating Scale',
    KELS = 'Kohlman Evaluation of Living Skills (KELS)',
    LOWER_EXTREMITY_FUNCTIONAL_SCALE = 'Lower Extremity Functional Scale',
    LYSHOLM_KNEE_RATING_SCALE = 'Lysholm Knee Rating Scale',
    MASA = 'Mann Assessment of Swallowing Ability (MASA)',
    MACTAR = 'McMaster Toronto Arthritis Patient Preference Questionnaire (MACTAR)',
    MINI_MENTAL_STATE_EXAM = 'Mini-Mental State Exam',
    MONTREAL_COGNITIVE_ASSESSMENT = 'Montreal Cognitive Assessment',
    MVPT = 'Motor-Free Visual Perception Test (MVPT)',
    NIH_STROKE_SCALE = 'NIH Stroke Scale',
    NOMS_Attention = 'NOMS - Attention',
    NOMS_MEMORY = 'NOMS - Memory',
    NOMS_MOTOR_SPEECH = 'NOMS - Motor Speech',
    NOMS_Reading = 'NOMS - Reading',
    NOMS_SPOKEN_LANGUAGE = 'NOMS - Spoken Language',
    NOMS_SPOKEN_LANGUAGE_Expression = 'NOMS - Spoken Language Expression',
    NOMS_SWALLOWING = 'NOMS - SWALLOWING',
    NOMS_Writing = 'NOMS - Writing',
    ORPINGTON_PROGNOSTIC_SCALE = 'Orpington Prognostic Scale',
    OSWESTRY_LOW_BACK_PAIN_DISABILITY_QUESTIONNAIRE = 'Oswestry Low Back Pain Disability Questionnaire',
    PAIN_ASSESSMENT_IN_ADVANCED_DEMENTIA = 'Pain Assessment in Advanced Dementia',
    PAIN_DISABILITY_INDEX = 'Pain Disability Index',
    PAIN_RATING_SCALE = 'Pain Rating Scale',
    PHYSICAL_PERFORMANCE_TEST = 'Physical Performance Test',
    POSTURAL_ASSESSMENT_SCALE_FOR_STROKE_PATIENTS = 'Postural Assessment Scale for Stroke Patients',
    PHQ9 = 'Patient Health Questionnaire (PHQ-2/PHQ-9)',
    ROMBERG_SHARPENED_ROMBERG = 'Romberg/Sharpened Romberg',
    RIPA_G = 'Ross Information Processing Assessment Geriatric (RIPA - G)',
    SHORT_FORM_MCGILL_PAIN_QUESTIONNAIRE = 'Ross Information Processing Assessment Geriatric (RIPA - G)',
    SIMPLE_SHOULDER_TEST = 'Simple Shoulder Test',
    SLUMS = 'Saint Louis University Mental Status (SLUMS)',
    SNELLEN_EYE_CHART = 'Snellen Eye Chart',
    STROKE_IMPACT_SCALE = 'Stroke Impact Scale',
    STROKE_REHABILITATION_ASSESSMENT_OF_MOVEMENT = 'Stroke Rehabilitation Assessment of Movement',
    TEST_OF_VISUAL_PERCEPTUAL_SKILLS_REVISED = 'Test of Visual Perceptual Skills Revised',
    SPPB = 'Short Physical Performance Battery Protocol (SPPB) ',
    CPS = 'Cognitive Performance Scale (CPS)',
    ROLAND_QUESTIONNAIRE = 'The Roland Disability Score Questionnaire on Low Back Pain',
    TUG = 'Timed Up and Go (TUG)',
    TINETTI_ASSESSMENT_TOOLS = 'Tinetti Assessment Tools',
    WOMAC_OSTEOARTHRITIS_INDEX = 'WOMAC Osteoarthritis Index',
    BERG_BALANCE = 'Berg Balance Measure',
    DYNAMIC_GAIT_INDEX = 'Dynamic Gait Index (DGI)',
    TIME_UP_AND_GO_TUG = 'Time Up and Go (TUG)',
    TINETTI_ASSESSMENT = 'Tinetti Assessment',
    FUNCTIONAL_REACH_TEST = 'Functional Reach Test (FRT)',
    SIT_TO_STAND_5_SECOND = '5 second sit to stand',
    SIT_TO_STAND_30_SECOND = '30-second sit to stand',
    WALK_TEST_7_MIN = '7 min walk test',
    Functional_Oral_Intake = 'Functional Oral Intake',
    Dysphagia_Outcome_and_Severity_Scale = 'Dysphagia Outcome and Severity Scale (DOSS)',
    PASS = 'Postural Assessment Scale for Stroke (PASS)',
}

export enum CARDIOPULMONARY_IMPAIRMENTS {
    VITAL_SIGNS = 'Vital Signs',
    BREATHING_PATTERN = 'Breathing Pattern',
    BREATH_SUPPORT = 'Breath Support',
    LE_EDEMA = 'LE Edema',
    BORG_SCALE = 'BORG Scale/Rate of Perceived Exertion',
    PULMONARY_STATUS = 'Pulmonary Status',
    LUNGS_SOUNDS = 'Lungs Sound',
    COMPENSATORY_STRATEGIES = 'Compensatory Strategies',
}

export enum NEURO_MUSCULO_SKELETAL_IMPAIRMENTS {
    POSTURAL_ALIGNMENT = 'Postural Alignment',
    MUSCLE_TONE_UE_L_R = 'Muscle Tone UE (L/R)',
    MUSCLE_TONE_LE_L_R = 'Muscle Tone LE (L/R)',
    MUSCLE_TONE_MODIFIED_ASHWORTH_UE_L_R = 'Muscle Tone Modified Ashworth UE (L/R)',
    MUSCLE_TONE_MODIFIED_ASHWORTH_LE_L_R = 'Muscle Tone Modified Ashworth LE (L/R)',
    INVOLUNTARY_MOVEMENTS = 'Involuntary Movements',
    WEIGHT_BEARING_STATUS_UE_L_R = 'Weight Bearing Status UE (L/R)',
    WEIGHT_BEARING_STATUS_LE_L_R = 'Weight Bearing Status LE (L/R)',
    WEIGHT_SHIFT_L_R = 'Weight Shift (L/R)',
    FALL_RISK_ASSESSMENT = 'Fall Risk Assessment',
    BALANCE = 'Balance',
    REACTIVE_STRATEGIES = 'Reactive Strategies',
    OVERALL_COORDINATION_UE = 'Overall Coordination (UE)',
    OVERALL_COORDINATION_LE = 'Overall Coordination (LE)',
    SOFT_TISSUE_SKIN_INTEGRITY = 'Soft Tissue/Skin Integrity',
    EDEMA = 'Edema',
    PITTING_EDEMA = 'Pitting Edema',
    LEG_LENGTH_DISCREPANCY = 'Leg Length Discrepancy',
    LIMB_GIRTH_MEASUREMENT = 'Limb Girth Measurement',
    DOES_CLIENT_EXPERIENCE_INCONTINENCE = 'Does client experience Incontinence',
    PATHOLOGICAL_GAIT_DEVIATION = 'Pathological Gait Deviation',
    GAIT_DEVIATIONS = 'Gait Deviations',
    REFLEXES_UE_L_R = 'Reflexes UE (L/R)',
    REFLEXES_LE_L_R = 'Reflexes LE (L/R)',
    ACTIVITY_TOLERANCE = 'Activity Tolerance',
}

export enum PAIN {
    LOCATION = 'Location',
    PAIN_EFFECTS_ON_FUNCTION = 'Pain effects on function',
    FREQUENCY_OF_PAIN = 'Frequency of Pain',
    PAIN_SCALES = 'Pain Scales',
    PAIN_TYPE = 'Pain Type',
    PAIN_DESCRIPTION = 'Pain Description',
    WHAT_RELIEVES_PAIN = 'What relieves pain?',
    WHAT_EXACERBATES_PAIN = 'What Exacerbates pain?',
    MEDICATIONS_TO_RELIEVE_PAIN = 'Medications used to relief pain?',
    IS_SKILLED_THERAPY_NEEDED_TO_ADDRESS_PAIN = 'Is skilled therapy needed to address pain?',
}

export enum SENSORY {
    SENSATION_UE_L_R = 'Sensation UE L/R',
    SENSATION_LE_L_R = 'Sensation LE L/R',
    PROPRIOCEPTION = 'Proprioception',
}
export enum COGNITION {
    LEVEL_OF_AROUSAL_ALERTNESS = 'Level of Arousal/Alertness',
    ATTENTION = 'Attention',
    ORIENTATION = 'Orientation',
    SEQUENCING = 'Sequencing',
    PROBLEM_SOLVING = 'Problem Solving',
    SAFETY_AWARENESS = 'Safety Awareness',
    ORGANIZATION = 'Organization',
    PLANNING = 'Planning',
    ABSTRACT_THINKING = 'Abstract Thinking',
    JUDGEMENT_INSIGHT = 'Judgement/Insight',
    FOLLOW_DIRECTIONS = 'Follows Directions',
    ABILITY_TO_LEARN_NEW_INFORMATION = 'Ability to learn new information',
    ABILITY_TO_UNDERSTAND_OTHERS = 'Ability to understand others',
    SHORT_TERM_MEMORY = 'Short Term Memory',
    LONG_TERM_MEMORY = 'Long Term Memory',
}
export enum VISION_PERCEPTION {
    VISUAL_ACUITY = 'Visual Acuity',
    VISUAL_FIELD_CUT = 'Visual Field Cut',
    VISUAL_FIXATION = 'Visual Fixation',
    GAZE_STABILIZATION = 'Gaze Stabilization',
    TRACKING_AND_SCANNING = 'Tracking & Scanning',
    DEPTH_PERCEPTION = 'Depth Perception',
    NEGLECT_SYNDROMES = 'Neglect Syndromes',
}

export enum ORAL_MOTOR_EXAMINATION {
    FACIAL_SYMMETRY = 'Facial Symmetry',
    FACIAL_SENSATION = 'Facial Sensation',
    MANDIBULAR_STRENGTH = 'Mandibular Strength',
    MANDIBULAR_TONE = 'Mandibular Tone',
    MANDIBULAR_ROM = 'Mandibular ROM',
    MANDIBULAR_COORDINATION = 'Mandibular Coordination',
    SALIVA_MANAGEMENT = 'Saliva Management',
    LINGUAL_SENSATION = 'Lingual Sensation',
    LINGUAL_TONE = 'Lingual Tone',
    LINGUAL_STRENGTH = 'Lingual Strength',
    LINGUAL_ROM = 'Lingual ROM',
    LINGUAL_COORDINATION = 'Lingual Coordination',
    LABIAL_SENSATION = 'Labial Sensation',
    LABIAL_TONE = 'Labial Tone',
    LABIAL_STRENGTH = 'Labial Strength',
    LABIAL_ROM = 'Labial ROM',
    LABIAL_COORDINATION = 'Labial Coordination',
}

export enum AUDITORY_COMPREHENSION {
    UNDERSTANDING_YES_NO = 'Understanding yes/no questions',
    FOLLOWING_COMMANDS = 'Following commands',
    DISCRIMINATING_BODY_PARTS = 'Discriminating body parts',
    DISCRIMINATING_OBJECTS = 'Discriminating objects',
    UNDERSTANDING_OPEN_ENDED_QUESTIONS = 'Understanding open ended questions',
    UNDERSTANDING_CONVERSATION = 'Understanding conversation',
    UNDERSTANDING_GESTURES = 'Understanding gestures',
}

export enum READING_COMPREHENSION {
    LETTER_RECOGNITION = 'Letter Recognition',
    WORD_OBJECT_MATCHING = 'Word Object Matching',
    WORD_PICTURE_MATCHING = 'Word Picture Matching',
    PHRASES = 'Phrases',
    SENTENCES = 'Sentences',
    PARAGRAPHS = 'Paragraphs',
    FUNCTIONAL_LANGUAGE = 'Functional Language',
}

export enum WRITTEN_LANGUAGE {
    SIGNATURE = 'Signature',
    LETTERS_TO_SPOKEN_DICTATION = 'Letters to spoken dictation',
    LETTERS_TO_COPY = 'Letters to copy',
    WORDS_TO_DICTATION = 'Words to dictation',
    WORDS_TO_COPY = 'Words to copy',
    SENTENCES_TO_DICTATION = 'Sentences to dictation',
    SENTENCES_TO_COPY = 'Sentences to copy',
}

export enum VERBAL_EXPRESSION {
    MAKING_NEEDS_KNOWN = 'Making needs known',
    CONVERSATION = 'Conversation',
    AUTOMATIC_SPEECH = 'Automatic Speech',
    SINGING = 'Singing',
    REPEATING = 'Repeating',
    NAMING = 'Naming',
    DESCRIBING = 'Describing',
    EXPRESS_WITH_GESTURES = 'Express with gestures',
    DOES_PATIENT_NEED_AAC = 'Does patient need AAC (augmentative/alternative communication)?',
}

export enum MOTOR_SPEECH {
    ARTICULATION = 'Articulation',
    SPEECH_INTELLIGIBILITY = 'Speech Intelligibility',
    SIMPLE_SENTENCES = 'Simple Sentences',
    COMPLEX_SENTENCES = 'Complex Sentences',
    SIMPLE_CONVERSATION = 'Simple Conversation',
    COMPLEX_CONVERSATION = 'Complex Conversation',
    RESONANCE_PROSODY = 'Resonance/Prosody',
    VOCAL_INTENSITY = 'Vocal Intensity',
    SUSTAINED_PHONATION = 'Sustained Phonation',
    FLUENCY = 'Fluency',
    VERBAL_AGILITY_COORDINATION = 'Verbal agility/coordination',
    FORMAL_DYSARTHRIA_APPRAXIA_ASSESSMENT = 'Formal Dysarthria/Apraxia Assessments',
}

export enum VOICE_EVALUATION {
    INFORMAL_ASSESSMENT = 'Informal Assessment',
    VOCAL_HYGIENE = 'Vocal Hygiene',
    DAILY_VOCAL_ACTIVITIES = 'Daily Vocal Activities',
    REFLUX_HISTORY = 'Reflux History',
    PITCH = 'Pitch',
    VOCAL_QUALITY = 'Vocal quality',
    POSTURE = 'Posture',
    FORMAL_VOICE_ASSESSMENT = 'Formal Voice Assessment',
}

export enum GENERAL_PROCESSING {
    ATTENTION = 'Attention',
    INSIGHT = 'Insight',
    ORIENTATION = 'Orientation',
}

export enum MEMORY {
    SHORT_TERM = 'Short Term',
    LONG_TERM = 'Long Term',
    PROCEDURAL = 'Procedural',
    PROSPECTIVE = 'Prospective',
    CATEGORIZATION = 'Categorization',
    JUDGEMENT = 'Judgement',
}

export enum EXECUTIVE_FUNCTIONING {
    VERBAL_SEQUENCING = 'Verbal Sequencing',
    THOUGHT_ORGANIZATION = 'Thought Organization',
    PLANNING = 'Planning',
    CALCULATIONS = 'Calculations',
}

export enum PRAGMATICS {
    INITIATION = 'Initiation',
    REQUESTING = 'Requesting',
    TOPIC_MAINTENANCE = 'Topic maintenance',
    TURN_TAKING = 'Turn taking',
    INHIBITION = 'Inhibition',
    SELF_MONITORING = 'Self Monitoring',
    SELF_CORRECTION = 'Self Correction',
}

export enum STANDARDIZED_COGNITIVE_ASSESSMENTS {
    ORIENTATION = 'Orientation',
    SEQUENCING = 'Sequencing',
    PROBLEM_SOLVING = 'Problem Solving',
    SAFETY_AWARENESS = 'Safety Awareness',
}

export enum SWALLOWING {
    PRESENCE_OF_SWALLOWING_DISORDER = 'Presence of Swallowing Disorder',
    RESPIRATORY_STATUS = 'Respiratory Status',
    DENTITION = 'Dentition',
    ORAL_HYGIENE = 'Oral hygiene',
    PREVIOUS_INSTRUMENTAL_SWALLOWING = 'Previous Instrumental Swallowing Assessments',
    PREVIOUS_DIET_SOLIDS = 'Previous Diet-Solids (IDDSI)',
    PREVIOUS_DIET_LIQUIDS = 'Previous Diet-Liquids (IDDSI)',
    CURRENT_DIET_SOLIDS = 'Current Diet-Solids (IDDSI)',
    CURRENT_DIET_LIQUIDS = 'Current Diet-Liquids (IDDSI)',
    PREVIOUS_DIET_SOLIDS_OTHER = 'Previous Diet-Solids (Other)',
    PREVIOUS_DIET_LIQUIDS_OTHER = 'Previous Diet-Liquids (Other)',
    CURRENT_DIET_SOLIDS_OTHER = 'Current Diet-Solids (Other)',
    CURRENT_DIET_LIQUIDS_OTHER = 'Current Diet-Liquids (Other)',
    LIQUID_INTAKE_METHOD = 'Liquid Intake Method',
    SOLID_INTAKE_METHOD = 'Solid Intake Method',
    WEIGHT = 'Weight',
    SUPERVISION_PRIOR_TO_ONSET = 'Supervision prior to onset',
    ABLE_TO_FEED_SELF = 'Able to feed self?',
    POSITIONING_RELATED_TO_INTAKE = 'Positioning related to intake',
    LARYNGEAL_PHARYNGEAL_EXAMINATION = 'Laryngeal/Pharyngeal Examination',
    GAG_REFLEX = 'Gag reflex',
    VOLITIONAL_COUGH = 'Volitional Cough',
    DRY_SWALLOW = 'Dry Swallow',
    VOCAL_QUALITY = 'Vocal Quality',
    SOLID_TEXTURES_TESTED = 'Solid Textures Tested',
    ORAL_PHARYNGEAL_PERFORMANCE = 'Oral/Pharyngeal Performance',
    LIQUID_TEXTURES_TESTED = 'Liquid Textures Tested',
    LARYNGEAL_PHARYNGEAL_PERFORMANCE = 'Laryngeal/Pharyngeal Performance',
    ESOPHAGEAL_SYMPTOMS_PRESENT = 'Esophageal Symptoms Present',
    STANDARDIZED_SWALLOWING_ASSESSMENTS = 'Standardized Swallowing Assessments',
    ADDITIONAL_ANALYSIS = 'Additional Analysis',
    DIET_RECOMMENDATIONS_SOLIDS = 'Diet Recommendations - Solids',
    DIET_RECOMMENDATIONS_LIQUIDS = 'Diet Recommendations - Liquids',
    INSTRUMENTAL_SWALLOWING_ASSESSMENT_INDICATED = 'Instrumental Swallowing Assessment Indicated?',
    COMPENSATORY_SWALLOWING_STRATEGIES = 'Compensatory Swallowing Strategies Recommended',
}
export enum Doc_Notes {
    Daily_note = 'dailyNote',
    Progress = 'progressNote',
    UPOC = 'updatedPlanOfCareNote',
    Re_Cert = 'recertificationNote',
    Discharge = 'dischargeNote',
    EVALUATION = 'evaluation',
    Addendum = 'addendum',
}
export enum EXPORT_DOC_NOTES {
    DAILY_NOTE = 'daily-note',
    PROGRESS_NOTE = 'progress-note',
    EVALUATION = 'evaluation-note',
    DISCHARGE_NOTE = 'discharge-note',
    RECERTIFICATION = 're-certification-note',
}
export enum EXPORT_DOC_NOTES_KEYS {
    DAILY_NOTE = 'dailyNote',
    PROGRESS_NOTE = 'progressNote',
    EVALUATION = 'evaluationNote',
    DISCHARGE_NOTE = 'dischargeNote',
    RECERTIFICATION = 'recertificationNote',
}

export const MODAL_HEADINGS = {
    UNMARK_ASSESSMENT_ITEM_AS_A_GOAL: 'Unmark as a goal',
    DELETE_ASSESSMENT_ITEM: 'Delete Assessment',
};

export const MODAL_MESSAGES = {
    DELETE_ASSESSMENT_ITEM_GOALS:
        'There are Goals associated with this assessment item. Are you sure you want to delete?',
    UNMARK_ASSESSMENT_ITEM_AS_A_GOAL:
        'Unmarking this item would also delete its associated goal and all its progress. Do you want to proceed ?',
    UNMARK_ASSESSMENT_ITEM_AND_DISCONTINUE_GOALS:
        'Unmarking this item would also discontinue its goal. Do you want to proceed?',
};

export const PATIENT_DEMOGRAPHICS = {
    MEDICAL_DIAGNOSIS: 'Medical Diagnosis',
    TREATMENT_DIAGNOSIS: 'Treatment Diagnosis',
    PRECAUTIONS: 'Precautions',
    CONTRAINDICATIONS: 'Contraindications',
};

// value must be camel-case to support search in searchable-list component
export const SEARCHABLE_LIST_NAMES = {
    MEDICAL_DIAGNOSIS: 'medicalDiagnosis',
    TREATMENT_DIAGNOSIS: 'treatmentDiagnosis',
    CPT_CODES: 'cptCodes',
    COMORBIDITY: 'comorbidity',
};

export enum AssistiveDevices {
    NONE = 'None',
    SHOWER_CHAIR = 'Shower Chair',
    GRAB_BARS = 'Grab Bars',
    LONG_HANDLE_SHOWER_HOSE = 'Long Handle Shower Hose',
    WASH_MIT = 'Wash Mit',
    BED_RAILS = 'Bed Rails',
    WEIGHTED_UTENSILS = 'Weighted Utensils',
    DIVIDED_PLATE = 'Divided Plate',
    REACHER = 'Reacher',
    LONG_HANDLED_SHOE_HORN = 'Long Handled Shoe Horn',
    ADAPTIVE_LISTENING_DEVICE = 'Adaptive Listening Device',
    BUTTON_HOOK = 'Button Hook',
    TRANSFER_BENCH = 'Transfer Bench',
    WHEELCHAIR_RAMP = 'Wheelchair Ramp',
    ELEVATED_COMMODE_SEAT = 'Elevated Commode Seat',
    COMMODE_3_IN_1 = '3 in 1 Commode',
    DRESSING_STICK = 'Dressing Stick',
    SOCK_AID = 'Sock Aid',
    LONG_HANDLED_SPONGE = 'Long Handled Sponge',
    LEG_LIFTER = 'Leg Lifter',
    NOSEY_CUP = 'Nosey Cup',
    PLATE_GUARD = 'Plate Guard',
    DYCEM = 'Dycem',
    ADAPTED_PLATES_AND_BOWLS = 'Adapted Plates and Bowls',
    NON_SKID_PLATES_AND_BOWLS = 'Non-Skid Plates and Bowls',
    UTENSIL_HOLDERS_FOR_BETTER_GRIP = 'Utensil Holders for Better Grip',
    SPECIALIZED_AND_AUTOMATED_FEEDING_DEVICES = 'Specialized and Automated Feeding Devices',
    SPECIALIZED_COOKING_UTENSILS = 'Specialized Cooking Utensils',
    PENCIL_GRIP = 'Pencil Grip',
    WEIGHTED_PENS_AND_PENCILS = 'Weighted Pens and Pencils',
    PENS_AND_PENCILS_OF_VARYING_SIZES = 'Pens and Pencils of Varying Sizes, Shapes and Textures',
    ADAPTED_TOOTH_BRUSHES = 'Adapted Tooth Brushes',
    DENTURE_BRUSHES = 'Denture Brushes',
    ORAL_IRRIGATOR = 'Oral Irrigator',
    ADAPTIVE_DEVICE_FOR_TOOTHPASTE = 'Adaptive Device for Toothpaste',
    WHEELCHAIR_ADAPTIVE_TECHNOLOGY = 'Wheelchair Adaptive Technology',
    OTHER = 'Other',
    SLIDE_BOARD = 'Slide Board',
    HOYER_LIFT = 'Hoyer Lift',
}

export enum EVAL_ONLY {
    EVAL_WITHOUT_POC = 'planOfCareOnlyEval',
    EVALUATION_TYPE = 'evaluationType',
}

export enum Note_Type {
    Self = 'self',
    Auto = 'auto',
}
