import { Component, OnDestroy } from '@angular/core';
import { ROUTER_UTILS } from '@app/helpers/utils/router.utils';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { PatientState } from '@app/store/store.interface';
import { AuthService, StoreService } from '@app/services';
import { FacilityAdmission } from '@app/models';
import { PayorInformation, TherapyAdmission } from '@app/interfaces';
import { take } from 'rxjs/operators';
import { Patient, TabItem } from '@app/interfaces';
import { ToasterService } from '@app/services/toaster.service';
import { PayorInformationService } from '@app/services/payorInformation.service';
import { AuthPolicies } from '@app/helpers/utils';

@Component({
    selector: 'app-stepper-nav',
    templateUrl: './stepper-nav.component.html',
    styleUrls: ['./stepper-nav.component.scss'],
})
export class StepperNavComponent implements OnDestroy {
    personalInformation: string = ROUTER_UTILS.config.addPatients.personalInformation;
    facilityAdmission: string = ROUTER_UTILS.config.addPatients.facilityAdmission;
    therapyAdmission: string = ROUTER_UTILS.config.therapyAdmission.root;
    payorInformation: string = ROUTER_UTILS.config.payorInformation.root;
    filesAndDocuments: string = ROUTER_UTILS.config.addPatients.filesAndDocuments;
    policies = AuthPolicies;
    tab$: Observable<any>;
    patientData: Patient;
    localTab: string;
    personalInformation$: Observable<Patient>;
    facilityAdmission$: Observable<FacilityAdmission>;
    therapyAdmission$: Observable<TherapyAdmission[]>;
    therapyAdmissionData: any;
    payorInformationData: any;

    facilityId: string;
    tabs: TabItem[];
    patientObs: any;
    payorInformation$: Observable<PayorInformation>;

    constructor(
        private store: Store<{
            addPatient: any;
            patient: PatientState;
            facilityAdmission: FacilityAdmission;
            therapyAdmission: TherapyAdmission[];
            payorInformation: PayorInformation;
        }>,

        private storeService: StoreService,
        private toasterService: ToasterService,
        private payorInformationService: PayorInformationService,
        private authService: AuthService
    ) {
        this.tab$ = store.pipe(select('addPatient'));
        this.personalInformation$ = store.pipe(select('patient'));
        this.facilityAdmission$ = store.pipe(select('facilityAdmission'));
        this.therapyAdmission$ = store.pipe(select('therapyAdmission'));
        this.payorInformation$ = store.pipe(select('payorInformation'));

        this.localTab = this.personalInformation;
        this.store.pipe(select('addPatient')).subscribe((addPatient) => {
            // reset cached payor on tab change
            this.payorInformationService.payorJSON = null;
            this.localTab = addPatient.selectedTab;
        });

        this.tabs = [
            {
                url: 'personal-information',
                title: 'Personal Information',
                highlight: false,
                underline: true,
                isDone: false,
                unauthorized: !this.authService.applyPolicy(this.policies.Patient.Add) && !this.authService.applyPolicy(this.policies.Patient.Edit),
            },
            {
                url: 'facility-admission',
                title: 'Facility Admission',
                highlight: false,
                underline: false,
                isDone: true,
                unauthorized: !this.authService.applyPolicy(this.policies.Patient.Add) && !this.authService.applyPolicy(this.policies.Patient.Edit),
            },
            {
                url: 'therapy-admission',
                title: 'Therapy Case',
                highlight: false,
                underline: false,
                isDone: true,
                unauthorized: !this.authService.applyPolicy(this.policies.Patient.Add) && !this.authService.applyPolicy(this.policies.Patient.Edit),
            },
            {
                url: 'payor-information',
                title: 'Payor Information',
                highlight: false,
                underline: false,
                isDone: true,
                unauthorized: !this.authService.applyPolicy(this.policies.Patient.Add) && !this.authService.applyPolicy(this.policies.Patient.Edit),
            },
        ];

        // Tabs initial events if patient is not already initialized (e.g. patient board)
        this.personalInformation$.pipe(take(1)).subscribe((data) => {
            if (!data.id) this.localTab = this.personalInformation;
            this.storeService.admissionStepper = this.localTab;
        });

        // Tabs subscribed events
        this.tab$.subscribe((data) => {
            this.tabs.forEach((tab) => {
                // check for selected tab
                if (tab.url === data.selectedTab) {
                    tab.highlight = true;
                } else tab.highlight = false;
            });
        });
        this.personalInformation$.subscribe((_personalInformation) => {
            if (_personalInformation.id !== null) {
                this.patientData = _personalInformation;
                this.tabs[0].underline = true;
                this.tabs[0].isDone = true;
                this.tabs[1].underline = true;
            } else {
                this.patientData = null;
                this.tabs[0].isDone = false;
            }
        });
        this.facilityAdmission$.subscribe((_facilityAdmission) => {
            if (_facilityAdmission.id !== null) {
                this.facilityId = _facilityAdmission.id;

                this.tabs[1].underline = true;
                this.tabs[1].isDone = true;
                this.tabs[2].underline = true;
                this.tabs[3].underline = true;
            } else {
                this.facilityId = undefined;
                this.tabs[1].underline = false;
                this.tabs[2].underline = false;
                this.tabs[3].underline = false;
                this.tabs[1].isDone = false;
                this.tabs[1].highlight = false;
            }
        });
        this.therapyAdmission$.subscribe((_therapyAdmission) => {
            if (_therapyAdmission[0]) {
                this.therapyAdmissionData = _therapyAdmission;
                this.tabs[2].underline = true;
                this.tabs[2].isDone = true;
            } else {
                this.therapyAdmissionData = null;
                this.tabs[2].isDone = false;
                this.tabs[2].highlight = false;
            }
        });
        this.payorInformation$.subscribe((_payorInformation) => {
            if (_payorInformation._id || _payorInformation.patient) {
                this.payorInformationData = _payorInformation;
                this.tabs[3].underline = true;
                this.tabs[3].isDone = true;
            } else {
                this.payorInformationData = null;
                this.tabs[3].isDone = false;
                this.tabs[3].highlight = false;
            }
        });
    }
    ngOnDestroy(): void {
        if (this.patientObs) {
            this.patientObs.unsubscribe();
        }
    }

    tabChange(event) {
        const isAllowed = this.authService.applyPolicy(this.policies.Patient.Add) || this.authService.applyPolicy(this.policies.Patient.Edit);

        if (event.title === 'Personal Information') {
            // if (this.patientData?.id) {
            //     isAllowed = this.authService.applyPolicy(this.policies.Patient.Edit);
            // } else {
            //     isAllowed = this.authService.applyPolicy(this.policies.Patient.Add);
            // }
            if (isAllowed) {
                this.onChangeTab(this.personalInformation);
            }
        } else if (event.title === 'Facility Admission') {
            // if (this.facilityId) {
            //     isAllowed = this.authService.applyPolicy(this.policies.Patient.Edit);
            // } else {
            //     isAllowed = this.authService.applyPolicy(this.policies.Patient.Add);
            // }
            if (isAllowed) {
                this.onChangeTab(this.facilityAdmission);
            }
        } else if (event.title === 'Therapy Case') {
            // if (this.therapyAdmissionData && this.therapyAdmissionData[0]) {
            //     isAllowed = this.authService.applyPolicy(this.policies.Patient.Edit);
            // } else {
            //     isAllowed = this.authService.applyPolicy(this.policies.Patient.Add);
            // }
            if (isAllowed) {
                this.onChangeTab(this.therapyAdmission);
            }
        } else if (event.title === 'Payor Information') {
            // if (this.payorInformationData) {
            //     isAllowed = this.authService.applyPolicy(this.policies.Patient.Edit);
            // } else {
            //     isAllowed = this.authService.applyPolicy(this.policies.Patient.Add);
            // }

            if (isAllowed) {
                this.onChangeTab(this.payorInformation);
            }
        }
    }
    onChangeTab(event) {
        if (event !== this.personalInformation && this.patientData?.id == null) {
            this.toasterService.show({ title: 'Failure', body: 'Please add / select patient first', type: 'error' });
            //this.alertService.error(' Please add / select patient first ');
            return;
        }

        if (event == this.facilityAdmission && !this.patientData.id && this.facilityId == undefined) {
            this.toasterService.show({
                title: 'Failure',
                body: ' Please add facility admission details first',
                type: 'error',
            });
            return;
        }
        if ((event == this.therapyAdmission || event == this.payorInformation) && this.facilityId == undefined) {
            this.toasterService.show({
                title: 'Failure',
                body: ' Please add facility admission details first',
                type: 'error',
            });
            return;
        }
        this.storeService.admissionStepper = event;
    }
}
