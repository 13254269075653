import { Component, Input, OnInit } from '@angular/core';
import { DocumentationReportService } from '@app/services';

@Component({
    selector: 'app-upoc-report',
    templateUrl: './upoc-report.component.html',
    styleUrls: ['./upoc-report.component.scss'],
})
export class UpocReportComponent implements OnInit {
    @Input() reportId: string;
    isDataLoaded: boolean;

    constructor(private docReportService: DocumentationReportService) {}

    async ngOnInit(): Promise<void> {
        this.isDataLoaded = await this.docReportService.prepareUpocData(this.reportId);
        if (this.isDataLoaded) {
            setTimeout(() => {
                this.docReportService.countLoadedNotes();
            }, 0);
        }
    }
}
