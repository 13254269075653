import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonSize } from '@app/interfaces';

@Component({
    selector: 'app-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
    @Input() size: ButtonSize;
    @Input() type: string;
    @Input() text: string;
    @Input() icon: string;
    @Input() filled: boolean;
    @Input() class: string;
    @Input() iconClass: string;
    @Input() disabled: boolean;
    @Input() rightIcon: boolean;
    @Input() circle = false;
    @Input() tooltipText: string;
    @Input() tooltipPlacement: string;
    @Input() iconStyle: string;
    @Input() style: string;
    @Input() innovaBtn = true;
    @Input() innovaBtnLayout = false;
    @Input() buttonClass: string;
    @Output() onClick: EventEmitter<void> = new EventEmitter();

    generateClass() {
        return `${this.innovaBtnLayout === true ? 'innova-btn-layout' : ''} ${
            this.innovaBtn === false ? '' : 'innova-btn'
        } ${this.size ? `btn-${this.size}` : ''} ${this.type ? `btn-${this.type}` : ''} ${
            this.circle === true
                ? 'btn btn-secondary btn-circle'
                : 'pl-4 pr-4 d-inline-flex flex-row align-items-center justify-content-center'
        } ${this.class} ${this.buttonClass}`;
    }
}
