import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthPolicies } from '@app/helpers/utils';
import { AuthService, FacilityManagementService, OrganizationManagementService, StoreService } from '@app/services';
import { Subject, takeUntil } from 'rxjs';
import { Button } from '@app/shared/toggle/toggle.component';

const LABEL = {
    active: 'Active',
    inactive: 'Inactive',
};
@Component({
    selector: 'app-facility-layout',
    templateUrl: './facility-layout.component.html',
    styleUrls: ['./facility-layout.component.scss'],
})
export class FacilityLayoutComponent implements OnInit, OnDestroy {
    facilityListData = [];
    buttons: Button[] = [];
    loadingObj: any;
    isAllowedToAdd = false;

    private destroy$: Subject<void> = new Subject<void>();
    filterFacilityData = [];
    currentTab = LABEL.active;
    enablePrint: boolean;
    eventEnded: string;
    selectedFacilities = [];
    isFacilitySelected = false;
    filtersReset = false;
    selectedType = '';
    selectedState = '';
    selectedOrganization = '';
    constructor(
        private facilityManagement: FacilityManagementService,
        private storeService: StoreService,
        private authService: AuthService,
        private organizationService: OrganizationManagementService
    ) {
        this.buttons = [
            { text: LABEL.active, isSelected: true, isDisabled: false },
            { text: LABEL.inactive, isSelected: false, isDisabled: false },
        ];
        this.loadingObj = this.storeService.getLoadingState();
        this.selectedOrganization = this.organizationService._currentOrganization.name;
    }

    ngOnInit(): void {
        this.isAllowedToAdd = this.authService.applyPolicy(AuthPolicies.Facilities.Add);
        this.facilityManagement._Facilities.pipe(takeUntil(this.destroy$)).subscribe((facilities) => {
            if (Array.isArray(facilities) && facilities) {
                this.facilityListData = facilities.sort((a, b) =>
                    (a.facilityName || '').toLowerCase().localeCompare((b.facilityName || '').toLowerCase())
                  );                  
                this.facilityListData = this.transformFacilityData(this.facilityListData);
            }
        });
        this.navUpdated(this.currentTab);
    }
    public SKELETON_CSS = {
        CELL: {
            width: '96%',
            height: '3.4em',
            margin: '0 2em 0.1em 2em',
        },
    };
    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
    navUpdated(e): void {
        this.currentTab = e;
        this.filterFacilityData = this.facilityListData.filter(
            (facility) => facility.status === this.currentTab.toLowerCase()
        );
        this.onResetFilters();
    }
    changeFilters(e): void {
        this.selectedType = e.type;
        this.selectedState = e.state;
        this.filterFacilityData = this.facilityListData.filter((facility) => {
            const nameCondition = facility.facilityName.toLowerCase().includes(e.facilityName);
            //const integrated condition = e.integrated === "All" || item.type === filterType;
            const typeCondition = e.type === 'All' || facility.facilityType === e.type;
            const stateCondition = e.state === 'All' || facility.mailingAddress.state === e.state;

            return (
                nameCondition && typeCondition && stateCondition && facility.status === this.currentTab.toLowerCase()
            );
        });
    }

    onResetFilters() {
        this.filtersReset = !this.filtersReset;
    }

    transformFacilityData(result: any[]): any[] {
        return result.map((facility) => ({
            ...facility,
            mailingAddress: {
                ...facility.mailingAddress,
                state: this.getStateInitials(facility.mailingAddress.state),
            },
        }));
    }

    getStateInitials(stateName: string): string {
        const states = this.facilityManagement.getUSAStates();
        const state = states.find((s) => s.stateName === stateName);
        return state ? state.stateCode : stateName;
    }
    // For checkbox selection
    setSelectedFacilities(event) {
        this.selectedFacilities = event;
    }
    // For Dropdown selection disable
    setIsFacilitySelected(event) {
        this.isFacilitySelected = event;
    }
    onEventEnded(event) {
        this.eventEnded = event;
        setTimeout(() => {
            this.eventEnded = '';
        }, 0);
    }
}
