<ng-container *ngIf="(loadingObs | async)?.isLoading; else loaded" [ngTemplateOutlet]="skeleton"></ng-container>
<ng-template #loaded>
    <div>
        <div *ngIf="!(loadingObs | async)?.isLoading" [class]="printMode ? 'col-md-11' : ''">
            <div *ngIf="!printMode" class="info-bar-padding" style="height: 65px">
                <div *ngIf="!evalHistorySide && currentDocSigned" class="w-100">
                    <app-info-bar
                        [documentType]="DOCUMENTATION_CONSTANTS.EVALUATION"
                        [statusChip]="{
                            show: currentAdmission?.documentation.evaluation.evaluationType.evalOnly ? true : false,
                            text: 'Eval Only'
                        }"
                        [disableButtons]="isDifferentDisciplineTherapist"
                        [isCoSign]="showCoSign"
                    ></app-info-bar>
                </div>
            </div>
            <div class="row">
                <div
                    class="col-lg-10 col-md-9 col-sm-9 mb-5"
                    [ngClass]="evalHistorySide == true ? 'col-lg-10' : 'col-lg-12'"
                    [class]="printMode ? '' : 'w-100'"
                >
                    <div id="medicalDiagnosisSection">
                        <app-view-mode-table
                            [tableTitle]="medicalDiagnosis.title"
                            [tableCol]="medicalDiagnosis.cols"
                            [tableData]="medicalDiagnosisData"
                            [pagination]="false"
                            [showCount]="false"
                            [headerFull]="true"
                            tableHeight="height:250px"
                        >
                        </app-view-mode-table>
                    </div>

                    <div class="row pt-lg-5 pt-3">
                        <div class="col-lg-6 col-md-12 mb-3" id="treatmentDiagnosisSection">
                            <app-view-mode-table
                                [tableTitle]="treatmentDiagnosis.title"
                                [tableCol]="treatmentDiagnosis.cols"
                                [tableData]="treatmentDiagnosis.data"
                                [pagination]="false"
                                [showCount]="false"
                                [headerFull]="true"
                                tableHeight="height:250px"
                            >
                            </app-view-mode-table>
                        </div>

                        <div class="col-lg-6 col-md-12" id="priorHospitalizationDatesSection">
                            <app-view-mode-table
                                [tableTitle]="priorHospitalization.title"
                                [tableCol]="priorHospitalization.cols"
                                [tableData]="priorHospitalization.data"
                                [pagination]="false"
                                [showCount]="false"
                                [headerFull]="true"
                                tableHeight="height:250px"
                            >
                            </app-view-mode-table>
                        </div>
                    </div>

                    <div class="row pt-lg-5 pt-3">
                        <div class="col-lg-6 col-md-12 mb-3" id="pastMedicalHistorySection">
                            <app-view-mode-table
                                [tableTitle]="pastMedicalHistory.title"
                                [tableCol]="pastMedicalHistory.cols"
                                [tableData]="pastMedicalHistory.data"
                                [pagination]="false"
                                [showCount]="false"
                                [headerFull]="true"
                                tableHeight="height:250px"
                            >
                            </app-view-mode-table>
                        </div>
                        <div class="col-lg-6 col-md-12" id="reasonForReferralSection">
                            <app-view-mode-paragraph
                                [paragraphTitle]="reasonReferral.title"
                                [paragraph]="reasonReferral.paragraph"
                                [innerHtml]="reasonReferral.paragraph"
                            >
                            </app-view-mode-paragraph>
                        </div>
                    </div>

                    <div class="row pt-lg-5 pt-3">
                        <div class="col-lg-6 col-md-12 mb-3" id="chiefComplaintSection">
                            <app-view-mode-paragraph
                                [paragraphTitle]="chiefComplaint.title"
                                [paragraph]="chiefComplaint.paragraph"
                                [innerHtml]="chiefComplaint.paragraph"
                            >
                            </app-view-mode-paragraph>
                        </div>
                        <div class="col-lg-6 col-md-12" id="precautionsSection">
                            <app-view-mode-table
                                [tableTitle]="precautions.title"
                                [tableCol]="precautions.cols"
                                [tableData]="precautions.data"
                                [pagination]="false"
                                [showCount]="false"
                                tableHeight="height:250px"
                            >
                            </app-view-mode-table>
                        </div>
                    </div>

                    <div class="row pt-lg-5 pt-3">
                        <div class="col-lg-6 col-md-12 mb-3" id="medicationsSection">
                            <app-view-mode-table
                                [tableTitle]="medication.title"
                                [tableCol]="medication.cols"
                                [tableData]="medication.data"
                                [pagination]="false"
                                [showCount]="false"
                                tableHeight="height:250px"
                            >
                            </app-view-mode-table>
                        </div>
                        <div class="col-lg-6 col-md-12" id="contraindicationsSection">
                            <app-view-mode-table
                                [tableTitle]="contraindications.title"
                                [tableCol]="contraindications.cols"
                                [tableData]="contraindications.data"
                                [pagination]="false"
                                [showCount]="false"
                                [headerFull]="true"
                                tableHeight="height:250px"
                            >
                            </app-view-mode-table>
                        </div>
                    </div>

                    <div class="row pt-lg-5 pt-3">
                        <div class="col-lg-6 col-md-12 mb-3" id="patientGoalsSection">
                            <app-view-mode-table
                                [tableTitle]="patientsGoals.title"
                                [tableCol]="patientsGoals.cols"
                                [tableData]="patientsGoals.data"
                                [pagination]="false"
                                [showCount]="false"
                                tableHeight="height:250px"
                            >
                            </app-view-mode-table>
                        </div>

                        <div class="col-lg-6 col-md-12" id="priorLevelOfFunctionSection">
                            <app-view-mode-paragraph
                                [paragraphTitle]="priorLevel.title"
                                [paragraph]="priorLevel.paragraph"
                                [innerHtml]="priorLevel.paragraph"
                            >
                            </app-view-mode-paragraph>
                        </div>
                    </div>

                    <div class="row pt-lg-5 pt-3">
                        <div class="col-lg-6 col-md-12 mb-3" id="socialDeterminantsOfHealthSection">
                            <app-view-mode-table
                                [tableTitle]="socialDeterminants.title"
                                [tableCol]="socialDeterminants.cols"
                                [tableData]="socialDeterminants.data"
                                [pagination]="false"
                                [showCount]="false"
                                tableHeight="height:250px"
                            >
                            </app-view-mode-table>
                        </div>

                        <div class="col-lg-6 col-md-12" id="environmentalFactorsSection">
                            <app-view-mode-table
                                [tableTitle]="environmentalFactors.title"
                                [tableCol]="environmentalFactors.cols"
                                [tableData]="environmentalFactors.data"
                                [pagination]="false"
                                [showCount]="false"
                                tableHeight="height:250px"
                            >
                            </app-view-mode-table>
                        </div>
                    </div>

                    <div class="row pt-lg-5 pt-3">
                        <div class="col-lg-6 col-md-12 mb-3" id="previousTherapySection">
                            <app-view-mode-paragraph
                                [paragraphTitle]="previousTherapy.title"
                                [paragraph]="previousTherapy.paragraph"
                                [innerHtml]="previousTherapy.paragraph"
                            >
                            </app-view-mode-paragraph>
                        </div>

                        <div class="col-lg-6 col-md-12" id="careGiverEducationSection">
                            <app-view-mode-paragraph
                                [paragraphTitle]="caregiverEducation.title"
                                [paragraph]="caregiverEducation.paragraph"
                                [innerHtml]="caregiverEducation.paragraph"
                            ></app-view-mode-paragraph>
                        </div>
                    </div>

                    <div class="pt-lg-5 pt-3" id="impairmentsSection">
                        <app-view-mode-table
                            [tableTitle]="impairments.title"
                            [tableCol]="impairments.cols"
                            [tableData]="impairments.data"
                            [pagination]="false"
                            [showCount]="false"
                            [headerFull]="true"
                            tableHeight="height:200px"
                        >
                        </app-view-mode-table>
                    </div>

                    <div class="pt-lg-5 pt-3" id="functionalDeficitsSection">
                        <app-view-mode-table
                            [tableTitle]="functionalDeficits.title"
                            [tableCol]="functionalDeficits.cols"
                            [tableData]="functionalDeficits.data"
                            [pagination]="false"
                            [showCount]="false"
                            tableHeight="height:200px"
                        >
                        </app-view-mode-table>
                    </div>

                    <div class="pt-lg-5 pt-3" id="standardizedTestsSection">
                        <app-view-mode-table
                            [tableTitle]="standardizedTest.title"
                            [tableCol]="standardizedTest.cols"
                            [tableData]="standardizedTest.data"
                            [pagination]="false"
                            [showCount]="false"
                            tableHeight="height:200px"
                        >
                        </app-view-mode-table>
                    </div>

                    <div class="row pt-lg-5 pt-3">
                        <div class="col-lg-6 col-md-12 mb-3" id="therapyNecessitySection">
                            <app-view-mode-paragraph
                                [paragraphTitle]="therapyNecessity.title"
                                [paragraph]="therapyNecessity.paragraph"
                                [innerHtml]="therapyNecessity.paragraph"
                            >
                            </app-view-mode-paragraph>
                        </div>
                        <div class="col-lg-6 col-md-12" id="interventionApproachesSection">
                            <app-view-mode-paragraph
                                [paragraphTitle]="interventionApproach.title"
                                [paragraph]="interventionApproach.paragraph"
                                [innerHtml]="interventionApproach.paragraph"
                            ></app-view-mode-paragraph>
                        </div>
                    </div>
                    <div *ngIf="!isEvalOnlyTherapy" class="mt-lg-5 mt-3" id="frequencyAndDurationSection">
                        <app-view-mode-table
                            [tableTitle]="frequencyDuration.title"
                            [tableCol]="frequencyDuration.cols"
                            [tableData]="frequencyDuration.data"
                            [pagination]="false"
                            [showCount]="false"
                            [headerFull]="true"
                            tableHeight="height:200px"
                        >
                        </app-view-mode-table>
                    </div>
                    <div *ngIf="!isEvalOnlyTherapy" class="mt-lg-5 mt-3" id="throughDateSection">
                        <app-view-mode-table
                            [tableTitle]="certification.title"
                            [tableCol]="certification.cols"
                            [tableData]="certification.data"
                            [pagination]="false"
                            [showCount]="false"
                            [headerFull]="true"
                            tableHeight="height:250px"
                        >
                        </app-view-mode-table>
                    </div>
                    <div *ngIf="!isEvalOnlyTherapy" class="mt-lg-5 mt-3" id="goalsSection">
                        <app-view-mode-goals tableTitle="Patient goals" [goalData]="goal" [isHistoryMode]="true">
                        </app-view-mode-goals>
                    </div>
                    <div *ngIf="!isEvalOnlyTherapy" class="mt-lg-5 mt-3" id="skilledServicesSection">
                        <app-view-mode-table
                            [tableTitle]="cptCodes.title"
                            [tableCol]="cptCodes.cols"
                            [tableData]="cptCodes.data"
                            [pagination]="false"
                            [showCount]="false"
                            [headerFull]="true"
                            tableHeight="height:250px"
                        ></app-view-mode-table>
                    </div>

                    <div *ngIf="!isEvalOnlyTherapy" class="mt-lg-5 mt-3" id="goalPurposeSection">
                        <app-view-mode-paragraph
                            [paragraphTitle]="prognosisAchieving.title"
                            [paragraph]="prognosisAchieving.paragraph"
                            [innerHtml]="prognosisAchieving.paragraph"
                        ></app-view-mode-paragraph>
                    </div>

                    <div *ngIf="!isEvalOnlyTherapy" class="row mt-lg-5 mt-3">
                        <div class="col-lg-6 col-md-12 mb-3" id="dischargePlansSection">
                            <app-view-mode-paragraph
                                [paragraphTitle]="anticipatedDischarge.title"
                                [paragraph]="anticipatedDischarge.paragraph"
                                [innerHtml]="anticipatedDischarge.paragraph"
                            ></app-view-mode-paragraph>
                        </div>

                        <div class="col-lg-6 col-md-12" id="dischargeDestinationSection">
                            <app-view-mode-paragraph
                                [paragraphTitle]="anticipatedDestination.title"
                                [paragraph]="anticipatedDestination.paragraph"
                                [innerHtml]="anticipatedDestination.paragraph"
                            ></app-view-mode-paragraph>
                        </div>
                    </div>

                    <div class="row mt-lg-5 mt-3">
                        <div class="col-lg-6 col-md-12 mb-3" id="evalTypeSection">
                            <app-view-mode-paragraph
                                [paragraphTitle]="evalType.title"
                                [paragraph]="evalType.paragraph"
                                [innerHtml]="evalType.paragraph"
                                [pinToRightText]="isEvalOnlyTherapy ? minutes.paragraph : ''"
                            >
                            </app-view-mode-paragraph>
                        </div>
                        <div class="col-lg-6 col-md-12" id="descriptionSection">
                            <app-view-mode-paragraph
                                [paragraphTitle]="evalDescription.title"
                                [paragraph]="evalDescription.paragraph"
                                [innerHtml]="evalDescription.paragraph"
                            >
                            </app-view-mode-paragraph>
                        </div>
                    </div>

                    <div *ngIf="isEvalOnlyTherapy" class="row mt-lg-5 mt-3" id="evalOnlyReasonSection">
                        <div class="col-md-12">
                            <app-view-mode-paragraph
                                [paragraphTitle]="evalOnlyReason.title"
                                [paragraph]="evalOnlyReason.paragraph"
                                [innerHtml]="evalOnlyReason.paragraph"
                            >
                            </app-view-mode-paragraph>
                        </div>
                    </div>

                    <div class="custom-card mt-lg-5 mt-3" id="addendumSection">
                        <div [ngClass]="!printMode && !evalHistorySide ? 'addendumBox' : ''">
                            <app-view-mode-table
                                [tableTitle]="addendum.title"
                                [tableCol]="addendum.cols"
                                [tableData]="addendum.data ? addendum.data : []"
                                [pagination]="false"
                                [showCount]="false"
                                [headerFull]="true"
                                [insideCard]="false"
                                tableHeight="250px"
                            ></app-view-mode-table>
                        </div>

                        <div id="addendumNote" class="pt-4 px-1" *ngIf="!printMode && !evalHistorySide">
                            <div class="col-md-12">
                                <app-text-box
                                    [isSaveButton]="true"
                                    backgroundClass="bg-body-background"
                                    (emitData)="updateAddendum($event)"
                                    [disabled]="(!authService.isManager && !authService.isTherapist) || admissionDischarged"
                                    [suggestionBoxValue]="currentAddendum"
                                    label="Add new addendum"
                                    maxLength="5000"
                                ></app-text-box>
                            </div>
                        </div>

                        <div class="row my-3 pr-3" *ngIf="!printMode && !evalHistorySide">
                            <div class="col-md-12">
                                <app-button
                                    type="btn btn-outline-secondary"
                                    text="Add"
                                    icon="chevron_right"
                                    [rightIcon]="true"
                                    class="ml-1 add-btn"
                                    [tooltipText]="tooltipCheck()"
                                    tooltipPlacement="left"
                                    [disabled]="isDifferentDisciplineTherapist"
                                    (click)="!admissionDischarged && addAddendumNote()"
                                    [style]="
                                        !admissionDischarged
                                            ? authService.isManager || authService.isTherapist
                                                ? ''
                                                : 'opacity:0.5'
                                            : 'opacity:0.5'
                                    "
                                ></app-button>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="showCoSign" class="co-sign-btn-holder bg-body-background text-right">
                        <app-button
                            type="btn btn-secondary"
                            text="Co-sign"
                            icon="chevron_right"
                            iconClass="small ml-1"
                            class="ml-2 mt-2"
                            buttonClass="py-2"
                            [rightIcon]="true"
                            [disabled]="!enableCoSignBtn"
                            (onClick)="onCoSignClicked()"
                        ></app-button>
                    </div>
                    <div
                        *ngIf="currentDocSigned"
                        class="open-history-btn text-white d-flex align-items-center"
                        [ngClass]="evalHistorySide == true ? 'hide' : 'show'"
                        (click)="openEvalHistory()"
                    >
                        <span class="material-icons-round text-white">history</span>
                        Open Edit History
                    </div>
                </div>
                <div class="col-lg-2 col-md-3 col-sm-3" *ngIf="!printMode">
                    <app-history (closed)="closeHistorySideBar($event)" header="Edit History" *ngIf="evalHistorySide">
                        <div
                            id="changeInstance"
                            *ngFor="let history of changeHistory; let i = index; let first = first; let last = last"
                            (click)="prepareChangeInstanceData(i)"
                        >
                            <div
                                *ngIf="!first || last"
                                class="main-body p-4"
                                [class.selected-changeInstance]="i == selectedChangeInstance"
                            >
                                <span class="text-secondary body-medium-bold">{{
                                    last
                                        ? (history.signed.signDate | date: 'MMM d, y, h:mm a')
                                        : (history.changeHistory.editedAt | date: 'MMM d, y, h:mm a')
                                }}</span>
                                <ng-container *ngIf="history?.changeHistory?.changedFields?.length > 0 && !last">
                                    <p
                                        class="change-item-text"
                                        [ngClass]="{
                                            'underline-item':
                                                change.fieldKey === selectedLabel && i === selectedChangeInstance
                                        }"
                                        *ngFor="let change of history.changeHistory.changedFields"
                                        (click)="scroll(change.fieldKey, i)"
                                    >
                                        {{
                                            'appTranslation.pages.documentation.evaluation' + '.' + change.fieldKey
                                                | translate
                                        }}
                                    </p>
                                </ng-container>
                                <ng-container *ngIf="last">
                                    <p class="change-item-text">Original Document</p>
                                </ng-container>
                                <p class="mb-0">
                                    <svg
                                        width="8"
                                        height="8"
                                        viewBox="0 0 8 8"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <circle cx="4" cy="4" r="3.5" fill="#69A981" />
                                    </svg>
                                    <span class="ml-2 body-small">{{
                                        last
                                            ? history.signed.signedBy.lastName +
                                              ', ' +
                                              history.signed.signedBy.firstName
                                            : history.changeHistory.signedBy.lastName +
                                              ', ' +
                                              history.changeHistory.signedBy.firstName
                                    }}</span>
                                </p>
                            </div>
                            <div class="bg-secondary-a20" style="height: 1px"></div>
                        </div>
                    </app-history>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #skeleton>
    <div>
        <br />
        <ngx-skeleton-loader
            appearance="line"
            [theme]="{ width: '25em', height: '7em', 'margin-left': '2em' }"
        ></ngx-skeleton-loader>
        <ngx-skeleton-loader
            appearance="line"
            [theme]="{ width: '70em', height: '7em', 'margin-left': '3em' }"
        ></ngx-skeleton-loader>
        <br />
        <ngx-skeleton-loader
            appearance="line"
            [theme]="{ width: '25em', height: '5em', 'margin-left': '2em' }"
        ></ngx-skeleton-loader>
        <br />
        <ngx-skeleton-loader
            appearance="line"
            [theme]="{ width: '80%', height: '5em', 'margin-left': '2em' }"
        ></ngx-skeleton-loader>
        <br />
        <ngx-skeleton-loader
            appearance="line"
            [theme]="{ width: '40%', height: '4em', 'margin-left': '2em' }"
        ></ngx-skeleton-loader>
        <br />
        <ngx-skeleton-loader
            appearance="line"
            [theme]="{ width: '80%', height: '30em', 'margin-left': '2em' }"
        ></ngx-skeleton-loader>
    </div>
</ng-template>
