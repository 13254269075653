<div class="custom-card">
    <div class="header">
        <p class="text-secondary">{{ tableTitle }}</p>
    </div>
    <div class="body">
        <table class="table table-borderless">
            <ng-container *ngFor="let goal of goalData">
                <tr>
                    <td>
                        <span class="text-secondary body-large-bold" [innerHtml]="goal?.title">{{ goal?.title }}</span>
                        <tr>
                            <td style="padding-left: 0px !important">
                                <strong class="text-secondary body-medium-semibold">PLOF: </strong>
                                <span
                                    class="header-text"
                                    [class.align-middle]="isHistoryMode"
                                    [innerHtml]="goal?.plof ? goal.plof : 'N/A'"
                                    >{{ goal?.plof }}</span
                                >
                            </td>
                            <td>
                                <strong class="text-secondary body-medium-semibold">CLOF: </strong>
                                <span
                                    class="header-text"
                                    [class.align-middle]="isHistoryMode"
                                    [innerHtml]="goal?.clof"
                                    >{{ goal?.clof }}</span
                                >
                            </td>
                            <td>
                                <strong class="text-secondary body-medium-semibold">Target: </strong>
                                <span
                                    class="header-text"
                                    [class.align-middle]="isHistoryMode"
                                    [innerHtml]="goal?.target"
                                    >{{ goal?.target }}</span
                                >
                            </td>
                            <td>
                                <strong class="text-secondary body-medium-semibold">Status: </strong>
                                <span
                                    class="header-text"
                                    [class.align-middle]="isHistoryMode"
                                    [innerHtml]="goal?.status"
                                    >{{ goal?.status }}</span
                                >
                            </td>
                        </tr>
                        <tr>
                            <td class="pl-0" style="width: 700px">
                                <strong class="text-secondary body-medium-semibold">Goal Purpose: </strong>
                                <span
                                    class="header-text"
                                    [class.align-middle]="isHistoryMode"
                                    [innerHtml]="goal?.goalPurpose ? goal.goalPurpose : 'N/A'"
                                    >{{ goal?.goalPurpose }}</span
                                >
                            </td>
                        </tr>
                    </td>
                    <td class="date" [innerHtml]="goal?.updatedDate || goal?.date">{{ goal?.updatedDate || goal?.date }}</td>
                </tr>
                <tr>
                    <td colspan="2">
                        <div class="bg-secondary-a20 mb-2" style="height: 1px"></div>
                    </td>
                </tr>
                <ng-container *ngIf="goal?.childGoal?.length > 0">
                    <ng-container *ngFor="let subGoal of goal.childGoal">
                        <tr>
                            <td class="subRow">
                                <span class="text-secondary body-large-bold" [innerHtml]="subGoal?.title">{{
                                    subGoal?.title
                                }}</span>
                                <tr>
                                    <td style="padding-left: 0px !important">
                                        <strong class="text-secondary body-medium-semibold">PLOF: </strong>
                                        <span
                                            class="header-text"
                                            [class.align-middle]="isHistoryMode"
                                            [innerHtml]="subGoal?.plof ? subGoal.plof : 'N/A'"
                                            >{{ subGoal?.plof }}</span
                                        >
                                    </td>
                                    <td>
                                        <strong class="text-secondary body-medium-semibold">CLOF: </strong>
                                        <span
                                            class="header-text"
                                            [class.align-middle]="isHistoryMode"
                                            [innerHtml]="subGoal.clof"
                                            >{{ subGoal?.clof }}</span
                                        >
                                    </td>
                                    <td>
                                        <strong class="text-secondary body-medium-semibold">Target: </strong>
                                        <span
                                            class="header-text"
                                            [class.align-middle]="isHistoryMode"
                                            [innerHtml]="subGoal?.target"
                                            >{{ subGoal?.target }}</span
                                        >
                                    </td>
                                    <td>
                                        <strong class="text-secondary body-medium-semibold">Status: </strong>
                                        <span
                                            class="header-text"
                                            [class.align-middle]="isHistoryMode"
                                            [innerHtml]="subGoal?.status"
                                            >{{ subGoal?.status }}</span
                                        >
                                    </td>
                                </tr>
                                <tr>
                                    <td class="pl-0">
                                        <strong class="text-secondary body-medium-semibold">Goal Purpose: </strong>
                                        <span
                                            class="header-text"
                                            [class.align-middle]="isHistoryMode"
                                            [innerHtml]="subGoal?.goalPurpose ? subGoal.goalPurpose : 'N/A'"
                                            >{{ subGoal?.goalPurpose }}</span
                                        >
                                    </td>
                                </tr>
                            </td>
                            <td class="date" [innerHtml]="subGoal?.updatedDate || subGoal?.date">{{ subGoal?.updatedDate || subGoal?.date }}</td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <div class="bg-secondary-a20 mb-2 mr-left" style="height: 1px"></div>
                            </td>
                        </tr>
                    </ng-container>
                </ng-container>
            </ng-container>
        </table>
    </div>
</div>
