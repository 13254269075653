import { Pipe, PipeTransform } from '@angular/core';
import { secondsToHours, secondsToMinutes } from 'date-fns';

@Pipe({
    name: 'secondsToHoursMinutesSeconds',
})
export class SecondsToHoursMinutesSecondsPipe implements PipeTransform {

    transform(seconds: number, ...args: string[]): unknown {

        if (seconds) {
            seconds = parseInt(seconds.toString());
            const hours = secondsToHours(seconds);
            const remainingMinutes = (secondsToMinutes(seconds) % 60);
            const remainingSeconds = (seconds % 60);
            if (args[0].includes('no-seconds')) {
                if (args[0].includes('no-minutes')) {
                    return `${hours}h`;
                }
                if (args[0].includes(':')) {
                    if (hours === 0) return `0:${secondsToMinutes(seconds) < 10 ? '0' + secondsToMinutes(seconds) : secondsToMinutes(seconds)}`;
                    else return `${hours}:${remainingMinutes < 10 ? '0' + remainingMinutes : remainingMinutes}`;
                } else {
                    if (hours === 0) return `${secondsToMinutes(seconds)} mins`;
                    else return `${hours}h ${remainingMinutes}mins`;
                }
            } else {
                if (args[0].includes(':')) {
                    if (hours === 0) return `0:${secondsToMinutes(seconds) < 10 ? '0' + secondsToMinutes(seconds) : secondsToMinutes(seconds)}:${remainingSeconds < 10 ? '0' + remainingSeconds : remainingSeconds}`;
                    else return `${hours}:${remainingMinutes < 10 ? '0' + remainingMinutes : remainingMinutes}:${remainingSeconds < 10 ? '0' + remainingSeconds : remainingSeconds}`;
                } else {
                    if (hours === 0) return `${secondsToMinutes(seconds)} mins ${remainingSeconds} secs`;
                    else return `${hours}h ${remainingMinutes}mins ${remainingSeconds} secs`;
                }
            }
        } else {
            if (args[0].includes('no-seconds')) {
                if (args[0].includes('no-minutes')) {
                    return `0h`;
                }
                if (args[0].includes(':')) {
                    return `0:00`;
                } else {
                    return `0h 0mins`;
                }
            } else {
                if (args[0].includes(':')) {
                    return `0:00:00`;
                } else {
                    return `0h 0mins 0secs`;
                }
            }
        }
    }
}
