<div [class]="'tabs-container  d-flex flex-row w-100 ' + class" role="group" aria-label="Basic example">
    <div id="tabs" class="d-inline-flex overflow-auto" [ngClass]="{ 'w-100': fullWidth }">
        <button
            *ngFor="let button of buttons"
            type="button"
            [class.selected-tab]="button.isSelected"
            [class.unselected-tab]="!button.isSelected"
            [disabled]="button.isDisabled"
            [class]="'body-medium-semibold  ' + buttonClass"
            (click)="setSelectedButton(button.text)"
            [ngbTooltip]="button.tooltip"
            [placement]="button.toolTipPlacement"
        >
            <span
                [ngbTooltip]="
                    button.text === 'Other Services' && button.isDisabled ? 'This functionality is coming soon' : ''
                "
                >{{ button.text
                }}<span *ngIf="button.count >= 0" [ngClass]="button.isSelected ? 'count-selected' : 'count-unselected'"
                    >&nbsp;&nbsp;&nbsp;({{ button.count }})</span
                ></span
            >
        </button>
    </div>
    <button *ngIf="hasNavigation" class="body-medium-semibold tabs-nav-btn rt bg-white" (click)="nextTab()">
        <span class="material-icons-outlined text-white-100" style="vertical-align: bottom"> chevron_right </span>
    </button>
</div>
