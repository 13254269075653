import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@environments/environment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { cloneDeep } from 'lodash';
const baseUrl = environment.apiUrl + '/clocked-session';
// eslint-disable-next-line spellcheck/spell-checker
const npcBaseUrl = environment.apiUrl + '/npcActivity';

@Injectable({
    providedIn: 'root',
})
export class TimeTrackingService {
    clockUpdated$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    milesUpdated$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    approveSubject$: Subject<void> = new Subject<void>();
    spentTimeInMinutes$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    timeTrackData$: BehaviorSubject<null | object> = new BehaviorSubject<null | object>(
        JSON.parse(localStorage.getItem('time-tracking'))
    );

    constructor(private http: HttpClient) {}

    private generateQueryParams(queryParams: any): HttpParams {
        let params = new HttpParams();
        for (const key of Object.keys(queryParams)) {
            params = params.append(key, queryParams[key]);
        }
        return params;
    }

    searchAllSessions(data: any): Observable<any[]> {
        const url = `${baseUrl}/search-all-sessions`;
        return this.http.post<any>(url, data).pipe(map((v) => v.data));
    }
    approveSessions(data: any): Observable<any> {
        const url = `${baseUrl}/approve`;
        return this.http.post<any>(url, data).pipe(map((v) => v.data));
    }

    getGroupedCSByTherapist(queryParams: any): Observable<any> {
        const params = this.generateQueryParams(queryParams);
        return this.http.get<any>(`${baseUrl}/get-grouped-cs-by-therapist`, { params }).pipe(map((v) => v.data));
    }

    getGroupedCSByFacility(queryParams: any): Observable<any> {
        const params = this.generateQueryParams(queryParams);
        return this.http.get<any>(`${baseUrl}/get-grouped-cs-by-facility`, { params }).pipe(map((v) => v.data));
    }

    async trackTime(params: any, action): Promise<Observable<any>> {
        let url = `${baseUrl}`;
        if (action === 'Start') {
            url += '/clock-in';
        } else if (action === 'Stop') {
            url += '/clock-out';
        }
        return this.http.post<any>(url, params);
    }

    updateTime(params: object, sessionId: string) {
        return this.http.put<any>(`${baseUrl}/update-hours/${sessionId}`, params);
    }

    deleteSession(sessionId: string) {
        return this.http.delete<any>(`${baseUrl}/` + sessionId);
    }

    addManualSession(session: any) {
        return this.http.post<any>(`${baseUrl}/create-manual-hours`, session);
    }

    addNPCActivity(payload: any) {
        // eslint-disable-next-line spellcheck/spell-checker
        return this.http.post<any>(`${npcBaseUrl}`, payload);
    }

    updateNPCActivity(payload: any) {
        const id = payload.id ? payload.id : payload._id;
        // eslint-disable-next-line spellcheck/spell-checker
        return this.http.put<any>(`${npcBaseUrl}/${id}`, payload);
    }
    deleteNPCActivity(payload: any) {
        // eslint-disable-next-line spellcheck/spell-checker
        return this.http.delete<any>(`${npcBaseUrl}/${payload.id}`);
    }

    getLogs(payload: any) {
        // eslint-disable-next-line spellcheck/spell-checker
        return this.http.post<any>(`${npcBaseUrl}/npc-by-therapist`, payload);
    }

    getLogsByTherapistAndFacilityId(payload: any) {
        // eslint-disable-next-line spellcheck/spell-checker
        return this.http.post<any>(`${npcBaseUrl}/npc-by-therapist-and-facility`, payload);
    }
    addTimeInputToSession(session: any) {
        session.clockedInTime = {
            hour: new Date(session.clockIn).getHours(),
            minute: new Date(session.clockIn).getMinutes(),
            second: new Date(session.clockIn).getSeconds(),
        };
        session.clockOutTime = {
            hour: session.clockOut ? new Date(session.clockOut).getHours() : new Date().getHours(),
            minute: session.clockOut ? new Date(session.clockOut).getMinutes() : new Date().getMinutes(),
            second: session.clockOut ? new Date(session.clockOut).getSeconds() : new Date().getSeconds(),
        };

        session.history.unshift(cloneDeep(session));
        delete session.history[0].history;

        return session;
    }
}
