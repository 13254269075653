import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ColDef, GridApi, SortChangedEvent } from 'ag-grid-community';
import { BtnCellRenderer } from '@app/helpers/AgGrid';
import { Router } from '@angular/router';
import { ROUTER_UTILS } from '@app/helpers';
import { SwitchCellRendererComponent } from '@app/data-grid/cell-renderers/switch-cell-renderer/switch-cell-renderer.component';
import { AuthService, FacilityManagementService, ModalService, OrganizationManagementService } from '@app/services';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { GridActionsService } from '@app/data-grid/services/grid-actions.service';
import { DeletionConfirmationDialogComponent } from '@app/shared/deletion-confirmation-dialog/deletion-confirmation-dialog.component';
import { GridRowAction } from '@app/data-grid/interfaces/grid-row-action';
import { AuthPolicies } from '@app/helpers/utils';

const rootURL = ROUTER_UTILS.config.admin.root;
const facilityURL = ROUTER_UTILS.config.admin.facility.root;
const facilityDetailsURL = ROUTER_UTILS.config.admin.facility.details;
@Component({
    selector: 'app-facility-list',
    templateUrl: './facility-list.component.html',
    styleUrls: ['./facility-list.component.scss'],
})
export class FacilityListComponent implements OnInit {
    @Input() facilities: any[];
    @Output() selectedFacilities: EventEmitter<any> = new EventEmitter();
    @Output() isFacilitySelected: EventEmitter<boolean> = new EventEmitter();
    selectedFacilitiesList = [];
    private facilitySubscription: Subscription;
    @Input() eventEnded: boolean;
    policies = AuthPolicies;
    currentFacility: any;
    actionFrom = 'header';
    sortInfo = {
        sortCol: '',
        sortOrder: '',
    };
    @Input() currentTab = 'Active';
    columnDefs: ColDef[] = [
        // {
        //     headerName: 'No',
        //     field: 'no',
        //     valueGetter: 'node.rowIndex + 1',
        // },
        {
            headerName: 'Facility Name',
            field: 'facilityName',
            checkboxSelection: true,
            headerCheckboxSelection: true,
            sortable: true,
            sortingOrder: ['desc', 'asc'],
            comparator: (valueA: string, valueB: string) => {
                if (valueA.toLowerCase() > valueB.toLowerCase()) {
                    return -1; // Negative value means A comes before B
                } else if (valueA.toLowerCase() < valueB.toLowerCase()) {
                    return 1; // Positive value means B comes before A
                } else {
                    return 0; // Zero means they are equal
                }
            },
        },
        {
            headerName: 'Facility Type',
            field: 'facilityType',
            sortable: true,
            sortingOrder: ['desc', 'asc'],
        },
        // {
        //     headerName: 'Status',
        //     field: 'status',
        // },
        // {
        //     headerName: 'Facility No',
        //     field: 'facilityNumber',
        // },
        // {
        //     headerName: 'County',
        //     field: 'county',
        //     valueGetter: (v) => {
        //         return v.data.mailingAddress.county ? v.data.mailingAddress.county : '';
        //     },
        // },
        {
            headerName: 'State',
            field: 'state',
            sortable: true,
            sortingOrder: ['desc', 'asc'],
            valueGetter: (v) => {
                return v.data.mailingAddress.state ? v.data.mailingAddress.state : '';
            },
        },
        {
            headerName: 'Go-Live',
            field: 'goLiveEnabled',
            maxWidth: 150,
            cellRendererSelector: () => {
                return {
                    component: SwitchCellRendererComponent,
                    params: {
                        gridName: 'facilities',
                        disabled: !this.AuthService.applyPolicy(this.policies.GoLive.Access)
                    },
                };
                // if (params.data.facilityOID) {
                // }
                // else {
                //     return {
                //         component: EmptyCellRendererComponent,
                //     };
                // }
            },
        },
        {
            headerName: 'Integrated',
            type: 'centerAligned',
            headerClass: 'ag-header-cell-center',
            cellRenderer: (params) => {
                return params.data.facilityOID
                    ? '<div class="check-mark btn-success"> <span class="material-symbols-outlined text-white ">' +
                          'done' +
                          '</span> </div>'
                    : '';
            },
        },
        {
            headerName: '',
            field: 'type',
            type: 'rightAligned',
            maxWidth: 150,
            cellRenderer: BtnCellRenderer,
            cellRendererParams: {
                clicked: (field: any) => {
                    this.facilityDetails(field);
                },
            },
        },
    ];

    onSortChanged(event: SortChangedEvent) {
        console.log('event', event);
        const columns = event.columnApi.getAllColumns();
        const sortModel = columns
            .filter((column) => column.getSort())
            .map((column) => ({
                columnName: column.getColId(),
                sortOrder: column.getSort(),
            }));
        this.sortInfo.sortCol = sortModel[0].columnName;
        this.sortInfo.sortOrder = sortModel[0].sortOrder;
    }

    gridApi: GridApi;
    onDestroy$: Subject<boolean> = new Subject();
    constructor(
        private router: Router,
        private facilityManagementService: FacilityManagementService,
        private gridActionService: GridActionsService,
        private modal: ModalService,
        private organizationService: OrganizationManagementService,
        private AuthService: AuthService
    ) {
        this.organizationService.updatedTab = '';
        this.gridActionService.action.pipe(takeUntil(this.onDestroy$)).subscribe((val: GridRowAction) => {
            if(val.actionType === 'status changed' && val.gridName == 'facilities' && val.data){
                this.openStatusModal(val.data._id || val.data.id, val.data.isActive, val.rowIndex);
            }
        });
    }

    ngOnInit() {
        this.facilitySubscription = this.facilityManagementService.currentFacilitySubject$.subscribe(
            (facility) => {
                if(this.currentFacility){
                    if(facility?._id === this.currentFacility?._id && facility.goLiveEnabled !== this.currentFacility.goLiveEnabled && (this.currentTab.toLowerCase() === this.currentFacility.status.toLowerCase())){
                        if(this.actionFrom !== 'facilityList'){
                        this.facilities.find((item) => item?._id === facility?._id).goLiveEnabled = facility.goLiveEnabled
                        this.gridApi.setRowData(this.facilities);
                        }
                        else{
                            this.actionFrom = 'header'
                        }
                        
                    }
                }
              this.currentFacility = facility;
            }
          );
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['eventEnded']) {
            const currentValue = changes['eventEnded'].currentValue;
            this.handleEventEndedChange(currentValue);
        }
    }

    handleEventEndedChange(newValue: boolean) {
        if (newValue) {
            this.gridApi.deselectAll();
        }
    }

    openStatusModal(id: string, isActive: boolean, rowIndex: number) {
        const dialogRef = this.modal.open(DeletionConfirmationDialogComponent, {
            data: {
                heading: 'Go Live Confirmation',
                resourceType: 'facilities',
                message: isActive
                    ? 'Turning this on would require evaluations of all the patients to be filled in as Regular Evals.'
                    : 'Turning this off would make evaluations of all the patients to be filled in as Partial Evals or JDoc Evals.',
                button1Text: 'Continue',
            },
        });
        dialogRef.afterClosed().subscribe((isContinue) => {
            if (isContinue) {
                if(this.currentFacility?._id === id){
                    this.actionFrom = 'facilityList'
                }
                this.facilityManagementService.toggleFacilityGoLive(id, isActive).subscribe((response) => {
                    response._id = id;
                    if (this.facilityManagementService._currentFacility.id == response.id) {
                        this.facilityManagementService._currentFacility = response;
                    }
                    const facilities = this.facilityManagementService._Facilities.value
                    const index = facilities.findIndex(
                        (facility) => facility?._id == response.id || facility?.id == response.id
                    );
                    if (index > -1) {
                        facilities[index] = response;
                        this.facilityManagementService.updateFacilities(facilities);
                    }
                });
            } else {
                this.facilities[rowIndex].isActive = !isActive;
                this.facilities[rowIndex].goLiveEnabled = !isActive;
                this.gridApi.setRowData(this.facilities);
                this.gridActionService.resetSelectedAction();
            }
        });
    }
    initGrid(event: any) {
        this.gridApi = event.api;
    }

    // openViewMode(event) {
    //     const rootURL = ROUTER_UTILS.config.admin.root + '/' + ROUTER_UTILS.config.admin.facility.root;
    //     const url = ROUTER_UTILS.config.admin.facility.viewFacility;
    //     this.router.navigate([`${rootURL}/${url}`], { queryParams: { facility: event.id } });
    // }

    facilityDetails(facility) {
        this.router.navigate([`${rootURL}/${facilityURL}/${facilityDetailsURL}`], {
            queryParams: {
                id: facility._id || facility.id || facility.facilityId,
                name: facility?.facilityName,
            },
        });
    }
    rowSelection(event = []) {
        let sortedData = event;
        if (this.sortInfo.sortOrder && this.sortInfo.sortCol) {
            const colName = this.sortInfo.sortCol;
            if (this.sortInfo.sortOrder === 'desc') {
                sortedData = event.sort((a, b) => a[colName].toLowerCase().localeCompare(b[colName].toLowerCase()));
            } else {
                sortedData = event.sort((a, b) => b[colName].toLowerCase().localeCompare(a[colName].toLowerCase()));
            }
        }
        this.selectedFacilities.emit(sortedData || []);
        if (event.length > 0) {
            this.isFacilitySelected.emit(true);
        } else {
            this.isFacilitySelected.emit(false);
        }
    }
    ngOnDestroy() {
        this.onDestroy$.next(true);
    }
}
