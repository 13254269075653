<div>
    <div *ngIf="!loading && !npcList?.length && !activities">
        <div class="custom-card">
            <div class="header">
                <p>{{ title }}</p>
            </div>
            <div class="body card-padding">
                <div class="no-activities-parent">
                    <div class="no-activities">No other Services Added</div>
                    <div>
                        <button (click)="addActivities()" class="innova-btn btn-secondary ml-1">Add Services</button>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!isRehabCenter" class="no-activities-parent">
            <div class="btns">
                <button (click)="backButton()" class="innova-btn button-skip btn-secondary ml-1 cancel-btn">
                    {{ backTitle }}
                </button>
                <button (click)="skipNow()" class="innova-btn button-skip btn-secondary ml-1 cancel-btn">Skip</button>
            </div>
        </div>
    </div>

    <div *ngIf="(!loading && npcList?.length) || activities">
        <div class="custom-card">
            <div class="header">
                <p>{{ title }}</p>
                <span class="cursor-pointer" [ngClass]="{ 'plus-icon': isEditMode }" (click)="addNPC()">
                    <img loading="lazy" class="edit-icon" [src]="isEditMode ? addIcon : editIcon" />
                </span>
            </div>

            <form [formGroup]="form" (ngSubmit)="openConfirmationModal()">
                <table class="data-table">
                    <thead>
                        <tr>
                            <ng-container *ngFor="let header of headerSizes">
                                <th
                                    *ngIf="header.title !== 'Billable' || isEditMode"
                                    [style.width]="header.size"
                                    [style.paddingRight]="header.padding"
                                >
                                    {{ header.title }}
                                </th>
                            </ng-container>
                        </tr>
                    </thead>
                    <tbody formArrayName="rows">
                        <ng-container *ngFor="let item of rowForms.controls; let i = index" >
                        <tr  [formGroupName]="i" *ngIf="!item.get('isDeleted').value" >
                            <td [ngClass]="isEditMode ? 'editable-name-padding' : 'name-padding'">
                                <input
                                    placeholder="Enter Activity Name"
                                    type="text"
                                    class="form-control border"
                                    [ngClass]="{ 'remove-border': !isEditMode }"
                                    formControlName="activity"
                                />
                            </td>
                            <td
                                [ngClass]="{
                                    'disable-select': !isEditMode,
                                    'select-padding': !isEditMode,
                                    'editable-select-padding': isEditMode
                                }"
                                class="select-unit"
                            >
                                <app-select
                                    formControlName="unit"
                                    [options]="units"
                                    [selectedValue]="unit"
                                ></app-select>
                            </td>

                            <td *ngIf="isEditMode">
                                <label [ngClass]="{ 'disable-click': !isEditMode }" class="checkbox-label">
                                    <label class="toggle-switch">
                                        <input
                                            type="checkbox"
                                            formControlName="billable"
                                            (change)="resetInputValue(item)"
                                        />
                                        <span class="slider"></span>
                                    </label>
                                </label>
                            </td>
                            <td class="rate-cell" [ngClass]="{ 'editable-rate-padding': isEditMode }">
                                <div [ngClass]="isEditMode ? 'edit-mode-input' : 'input-container'">
                                    <span
                                        *ngIf="item.get('billable').value"
                                        [ngClass]="!item.get('billable').value ? 'editable-currency' : 'dollar'"
                                        >$</span
                                    >
                                    <input
                                        type="text"
                                        class="form-control"
                                        [placeholder]="getPlaceholderText(item)"
                                        (input)="validateInput($event)"
                                        maxlength="4"
                                        [ngClass]="{
                                            'remove-border': !isEditMode,
                                            'non-billable': !item.get('billable').value,
                                            billable: item.get('billable').value
                                        }"
                                        formControlName="rate"
                                    />
                                    <span
                                        *ngIf="item.get('billable').value"
                                        [ngClass]="{
                                            'disable-rate': !item.get('billable').value,
                                            'adjust-width': !isEditMode
                                        }"
                                        class="rate"
                                        >{{ getUnit(item.get('unit').value) }}</span
                                    >
                                </div>
                            </td>

                            <td>
                                <span *ngIf="isEditMode" class="cursor-pointer delete-icon" (click)="deleteNPC(i)">
                                    <img loading="lazy" src="./../../../assets/icons/delete-icon2.svg" />
                                </span>
                            </td>
                        </tr>
                    </ng-container>
                    </tbody>
                </table>
            </form>
        </div>
        <div *ngIf="isEditMode" class="no-activities-parent">
            <div class="btns">
                <button
                    (click)="handleCancel()"
                    [ngClass]="{ 'change-border': isRehabCenter }"
                    class="innova-btn button-skip btn-secondary ml-1 cancel-btn"
                >
                    {{ backTitle }}
                </button>
                <button
                    [disabled]="isMandatoryFieldsFilled() || ( npcList?.length && isDataSame())"
                    (click)="openConfirmationModal()"
                    class="btn-secondary innova-btn innova-btn-lg save-btn"
                >
            <span *ngIf="isDataSaving" class="spinner-border spinner-border-sm mr-1 text-primary"></span>

                    Save
                </button>
            </div>
        </div>
    </div>
</div>

<div *ngIf="loading">
    <ng-container>
        <div>
            <br />
            <ngx-skeleton-loader appearance="line" [theme]="SKELETON_CSS.CELL"></ngx-skeleton-loader>
            <ngx-skeleton-loader appearance="line" [theme]="SKELETON_CSS.CELL"></ngx-skeleton-loader>
            <ngx-skeleton-loader appearance="line" [theme]="SKELETON_CSS.CELL"></ngx-skeleton-loader>
            <ngx-skeleton-loader appearance="line" [theme]="SKELETON_CSS.CELL"></ngx-skeleton-loader>
            <ngx-skeleton-loader appearance="line" [theme]="SKELETON_CSS.CELL"></ngx-skeleton-loader>
            <ngx-skeleton-loader appearance="line" [theme]="SKELETON_CSS.CELL"></ngx-skeleton-loader>
            <ngx-skeleton-loader appearance="line" [theme]="SKELETON_CSS.CELL"></ngx-skeleton-loader>
            <ngx-skeleton-loader appearance="line" [theme]="SKELETON_CSS.CELL"></ngx-skeleton-loader>
            <ngx-skeleton-loader appearance="line" [theme]="SKELETON_CSS.CELL"></ngx-skeleton-loader>
            <ngx-skeleton-loader appearance="line" [theme]="SKELETON_CSS.CELL"></ngx-skeleton-loader>
            <ngx-skeleton-loader appearance="line" [theme]="SKELETON_CSS.CELL"></ngx-skeleton-loader>
            <hr class="table-footer" />
            <div class="pagination-loader">
                <ngx-skeleton-loader [theme]="{ width: '10em', 'margin-right': '0' }"></ngx-skeleton-loader>
                <ngx-skeleton-loader [theme]="{ width: '10em', 'margin-left': '5em' }"></ngx-skeleton-loader>
            </div>
        </div>
    </ng-container>
</div>
