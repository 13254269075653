/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-add-appointment-notes',
    templateUrl: './add-appointment-notes.component.html',
    styleUrls: ['./add-appointment-notes.component.scss'],
})
export class AddAppointmentNotesComponent implements OnInit {
    @Input() widthSmall = 'w-25';
    @Input() widthLarge = 'w-75';
    @Input() day = '';
    @Input() note = '';
    @Output() deleteDay: EventEmitter<any> = new EventEmitter();
    @Output() saveNote: EventEmitter<any> = new EventEmitter();
    editIcon = false;
    constructor() {}

    ngOnInit(): void {
        this.editIcon = !!this.note.length;
    }

    /**
     * Emitting note to planner to save it for particular day
     */
    saveDayNote(): void {
        this.saveNote.emit({ day: this.day, note: this.note});
        this.editIcon = true;
    }

    deleteDayNote(): void {
        this.deleteDay.emit(this.day);
    }
}
