import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { BehaviorSubject, switchMap, tap } from 'rxjs';
import { FacilityManagementService } from './facilityManagement.service';
import { AuthService } from './auth.service';
import { IOrganization } from '@app/interfaces/organization';
import { Role, User } from '@app/models';

const baseUrl = `${environment.apiUrl}`;
@Injectable({
    providedIn: 'root',
})
export class OrganizationManagementService {
    private organizations$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
    private currentOrganization$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    public updatedTab = '';
    allOrganizations: any[] = [];
    private currentOrganization: any;
    organizationId = '';
    selectedOrganization: any;
    currentFacilityId = '';
    constructor(
        private http: HttpClient,
        private _facilityManagement: FacilityManagementService,
        private authService: AuthService
    ) {
        if (this.authService.accountValue) {
            this.getOrganizationsList();
        }
        this.currentFacilityId = this._facilityManagement?._currentFacility?.id;
        this.authService.logout$.subscribe((userLoggedOut) => {
            // * this is for logout, if account null remove organization & facility data
            if (userLoggedOut) {
                this._currentOrganization = null;
                this._facilityManagement._currentFacility = null;
            }
        });
    }

    saveOrganization(data) {
        return this.http
            .post(`${baseUrl}/organization`, data, {
                withCredentials: true,
            })
            .pipe(
                tap((data: any) => {
                    const org = {
                        id: data.data.id,
                        name: data.data.name,
                        companyCode: data.data.companyCode,
                        facility: [],
                        facilities: [],
                    };
                    this.allOrganizations.push(org);
                    this.__Organizations = this.allOrganizations;
                })
            );
    }

    saveNonPatientCare(data) {
        return this.http.post(`${baseUrl}/organization/createNonPatientCare`, data, {
            withCredentials: true,
        });
    }
    getOrganizationFacilitiesById(id: string) {
        return this.http.get<any>(`${baseUrl}/organization/${id}`, { withCredentials: true });
    }

    updateOrganization(id, data) {
        return this.http.put(`${baseUrl}/organization/${id}`, data, {
            withCredentials: true,
        });
    }

    setOrganizationBasedOnRole(user: User): Promise<boolean> {
        return new Promise((resolve) => {
            const isSuperAdmin = !!user?.roles?.find((role) => role.baseRole === Role.SuperAdmin);
            if (isSuperAdmin) {
                this.setOrganizationAndFacilities(user).then((status) => resolve(!!status));
            } else if (user.userRoles && user.userRoles.length) {
                this.getOrganizationsList().then((status) => resolve(!!status));
            }
        });
    }

    setOrganizationAndFacilities(user: User): Promise<boolean> {
        return new Promise((resolve) => {
            let organizations;
            this.http
                .get<{ data: IOrganization[] }>(`${baseUrl}/organization`, { withCredentials: true })
                .pipe(
                    switchMap((result: { data: IOrganization[] }) => {
                        organizations = result.data;
                        return this.http.get<any>(`${baseUrl}/user/${user.id}/get-roles`, { withCredentials: true });
                    })
                )
                .subscribe((result) => {
                    this.allOrganizations = organizations;
                    this.allOrganizations = this.allOrganizations.map((org) =>
                        org.id ? { ...org, _id: org.id } : { ...org, id: org._id }
                    );
                    this.allOrganizations = this.allOrganizations.map((organization) => {
                        return {
                            ...organization,
                            facilities: organization.facility,
                        };
                    });
                    const organizationId = user.organization;
                    if (!this?._currentOrganization) {
                        this._currentOrganization = this.allOrganizations.find(
                            (organization) => organization.id === organizationId
                        );
                        const id = this._currentOrganization._id || this._currentOrganization.id;
                        this._currentOrganization = {
                            ...this._currentOrganization,
                            id,
                            _id: id,
                        };
                    } else {
                        const id = this._currentOrganization._id || this._currentOrganization.id;
                        this._currentOrganization = this.allOrganizations.find(
                            (organization) => organization.id === id
                        );
                        this._currentOrganization = {
                            ...this._currentOrganization,
                            id,
                            _id: id,
                        };
                        const currentOrg = this._currentOrganization;
                        this._currentOrganization.facilities ||= this?._currentOrganization.facility; // here facility is an array
                        this._currentOrganization = { ...currentOrg, facilities: currentOrg.facility };
                    }
                    const currentFacilityId =
                        this?._facilityManagement?._currentFacility?._id || user?.selectedFacility;
                    const facilities = this._currentOrganization.facilities;
                    this._facilityManagement._currentFacility =
                        facilities.find((facility) => facility._id === currentFacilityId) || facilities[0];

                    this.__Organizations = this.allOrganizations;
                    this._facilityManagement._Facilities = this._currentOrganization.facilities;

                    if (result && result.data && !!result.data.length) {
                        const currentOrg = result.data.find((org) => org._id === this._currentOrganization._id);
                        const currentOrgFacilities = currentOrg && currentOrg.facilities ? currentOrg.facilities : [];
                        const selectedOrgFacility =
                            currentOrgFacilities && !!currentOrgFacilities.length
                                ? currentOrgFacilities.find(
                                      (facility) => facility._id === this._facilityManagement._currentFacility._id
                                  )
                                : null;
                        const userRoles = selectedOrgFacility ? selectedOrgFacility.roles : [];

                        this.authService.roleSubject.next(userRoles);
                        resolve(true);
                    } else {
                        // ? temporary code
                        this.authService.roleSubject.next([]);

                        resolve(true);
                    }
                });
        });
    }

    getOrganizationsList(): Promise<boolean> {
        const user = this.authService?.accountValue?.user;
        if (!user) return new Promise((resolve) => resolve(true));
        return new Promise((resolve, reject) => {
            try {
                this.http
                    .get<any>(`${baseUrl}/user/${user.id}/get-roles`, { withCredentials: true })
                    .subscribe((organizations) => {
                        this.allOrganizations = organizations.data;
                        const user = this.authService.accountValue.user;
                        /**
                         * If current organization is not set then get it from local storage otherwise set the first one from the list.
                         */

                        const currentOrgId = this?._currentOrganization?._id || user.organization;
                        this._currentOrganization =
                            this.allOrganizations.find((org) => org._id === currentOrgId) || this.allOrganizations[0];

                        /**
                         * If current facility is not set then get it from local storage otherwise set the first one from the list.
                         */

                        const currentFacilityId =
                            this?._facilityManagement?._currentFacility?._id || user?.selectedFacility;
                        this._facilityManagement._currentFacility =
                            this._currentOrganization.facilities.find(
                                (facility) => facility._id === currentFacilityId
                            ) || this._currentOrganization.facilities[0];

                        /**
                         * Save current roles in role subject in auth service
                         */
                        const userRoles = organizations.data
                            .find((org) => org._id === this._currentOrganization._id)
                            .facilities.find(
                                (facility) => facility._id === this._facilityManagement._currentFacility._id
                            ).roles;

                        this.authService.roleSubject.next(userRoles);
                        this.__Organizations = this.allOrganizations;
                        this._facilityManagement._Facilities = this._currentOrganization.facilities;
                        resolve(true);
                    });
            } catch (error) {
                console.log('error: ', error);
                reject(error);
            }
        });
    }

    set currentOrganizationSubject$(organization: any) {
        this.currentOrganization$.next(organization);
    }

    get currentOrganizationSubject$() {
        return this.currentOrganization$;
    }
    set __Organizations(organizations: any) {
        this.organizations$.next(organizations);
    }

    get __Organizations() {
        return this.organizations$;
    }
    set _currentOrganization(organization) {
        localStorage.setItem('current-organization', JSON.stringify(organization));
        this.currentOrganization = organization;
        this.currentOrganizationSubject$ = organization;
    }
    get _currentOrganization() {
        return this.currentOrganization
            ? this.currentOrganization
            : localStorage.getItem('current-organization') !== 'undefined'
            ? JSON.parse(localStorage.getItem('current-organization'))
            : null;
    }
    removeCurrentOrganization() {
        localStorage.removeItem('current-organization');
    }

    mapOrganizationsForDropDown() {
        if (this.allOrganizations && this.allOrganizations.length > 0) {
            const mapOrganizations = this.allOrganizations.map((org) => {
                return { id: org._id ? org._id : org.id, name: org.name, facilityCount: org.facilities.length };
            });
            return mapOrganizations;
        }
    }
}
