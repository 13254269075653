import { Component } from '@angular/core';
import { GridActionsService } from '@app/data-grid/services/grid-actions.service';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-dropdown-cell-renderer',
    templateUrl: './dropdown-cell-renderer.component.html',
    styleUrls: ['./dropdown-cell-renderer.component.scss'],
    providers: [{ provide: NgbDropdown }],
})
export class DropdownCellRendererComponent implements ICellRendererAngularComp {
    public options: any[] = [];
    private _params: any;
    private _gridName: string;

    // grids with generic way of passing payload (through params)
    private _genericGrids = [
        'skilledServicesDN',
        'Organizations',
        'facilitiesCols',
        'payorCols',
        'templateCols',
        'caseloads',
        'notes',
        'recipientCols',
        'facilityInvoiceProfile',
    ];

    constructor(private gridActionService: GridActionsService) {}
    agInit(params: ICellRendererParams): void {
        this._params = params;

        this._gridName = this._params.gridName;

        if (!this._genericGrids.includes(this._gridName)) {
            // custom payload (mixture of 'params' or 'data')
            this.options = this[this._gridName + 'Options']();
            return;
        }

        // generic way for passing payload (through params)
        this.options = this._params.options;
    }
    checkDisabled() {
        return !this.options.find((o) => !o.disabled);
    }
    refresh(): boolean {
        return false;
    }

    dottedDropdownSelect(action: string) {
        const { data, rowIndex, gridName } = this._params;
        this.gridActionService.setSelectedAction(action, data, rowIndex, gridName ? gridName : '');
    }

    insuranceCoverageOptions(): any[] {
        return this._params.options;
    }
}
