<div class="custom-card">
    <div class="header d-flex flex-row align-items-center justify-content-between">
        <div class="d-flex align-items-center">
            <p>Treatment</p>
            <!-- a single service cannot have more than 6 units, if needed  -->
            <div *ngIf="greaterThanSixUnits" class="ml-4 ml-2 d-flex">
                <span class="material-symbols-outlined text-danger"> info </span>
                <div class="ml-2 text-danger px-0">A single service cannot exceed 6 Units</div>
            </div>
        </div>
        <div class="right-menu">
            <div class="header-checkbox">
                <ng-container *ngTemplateOutlet="checkbox"></ng-container>
            </div>
            <div
                class="text-secondary text-underline"
                [ngClass]="!previousPlanTreatment ? 'disable' : 'tx-plan-btn'"
                (click)="viewPlanTreatment()"
            >
                Previous Tx Plan
            </div>
            <button *ngIf="cptData.length !== 0" (click)="addCptCode($event)" class="btn btn-secondary btn-circle">
                <span class="material-symbols-outlined solid"> add </span>
            </button>
        </div>
    </div>
    <div class="body">
        <!-- If grid is empty -->
        <div
            *ngIf="cptData.length === 0"
            class="d-flex flex-row align-items-center justify-content-center"
            style="height: 200px"
        >
            <div class="d-flex flex-column align-items-center justify-content-center">
                <div class="skilled-services-menu">
                    <div class="btns">
                        <button
                            class="btn-outline innova-btn d-inline-flex align-items-center mr-3"
                            [disabled]="!this.currentDN?.objectiveAssessment?.previousCptCodes?.length || switchValue"
                            *ngIf="mode"
                            (click)="addPreviousCptCode()"
                        >
                            <span class="material-symbols-outlined mr-1">history</span>
                            Use Previous Data
                        </button>
                        <button
                            class="btn-secondary innova-btn d-inline-flex align-items-center"
                            *ngIf="mode"
                            (click)="addCptCode($event)"
                        >
                            <span class="material-symbols-outlined">add</span>
                            Add CPT code
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <!-- If grid is not empty then show grid -->
        <div *ngIf="cptData.length !== 0">
            <app-data-grid
                domLayout="autoHeight"
                [defaultColDef]="defaultColDef"
                [tooltipShowDelay]="tooltipShowDelay"
                [tooltipHideDelay]="tooltipHideDelay"
                [columnDefs]="cptCols"
                [rowData]="cptData"
                [gridOptions]="gridOptions"
                [getRowStyle]="getRowStyle"
                [pinnedBottomRowData]="pinnedRow"
                (cellValueChanged)="onCellValueChanged($event)"
                (gridReady)="initGrid($event)"
                id="cpt-code-table"
            ></app-data-grid>
        </div>
        <ng-container *ngIf="cptData.length">
            <div class="d-flex bg-white mb-4 mt-1">
                <div class="body-large-bold text-secondary px-3">Other Tx Minutes</div>
                <!-- other tx minutes greater than cpt warning, if needed  -->
                <div *ngIf="txGreaterThanCPT" class="ml-4 ml-2 d-flex">
                    <span class="material-symbols-outlined text-danger"> info </span>
                    <div class="ml-2 text-danger px-0">Cannot be greater than MDS CPT Code Minutes</div>
                </div>
            </div>
            <hr class="m-0 pb-3 bg-white" />

            <div *ngIf="!otherModeBtnsStatus.bottom">
                <app-data-grid
                    domLayout="autoHeight"
                    [defaultColDef]="defaultColDef"
                    [columnDefs]="otherModeCols"
                    [rowData]="otherModeArr"
                ></app-data-grid>
            </div>

            <div
                *ngIf="otherModeBtnsStatus.bottom"
                class="d-flex flex-row align-items-center justify-content-center other-minutes-btn mb-3"
            >
                <app-button
                    *ngIf="allTherapyAdmissions.length <= 1 && !mdsCodeExists"
                    type="outline-secondary"
                    icon="chevron_right"
                    [rightIcon]="true"
                    [text]="'CO-TREATMENT/CONCURRENT'"
                    (click)="addMinutesModal()"
                    ngbTooltip="No MDS code(s) is selected and/or patient does not have another therapy admission"
                    [disabled]="true"
                    ></app-button>
                    <app-button
                    *ngIf="allTherapyAdmissions.length > 1 || mdsCodeExists"
                    type="outline-secondary"
                    icon="chevron_right"
                    [rightIcon]="true"
                    [text]="'CO-TREATMENT/CONCURRENT'"
                    (click)="addMinutesModal()"
                    [disabled]="!allowMinutes"
                ></app-button>
            </div>
        </ng-container>
    </div>
</div>

<ng-template #AddMinutesBtn> </ng-template>

<ng-template #checkbox>
    <div class="checkbox custom-control text-center">
        <label class="label">Telehealth Visit</label>
        <app-switch
            type="checkbox"
            id="telehealthCheck"
            [(ngModel)]="switchValue"
            (change)="emitChangedValue()"
            [ngClass]="{
                'custom-control-label-disabled-on': !switchValue,
                'custom-control-label-disabled-off': switchValue
            }"
        ></app-switch>
    </div>
</ng-template>
