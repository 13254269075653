import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';

import { ActivatedRoute, Router } from '@angular/router';
import { DIALOG_DATA } from '@app/helpers/dialog-tokens';
import { FacilityAdmission, Patient, PayorInformation, TherapyAdmission } from '@app/interfaces';
import {
    AuthService,
    DailyNotesService,
    DialogRef,
    RecertificationNoteService,
    DocumentationService,
    FacilityManagementService,
    MdsService,
    PrintService,
    ProgressNoteService,
    StoreService,
    ToasterService,
    DischargeNoteService,
    TherapyAdmissionService,
    DocumentationReportService,
    AppService,
} from '@app/services';
import { TherapistService } from '@app/services/therapist.service';
import { select, Store } from '@ngrx/store';
import { Observable, Subject, Subscription, first, takeUntil } from 'rxjs';
import { cloneDeep } from 'lodash';
import { spinnerDocumentStateChangeAction, therapyadmissionChangedAction } from '@app/store';
import { Doc_Notes } from '@app/interfaces/documentation';
import { EvaluationService } from '@app/services/evaluation.service';
import { ROUTER_UTILS, checkCoSignEligibility } from '@app/helpers';
import { DOCUMENTATION_ROUTES } from '@app/helpers/constants';

@Component({
    selector: 'app-sign-note',
    templateUrl: './sign-note.component.html',
    styleUrls: ['./sign-note.component.scss'],
})
export class SignNoteComponent implements OnInit, OnDestroy {
    integrationEntity: any;
    integratedOrganizationId: string;
    facilityId: string;
    private readonly onDestroy = new Subject<void>();
    heading = 'Sign the ';
    options: string[];
    isAgree = false;
    pendingResponse = false;
    selectedOption: string;
    public therapyAdmission;
    public admissionType = null;
    therapistDiscipline: string;
    loading: boolean;
    user: any;
    therapyDiscipline: string;
    dailyNoteIndex: any;
    progressNoteIndex: any;
    upocIndex: any;
    recertificationIndex: any;
    currentTherapyAdmission: TherapyAdmission;
    loader: boolean;
    facilityAdmission$: Observable<FacilityAdmission>;
    facilityAdmission: FacilityAdmission;
    currentTherapyAdmissions: any;
    payorInformation: any;
    isEditMode: boolean;
    subscription = new Subscription();
    patient: any;
    exportEnabled = false;
    evalOnly = false;
    addCoSignInDoc = false;
    coSignSettings = [];
    coSignedObject;
    previousUrl = '';
    loggedInUser = '';
    addendumDate = '';

    constructor(
        @Inject(DIALOG_DATA) private data,
        private docReportService: DocumentationReportService,
        private ref: DialogRef,
        private router: Router,
        private mdsService: MdsService,
        private store: Store<{
            payorInformation: PayorInformation;
            facilityAdmission: FacilityAdmission;
            therapyAdmission: TherapyAdmission[];
            user: any;
            patient: Patient;
        }>,
        private therapistService: TherapistService,
        private toasterService: ToasterService,
        private authService: AuthService,
        private location: Location,
        private documentationService: DocumentationService,
        private route: ActivatedRoute,
        private facilityManagementService: FacilityManagementService,
        private storeService: StoreService,
        private printService: PrintService,
        private evaluationService: EvaluationService,
        private dailyNoteService: DailyNotesService,
        private progressNoteService: ProgressNoteService,
        private recertificationNoteService: RecertificationNoteService,
        private dischargeNoteService: DischargeNoteService,
        private therapyAdmissionService: TherapyAdmissionService,
        private appService: AppService
    ) {
        this.appService.previousUrl$.subscribe((previousValue) => {
            this.previousUrl = previousValue;
        });
        this.coSignedObject = {
            signedDate: null,
            signedBy: null,
        };
        this.store
            .select('patient')
            .pipe(takeUntil(this.onDestroy))
            .subscribe((patient) => {
                this.patient = patient;
                this.integrationEntity = patient.integrationEntity;
            });
        this.store
            .select('facilityAdmission')
            .pipe(takeUntil(this.onDestroy))
            .subscribe((facilityAdmission) => {
                this.facilityId =
                    typeof facilityAdmission.facility === 'string'
                        ? facilityAdmission.facility
                        : (facilityAdmission.facility as any)._id;
                this.integratedOrganizationId = (facilityAdmission.facility as any)?.organization?._id;
                this.facilityManagementService
                    .getFacilityById(this.facilityId)
                    .pipe()
                    .subscribe((res: any) => {
                        this.exportEnabled = res.data.exportEnabled;
                        this.coSignSettings = res.data.coSignSettings;
                    });
            });
        this.isEditMode = data.isEditMode;
        this.heading = this.heading + data.heading;
        this.store
            .pipe(select('payorInformation'))
            .pipe(takeUntil(this.onDestroy))
            .subscribe((payorInformation) => {
                this.payorInformation = payorInformation;
            });
        this.storeService
            .getDocumentLoadingState()
            .pipe(takeUntil(this.onDestroy))
            .subscribe((state) => {
                this.loader = state.isLoading;
            });
        this.route.queryParamMap.subscribe({
            next: (params) => {
                this.therapyDiscipline = params.get('discipline');
                this.addendumDate = params.get('date');
            },
        });

        store
            .pipe(select('user'))
            .pipe(takeUntil(this.onDestroy))
            .subscribe((user) => {
                if (this.authService.isTherapist || this.authService.isManager || !this.data.goLiveEnabled) {
                    this.user = user.user;
                } else {
                    this.toasterService.show({
                        title: 'Caution!',
                        body: 'You are not authorized to sign Docs.',
                        type: 'warning',
                    });
                    this.loading = false;
                }
            });
        this.facilityAdmission$ = this.store.select('facilityAdmission');
        this.facilityAdmission$.pipe(takeUntil(this.onDestroy)).subscribe((facilityAdmission) => {
            this.facilityAdmission = facilityAdmission;
        });
        this.store
            .select('therapyAdmission')
            .pipe(takeUntil(this.onDestroy))
            .subscribe((therapyAdmissions) => {
                if (therapyAdmissions && therapyAdmissions.length > 0) {
                    this.currentTherapyAdmissions = therapyAdmissions;
                    this.therapyAdmission = cloneDeep(
                        therapyAdmissions.filter((admission) => admission.therapyDiscipline != this.therapyDiscipline)
                    );

                    this.currentTherapyAdmission =
                        this.therapyAdmissionService.initCurrentTherapyAdmissionByIdQueryString(therapyAdmissions);
                    this.therapyDiscipline ||= this.currentTherapyAdmission.therapyDiscipline;
                    this.therapyDiscipline ||= 'PT';
                    if (this.currentTherapyAdmission && data.documentType == 'dailyNote' && !data.addendumNote) {
                        this.dailyNoteIndex = this.getSelectedDailyNote();
                        this.documentationService.setCurrentTherapyAdmission(
                            therapyAdmissions,
                            this.currentTherapyAdmission,
                            this.therapyDiscipline
                        );
                        //this.storeService.resetPlannerTherapyAdmissions(); // when add time in daily notes reflect in planner
                    }
                    if (this.currentTherapyAdmission && data.documentType == 'progressNote' && !data.addendumNote) {
                        this.progressNoteIndex = this.getSelectedProgressNote();
                    }
                    if (this.currentTherapyAdmission && data.documentType == Doc_Notes.UPOC && !data.addendumNote) {
                        this.upocIndex = this.getSelectedUPOCNote();
                    }
                    if (this.currentTherapyAdmission && data.documentType == Doc_Notes.Re_Cert && !data.addendumNote) {
                        this.recertificationIndex = this.getSelectedRecertificationNote();
                    }
                }
            });
    }
    ngOnDestroy() {
        this.onDestroy.next();
        this.subscription.unsubscribe();
    }
    ngOnInit(): void {
        this.options = this.data.options ?? ['Daily-note', 'Progress', 'UPOC', 'Re-Cert', 'Discharge', 'Addendum'];
        this.evalOnly = this.data.evaluation?.evaluationType?.evalOnly;
        // Showing Currently logged in user's credentials in sign modal
        if (this.authService.isTherapist) {
            const user = this.authService.accountValue.user;
            if (user.firstName && user.lastName && user.therapist) {
                this.loggedInUser = `${user.lastName}, ${user.firstName} ${' - ' + user.therapist.therapistType}`;
            }
        }
    }
    onClose(data?: any) {
        this.ref.close(data);
    }
    getSelectedDailyNote() {
        const index = this.currentTherapyAdmission.documentation.dailyNote.findIndex(
            (x: any) => x.id == this.data.dailyNote.id
        );
        this.documentationService.documentNoteIndex = index;
        return index;
    }
    getSelectedProgressNote() {
        const index = this.currentTherapyAdmission.documentation.progressNote.findIndex(
            (x: any) => x.id == this.data.progressNote.id
        );
        this.documentationService.documentNoteIndex = index;
        return index;
    }

    getSelectedUPOCNote() {
        const index = this.currentTherapyAdmission.documentation.updatedPlanOfCareNote.findIndex(
            (x: any) => x.id == this.data.upoc.id
        );
        this.documentationService.documentNoteIndex = index;
        return index;
    }
    getSelectedRecertificationNote() {
        const index = this.currentTherapyAdmission.documentation.recertificationNote.findIndex(
            (x: any) => x.id == this.data.recertification.id
        );
        this.documentationService.documentNoteIndex = index;
        return index;
    }
    onSign() {
        this.addCoSignInDoc = checkCoSignEligibility(this.user, this.coSignSettings);
        if (this.authService.isTherapist || this.authService.isManager || !this.data.goLiveEnabled) {
            if (this.data.documentType === 'evaluation') {
                if (this.isEditMode) this.onClose(true);
                else this.signedEvaluationNote();
            }
            if (this.data.documentType === 'dailyNote') {
                if (this.isEditMode) this.onClose(true);
                else this.signedDailyNote();
            }
            if (this.data.documentType === 'dischargeNote') {
                if (this.isEditMode) this.onClose(true);
                else this.signedDischargeNote();
            }
            // /progressNote
            if (this.data.documentType === 'progressNote') {
                if (this.isEditMode) this.onClose(true);
                else this.signedProgressNote();
            }
            if (this.data.documentType === 'addendumNote') {
                this.signedAddendumNote();
            }
            if (this.data.documentType === Doc_Notes.UPOC) {
                this.signedUPOCNote();
            }
            if (this.data.documentType === Doc_Notes.Re_Cert) {
                this.signedRecertificationNote();
            }
        } else {
            this.toasterService.show({
                title: 'Caution!',
                body: 'You are not authorized to sign Docs.',
                type: 'warning',
            });
        }
    }
    async signedProgressNote() {
        if (this.pendingResponse) {
            return;
        }
        this.pendingResponse = true;
        this.data.payor = this.payorInformation.payor;
        const planTherapy: any = this.currentTherapyAdmission.planTherapy;
        this.data.planTherapy = planTherapy?._id;
        this.data.facilityId = this.facilityManagementService._currentFacility.id;
        if (this.data.addendumNote) {
            const addendumNote = {
                ...this.data.addendumNote,
                signed: {
                    signDate: new Date(),
                    signedBy: this.user.id,
                    therapistType: this.user?.therapist?.therapistType,
                },
            };
            this.documentationService
                .documentAddendumNote('progressNote', this.data.progressNote, addendumNote)
                .pipe(first())
                .subscribe((result: any) => {
                    this.pendingResponse = false;
                    if (result) {
                        this.toasterService.show({
                            title: 'Success',
                            body: 'New Addendum Note Created',
                            type: 'success',
                        });
                        addendumNote.signed.signedBy = this.getSignedBy();
                        if (result?.data?.progressNote?.type == 'self') {
                            const therapyAdmissions = cloneDeep(
                                this.currentTherapyAdmissions.filter(
                                    (admission) => admission._id !== this.currentTherapyAdmission._id
                                )
                            );

                            this.currentTherapyAdmission.documentation = result.data.therapyAdmission.documentation;
                            therapyAdmissions.push(this.currentTherapyAdmission);
                            this.store.dispatch(
                                therapyadmissionChangedAction({
                                    therapyadmission: therapyAdmissions,
                                })
                            );
                        } else {
                            const therapyAdmissions = cloneDeep(
                                this.currentTherapyAdmissions.filter(
                                    (admission) => admission._id !== this.currentTherapyAdmission._id
                                )
                            );
                            const index = this.currentTherapyAdmission.documentation.progressNote.findIndex(
                                (x: any) => x.id == this.data.progressNote
                            );
                            if (!this.currentTherapyAdmission.documentation.progressNote[index].addendum) {
                                this.currentTherapyAdmission.documentation.progressNote[index].addendum = [];
                            }
                            addendumNote.signed.signedBy = [
                                {
                                    _id: addendumNote.signed.signedBy,
                                    firstName: this.user.firstName,
                                    lastName: this.user.lastName,
                                    roles: this.user.roles,
                                    therapistType: this.therapistDiscipline,
                                },
                            ];
                            this.currentTherapyAdmission.documentation.progressNote[index].addendum.push(addendumNote);
                            therapyAdmissions.push(this.currentTherapyAdmission);
                            this.store.dispatch(
                                therapyadmissionChangedAction({
                                    therapyadmission: therapyAdmissions,
                                })
                            );
                        }
                        this.progressNoteService.dataForEdit = [];
                        this.ref.close();
                    }
                });
        } else {
            const progressNote = {
                ...this.data.progressNote,
                signed: {
                    signDate: new Date(),
                    signedBy: this.user.id,
                    therapistType: this.user?.therapist?.therapistType,
                },
            };
            if (this.addCoSignInDoc) progressNote.coSigned = this.coSignedObject;
            const facilityId = this.facilityId;
            const patientIntegrationObject = this.integrationEntity;
            const queryParams = {
                facilityId,
                integratedPatientId: patientIntegrationObject?.patientId,
                integratedOrganizationName: patientIntegrationObject?.name,
                integratedOrganizationId: this.integratedOrganizationId,
                therapyDiscipline: this.therapyDiscipline,
                patientName: this.patient.lastName + '_' + this.patient.firstName,
                documentationId: this.currentTherapyAdmission.documentation.id,
            };
            this.documentationService.setSignatureProcessStatus(true);
            if (this.exportEnabled && this.patient.isAnIntegratedPatient) {
                progressNote.signed = { signDate: new Date(), signedBy: this.user };
                progressNote.status = 'Completed';
                const progressNotePrint = {
                    id: this.currentTherapyAdmission.id
                        ? this.currentTherapyAdmission.id
                        : this.currentTherapyAdmission?._id,
                    type: 'Progress Note',
                    assignedTherapists: this.currentTherapyAdmission.assignedTherapists,
                    progressNote: progressNote,
                    status: ['Completed'],
                };
                const isDataLoaded = await this.docReportService.prepareProgressNoteDataForIntegration(
                    progressNotePrint.progressNote
                );
                this.storeService.setDocumentLoadingState(true);
                if (isDataLoaded) {
                    const report = this.docReportService.progressNote;
                    progressNote.reportData = report;
                    progressNote.status = 'Incomplete';
                    this.currentTherapyAdmission.documentation.progressNote[this.progressNoteIndex] = progressNote;
                    this.documentationService
                        .updateDocumentation(this.currentTherapyAdmission, 'progressNote', queryParams)
                        .then((result: any) => {
                            this.documentationService.setSignatureProcessStatus(false);
                            this.pendingResponse = false;
                            this.toasterService.show({
                                title: 'Success',
                                body: 'Document signed successfully.',
                                type: 'success',
                            });
                            if (this.data.progressNote.type == 'self' && this.currentTherapyAdmission) {
                                const therapyAdmissions = cloneDeep(
                                    this.currentTherapyAdmissions.filter(
                                        (admission) => admission._id !== this.currentTherapyAdmission?._id
                                    )
                                );

                                const index = this.currentTherapyAdmission?.documentation?.progressNote?.findIndex(
                                    (note) => note.id === result.data.id
                                );
                                if (index > -1) {
                                    this.currentTherapyAdmission.documentation.progressNote[index] = result.data;
                                }

                                therapyAdmissions.push(this.currentTherapyAdmission);
                                this.store.dispatch(
                                    therapyadmissionChangedAction({
                                        therapyadmission: therapyAdmissions,
                                    })
                                );
                            }
                            this.storeService.setDocumentLoadingState(false);
                            this.progressNoteService.dataForEdit = [];
                            this.ref.close();
                            this.router.navigate(
                                [
                                    ROUTER_UTILS.config.documentationViewMode.root +
                                    DOCUMENTATION_ROUTES.PROGRESS_NOTE_VIEW_URL,
                                ],
                                { queryParamsHandling: 'preserve' }
                            );
                        });
                }
            } else {
                this.currentTherapyAdmission.documentation.progressNote[this.progressNoteIndex] = progressNote;
                this.documentationService
                    .updateDocumentation(this.currentTherapyAdmission, 'progressNote')
                    .then((result: any) => {
                        this.documentationService.setSignatureProcessStatus(false);
                        this.pendingResponse = false;
                        this.toasterService.show({
                            title: 'Success',
                            body: 'Document signed successfully.',
                            type: 'success',
                        });
                        if (this.data.progressNote.type == 'self') {
                            const therapyAdmissions = cloneDeep(
                                this.currentTherapyAdmissions.filter(
                                    (admission) => admission._id !== this.currentTherapyAdmission._id
                                )
                            );

                            const index = this.currentTherapyAdmission?.documentation?.progressNote?.findIndex(
                                (note) => note.id === result.data.id
                            );
                            if (index > -1) {
                                this.currentTherapyAdmission.documentation.progressNote[index] = result.data;
                            }

                            therapyAdmissions.push(this.currentTherapyAdmission);
                            this.store.dispatch(
                                therapyadmissionChangedAction({
                                    therapyadmission: therapyAdmissions,
                                })
                            );
                        }
                        this.progressNoteService.dataForEdit = [];
                        this.ref.close();
                        this.router.navigate(
                            [
                                ROUTER_UTILS.config.documentationViewMode.root +
                                DOCUMENTATION_ROUTES.PROGRESS_NOTE_VIEW_URL,
                            ],
                            { queryParamsHandling: 'preserve' }
                        );
                    });
            }
        }
    }
    async signedUPOCNote() {
        if (this.pendingResponse) {
            return;
        }
        this.pendingResponse = true;
        if (this.data.addendumNote) {
            const addendumNote = {
                ...this.data.addendumNote,
                signed: {
                    signDate: new Date(),
                    signedBy: this.user.id,
                    therapistType: this.user?.therapist?.therapistType,
                },
            };
            this.documentationService
                .documentAddendumNote('upoc', this.data.upocNote, addendumNote)
                .pipe(first())
                .subscribe((result: any) => {
                    this.pendingResponse = false;
                    if (result) {
                        this.toasterService.show({
                            title: 'Success',
                            body: 'New Addendum Note Created',
                            type: 'success',
                        });
                        addendumNote.signed.signedBy = this.getSignedBy();
                        const therapyAdmissions = cloneDeep(
                            this.currentTherapyAdmissions.filter(
                                (admission) => admission._id !== this.currentTherapyAdmission._id
                            )
                        );
                        const index = this.currentTherapyAdmission.documentation.updatedPlanOfCareNote.findIndex(
                            (x: any) => x.id == this.data.upocNote
                        );
                        if (!this.currentTherapyAdmission.documentation.updatedPlanOfCareNote[index].addendum) {
                            this.currentTherapyAdmission.documentation.updatedPlanOfCareNote[index].addendum = [];
                        }
                        addendumNote.signed.signedBy = [
                            {
                                _id: addendumNote.signed.signedBy,
                                firstName: this.user.firstName,
                                lastName: this.user.lastName,
                                roles: this.user.roles,
                                therapistType: this.therapistDiscipline,
                            },
                        ];
                        this.currentTherapyAdmission.documentation.updatedPlanOfCareNote[index].addendum.push(
                            addendumNote
                        );
                        therapyAdmissions.push(this.currentTherapyAdmission);
                        this.store.dispatch(
                            therapyadmissionChangedAction({
                                therapyadmission: therapyAdmissions,
                            })
                        );
                        this.ref.close();
                    }
                });
        } else {
            const upoc = {
                ...this.data.upoc,
                signed: {
                    signDate: new Date(),
                    /**
                     * Signed by object was being assigned this.user.id. Which is not a therapist object.
                     * This caused undefined, undefined therapist name bug in exported UPOC documents.
                     * Removed it to fix undefined, undefined therapist name bug, not it is working.
                     */
                    signedBy: this.user,
                    therapistType: this.user?.therapist?.therapistType,
                },
            };
            upoc.payor = this.payorInformation.payor
            const facilityId = this.facilityId;
            const patientIntegrationObject = this.integrationEntity;
            /**
             * Creating queryParams for UPOC export.
             * Backend needs this data to export UPOC document to integrated systems.
             */
            const queryParams = {
                facilityId,
                integratedPatientId: patientIntegrationObject?.patientId,
                integratedOrganizationName: patientIntegrationObject?.name,
                integratedOrganizationId: this.integratedOrganizationId,
                therapyDiscipline: this.therapyDiscipline,
                patientName: this.patient.lastName + '_' + this.patient.firstName,
                documentationId: this.currentTherapyAdmission.documentation.id,
            };
            this.documentationService.setSignatureProcessStatus(true);
            if (this.addCoSignInDoc) upoc.coSigned = this.coSignedObject;
            /**
             * This block of code,
             *  - Exports UPOC document to integrated systems.
             *  - Should not run for facilities that does not have export enabled.
             *  - Should also not run for non-integrated patients
             */
            if (this.exportEnabled && this.patient.isAnIntegratedPatient) {
                this.currentTherapyAdmission.documentation.updatedPlanOfCareNote[this.upocIndex] = upoc;
                const isDataLoaded = await this.docReportService.prepareUpocDataForIntegration(
                    upoc
                );
                if (isDataLoaded) {
                    const report = this.docReportService.updatedPlanOfCareNote;
                    upoc.reportData = report;
                    upoc.status = 'Completed';
                    this.currentTherapyAdmission.documentation.updatedPlanOfCareNote[this.upocIndex] = upoc;
                    this.documentationService
                        .updateDocumentation(this.currentTherapyAdmission, Doc_Notes.UPOC, queryParams)
                        .then((res: any) => {
                            const therapyAdmissions = cloneDeep(
                                this.currentTherapyAdmissions.filter(
                                    (admission) => admission._id !== this.currentTherapyAdmission._id
                                )
                            );

                            // getting the latest/created upoc by checking signed date
                            const allUPOCs = res.data.therapyAdmission.documentation.updatedPlanOfCareNote;
                            const createdUPOC = allUPOCs.reduce((latest, current) => {
                                return new Date(current.signed.signDate) > new Date(latest.signed.signDate) ? current : latest;
                            }, allUPOCs[0]);

                            const facilityAdmission = cloneDeep(this.facilityAdmission);

                            this.currentTherapyAdmission.treatmentDiagnosis = createdUPOC.patientHistory.treatmentDiagnosis;
                            facilityAdmission.medicalDiagnosis.icd10Codes = createdUPOC.patientHistory.medicalDiagnosis;

                            therapyAdmissions.push(this.currentTherapyAdmission);

                            this.store.dispatch(
                                therapyadmissionChangedAction({
                                    therapyadmission: therapyAdmissions,
                                })
                            );
                            this.storeService.setFacilityAdmission(facilityAdmission);

                            this.documentationService.setSignatureProcessStatus(false);
                            this.pendingResponse = false;
                            this.toasterService.show({
                                title: 'Success',
                                body: 'Document signed successfully.',
                                type: 'success',
                            });
                            this.ref.close();
                            this.router.navigate(
                                [
                                    `/${ROUTER_UTILS.config.documentationViewMode.root}/${ROUTER_UTILS.config.documentationViewMode.updatePlanOfCare.root}`,
                                ],
                                {
                                    queryParams: {
                                        doc_type: 'Updated Plan of Care',
                                        discipline: 'PT',
                                        doc: this.data.upoc.id,
                                        dueDate: this.data.upoc.day,
                                    },
                                    queryParamsHandling: 'merge',
                                }
                            );
                        });
                }
            } else {
                this.currentTherapyAdmission.documentation.updatedPlanOfCareNote[this.upocIndex] = upoc;
                this.documentationService
                    .updateDocumentation(this.currentTherapyAdmission, Doc_Notes.UPOC)
                    .then((res: any) => {
                        const therapyAdmissions = cloneDeep(
                            this.currentTherapyAdmissions.filter(
                                (admission) => admission._id !== this.currentTherapyAdmission._id
                            )
                        );

                        // getting the latest/created upoc by checking signed date
                        const allUPOCs = res.data.therapyAdmission.documentation.updatedPlanOfCareNote;
                        const createdUPOC = allUPOCs.reduce((latest, current) => {
                            return new Date(current.signed.signDate) > new Date(latest.signed.signDate) ? current : latest;
                        }, allUPOCs[0]);

                        const facilityAdmission = cloneDeep(this.facilityAdmission);

                        this.currentTherapyAdmission.treatmentDiagnosis = createdUPOC.patientHistory.treatmentDiagnosis;
                        facilityAdmission.medicalDiagnosis.icd10Codes = createdUPOC.patientHistory.medicalDiagnosis;

                        therapyAdmissions.push(this.currentTherapyAdmission);

                        this.store.dispatch(
                            therapyadmissionChangedAction({
                                therapyadmission: therapyAdmissions,
                            })
                        );
                        this.storeService.setFacilityAdmission(facilityAdmission);

                        this.documentationService.setSignatureProcessStatus(false);
                        this.pendingResponse = false;
                        this.toasterService.show({
                            title: 'Success',
                            body: 'Document signed successfully.',
                            type: 'success',
                        });
                        this.ref.close();
                        this.router.navigate(
                            [
                                `/${ROUTER_UTILS.config.documentationViewMode.root}/${ROUTER_UTILS.config.documentationViewMode.updatePlanOfCare.root}`,
                            ],
                            {
                                queryParams: {
                                    doc_type: 'Updated Plan of Care',
                                    discipline: 'PT',
                                    doc: this.data.upoc.id,
                                    dueDate: this.data.upoc.day,
                                },
                                queryParamsHandling: 'merge',
                            }
                        );
                    });
            }
        }
    }
    async signedRecertificationNote() {
        if (this.pendingResponse) {
            return;
        }
        this.pendingResponse = true;
        if (this.data.addendumNote) {
            const addendumNote = {
                ...this.data.addendumNote,
                signed: {
                    signDate: new Date(),
                    signedBy: this.user.id,
                    therapistType: this.user?.therapist?.therapistType,
                },
            };
            this.documentationService
                .documentAddendumNote('recertification', this.data.recertNote, addendumNote)
                .pipe(first())
                .subscribe((result: any) => {
                    this.pendingResponse = false;
                    if (result) {
                        this.toasterService.show({
                            title: 'Success',
                            body: 'New Addendum Note Created',
                            type: 'success',
                        });
                        addendumNote.signed.signedBy = this.getSignedBy();
                        const therapyAdmissions = cloneDeep(
                            this.currentTherapyAdmissions.filter(
                                (admission) => admission._id !== this.currentTherapyAdmission._id
                            )
                        );
                        const index = this.currentTherapyAdmission.documentation.recertificationNote.findIndex(
                            (x: any) => x.id == this.data.recertNote
                        );
                        if (!this.currentTherapyAdmission.documentation.recertificationNote[index].addendum) {
                            this.currentTherapyAdmission.documentation.recertificationNote[index].addendum = [];
                        }
                        addendumNote.signed.signedBy = [
                            {
                                _id: addendumNote.signed.signedBy,
                                firstName: this.user.firstName,
                                lastName: this.user.lastName,
                                roles: this.user.roles,
                                therapistType: this.therapistDiscipline,
                            },
                        ];
                        this.currentTherapyAdmission.documentation.recertificationNote[index].addendum.push(
                            addendumNote
                        );
                        therapyAdmissions.push(this.currentTherapyAdmission);
                        this.store.dispatch(
                            therapyadmissionChangedAction({
                                therapyadmission: therapyAdmissions,
                            })
                        );
                        this.ref.close();
                    }
                });
        } else {
            const recertification = {
                ...this.data.recertification,
                signed: {
                    signDate: new Date(),
                    signedBy: this.user.id,
                    therapistType: this.user?.therapist?.therapistType,
                },
            };
            if (this.addCoSignInDoc) recertification.coSigned = this.coSignedObject;

            const facilityId = this.facilityId;
            const patientIntegrationObject = this.integrationEntity;
            const queryParams = {
                facilityId,
                integratedPatientId: patientIntegrationObject?.patientId,
                integratedOrganizationName: patientIntegrationObject?.name,
                integratedOrganizationId: this.integratedOrganizationId,
                therapyDiscipline: this.therapyDiscipline,
                patientName: this.patient.lastName + '_' + this.patient.firstName,
                documentationId: this.currentTherapyAdmission.documentation.id,
            };
            this.documentationService.setSignatureProcessStatus(true);
            if (this.exportEnabled && this.patient.isAnIntegratedPatient) {
                recertification.signed = { signDate: new Date(), signedBy: this.user };
                recertification.status = 'Completed';
                const recertificationNotePrint = {
                    id: this.currentTherapyAdmission.id
                        ? this.currentTherapyAdmission.id
                        : this.currentTherapyAdmission?._id,
                    type: 'Recertification',
                    assignedTherapists: this.currentTherapyAdmission.assignedTherapists,
                    recertificationNote: recertification,
                    status: ['Completed'],
                };
                const isDataLoaded = await this.docReportService.prepareRecertificationDataForIntegration(
                    recertificationNotePrint.recertificationNote
                );
                this.storeService.setDocumentLoadingState(true);
                if (isDataLoaded) {
                    const report = this.docReportService.recertificationNote;
                    recertification.reportData = report;
                    recertification.status = 'Incomplete';

                    this.currentTherapyAdmission.documentation.recertificationNote[this.recertificationIndex] =
                        recertification;
                    this.documentationService
                        .updateDocumentation(this.currentTherapyAdmission, Doc_Notes.Re_Cert, queryParams)
                        .then((res: any) => {
                            const therapyAdmissions = cloneDeep(
                                this.currentTherapyAdmissions.filter(
                                    (admission) => admission._id !== this.currentTherapyAdmission._id
                                )
                            );

                            // getting the latest/created Recertification by checking signed date
                            const allRecertification = res.data.therapyAdmission.documentation.recertificationNote;
                            const createdRecertification = allRecertification.reduce((latest, current) => {
                                return new Date(current.signed.signDate) > new Date(latest.signed.signDate)
                                    ? current
                                    : latest;
                            }, allRecertification[0]);

                            const facilityAdmission = cloneDeep(this.facilityAdmission);

                            this.currentTherapyAdmission.treatmentDiagnosis =
                                createdRecertification.patientHistory.treatmentDiagnosis;
                            facilityAdmission.medicalDiagnosis.icd10Codes =
                                createdRecertification.patientHistory.medicalDiagnosis;

                            therapyAdmissions.push(this.currentTherapyAdmission);

                            this.store.dispatch(
                                therapyadmissionChangedAction({
                                    therapyadmission: therapyAdmissions,
                                })
                            );
                            this.storeService.setFacilityAdmission(facilityAdmission);

                            this.documentationService.setSignatureProcessStatus(false);
                            this.pendingResponse = false;
                            this.toasterService.show({
                                title: 'Success',
                                body: 'Document signed successfully.',
                                type: 'success',
                            });
                            this.storeService.setDocumentLoadingState(false);
                            this.ref.close();
                            // navigate to recertification view mode
                            this.router.navigate(
                                [
                                    ROUTER_UTILS.config.documentationViewMode.root +
                                    DOCUMENTATION_ROUTES.RECERTIFICATION_NOTE_URL,
                                ],
                                { queryParamsHandling: 'preserve' }
                            );
                        });
                }
            } else {
                this.currentTherapyAdmission.documentation.recertificationNote[this.recertificationIndex] =
                    recertification;
                this.documentationService
                    .updateDocumentation(this.currentTherapyAdmission, Doc_Notes.Re_Cert)
                    .then((res: any) => {
                        const therapyAdmissions = cloneDeep(
                            this.currentTherapyAdmissions.filter(
                                (admission) => admission._id !== this.currentTherapyAdmission._id
                            )
                        );

                        // getting the latest/created Recertification by checking signed date
                        const allRecertification = res.data.therapyAdmission.documentation.recertificationNote;
                        const createdRecertification = allRecertification.reduce((latest, current) => {
                            return new Date(current.signed.signDate) > new Date(latest.signed.signDate)
                                ? current
                                : latest;
                        }, allRecertification[0]);

                        const facilityAdmission = cloneDeep(this.facilityAdmission);

                        this.currentTherapyAdmission.treatmentDiagnosis =
                            createdRecertification.patientHistory.treatmentDiagnosis;
                        facilityAdmission.medicalDiagnosis.icd10Codes =
                            createdRecertification.patientHistory.medicalDiagnosis;

                        therapyAdmissions.push(this.currentTherapyAdmission);

                        this.store.dispatch(
                            therapyadmissionChangedAction({
                                therapyadmission: therapyAdmissions,
                            })
                        );
                        this.storeService.setFacilityAdmission(facilityAdmission);

                        this.documentationService.setSignatureProcessStatus(false);
                        this.pendingResponse = false;
                        this.toasterService.show({
                            title: 'Success',
                            body: 'Document signed successfully.',
                            type: 'success',
                        });
                        this.ref.close();
                        if (this.previousUrl.includes('therapist-pending-documents')) {
                            window.history.back();
                        } else {
                            // navigate to recertification view mode
                            this.router.navigate(
                                [
                                    ROUTER_UTILS.config.documentationViewMode.root +
                                    DOCUMENTATION_ROUTES.RECERTIFICATION_NOTE_URL,
                                ],
                                { queryParamsHandling: 'preserve' }
                            );
                        }
                    });
            }
        }
    }
    async signedDischargeNote() {
        if (this.pendingResponse) {
            return;
        }
        this.pendingResponse = true;
        if (this.data.addendumNote) {
            const addendumNote = {
                ...this.data.addendumNote,
                signed: {
                    signDate: new Date(),
                    signedBy: this.user.id,
                    therapistType: this.user?.therapist?.therapistType,
                },
            };
            this.documentationService
                .documentAddendumNote('dischargeNote', this.data.dischargeNote, addendumNote)
                .pipe(first())
                .subscribe((result: any) => {
                    this.pendingResponse = false;
                    if (result) {
                        this.toasterService.show({
                            title: 'Success',
                            body: 'New Addendum Note Created',
                            type: 'success',
                        });
                        addendumNote.signed.signedBy = this.getSignedBy();
                        const therapyAdmissions = cloneDeep(
                            this.currentTherapyAdmissions.filter(
                                (admission) => admission._id !== this.currentTherapyAdmission._id
                            )
                        );
                        addendumNote.signed.signedBy = [
                            {
                                _id: addendumNote.signed.signedBy,
                                firstName: this.user.firstName,
                                lastName: this.user.lastName,
                                roles: this.user.roles,
                                therapistType: this.therapistDiscipline,
                            },
                        ];
                        this.currentTherapyAdmission.documentation.dischargeNote.addendum.push(addendumNote);
                        therapyAdmissions.push(this.currentTherapyAdmission);
                        this.store.dispatch(
                            therapyadmissionChangedAction({
                                therapyadmission: therapyAdmissions,
                            })
                        );
                        this.dischargeNoteService.dataForEdit = [];
                        this.ref.close();
                    }
                });
        } else {
            const dischargeNote = {
                ...this.data.dischargeNote,
                mds: { ...this.facilityAdmission.mds, slp: this.getSlpFromMedicalDiagnosis(this.facilityAdmission) },
                signed: {
                    signDate: new Date(),
                    signedBy: this.user.id,
                    therapistType: this.user?.therapist?.therapistType,
                },
            };
            if (this.addCoSignInDoc) dischargeNote.coSigned = this.coSignedObject;
            const facilityId = this.facilityId;
            const patientIntegrationObject = this.integrationEntity;
            const queryParams = {
                facilityId,
                integratedPatientId: patientIntegrationObject?.patientId,
                integratedOrganizationName: patientIntegrationObject?.name,
                integratedOrganizationId: this.integratedOrganizationId,
                therapyDiscipline: this.therapyDiscipline,
                patientName: this.patient.lastName + '_' + this.patient.firstName,
                documentationId: this.currentTherapyAdmission.documentation.id,
            };
            this.documentationService.setSignatureProcessStatus(true);
            if (this.exportEnabled && this.patient.isAnIntegratedPatient) {
                dischargeNote.signed = { signDate: new Date(), signedBy: this.user };
                dischargeNote.status = 'Completed';
                const dischargeNotePrint = {
                    id: this.currentTherapyAdmission.id
                        ? this.currentTherapyAdmission.id
                        : this.currentTherapyAdmission?._id,
                    type: 'Discharge Note',
                    assignedTherapists: this.currentTherapyAdmission.assignedTherapists,
                    dischargeNote: dischargeNote,
                    status: ['Completed'],
                };
                const isDataLoaded = await this.docReportService.prepareDischargeDataForIntegration(
                    dischargeNotePrint.dischargeNote
                );
                this.storeService.setDocumentLoadingState(true);
                if (isDataLoaded) {
                    const report = this.docReportService.dischargeNote;
                    dischargeNote.reportData = report;
                    dischargeNote.status = 'Incomplete';
                    this.currentTherapyAdmission.documentation.dischargeNote = dischargeNote;
                    this.currentTherapyAdmission.isDischarged = true;
                    this.documentationService
                        .updateDocumentation(this.currentTherapyAdmission, 'dischargeNote', queryParams)
                        .then(() => {
                            this.mdsService
                                .getMds(this.facilityAdmission.mds._id)
                                .pipe(takeUntil(this.onDestroy))
                                .subscribe((mds) => {
                                    this.documentationService.setSignatureProcessStatus(false);
                                    window.history.back();
                                    this.pendingResponse = false;
                                    const facilityAdmission = cloneDeep(this.facilityAdmission);
                                    facilityAdmission.mds = mds;
                                    facilityAdmission.mds._id = mds.id;
                                    this.storeService.setFacilityAdmission(facilityAdmission);
                                    this.toasterService.show({
                                        title: 'Success',
                                        body: 'Document signed successfully.',
                                        type: 'success',
                                    });
                                    this.storeService.setDocumentLoadingState(false);
                                    this.dischargeNoteService.dataForEdit = [];
                                    this.ref.close();
                                    this.router.navigate(
                                        [
                                            ROUTER_UTILS.config.documentationViewMode.root +
                                            DOCUMENTATION_ROUTES.DISCHARGE_NOTE_VIEW_URL,
                                        ],
                                        { queryParamsHandling: 'preserve' }
                                    );
                                });
                        });
                }
            } else {
                this.currentTherapyAdmission.documentation.dischargeNote = dischargeNote;
                this.currentTherapyAdmission.isDischarged = true;
                this.documentationService
                    .updateDocumentation(this.currentTherapyAdmission, 'dischargeNote')
                    .then(() => {
                        this.mdsService
                            .getMds(this.facilityAdmission.mds._id)
                            .pipe(takeUntil(this.onDestroy))
                            .subscribe((mds) => {
                                this.documentationService.setSignatureProcessStatus(false);
                                this.pendingResponse = false;
                                const facilityAdmission = cloneDeep(this.facilityAdmission);
                                facilityAdmission.mds = mds;
                                facilityAdmission.mds._id = mds.id;
                                this.storeService.setFacilityAdmission(facilityAdmission);
                                this.toasterService.show({
                                    title: 'Success',
                                    body: 'Document signed successfully.',
                                    type: 'success',
                                });
                                this.dischargeNoteService.dataForEdit = [];
                                this.ref.close();
                                if (this.previousUrl && this.previousUrl.includes('therapist-pending-documents')) {
                                    window.history.back();
                                } else {
                                    this.router.navigate(
                                        [
                                            ROUTER_UTILS.config.documentationViewMode.root +
                                            DOCUMENTATION_ROUTES.DISCHARGE_NOTE_VIEW_URL,
                                        ],
                                        { queryParamsHandling: 'preserve' }
                                    );
                                }
                            });
                    });
            }
        }
    }
    async signedDailyNote() {
        if (this.pendingResponse) {
            return;
        }
        this.pendingResponse = true;
        if (this.data.addendumNote) {
            const addendumNote = {
                ...this.data.addendumNote,
                signed: {
                    signDate: new Date(),
                    signedBy: this.user.id,
                    therapistType: this.user?.therapist?.therapistType,
                },
            };
            this.documentationService
                .documentAddendumNote('dailyNote', this.data.dailyNote, addendumNote)
                .pipe(first())
                .subscribe((result: any) => {
                    this.pendingResponse = false;
                    if (result) {
                        this.toasterService.show({
                            title: 'Success',
                            body: 'New Addendum Note Created',
                            type: 'success',
                        });
                        addendumNote.signed.signedBy = this.getSignedBy();
                        const therapyAdmissions = cloneDeep(
                            this.currentTherapyAdmissions.filter(
                                (admission) => admission._id !== this.currentTherapyAdmission._id
                            )
                        );
                        const index = this.currentTherapyAdmission.documentation.dailyNote.findIndex(
                            (x: any) => x.id == this.data.dailyNote
                        );
                        if (!this.currentTherapyAdmission.documentation.dailyNote[index].addendum) {
                            this.currentTherapyAdmission.documentation.dailyNote[index].addendum = [];
                        }
                        addendumNote.signed.signedBy = [
                            {
                                _id: addendumNote.signed.signedBy,
                                firstName: this.user.firstName,
                                lastName: this.user.lastName,
                                roles: this.user.roles,
                                therapistType: this.therapistDiscipline,
                            },
                        ];
                        this.currentTherapyAdmission.documentation.dailyNote[index].addendum.push(addendumNote);
                        therapyAdmissions.push(this.currentTherapyAdmission);
                        this.store.dispatch(
                            therapyadmissionChangedAction({
                                therapyadmission: therapyAdmissions,
                            })
                        );
                        this.ref.close();
                        this.dailyNoteService.dataForEdit = [];
                    }
                });
        } else {
            const dailyNote = {
                ...this.data.dailyNote,
                signed: {
                    signDate: new Date(),
                    signedBy: this.user.id,
                    therapistType: this.user?.therapist?.therapistType,
                },
            };
            if (this.addCoSignInDoc) dailyNote.coSigned = this.coSignedObject;
            dailyNote.payor = this.payorInformation.payor;

            const facilityId = this.facilityId;
            const patientIntegrationObject = this.integrationEntity;
            const queryParams = {
                facilityId,
                integratedPatientId: patientIntegrationObject?.patientId,
                integratedOrganizationName: patientIntegrationObject?.name,
                integratedOrganizationId: this.integratedOrganizationId,
                therapyDiscipline: this.therapyDiscipline,
                patientName: this.patient.lastName + '_' + this.patient.firstName,
                documentationId: this.currentTherapyAdmission.documentation.id,
            };
            this.documentationService.setSignatureProcessStatus(true);
            if (this.exportEnabled && this.patient.isAnIntegratedPatient) {
                dailyNote.signed = { signDate: new Date(), signedBy: this.user };
                dailyNote.status = 'Completed';
                const dailyNotePrint = {
                    id: this.currentTherapyAdmission.id
                        ? this.currentTherapyAdmission.id
                        : this.currentTherapyAdmission?._id,
                    type: 'Daily Note',
                    assignedTherapists: this.currentTherapyAdmission.assignedTherapists,
                    dailyNote: dailyNote,
                    status: ['Completed'],
                };
                const isDataLoaded = await this.docReportService.prepareDailyNoteDataForIntegration(
                    dailyNotePrint.dailyNote
                );
                this.storeService.setDocumentLoadingState(true);
                if (isDataLoaded) {
                    const report = this.docReportService.dailyNote;
                    dailyNote.reportData = report;
                    dailyNote.status = 'Incomplete';
                    this.currentTherapyAdmission.documentation.dailyNote[this.dailyNoteIndex] = dailyNote;
                    this.documentationService
                        .updateDocumentation(this.currentTherapyAdmission, 'dailyNote', queryParams)
                        .then(() => {
                            this.documentationService.setSignatureProcessStatus(false);
                            this.pendingResponse = false;
                            this.toasterService.show({
                                title: 'Success',
                                body: 'Document signed successfully.',
                                type: 'success',
                            });
                            this.storeService.setDocumentLoadingState(false);
                            this.dailyNoteService.dataForEdit = [];
                            this.ref.close();
                            if (this.previousUrl.includes('therapist-pending-documents')) {
                                window.history.back();
                            } else {
                                this.router.navigate(
                                    [
                                        ROUTER_UTILS.config.documentationViewMode.root +
                                        DOCUMENTATION_ROUTES.DAILY_NOTE_VIEW_URL,
                                    ],
                                    { queryParamsHandling: 'preserve' }
                                );
                            }
                        });
                }
            } else {
                this.currentTherapyAdmission.documentation.dailyNote[this.dailyNoteIndex] = dailyNote;
                this.documentationService.updateDocumentation(this.currentTherapyAdmission, 'dailyNote').then(() => {
                    this.pendingResponse = false;
                    this.documentationService.setSignatureProcessStatus(false);
                    this.toasterService.show({
                        title: 'Success',
                        body: 'Document signed successfully.',
                        type: 'success',
                    });
                    this.dailyNoteService.dataForEdit = [];
                    this.ref.close();
                    if (this.previousUrl && this.previousUrl.includes('therapist-pending-documents')) {
                        window.history.back();
                    } else {
                        this.router.navigate(
                            [ROUTER_UTILS.config.documentationViewMode.root + DOCUMENTATION_ROUTES.DAILY_NOTE_VIEW_URL],
                            { queryParamsHandling: 'preserve' }
                        );
                    }
                });
            }
        }
    }
    getSlpFromMedicalDiagnosis(facilityAdmission) {
        const slps: string[] = [];
        facilityAdmission.medicalDiagnosis.icd10Codes.map((obj: any) => {
            if (obj.comorbidity && obj.comorbidity != 'N/A') slps.push(obj.icd10);
        });
        facilityAdmission.mds.slp.map((slp: any) => {
            slp && slps.push(slp._id);
        });
        return [...new Set([...slps].map((slp) => slp))];
    }
    // Signed evaluation is updated here.
    async signedEvaluationNote() {
        if (this.pendingResponse) {
            return;
        }
        this.pendingResponse = true;
        if (this.data.addendumNote) {
            const addendumNote = {
                ...this.data.addendumNote,
                signed: {
                    signDate: new Date(),
                    signedBy: this.user.id,
                    therapistType: this.user?.therapist?.therapistType,
                },
            };
            this.documentationService
                .documentAddendumNote(
                    'evaluation',
                    this.currentTherapyAdmission.documentation.evaluation.id,
                    addendumNote
                )
                .pipe(first())
                .subscribe((result: any) => {
                    this.pendingResponse = false;
                    if (result) {
                        this.toasterService.show({
                            title: 'Success',
                            body: 'New Addendum Note Created',
                            type: 'success',
                        });
                        addendumNote.signed.signedBy = this.getSignedBy();
                        const therapyAdmissions = cloneDeep(
                            this.currentTherapyAdmissions.filter(
                                (admission) => admission._id !== this.currentTherapyAdmission._id
                            )
                        );
                        addendumNote.signed.signedBy = [
                            {
                                _id: addendumNote.signed.signedBy,
                                firstName: this.user.firstName,
                                lastName: this.user.lastName,
                                roles: this.user.roles,
                                therapistType: this.therapistDiscipline,
                            },
                        ];
                        this.currentTherapyAdmission.documentation.evaluation.addendum.push(addendumNote);
                        therapyAdmissions.push(this.currentTherapyAdmission);
                        this.store.dispatch(
                            therapyadmissionChangedAction({
                                therapyadmission: therapyAdmissions,
                            })
                        );
                        this.evaluationService.dataForEdit = [];
                        this.ref.close();
                    }
                });
        } else {
            const evaluation = {
                ...this.data.evaluation,
                mds: { ...this.facilityAdmission.mds, slp: this.getSlpFromMedicalDiagnosis(this.facilityAdmission) },
                signed: {
                    signDate: new Date(),
                    signedBy: this.user.id,
                    therapistType: this.user?.therapist?.therapistType,
                },
            };
            if (this.addCoSignInDoc) evaluation.coSigned = this.coSignedObject;
            const facilityId = this.facilityId;
            const patientIntegrationObject = this.integrationEntity;
            const queryParams = {
                facilityId,
                integratedPatientId: patientIntegrationObject?.patientId,
                integratedOrganizationName: patientIntegrationObject?.name,
                integratedOrganizationId: this.integratedOrganizationId,
                therapyDiscipline: this.therapyDiscipline,
                patientName: this.patient.lastName + '_' + this.patient.firstName,
                documentationId: this.currentTherapyAdmission.documentation.id,
                payor: this.payorInformation.payor.type,
            };

            if (this.exportEnabled && this.patient.isAnIntegratedPatient) {
                this.documentationService.setSignatureProcessStatus(true);
                evaluation.signed = { signDate: new Date(), signedBy: this.user };
                const evaluationPrint = {
                    id: this.currentTherapyAdmission.id
                        ? this.currentTherapyAdmission.id
                        : this.currentTherapyAdmission?._id,
                    type: 'Evaluation',
                    assignedTherapists: this.currentTherapyAdmission.assignedTherapists,
                    evaluation: evaluation,
                    status: ['Completed'],
                };
                const isDataLoaded = await this.docReportService.prepareEvaluationDataForIntegration(
                    evaluationPrint.evaluation
                );
                this.storeService.setDocumentLoadingState(true);
                if (isDataLoaded) {
                    const report = this.docReportService.evaluationNote;
                    evaluation.reportData = report;

                    this.currentTherapyAdmission.documentation.evaluation = evaluation;
                    this.documentationService
                        .updateDocumentation(this.currentTherapyAdmission, 'evaluation', queryParams)
                        .then(
                            () => {
                                this.mdsService
                                    .getMds(this.facilityAdmission.mds._id)
                                    .pipe(takeUntil(this.onDestroy))
                                    .subscribe((mds) => {
                                        this.pendingResponse = false;
                                        this.evaluationService.dataForEdit = [];
                                        const facilityAdmission = cloneDeep(this.facilityAdmission);
                                        facilityAdmission.mds = mds;
                                        facilityAdmission.mds._id = mds.id;
                                        this.storeService.setFacilityAdmission(facilityAdmission);
                                        this.toasterService.show({
                                            title: 'Success',
                                            body: 'Document signed successfully.',
                                            type: 'success',
                                        });
                                        this.storeService.setDocumentLoadingState(false);
                                        if (
                                            evaluation.evaluationType.evalOnly ||
                                            !this.currentTherapyAdmission.assignedTherapists ||
                                            !this.currentTherapyAdmission.assignedTherapists.find(
                                                (x: any) => x.role == 'Evaluating Therapist'
                                            )
                                        ) {
                                            this.documentationService.setSignatureProcessStatus(false);
                                            this.updateTherapyAdmission();
                                        } else {
                                            this.documentationService.setSignatureProcessStatus(false);
                                            this.toasterService.show({
                                                title: 'Success',
                                                body: 'Document signed successfully.',
                                                type: 'success',
                                            });
                                            this.ref.close();
                                            if (this.previousUrl.includes('therapist-pending-documents')) {
                                                window.history.back();
                                            } else {
                                                this.router.navigate(
                                                    [
                                                        ROUTER_UTILS.config.documentationViewMode.root +
                                                        DOCUMENTATION_ROUTES.EVALUATION_URL,
                                                    ],
                                                    { queryParamsHandling: 'preserve' }
                                                );
                                            }
                                        }
                                    });
                            },
                            (error) => {
                                this.documentationService.setSignatureProcessStatus(false);
                                this.pendingResponse = false;
                                console.log('eval error', error);
                            }
                        );
                }
            } else {
                this.documentationService.setSignatureProcessStatus(true);
                this.currentTherapyAdmission.documentation.evaluation = evaluation;
                this.documentationService
                    .updateDocumentation(this.currentTherapyAdmission, 'evaluation', queryParams)
                    .then(
                        () => {
                            this.mdsService
                                .getMds(this.facilityAdmission.mds._id)
                                .pipe(takeUntil(this.onDestroy))
                                .subscribe((mds) => {
                                    this.pendingResponse = false;
                                    this.evaluationService.dataForEdit = [];
                                    const facilityAdmission = cloneDeep(this.facilityAdmission);
                                    facilityAdmission.mds = mds;
                                    facilityAdmission.mds._id = mds.id;
                                    this.storeService.setFacilityAdmission(facilityAdmission);
                                    if (
                                        evaluation.evaluationType.evalOnly ||
                                        !this.currentTherapyAdmission.assignedTherapists ||
                                        !this.currentTherapyAdmission.assignedTherapists.find(
                                            (x: any) => x.role == 'Evaluating Therapist'
                                        )
                                    ) {
                                        this.documentationService.setSignatureProcessStatus(false);
                                        this.updateTherapyAdmission();
                                    } else {
                                        this.documentationService.setSignatureProcessStatus(false);
                                        this.toasterService.show({
                                            title: 'Success',
                                            body: 'Document signed successfully.',
                                            type: 'success',
                                        });
                                        this.ref.close();
                                        if (this.previousUrl.includes('therapist-pending-documents')) {
                                            window.history.back();
                                        } else {
                                            this.router.navigate(
                                                [
                                                    ROUTER_UTILS.config.documentationViewMode.root +
                                                    DOCUMENTATION_ROUTES.EVALUATION_URL,
                                                ],
                                                { queryParamsHandling: 'preserve' }
                                            );
                                        }
                                    }
                                });
                        },
                        (error) => {
                            this.documentationService.setSignatureProcessStatus(false);
                            this.pendingResponse = false;
                            console.log('eval error', error);
                        }
                    );
            }
        }
    }
    async signedAddendumNote() {
        if (this.pendingResponse) {
            return;
        }
        this.documentationService.setSignatureProcessStatus(true);
        this.pendingResponse = true;
        const documentation = this.currentTherapyAdmission.documentation as any;
        const addendumNote = {
            ...this.data.addendumNote,
            documentation_id: documentation.id,
            day: this.addendumDate,
            signed: {
                signDate: new Date(),
                signedBy: this.user.id,
                therapistType: this.user?.therapist?.therapistType,
            },
        };
        const docName = 'addendum';
        const therapyAdmissions = cloneDeep(
            this.therapyAdmission.filter((admission) => admission.therapyDiscipline != this.admissionType)
        );
        this.store.dispatch(
            spinnerDocumentStateChangeAction({
                selectedLoadingSpinnerState: { isLoading: true },
            })
        );
        if (this.exportEnabled && this.patient.isAnIntegratedPatient) {
            const addendumPrint = {
                id: this.currentTherapyAdmission.id
                    ? this.currentTherapyAdmission.id
                    : this.currentTherapyAdmission?._id,
                type: 'Addendum',
                assignedTherapists: this.currentTherapyAdmission.assignedTherapists,
                addendumNote: addendumNote,
                status: ['Completed'],
            };
            const isDataLoaded = await this.docReportService.prepareAddendumNoteData(
                null,
                addendumPrint.addendumNote,
                this.currentTherapyAdmission
            );
            this.docReportService.setCurrentTherapy(this.currentTherapyAdmission);
            this.storeService.setDocumentLoadingState(true);
            if (isDataLoaded) {
                // Example of how daily note get prepared data for print
                const report = this.docReportService.addendumNote;
                addendumNote.reportData = report;
                addendumNote.facilityId = this.facilityId;
                addendumNote.integratedPatientId = this.patient.integrationEntity.patientId;
                addendumNote.therapyDiscipline = report.header.discipline;
                addendumNote.patientName = `${this.patient.lastName}, ${this.patient.firstName}`;
                addendumNote.documentationId = this.currentTherapyAdmission.documentation.id;
                addendumNote.timestamp = new Date().toISOString();
                addendumNote.patient = this.patient;
                addendumNote.status = 'Completed';
                // There will be only one note for export, so index will be 0.
                this.currentTherapyAdmission.documentation.addendum[0] = addendumNote;
                this.documentationService
                    .documentCreate(docName, addendumNote)
                    .pipe(first())
                    .subscribe(
                        (result: any) => {
                            this.pendingResponse = false;
                            if (result.success) {
                                const id = result?.data?.id?.value;
                                result.data.id = id;
                                this.store.dispatch(
                                    spinnerDocumentStateChangeAction({
                                        selectedLoadingSpinnerState: { isLoading: false },
                                    })
                                );
                                this.currentTherapyAdmission.documentation.addendum.push(result.data);
                                therapyAdmissions.push(this.currentTherapyAdmission);
                                this.store.dispatch(
                                    therapyadmissionChangedAction({
                                        therapyadmission: therapyAdmissions,
                                    })
                                );
                                this.toasterService.show({
                                    title: 'Success',
                                    body: 'Addendum created and signed successfully.',
                                    type: 'success',
                                });
                                this.documentationService.setSignatureProcessStatus(false);
                                this.ref.close();
                                this.location.back();
                                this.location.back();
                            }
                        },
                        (err) => {
                            this.documentationService.setSignatureProcessStatus(false);
                            this.pendingResponse = false;
                            this.store.dispatch(
                                spinnerDocumentStateChangeAction({
                                    selectedLoadingSpinnerState: { isLoading: false },
                                })
                            );
                            console.error(err);
                        }
                    );
            }
        } else {
            this.documentationService
                .documentCreate(docName, addendumNote)
                .pipe(first())
                .subscribe(
                    (result: any) => {
                        this.pendingResponse = false;
                        if (result.success) {
                            this.store.dispatch(
                                spinnerDocumentStateChangeAction({
                                    selectedLoadingSpinnerState: { isLoading: false },
                                })
                            );
                            // As Addendum creation will make an Audit log, so need to fetch the whole updated documentation and update in store
                            this.currentTherapyAdmission.documentation = result.data.therapyAdmissions.documentation;
                            therapyAdmissions.push(this.currentTherapyAdmission);
                            this.store.dispatch(
                                therapyadmissionChangedAction({
                                    therapyadmission: therapyAdmissions,
                                })
                            );
                            this.toasterService.show({
                                title: 'Success',
                                body: 'Addendum created and signed successfully.',
                                type: 'success',
                            });
                            this.documentationService.setSignatureProcessStatus(false);
                            this.ref.close();
                            this.location.back();
                        }
                    },
                    (err) => {
                        this.documentationService.setSignatureProcessStatus(false);
                        this.pendingResponse = false;
                        this.store.dispatch(
                            spinnerDocumentStateChangeAction({
                                selectedLoadingSpinnerState: { isLoading: false },
                            })
                        );
                        console.error(err);
                    }
                );
        }
    }
    getSignedBy() {
        return [
            {
                firstName: this.user.firstName,
                lastName: this.user.lastName,
                therapistType: this.user.therapist.therapistType,
                roles: this.user.roles,
            },
        ];
    }
    updateTherapyAdmission() {
        const therapyAdmissions = cloneDeep(
            this.currentTherapyAdmissions.filter((admission) => admission._id !== this.currentTherapyAdmission._id)
        );
        this.therapyAdmissionService
            .getTherapyAdmission(this.currentTherapyAdmission._id)
            .subscribe((admission: any) => {
                admission.documentation.evaluation.signed.signedBy = this.getSignedBy()[0];
                therapyAdmissions.push(admission);
                this.store.dispatch(
                    therapyadmissionChangedAction({
                        therapyadmission: therapyAdmissions,
                    })
                );
                this.toasterService.show({
                    title: 'Success',
                    body: 'Document signed successfully.',
                    type: 'success',
                });
                this.ref.close();
                window.history.back();
            });
    }
}
