import { Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { PatientState } from '@app/store/store.interface';
import { Observable, Subject, take, takeUntil } from 'rxjs';
import {
    FacilityAdmissionService,
    FacilityManagementService,
    StoreService,
    TherapyAdmissionService,
} from '@app/services';
import { FacilityAdmission } from '@app/models';
import { PayorInformation, TherapyAdmission, TherapyDiscipline } from '@app/interfaces';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
    spinnerDocumentStateChangeAction,
    therapyadmissionChangedAction,
    therapyDisciplineChangedAction,
} from '@app/store';
import { cloneDeep, uniq } from 'lodash';
import { TIME } from '@app/helpers/constants';
import { ROUTER_UTILS } from '@app/helpers';

@Component({
    selector: 'app-mrn-bar',
    templateUrl: './mrn-bar.component.html',
    styleUrls: ['./mrn-bar.component.scss'],
})
export class MrnBarComponent implements OnInit, OnDestroy {
    @ViewChild('mrnBar', { static: false }) mrnBar: ElementRef;
    widthToTransform = '';
    isSticky = false;
    @Input() type: 'documentation' | 'admission' = 'admission';
    @Input() isChevron = false;
    form: FormGroup = new FormGroup({
        date: new FormControl('03/22/2022'),
    });
    patient$: Observable<PatientState>;
    public patientData$: Observable<PatientState>;
    facilityAdmission$: Observable<FacilityAdmission>;
    therapyAdmission$: Observable<TherapyAdmission[]>;
    criteria: { property: string; descending: boolean };
    payorInformation$: Observable<PayorInformation>;
    therapyDiscipline$: Observable<TherapyDiscipline>;
    private readonly onDestroy = new Subject<void>();
    currentTherapyAdmission: TherapyAdmission;
    activeDiscipline: string;
    selectedNote: string;
    currentDiscipline: TherapyDiscipline;
    documentDueDate: string;
    rootPath: string;
    pathParams: any;
    pccURL = '/' + ROUTER_UTILS.config.patients.pcc.root;
    therapyAdmissionUrl = '/' + ROUTER_UTILS.config.patients.pcc.therapyCase.root + '/' + ROUTER_UTILS.config.patients.pcc.therapyCase.therapyAdmission;

    

    loadingObs: any;
    constructor(
        private route: ActivatedRoute,
        private store: Store<{
            patient: PatientState;
            facilityAdmission: FacilityAdmission;
            therapyAdmission: TherapyAdmission[];
            payorInformation: PayorInformation;
            therapyDiscipline: TherapyDiscipline;
        }>,
        private facilityAdmissionService: FacilityAdmissionService,
        private therapyAdmissionService: TherapyAdmissionService,
        private storeService: StoreService,
        private render: Renderer2,
        private facilityManagementService: FacilityManagementService,
        private router: Router
    ) {
        this.patient$ = store.pipe(select('patient'));
        this.facilityAdmission$ = store.pipe(select('facilityAdmission'));
        this.therapyAdmission$ = store.pipe(select('therapyAdmission'));
        this.payorInformation$ = store.pipe(select('payorInformation'));
        this.therapyDiscipline$ = store.pipe(select('therapyDiscipline'));
        this.route.queryParamMap.subscribe({
            next: (params) => {
                this.currentDiscipline = params.get('discipline') as TherapyDiscipline;
            },
        });
    }
    ngOnInit(): void {
        window.addEventListener('scroll', this.scroll, true);
        window.addEventListener('resize', this.resize, true);
        this.loadingObs = this.storeService.getLoadingState();
        this.route.queryParams.pipe(take(1)).subscribe((params) => {
            if (this.type == 'documentation') {
                this.activeDiscipline = params.discipline;
                this.selectedNote = params.note;
                this.store.dispatch(therapyDisciplineChangedAction({ therapyDiscipline: params.discipline }));
            }
            this.documentDueDate = params.dueDate;
        });
        this.route.queryParamMap.subscribe({
            next: (params) => {
                if (params.get('caseLoads')) {
                    this.rootPath = '/therapist-caseload';
                } else if (params.get('therapistDashboard')) {
                    this.rootPath = '/therapist-co-sign';
                    this.pathParams = null;
                } else if (params.get('dorDashboard')) {
                    this.rootPath = '/dor-co-sign';
                    this.pathParams = null;
                } else if (params.get('pendingDoc')) {
                    this.rootPath = '/therapist-pending-documents';
                } else if (this.router.url.includes('viewMode')) {
                    const queryString = this.route.snapshot.queryParams;
                    this.rootPath = this.pccURL + this.therapyAdmissionUrl;
                    this.pathParams = { patient: queryString['patient'], tab: 'documentation', 'therapy-case': queryString['therapy-case'], 'facilityAd': queryString['facilityAd'] };
            
                }
            },
        });
        this.therapyAdmission$.pipe(takeUntil(this.onDestroy)).subscribe((therapyAdmissions) => {
            if (this.type == 'admission') {
                this.activeDiscipline = uniq(therapyAdmissions.map((admission) => admission.therapyDiscipline)) + ' ';
            }
            this.currentTherapyAdmission = this.therapyAdmissionService.initCurrentTherapyAdmissionByIdQueryString(therapyAdmissions);
        });
        this.criteria = {
            property: 'therapyDiscipline',
            descending: true,
        };
    }
    updateSOCDate(SOCDate: string) {
        let medicalDiagnosis = [];
        this.facilityAdmission$.pipe(takeUntil(this.onDestroy)).subscribe((facilityAdmission) => {
            if (facilityAdmission.medicalDiagnosis && facilityAdmission.medicalDiagnosis.icd10Codes) {
                medicalDiagnosis = facilityAdmission.medicalDiagnosis.icd10Codes;
            }
        });
        const therapyAdmission = {
            id: this.currentTherapyAdmission._id,
            SOCDate: new Date(new Date(SOCDate).setHours(TIME.hours, TIME.minutes, TIME.seconds)).toISOString(),
            therapyDiscipline: this.currentTherapyAdmission.therapyDiscipline || this.activeDiscipline,
            planTherapy: this.currentTherapyAdmission.planTherapy,
            icd10Codes: medicalDiagnosis,
        };
        this.store.dispatch(
            spinnerDocumentStateChangeAction({
                selectedLoadingSpinnerState: { isLoading: true },
            })
        );
        this.therapyAdmissionService
            .therapyAdmissionUpdate(therapyAdmission, this.facilityManagementService._currentFacility.id)
            .subscribe((v) => {
                const evaluation: any = this.currentTherapyAdmission.documentation.evaluation;
                v.documentation.evaluation.assignedTherapists = evaluation.assignedTherapists;
                this.store
                    .select('therapyAdmission')
                    .pipe(take(1))
                    .subscribe((data) => {
                        const temp = cloneDeep(data).filter(
                            (admission) =>
                                admission._id !== this.currentTherapyAdmission._id
                        );
                        temp.push(v);
                        this.store.dispatch(therapyadmissionChangedAction({ therapyadmission: temp }));
                        this.store.dispatch(
                            spinnerDocumentStateChangeAction({
                                selectedLoadingSpinnerState: { isLoading: false },
                            })
                        );
                    });
            });
    }

    scroll = (event): void => {
        this.modifyMRNBar(event, false);
    };

    resize = (event): void => {
        this.modifyMRNBar(event, true);
    };
    // shrinking & sticking mrn-bar to top center
    modifyMRNBar(event, reSized): void {
        const mrnPlaceholder = document.getElementById('mrn-placeholder');
        const scrollAble = document.getElementById('content');
        const barToShrink = document.getElementById('mrn-bar');

        if (barToShrink && this.router.url !== '/admission') {
            const parentWidth = barToShrink !== null ? this.render.parentNode(barToShrink).offsetWidth : 0;
            if (
                (scrollAble.scrollTop > 55 && !barToShrink.classList.contains('sticky')) ||
                (scrollAble.scrollTop > 55 && reSized)
            ) {
                this.render.addClass(barToShrink, 'sticky');
                // need to get width AFTER it settles down shrinking
                setTimeout(() => {
                    const barWidth = barToShrink.offsetWidth;
                    this.widthToTransform = 'translateX(' + ((parentWidth - barWidth) / 2).toString() + 'px)';
                }, 35);

                this.isSticky = true;
                this.render.addClass(mrnPlaceholder, 'activated');
            } else if (scrollAble.scrollTop < 55 && barToShrink.classList.contains('sticky')) {
                this.render.removeClass(barToShrink, 'sticky');
                setTimeout(() => {
                    this.widthToTransform = '';
                }, 35);
                this.isSticky = false;

                this.render.removeClass(mrnPlaceholder, 'activated');
            }
        }
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        window.removeEventListener('scroll', this.scroll, true);
        window.removeEventListener('resize', this.scroll, true);
    }
}
