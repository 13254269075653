import {
    format,
    startOfWeek,
    addDays,
    subDays,
    startOfMonth,
    endOfMonth,
    endOfWeek,
    isSameMonth,
    isSameDay,
    subMonths,
    addMonths,
} from 'date-fns';

interface WeekDay {
    date: Date;
    isActiveDay: boolean;
    isToday: boolean;
    dayName: string;
}

export class InnovaCalendar {
    // To keep track of the selected date
    private _selectedDate: Date;
    // To work with a specific language
    private _locale: string;
    // For setting the length of week day name. Values 'long' for 'Monday' and 'short' for 'Mon',
    private _weekDayLength: 'long' | 'short';
    // To keep help rendering the date in currently visible month
    private _activeDate: Date;
    private _header: string;
    // Week data
    private _weekStartDate: Date;
    private _weekDays: any[];
    private _week: WeekDay[];
    private _weekView: WeekDay[];
    private _allWeeks: WeekDay[];
    constructor() {
        // Basic info
        this._locale = 'en-US';
        this._weekDayLength = 'short';
        // Base date
        this._selectedDate = new Date();
        this._activeDate = new Date();
        this._header = format(this.activeDate, 'MMMM yyyy');
        // Week data
        this._weekStartDate = startOfWeek(this._activeDate);
        this._weekDays = [];
        // Generating
        // Generating week days
        this.generateWeekDays();
        // Generating this week dates
        this._week = this.renderSingleWeek(this._selectedDate, this._selectedDate, this._activeDate);
        this.renderWeekView(new Date());
        this.renderCalendar();
    }
    // Setters and Getters
    get locale() {
        return this._locale;
    }
    set locale(value: string) {
        this._locale = value;
        this.renderAll();
    }
    get weekDayLength() {
        return this._weekDayLength;
    }
    set weekDayLength(value: 'long' | 'short') {
        this._weekDayLength = value;
        this.renderAll();
    }
    set selectedDate(value: Date) {
        this._selectedDate = value;
    }
    get selectedDate() {
        return this._selectedDate;
    }
    set activeDate(value: Date) {
        this._activeDate = value;
    }
    get activeDate() {
        return this._activeDate;
    }
    get header() {
        return this._header;
    }
    set header(value: string) {
        this._header = value;
    }
    // Week data getters and setters
    get weekDays() {
        return this._weekDays;
    }
    set weekDays(value: any[]) {
        this._weekDays = value;
    }
    get weekView() {
        return this._weekView;
    }
    set weekView(value: WeekDay[]) {
        this._weekView = value;
    }
    get week() {
        return this._week;
    }
    set week(value: WeekDay[]) {
        this._week = value;
    }
    set allWeeks(value: WeekDay[]) {
        this._allWeeks = value;
    }
    get allWeeks() {
        return this._allWeeks;
    }
    // Actions
    // this function returns the names of week days
    generateWeekDays() {
        // days = ['sun']
        // const this._weekStartDate = startOfWeek(activeDate);
        this._weekDays = [];
        for (let day = 0; day < 7; day++) {
            const tempDay = addDays(this._weekStartDate, day);
            this._weekDays.push(tempDay.toLocaleDateString(this._locale, { weekday: this._weekDayLength }));
        }
    }
    // This method generates the dates for current week and is used by the month view
    renderSingleWeek(date: Date, selectedDate: Date, activeDate: Date) {
        let currentDate = date;
        const week = [];
        for (let day = 0; day < 7; day++) {
            const cloneDate = currentDate;
            week.push({
                isActiveDay: isSameMonth(currentDate, activeDate),
                isToday: isSameDay(currentDate, new Date()),
                date: cloneDate,
                dayName: currentDate.toLocaleDateString(this._locale, { weekday: this._weekDayLength }),
            });
            currentDate = addDays(currentDate, 1);
        }
        return week;
    }
    // This method is used to generate week view
    renderWeekView(date: Date) {
        this._weekView = Array(7)
            .fill(new Date(date))
            .map((item, index) => {
                const date = new Date(item.setDate(item.getDate() - item.getDay() + index));
                date.setHours(0, 0, 0, 0);
                return {
                    date: date,
                    isToday: isSameDay(date, new Date()),
                    isActiveDay: isSameMonth(date, new Date()),
                    dayName: date.toLocaleDateString(this._locale, { weekday: this._weekDayLength }),
                };
            });
    }
    // this function returns the dates of this month and week
    renderCalendar() {
        const startOfTheSelectedMonth = startOfMonth(this._activeDate);
        const endOfTheSelectedMonth = endOfMonth(this._activeDate);
        const startDate = startOfWeek(startOfTheSelectedMonth);
        const endDate = endOfWeek(endOfTheSelectedMonth);
        let currentDate = startDate;
        const allWeeks = [];

        while (currentDate <= endDate) {
            allWeeks.push(this.renderSingleWeek(currentDate, this._selectedDate, this._activeDate));
            currentDate = addDays(currentDate, 7);
        }
        this._allWeeks = allWeeks;
    }
    // rendering
    // To render previous month
    renderPreviousMonth() {
        this._activeDate = subMonths(this._activeDate, 1);
        this._selectedDate = this._activeDate;
        this.renderCalendar();
    }
    // To render the next month
    renderNextMonth() {
        this._activeDate = addMonths(this._activeDate, 1);
        this._selectedDate = this._activeDate;
        this.renderCalendar();
    }
    // To render month for the given date
    renderMonth(date: Date) {
        this._activeDate = addMonths(date, 0);
        this._selectedDate = this._activeDate;
        this.renderCalendar();
    }
    // Render week for the given date
    renderWeek(date: Date) {
        this._selectedDate = new Date(date);
        this.renderWeekView(date);
        this.renderCalendar();
    }
    // To render previous week
    renderPreviousWeek() {
        this._selectedDate = subDays(this._selectedDate, 7);
        this.renderWeekView(this._selectedDate);
        this.renderCalendar();
    }
    // To render next week
    renderNextWeek() {
        this._selectedDate = addDays(this._selectedDate, 7);
        this.renderWeekView(this._selectedDate);
        this.renderCalendar();
    }
    // To render current month from today
    renderCurrentMonth() {
        this._activeDate = new Date();
        this._activeDate = new Date();
    }
    // All necessary rendering functions should be called here.
    renderAll() {
        this.generateWeekDays();
        // Generating this week dates
        this._week = this.renderSingleWeek(this._selectedDate, this._selectedDate, this._activeDate);
        this.renderCalendar();
    }
}

// Documentations
/**
 * List of locales supported by the calendar
 * af-ZA
 * am-ET
 * ar-AE
 * ar-BH
 * ar-DZ
 * ar-EG
 *ar-IQ
 * ar-JO
 * ar-KW
 * ar-LB
 * ar-LY
 * ar-MA
 * arn-CL
 * ar-OM
 * ar-QA
 * ar-SA
 * ar-SD
 * ar-SY
 * ar-TN
 * ar-YE
 * as-IN
 * az-az
 * az-Cyrl-AZ
 * az-Latn-AZ
 * ba-RU
 * be-BY
 * bg-BG
 * bn-BD
 * bn-IN
 * bo-CN
 * br-FR
 * bs-Cyrl-BA
 * bs-Latn-BA
 * ca-ES
 * co-FR
 * cs-CZ
 * cy-GB
 * da-DK
 * de-AT
 * de-CH
 * de-DE
 * de-LI
 * de-LU
 * dsb-DE
 * dv-MV
 * el-CY
 * el-GR
 * en-029
 * en-AU
 * en-BZ
 * en-CA
 * en-cb
 * en-GB
 * en-IE
 * en-IN
 * en-JM
 * en-MT
 * en-MY
 * en-NZ
 * en-PH
 * en-SG
 * en-TT
 * en-US
 * en-ZA
 * en-ZW
 * es-AR
 * es-BO
 * es-CL
 * es-CO
 * es-CR
 * es-DO
 * es-EC
 * es-ES
 * es-GT
 * es-HN
 * es-MX
 * es-NI
 * es-PA
 * es-PE
 * es-PR
 * es-PY
 * es-SV
 * es-US
 * es-UY
 * es-VE
 * et-EE
 * eu-ES
 * fa-IR
 * fi-FI
 * fil-PH
 * fo-FO
 * fr-BE
 * fr-CA
 * fr-CH
 * fr-FR
 * fr-LU
 * fr-MC
 * fy-NL
 * ga-IE
 * gd-GB
 * gd-ie
 * gl-ES
 * gsw-FR
 * gu-IN
 * ha-Latn-NG
 * he-IL
 * hi-IN
 * hr-BA
 * hr-HR
 * hsb-DE
 * hu-HU
 * hy-AM
 * id-ID
 * ig-NG
 * ii-CN
 * in-ID
 * is-IS
 * it-CH
 * it-IT
 * iu-Cans-CA
 * iu-Latn-CA
 * iw-IL
 * ja-JP
 * ka-GE
 * kk-KZ
 * kl-GL
 * km-KH
 * kn-IN
 * kok-IN
 * ko-KR
 * ky-KG
 * lb-LU
 * lo-LA
 * lt-LT
 * lv-LV
 * mi-NZ
 * mk-MK
 * ml-IN
 * mn-MN
 * mn-Mong-CN
 * moh-CA
 * mr-IN
 * ms-BN
 * ms-MY
 * mt-MT
 * nb-NO
 * ne-NP
 * nl-BE
 * nl-NL
 * nn-NO
 * no-no
 * nso-ZA
 * oc-FR
 * or-IN
 * pa-IN
 * pl-PL
 * prs-AF
 * ps-AF
 * pt-BR
 * pt-PT
 * qut-GT
 * quz-BO
 * quz-EC
 * quz-PE
 * rm-CH
 * ro-mo
 * ro-RO
 * ru-mo
 * ru-RU
 * rw-RW
 * sah-RU
 * sa-IN
 * se-FI
 * se-NO
 * se-SE
 * si-LK
 * sk-SK
 * sl-SI
 * sma-NO
 * sma-SE
 * smj-NO
 * smj-SE
 * smn-FI
 * sms-FI
 * sq-AL
 * sr-BA
 * sr-CS
 * sr-Cyrl-BA
 * sr-Cyrl-CS
 * sr-Cyrl-ME
 * sr-Cyrl-RS
 * sr-Latn-BA
 * sr-Latn-CS
 * sr-Latn-ME
 * sr-Latn-RS
 * sr-ME
 * sr-RS
 * sr-sp
 * sv-FI
 * sv-SE
 * sw-KE
 * syr-SY
 * ta-IN
 * te-IN
 * tg-Cyrl-TJ
 * th-TH
 * tk-TM
 * tlh-QS
 * tn-ZA
 * tr-TR
 * tt-RU
 * tzm-Latn-DZ
 * ug-CN
 * uk-UA
 * ur-PK
 * uz-Cyrl-UZ
 * uz-Latn-UZ
 * uz-uz
 * vi-VN
 * wo-SN
 * xh-ZA
 * yo-NG
 * zh-CN
 * zh-HK
 * zh-MO
 * zh-SG
 * zh-TW
 * zu-ZA
 */
