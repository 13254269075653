import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ROUTER_UTILS } from '@app/helpers';
import { TabItem } from '@app/interfaces';
import { FacilityManagementService, OrganizationManagementService, ToasterService } from '@app/services';
import { take } from 'rxjs';

interface OrganizationData {
    companyCode: string;
    name: string;
}
@Component({
    selector: 'app-add-organization',
    templateUrl: './add-organization.component.html',
    styleUrls: ['./add-organization.component.scss'],
})
export class AddOrganizationComponent implements OnInit {
    organizationForm: FormGroup;
    isEditing: boolean;
    EditingData: any;
    loading: boolean;
    title = 'Add New Organization';
    tabs: TabItem[];
    showOrganizationalInfo = true;
    id = '';
    orgData: OrganizationData = { companyCode: '', name: '' };
    filteredStates = [];
    states = [];
    counties = [];
    mailingCounties: any[];
    constructor(
        private router: Router,
        private toasterService: ToasterService,
        private formBuilder: FormBuilder,
        private _organizationService: OrganizationManagementService,
        private facilityManagementService: FacilityManagementService
    ) {
        this.states = this.facilityManagementService.getUSAStates();
        this.filteredStates = this.states.map((item: any) => item.stateName);
        this.counties = this.facilityManagementService.getUSACounties();

        if (router.routerState.snapshot.url === '/admin/organization/edit') {
            if (router.getCurrentNavigation().extras.state) {
                this.isEditing = true;
                this.title = 'Edit Organization';
                this.loading = true;
                this.EditingData = router.getCurrentNavigation().extras.state.data;
            } else
                this.router.navigateByUrl(
                    ROUTER_UTILS.config.admin.root + '/' + ROUTER_UTILS.config.admin.organization.root
                );
        }

        
    }

    ngOnInit(): void {
        this.organizationForm = this.formBuilder.group({
            name: ['', [Validators.required]],
            companyCode: ['', [Validators.required]],
            address: this.formBuilder.group({
                address: ['', [Validators.required]],
                county: ['', [Validators.required]],
                state: ['', [Validators.required]],
                zipCode: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(5)]],
                phone: ['', [Validators.required]],
                fax: ['']

            })
        });
        if (this.isEditing) {
            this.organizationForm.patchValue(this.EditingData);
        }
        this.initializeTabs();
    }

    updateCounties(state: string) {
        const stateData = this.states?.filter((x) => x?.stateName === state);
            this.mailingCounties = this.counties
                ?.filter((x) => x?.stateCode === stateData[0]?.stateCode)
                ?.map((item) => item.countyName);
    }
    initializeTabs() {
        this.tabs = [
            {
                url: 'organization-information',
                title: 'Organization Information*',
                highlight: false,
                underline: true,
                isDone: false,
            },
            {
                url: 'non-patient-care',
                title: 'Other Services',
                highlight: false,
                underline: false,
                isDone: true,
            },
        ];
    }

    saveUpdateOrganization() {
        if (this.organizationForm.invalid) {
            this.organizationForm.markAllAsTouched();
            return;
        }
        if (this.id?.length && this.getStatus().toLowerCase() === 'continue') {
            this.selectedTab(1);
            return;
        }
        if (!this.isEditing) {
            this._organizationService
                .saveOrganization(this.organizationForm.value)
                .pipe(take(1))
                .subscribe(
                    (result: any) => {
                        if (result.success) {
                            this.toasterService.show({ title: 'Success', body: result.message, type: 'success' });
                        } else {
                            this.toasterService.show({ title: 'Failure', body: result.message, type: 'error' });
                        }
                        this.id = result.data.id;
                        this.orgData.name = result.data.name;
                        this.orgData.companyCode = result.data.companyCode;
                        if (!this.organizationForm.invalid) {
                            this.selectedTab(1);
                        }
                    },
                    (error) => {
                        this.toasterService.show({ title: 'Failure', body: error, type: 'error' });
                    }
                );
        } else {
            this._organizationService
                .updateOrganization(this.EditingData.id, this.organizationForm.getRawValue())
                .pipe(take(1))
                .subscribe(
                    (result: any) => {
                        if (result.success) {
                            this.toasterService.show({ title: 'Success', body: result.message, type: 'success' });
                            this.updateOrganizationData(result.data);
                        } else {
                            this.toasterService.show({ title: 'Failure', body: result.message, type: 'error' });
                        }
                        if (!this.organizationForm.invalid) {
                            this.selectedTab(1);
                        }
                    },
                    (err) => {
                        this.toasterService.show({ title: 'Failure', body: err, type: 'error' });
                    }
                );
        }
    }
    onCancel() {
        history.back();
    }

    updateOrganizationData(data) {
        this._organizationService.__Organizations.subscribe((org) => {
            const organization = org.find((item) => item.id == this.EditingData.id);
            organization.name = data.name;
            organization.companyCode = data.companyCode;
        });
    }

    getStatus() {
        if (this.id) {
            const isNameSame = this.organizationForm?.value?.name === this.orgData?.name;
            const isCompanyCodeSame = this.organizationForm?.value?.companyCode === this.orgData?.companyCode;

            return isNameSame && isCompanyCodeSame ? 'Continue' : 'Save and Continue';
        } else {
            return 'Save and Continue';
        }
    }

    selectedTab(index) {
        if (!this.organizationForm?.value?.name || !this.organizationForm?.value?.companyCode?.length) {
            this.toasterService.show({
                title: 'Failure',
                body: 'Please add Organization name / Company Code',
                type: 'error',
            });
            return;
        } else if (
            index === 1 &&
            (this.organizationForm?.value?.name || this.organizationForm?.value?.companyCode?.length)
        ) {
            if (!this.id && !this.isEditing) {
                return;
            }
            this.tabs[0].highlight = false;
            this.tabs[0].underline = true;
            this.tabs[0].isDone = true;
            this.tabs[1].highlight = true;
            this.tabs[1].underline = true;
            this.tabs[1].isDone = false;
            this.showOrganizationalInfo = false;
        } else if (index === 0) {
            this.showOrganizationalInfo = true;
            this.tabs[0].highlight = true;
            this.tabs[0].underline = true;
            this.tabs[0].isDone = !!this.id;
            this.tabs[1].highlight = false;
            this.tabs[1].underline = !!this.id;
            this.tabs[1].isDone = !!this.id;
        }
    }
}
