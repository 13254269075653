import { DatePipe } from '@angular/common';
import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CheckIconCellRendererComponent, DiffCellRendererComponent } from '@app/data-grid/cell-renderers';
import { isAllowedToCoSign } from '@app/helpers';
import {
    prepareDynamicArrayChangeLog,
    prepareDynamicGoalsArrayChangeLog,
    prepareSimpleTextChangeLog,
} from '@app/helpers/utils';
import { scrollToElementWithOffset } from '@app/helpers/utils/scroll.helper';
import { findTherapyCaseByParamId } from '@app/helpers/utils/therapy-case.utils';
import { DCChangeHistory, DischargeNote, FacilityAdmission, TherapyAdmission } from '@app/interfaces';
import { DOCUMENTATION_CONSTANTS, Doc_Notes } from '@app/interfaces/documentation';
import { PrintData } from '@app/interfaces/print';
import { Account } from '@app/models';
import { CoSignNoteComponent } from '@app/modules/patients/PCC/pcc-tabs/pcc-documentation/modals/co-sign-note/co-sign-note.component';
import { SignNoteComponent } from '@app/modules/patients/PCC/pcc-tabs/pcc-documentation/modals/sign-note/sign-note.component';
import {
    AuthService,
    DischargeNoteService,
    GoalService,
    ModalService,
    StoreService,
    ToasterService,
} from '@app/services';
import { TimeConfirmationDialogComponent } from '@app/shared/time-confirmation-dialog/time-confirmation-dialog.component';
import { select, Store } from '@ngrx/store';
import { ColDef } from 'ag-grid-community';
import { format, parse } from 'date-fns';
import { cloneDeep } from 'lodash';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'app-discharge-note',
    templateUrl: './discharge-note.component.html',
    styleUrls: ['./discharge-note.component.scss'],
})
export class DischargeNoteComponent implements OnInit, OnDestroy {
    @Input() printMode = false;
    @Input() printData: PrintData;
    noteTitle = 'Discharge Note';
    DOCUMENTATION_CONSTANTS = DOCUMENTATION_CONSTANTS;
    evalHistorySide: boolean;
    isDifferentDisciplineTherapist = false;
    therapistDiscipline: any;
    showCoSign = false;
    contentEl = document.getElementById('content');
    enableCoSignBtn = false;

    private readonly onDestroy = new Subject<void>();

    medicalDiagnosis: { title: string; cols: ColDef[]; data: Array<object> } = {
        title: 'Medical Diagnosis',
        cols: [
            { field: 'no', headerName: 'No.', width: 60, valueGetter: 'node.rowIndex + 1' },
            { field: 'code', headerName: 'Code', width: 100 },
            { field: 'description', headerName: 'Description', width: 300, wrapText: true, autoHeight: true },
            {
                field: 'pdpmClinicalCategory',
                headerName: 'PDPM Clinical Category',
                width: 300,
                wrapText: true,
                autoHeight: true,
            },
            {
                field: 'onsetDate',
                headerName: 'Onset Date',
                cellRenderer: (params) => {
                    return params.data.onsetDate ? this.transformDate(new Date(params.data.onsetDate)) : '-----';
                },
            },
            { field: 'slp', headerName: 'SLP Comorbidity' },
            { headerName: 'Primary DX', field: 'isPrimary', cellRenderer: CheckIconCellRendererComponent },
        ],
        data: [],
    };
    treatmentDiagnosis: { title: string; cols: ColDef[]; data: Array<object> } = {
        title: 'Treatment Diagnosis',
        cols: [
            { field: 'no', headerName: 'No.', maxWidth: 100, valueGetter: 'node.rowIndex + 1' },
            { field: 'code', headerName: 'Code', maxWidth: 100 },
            {
                field: 'description',
                headerName: 'Description',
                cellClass: ['body-medium', 'line-space'],
                autoHeight: true,
            },
        ],
        data: [],
    };
    assessmentTreatmentStarted: { title: string; paragraph: string } = {
        title: 'Patient Assessment since treatment started',
        paragraph: '',
    };
    functionalDeficits: { title: string; cols: ColDef[]; data: Array<object> } = {
        title: 'Functional Deficits',
        cols: [
            { field: 'no', headerName: 'No.', width: 30, valueGetter: 'node.rowIndex + 1' },
            {
                field: 'name',
                headerName: 'Name',
                cellRenderer: DiffCellRendererComponent,
            },
            {
                field: 'levelOfFunction',
                headerName: 'CLOF',
                autoHeight: true,
                wrapText: true,
                valueGetter(params) {
                    if (params.data.clof.currentValue) {
                        return {
                            currentValue: params.data.clof.currentValue?.levelOfFunction,
                            previousValue: params.data.clof.previousValue?.levelOfFunction
                                ? params.data.clof.previousValue?.levelOfFunction
                                : undefined,
                        };
                    } else return params.data.clof.levelOfFunction;
                },
                cellRenderer: DiffCellRendererComponent,
            },
        ],
        data: [],
    };
    sectionGG: { title: string; cols: ColDef[]; data: Array<object> } = {
        title: 'Section GG',
        cols: [
            { field: 'no', headerName: 'No.', width: 30, valueGetter: 'node.rowIndex + 1' },
            {
                field: 'name',
                headerName: 'Name',
                cellRenderer: DiffCellRendererComponent,
            },
            {
                field: 'levelOfFunction',
                headerName: 'CLOF',
                autoHeight: true,
                wrapText: true,
                valueGetter(params) {
                    if (params.data.clof.currentValue) {
                        return {
                            currentValue: params.data.clof.currentValue?.levelOfFunction,
                            previousValue: params.data.clof.previousValue?.levelOfFunction,
                        };
                    } else return params.data.clof.levelOfFunction;
                },
                cellRenderer: DiffCellRendererComponent,
            },
        ],
        data: [],
    };
    clinicalImpression: { title: string; paragraph: string } = {
        title: 'Clinical Impression',
        paragraph: '',
    };
    endOfCare: { title: string; paragraph: string } = {
        title: 'End of Care Reason',
        paragraph: '',
    };
    endOfCareDate: { title: string; paragraph: string } = {
        title: 'End of Care Date',
        paragraph: '',
    };
    dischargeInstructions: { title: string; paragraph: string } = {
        title: 'Discharge Instructions',
        paragraph: '',
    };
    anticipatedDischargeDestination: { title: string; paragraph: string } = {
        title: 'Anticipated discharge destination',
        paragraph: '',
    };
    caregiverEducation: { title: string; paragraph: string } = {
        title: 'Caregiver education',
        paragraph: '',
    };
    goal: any = [];
    descriptionCSS = () => {
        return {
            color: '#1C3775',
            'white-space': 'normal',
            height: 'auto',
            'line-height': 2,
        };
    };
    addendum: { title: string; cols: ColDef[]; data: Array<object> } = {
        title: 'Addendum',
        cols: [
            {
                field: 'description',
                width: 900,
                headerName: 'Description',
                autoHeight: true,
                wrapText: true,
            },
            {
                field: 'userNameRole',
                headerName: 'User name & Role',
                cellRenderer: (params) => {
                    const keyData = params.data?.signed?.signedBy[0];
                    return this.userNameFormatter(keyData);
                },
                autoHeight: true,
                width: 250,
            },
            {
                field: 'date',
                headerName: 'Date & Time',
                valueFormatter: (params) => {
                    const completionDate = params.data?.signed?.signDate;
                    const completionTime =
                        ('0' + new Date(completionDate).getHours()).slice(-2) +
                        ':' +
                        ('0' + new Date(completionDate).getMinutes()).slice(-2);
                    return completionDate
                        ? this.transformDate(new Date(completionDate)) + ' ' + completionTime
                        : '-------';
                },
            },
        ],
        data: [],
    };
    optionToShow = [
        {
            date: this.dateFormat('2021-03-25'),
            text: 'Green Field dade county',
            changes: ['Caregiver education', 'Vitals Updated', 'Plan of treatment updated'],
        },
        { date: this.dateFormat('2021-03-26'), text: 'Green Field dade county', changes: ['CPT Codes Updated'] },
    ];
    dateFormat(date): string {
        const d = parse(date, 'yyyy-MM-dd', new Date());
        return format(d, 'MMMM, dd h:mm a');
    }

    private transformDate = (date) => {
        if (!date) {
            return '';
        }
        return this.datePipe.transform(new Date(date), 'MM/dd/yyyy');
    };
    userNameFormatter(user: any) {
        return user?.roles
            ? `<p class="name mb-0" >${user.lastName + ', ' + user.firstName}</p><span>${user.roles.map(
                  (role) => role.name
              )}</span>`
            : `<p class="name mb-0" >${
                  this.user.user.lastName + ', ' + this.user.user.firstName
              }</p><span>${this.user.user.roles.map((role) => role.name)}</span>`;
    }
    admissionDischarged = false;
    currentAdmission: TherapyAdmission;
    therapyAdmissions: TherapyAdmission[];
    therapyDiscipline: string;
    medicalDiagnosisData: any[] = [];
    dischargeNoteId: string;
    dischargeNote: DischargeNote;
    loadingObs: any;
    user: any;
    currentAddendum: string;
    changeHistory: DCChangeHistory[];
    selectedChangeInstance = 0;
    selectedLabel: string;
    currentDocSigned = false;
    constructor(
        private route: ActivatedRoute,
        private storeService: StoreService,
        private datePipe: DatePipe,
        private modalService: ModalService,
        private authService: AuthService,
        private toasterService: ToasterService,
        private store: Store<{
            therapyAdmission: TherapyAdmission[];
            facilityAdmission: FacilityAdmission;
            user: Account;
        }>,
        private goalService: GoalService,
        private DCService: DischargeNoteService
    ) {
        this.loadingObs = this.storeService.getLoadingState();
        this.store
            .select('facilityAdmission')
            .pipe(takeUntil(this.onDestroy))
            .subscribe((data) => {
                if (data.patientDischarge) {
                    this.admissionDischarged = true;
                }
            });
        this.route.queryParamMap.subscribe({
            next: (params) => {
                this.therapyDiscipline = params.get('discipline');
                this.dischargeNoteId = params.get('doc');
            },
        });

        this.store.pipe(select('user')).subscribe((user) => {
            if (user) {
                this.user = user;
            }
        });
        this.DCService.getDCEditHistory(this.dischargeNoteId).subscribe({
            next: (result) => {
                if (result) {
                    this.changeHistory = result;
                }
            },
            complete: () => {
                this.prepareChangeInstanceData(0);
            },
        });
    }
    ngOnInit(): void {
        this.contentEl.addEventListener('scroll', this.divScroll, true);
        if (this.printMode) {
            const { discipline } = this.printData;
            this.therapyDiscipline = discipline;
        }
        this.store
            .select('therapyAdmission')
            .pipe(takeUntil(this.onDestroy))
            .subscribe((data) => {
                this.currentAddendum = '';
                this.therapyAdmissions = data;

                const queryString = this.route.snapshot.queryParams;
                this.currentAdmission = findTherapyCaseByParamId(data, queryString['therapy-case']);
                this.therapyDiscipline ||= this.currentAdmission?.therapyDiscipline;
                this.therapyDiscipline ||= 'PT';
                this.currentAdmission = cloneDeep(this.currentAdmission);

                if (this.currentAdmission) {
                    this.dischargeNote = this.currentAdmission.documentation.dischargeNote;
                    this.currentDocSigned = !!this.dischargeNote?.signed?.signDate;
                    this.showCoSign = isAllowedToCoSign(this.currentAdmission, this.user, this.dischargeNote.coSigned);
                    this.addendum.data = this.dischargeNote.addendum ? this.dischargeNote.addendum : [];
                }
            });
        if (this.authService.isTherapist) {
            this.therapistDiscipline = this.authService.accountValue.user.therapist.discipline;
            if (this.therapistDiscipline.replace('A', '') !== this.therapyDiscipline) {
                this.isDifferentDisciplineTherapist = true;
            }
        } else if (this.authService.isManager || this.authService.isSuperAdmin) this.isDifferentDisciplineTherapist = true;
    }

    onCoSignClicked() {
        if (this.admissionDischarged) {
            const data = {
                heading: 'Confirmation',
                icon: '',
                btn1Text: 'Cancel',
                btn2Text: 'Continue',
                lowerBodyValue: '',
                iconFilled: true,
                bodyText: `This patient has a facility discharge, would you like to continue?`,
            };
            const dialogRef = this.modalService.open(TimeConfirmationDialogComponent, {
                data
            });
            dialogRef.afterClosed().subscribe((res) => {
                if (res) {
                    this.modalService.open(CoSignNoteComponent, {
                        data: {
                            documentType: Doc_Notes.Discharge,
                            heading: 'Discharge note',
                            document: this.dischargeNote,
                            changeHistoryCount: this.changeHistory.length,
                        },
                    });
                }
            });
        } else {
            this.modalService.open(CoSignNoteComponent, {
                data: {
                    documentType: Doc_Notes.Discharge,
                    heading: 'Discharge note',
                    document: this.dischargeNote,
                    changeHistoryCount: this.changeHistory.length,
                },
            });
        }
    }

    prepareChangeInstanceData(item: number) {
        this.selectedLabel = '';
        this.medicalDiagnosis.data = this.changeHistory[item].patientHistory.medicalDiagnosis ?? [];
        this.treatmentDiagnosis.data = this.changeHistory[item].patientHistory.treatmentDiagnosis ?? [];
        this.assessmentTreatmentStarted.paragraph = prepareSimpleTextChangeLog(
            this.changeHistory,
            item,
            'subjectiveAssessment',
            'patientAssessmentSinceTreatmentStarted'
        );
        this.clinicalImpression.paragraph = prepareSimpleTextChangeLog(
            this.changeHistory,
            item,
            'assessment',
            'clinicalImpression'
        );
        this.endOfCare.paragraph = prepareSimpleTextChangeLog(
            this.changeHistory,
            item,
            'assessment',
            'endOfCareReason'
        );
        this.changeHistory[item].assessment.endOfCareDate = format(
            new Date(this.changeHistory[item].assessment.endOfCareDate),
            'M/dd/yyyy'
        );
        this.endOfCareDate.paragraph = prepareSimpleTextChangeLog(
            this.changeHistory,
            item,
            'assessment',
            'endOfCareDate'
        );
        this.dischargeInstructions.paragraph = prepareSimpleTextChangeLog(
            this.changeHistory,
            item,
            'plan',
            'dischargeInstructions'
        );
        this.anticipatedDischargeDestination.paragraph = prepareSimpleTextChangeLog(
            this.changeHistory,
            item,
            'plan',
            'dischargeDestination'
        );
        this.caregiverEducation.paragraph = prepareSimpleTextChangeLog(
            this.changeHistory,
            item,
            'plan',
            'caregiverEducation'
        );
        this.populateGoalsData(item);
        this.functionalDeficits.data = prepareDynamicArrayChangeLog(
            this.changeHistory,
            item,
            'objectiveAssessment',
            'functionalDeficits',
            'name'
        );
        this.sectionGG.data = prepareDynamicArrayChangeLog(
            this.changeHistory,
            item,
            'objectiveAssessment',
            'ggItems',
            'name'
        );
        this.selectedChangeInstance = item;
    }

    populateGoalsData(item: number) {
        const goals = prepareDynamicGoalsArrayChangeLog(this.changeHistory, item, null, 'goalsStatus', 'id');
        if (goals[0]?.goal?.currentValue) {
            this.goal = this.goalService.buildGoalsWithDiffView(goals);
        } else {
            this.goal = this.goalService.buildGoal(goals);
        }
    }

    /**
     * Function to scroll to a specific section when user clicks item on edit history section
     * @param id - Id of the section that user clicked
     * @param index - Index of the currently selected history (there can be multiple histories)
     */
    scroll(id: string, index: number): void {
        event.stopPropagation();
        if (index === this.selectedChangeInstance) {
            this.selectedLabel = id;
            id = this.generateSectionId(id);
            const sectionElement: ElementRef = new ElementRef(document.getElementById(id));
            scrollToElementWithOffset(sectionElement, 'start');
        } else {
            // If user is clicking on other history Instance, then select that history instance
            this.prepareChangeInstanceData(index);
        }
    }

    /**
     * Function to generate ID to move to that section in HTML
     * @param id - Current ID coming as label
     * @returns - ID according to sections
     */
    generateSectionId(id): string {
        // As there are some label that have dots so we need the later part
        const str = id.includes('.') ? id.split('.')[1] : id.split('.')[0];
        return str + 'Section';
    }

    updateAddendum(description) {
        this.currentAddendum = description;
    }

    padTo2Digits(number: number) {
        return number.toString().padStart(2, '0');
    }
    addAddendumNote() {
        if (this.authService.isManager || this.authService.isTherapist) {
            if (this.currentAddendum) {
                const addendumNote = {
                    description: this.currentAddendum,
                };
                this.modalService.open(SignNoteComponent, {
                    data: {
                        documentType: 'dischargeNote',
                        addendumNote,
                        heading: 'Addendum Note',
                        dischargeNote: this.dischargeNoteId,
                    },
                });
            } else {
                this.toasterService.show({ title: 'Failure', body: 'Add description', type: 'error' });
            }
        }
    }

    tooltipCheck = () => {
        if (this.admissionDischarged) {
            return 'Patient Discharged';
        } else if (!this.authService.isManager && !this.authService.isTherapist) {
            return 'You are not authorized to add addendum';
        } else if (this.isDifferentDisciplineTherapist) {
            return '';
        } else {
            return 'Add Addendum Note';
        }
    };

    openEvalHistory() {
        this.evalHistorySide = true;
        this.prepareChangeInstanceData(this.changeHistory?.length == 1 ? 0 : 1);
    }

    closeHistorySideBar(event: boolean) {
        this.evalHistorySide = event;
        this.prepareChangeInstanceData(0);
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.contentEl.removeEventListener('scroll', this.divScroll, true);
    }

    divScroll = (event): void => {
        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight - 500)
            this.enableCoSignBtn = true;
    };
}
