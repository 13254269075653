import { Component, Input } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
    selector: 'app-switch',
    templateUrl: './switch.component.html',
    styleUrls: ['./switch.component.scss'],
})
export class SwitchComponent implements ControlValueAccessor {
    @Input() label: string;
    @Input() value = false;
    @Input() disabled = false;
    @Input() controlContainerClass: string;
    @Input() formGroupClass: string;
    @Input() labelClass: string;
    @Input() removeMargin: '';
    @Input() rightAlignToggle: boolean;

    constructor(public ngControl: NgControl) {
        ngControl.valueAccessor = this;
    }

    valueChange(event: any) {
        this.propagateChange(event.target.checked);
    }
    get isRequired(): boolean {
        const control: AbstractControl = this.ngControl.control;
        if (control.validator === null) return false;
        return control.validator(control)?.required ?? false;
    }
    get hasError(): boolean {
        const control: AbstractControl = this.ngControl.control;
        if (control && control.touched && control.invalid) return true;
        return false;
    }
    // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
    propagateChange = (_: any) => {};
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onTouched = () => {};
    writeValue(obj: boolean): void {
        this.value = obj;
    }
    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}
