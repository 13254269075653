import { EvaluationQueryParams } from './../interfaces/internal-feed.interface';
import { Injectable } from '@angular/core';
import {
    ActionsCellRendererComponent,
    EmptyCellRendererComponent,
    ImpairmentsCellRendererComponent,
} from '@app/data-grid/cell-renderers';
import { PatientAssessmentSwitchCellRendererComponent } from '@app/modules/documentation/patient-assessment/modals/patient-assessment-switch-cell-renderer/patient-assessment-switch-cell-renderer.component';
import { ColDef } from 'ag-grid-community';
import { BehaviorSubject, Observable, Subject, map, take } from 'rxjs';
import { PatientAssessmentService } from './patient-assessment.service';
import { CptCodes, EvalChangeHistory, EvaluationDocument, FacilityAdmission, TherapyAdmission } from '@app/interfaces';
import { Store } from '@ngrx/store';
import { therapyadmissionChangedAction } from '@app/store';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { _compareArrays, getNestedObjectValue } from '@app/helpers/utils';
import { cloneDeep } from 'lodash';
import { StoreService } from './store.service';
import { FacilityAdmissionService } from './facilityAdmission.service';
import { NEURO_MUSCULO_SKELETAL_IMPAIRMENTS } from '@app/interfaces/documentation';
import { convertSecsToMins, placeZerosOnLeft } from '@app/helpers/utils/data.utils';
import { ClinicalCategoriesService } from './clinicalCategories.service';
import { FunctionalDeficitNoteComponent } from '@app/modules/documentation/patient-assessment/functional-deficit-note/functional-deficit-note.component';

const baseUrl = `${environment.apiUrl}`;

@Injectable({
    providedIn: 'root',
})
export class EvaluationService {
    public QuickLinkNotifySubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
    public QuickLinkClickedSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
    public submitClickedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public EvalTypeSubject: BehaviorSubject<CptCodes[]> = new BehaviorSubject<CptCodes[]>([]);
    public nextTab: Subject<{ url: string }> = new Subject<{ url: string }>();
    public loadedEvaluation: Subject<number> = new Subject<number>();
    dataForEdit = [];
    therapyAdmissionsSnapshot: TherapyAdmission[];
    facilityAdmissionSnapshot: FacilityAdmission;
    documentEditedSuccessfully = false;
    isEditModeActive = false;
    evaluationPDF = '';
    isPatientAssessmentScreen = false;
    isAnyDocumentCompleted = false;
    editModeWithEvalOnly = false;
    isEvalOnly = false;

    constructor(
        private patientAssessmentService: PatientAssessmentService,
        private store: Store<{ therapyAdmission: TherapyAdmission[] }>,
        private http: HttpClient,
        private storeService: StoreService,
        private facilityAdmissionService: FacilityAdmissionService,
        private clinicalCategoriesService: ClinicalCategoriesService
    ) {}

    checkAnyDocumentIsCompleted(currentAdmission: TherapyAdmission): boolean {
        this.isAnyDocumentCompleted = false;
        for (const key in currentAdmission.documentation) {
            if (key !== 'evaluation' && key !== 'id' && key !== '_id') {
                if (key === 'dischargeNote') {
                    this.isAnyDocumentCompleted =
                        currentAdmission.documentation[key] &&
                        currentAdmission.documentation[key].status === 'Completed';
                } else {
                    this.isAnyDocumentCompleted =
                        currentAdmission.documentation[key].length > 0 &&
                        currentAdmission.documentation[key].some((el) => el.status === 'Completed');
                }
                if (this.isAnyDocumentCompleted) {
                    return this.isAnyDocumentCompleted;
                }
            }
        }
        return this.isAnyDocumentCompleted;
    }

    getMedicalDiagnosisCols(): ColDef[] {
        return [
            { headerName: 'Code', field: 'code' },
            { headerName: 'Description', field: 'description' },
            { headerName: 'Pdpm Clinical Category', field: 'pdpm' },
            { headerName: 'Onset Date', field: 'onsetDate' },
            { headerName: 'Primary D/X', field: 'primaryDx' },
            { headerName: 'Actions', suppressMovable: true, lockPosition: 'right' },
        ];
    }
    getTreatmentDiagnosisCols(): ColDef[] {
        return [
            { headerName: 'Code', field: 'code' },
            { headerName: 'Description', field: 'description' },
            {
                headerName: 'Actions',
                width: 50,
                suppressMovable: true,
                lockPosition: 'right',
            },
        ];
    }
    getMiscCols(): ColDef[] {
        return [
            { headerName: 'No.', field: 'no', width: 50 },
            { headerName: 'Description', field: 'description' },
            {
                headerName: 'Actions',
                width: 50,
                suppressMovable: true,
                lockPosition: 'right',
            },
        ];
    }
    getImpairmentCols(): ColDef[] {
        // cellStyle: () => {return {'font-weight': 600 }}
        return [
            {
                headerName: 'No.',
                field: 'no',
                minWidth: 60,
                maxWidth: 60,
                valueGetter: 'node.rowIndex + 1',
            },
            {
                headerName: 'Name',
                field: 'impairmentName',
                cellRenderer: ImpairmentsCellRendererComponent,
                wrapText: true,
                autoHeight: true,
                minWidth: 300,
                cellStyle: () => {
                    return { wordBreak: 'normal' };
                },
            },
            { headerName: '', field: 'subSection', hide: true },
            {
                headerName: '',
                field: 'fieldName',
                wrapText: true,
                autoHeight: true,
                hide: true,
            },
            {
                headerName: 'Value',
                field: 'fieldValue',
                valueFormatter: (params: any) => {
                    if (params.data.impairmentName === NEURO_MUSCULO_SKELETAL_IMPAIRMENTS.ACTIVITY_TOLERANCE) {
                        const { min, sec } = convertSecsToMins(+params.data.fieldValue);
                        return placeZerosOnLeft(min, 2) + ':' + placeZerosOnLeft(sec, 2);
                    }

                    if (Array.isArray(params.value)) return params.value.map((val) => val.text).join(', ');
                    return params.value;
                },
                width: 350,
                wrapText: true,
                autoHeight: true,
                cellStyle: () => {
                    return { wordBreak: 'normal' };
                },
            },
            // { headerName: 'Score', field: 'score' },
            {
                headerName: 'Note',
                field: 'note',
                wrapText: true,
                autoHeight: true,
                width: 400,
                cellStyle: () => {
                    return { wordBreak: 'normal' };
                },
            },
            {
                headerName: 'Make a Goal',
                field: 'isAGoal',
                width: 200,
                // type: 'rightAligned',
                headerClass: 'alignCenter',
                cellRendererParams: { gridName: 'impairments' },
                cellRendererSelector: (params) => {
                    if (params.data.canBeAGoal) {
                        return {
                            component: PatientAssessmentSwitchCellRendererComponent,
                            params: {
                                noteType: this.patientAssessmentService._currentNote,
                                disabled: this.isEditModeActive && this.isAnyDocumentCompleted,
                            },
                        };
                    } else {
                        return {
                            component: EmptyCellRendererComponent,
                            params: {
                                showNAChip: true,
                                chipTooltip:
                                    'Goal for this item cannot be made. Please make a custom goal (In Plan of Care) if you still want to create a goal for this.',
                            },
                        };
                    }
                },
            },
            {
                headerName: 'Actions',
                suppressMovable: true,
                lockPosition: 'right',
                type: 'rightAligned',
                cellRenderer: ActionsCellRendererComponent,
                cellRendererParams: {
                    delete: true,
                    gridName: 'impairments',
                },
            },
        ];
    }
    getDeficitCols(): ColDef[] {
        return [
            {
                headerName: 'No.',
                field: 'no',
                minWidth: 60,
                maxWidth: 60,
                valueGetter: 'node.rowIndex + 1',
            },
            {
                headerName: 'Name',
                field: 'name',
                wrapText: true,
                cellStyle: () => {
                    return { wordBreak: 'normal' };
                },
                autoHeight: true,
            },
            {
                headerName: 'Current Level of Function',
                field: 'clof.levelOfFunction',
                width: 300,
                wrapText: true,
                cellStyle: () => {
                    return { wordBreak: 'normal' };
                },
                autoHeight: true,
                cellRenderer: FunctionalDeficitNoteComponent,
                cellRendererParams: (params) => {
                    return {
                        note: params.data.clof.note,
                    };
                },
            },
            {
                headerName: 'Prior level of Function',
                field: 'plof.levelOfFunction',
                width: 300,
                wrapText: true,
                cellStyle: () => {
                    return { wordBreak: 'normal' };
                },
                autoHeight: true,
                cellRenderer: FunctionalDeficitNoteComponent,
                cellRendererParams: (params) => {
                    return {
                        note: params.data.plof.note,
                    };
                },
            },
            {
                headerName: 'Make a Goal',
                field: 'isAGoal',
                width: 200,
                headerClass: 'alignCenter',
                cellRendererParams: { gridName: 'functionalDeficits' },
                cellRendererSelector: () => {
                    return {
                        component: PatientAssessmentSwitchCellRendererComponent,
                        params: {
                            noteType: this.patientAssessmentService._currentNote,
                            disabled: this.isEditModeActive && this.isAnyDocumentCompleted,
                        },
                    };
                },
            },
            {
                headerName: 'Actions',
                suppressMovable: true,
                lockPosition: 'right',
                type: 'rightAligned',
                cellRenderer: ActionsCellRendererComponent,
                cellRendererParams: { delete: true, edit: true, gridName: 'deficits' },
            },
        ];
    }
    getStandardizedTestCols(): ColDef[] {
        return [
            {
                headerName: 'No.',
                field: 'no',
                minWidth: 60,
                maxWidth: 60,
                valueGetter: (v) => {
                    return v.node.rowIndex + 1;
                },
            },
            {
                headerName: 'Name',
                field: 'name',
                wrapText: true,
                cellStyle: () => {
                    return { wordBreak: 'normal' };
                },
                autoHeight: true,
            },
            {
                headerName: 'Score',
                field: 'score',
                maxWidth: 140,
                cellRenderer: (params) =>
                    params.data?.totalScore ? `${params.data.score} / ${params.data.totalScore}` : params.data.score,
            },
            {
                headerName: 'Interpretation',
                field: 'interpretation',
                width: 400,
                wrapText: true,
                autoHeight: true,
                cellStyle: (param) => {
                    if (param.data.name.includes('(MASA)')) return { whiteSpace: 'pre' };
                    else return { wordBreak: 'normal' };
                },
            },
            {
                headerName: 'Note',
                field: 'note',
                width: 300,
                wrapText: true,
                autoHeight: true,
                cellStyle: (param) => {
                    if (param.data.name.includes('(MASA)')) return { whiteSpace: 'pre' };
                    else return { wordBreak: 'normal' };
                },
            },
            {
                headerName: 'Make a Goal',
                field: 'isAGoal',
                width: 200,
                headerClass: 'alignCenter',
                cellRendererParams: { gridName: 'standardizedTests' },
                cellRendererSelector: () => {
                    return {
                        component: PatientAssessmentSwitchCellRendererComponent,
                        params: {
                            noteType: this.patientAssessmentService._currentNote,
                            disabled: this.isEditModeActive && this.isAnyDocumentCompleted,
                        },
                    };
                },
            },
            {
                headerName: 'Actions',
                suppressMovable: true,
                lockPosition: 'right',
                type: 'rightAligned',
                cellRenderer: ActionsCellRendererComponent,
                cellRendererParams: {
                    delete: true,
                    edit: true,
                    gridName: 'standardizedTests',
                },
            },
        ];
    }

    prepareDataForEdit(
        section: string,
        fieldKey: string,
        fieldValue: string | object,
        type: string,
        allTherapyAdmissions: TherapyAdmission[],
        currentAdmission: TherapyAdmission
    ) {
        const foundIndex = allTherapyAdmissions.findIndex((ta) => ta._id === currentAdmission._id);
        let currentValue;
        if (type == 'textBox') {
            currentValue = fieldValue;
        } else {
            const arr = currentAdmission.documentation.evaluation[section][fieldKey];
            currentValue = arr;
        }
        let previousValue;
        let obj;
        const pathList = fieldKey.split('.');
        if (pathList && pathList.length > 1) {
            for (let i = 0; i < pathList.length; i++) {
                if (i === 0) {
                    obj = this.therapyAdmissionsSnapshot[foundIndex].documentation.evaluation[section][pathList[i]];
                } else if (i === pathList.length - 1) {
                    previousValue = getNestedObjectValue(obj, pathList[i]);
                }
            }
        } else {
            previousValue = this.therapyAdmissionsSnapshot[foundIndex].documentation.evaluation[section][fieldKey];
        }
        const changeInstance = {
            section,
            fieldName: '',
            fieldKey,
            currentValue: currentValue,
            previousValue,
        };
        const foundExisting = this.dataForEdit.findIndex((x) => x.fieldKey == fieldKey);
        if (foundExisting !== -1) {
            this.dataForEdit[foundExisting] = changeInstance;
            if (
                !Array.isArray(this.dataForEdit[foundExisting].currentValue) &&
                this.dataForEdit[foundExisting].currentValue === this.dataForEdit[foundExisting].previousValue
            ) {
                this.dataForEdit.splice(foundExisting, 1);
            } else if (Array.isArray(this.dataForEdit[foundExisting].currentValue)) {
                const comparison = _compareArrays(
                    this.dataForEdit[foundExisting].currentValue,
                    this.dataForEdit[foundExisting].previousValue
                );
                if (
                    Object.keys(comparison).every(
                        (x) =>
                            (comparison[x].description && fieldKey !== 'treatmentDiagnosis') ||
                            (fieldKey === 'treatmentDiagnosis' && comparison[x].description && comparison[x].onsetDate)
                    )
                )
                    this.dataForEdit.splice(foundExisting, 1);
            }
        } else {
            if (changeInstance.currentValue !== changeInstance.previousValue) this.dataForEdit.push(changeInstance);
        }
        allTherapyAdmissions[foundIndex] = currentAdmission;
        this.store.dispatch(
            therapyadmissionChangedAction({
                therapyadmission: allTherapyAdmissions,
            })
        );
        console.log('pushing the editor data...', this.dataForEdit);
    }

    checkMDDXChange(
        section: string,
        fieldKey: string,
        fieldValue: string | object,
        type: string,
        allTherapyAdmissions: TherapyAdmission[],
        currentAdmission: TherapyAdmission
    ) {
        const foundIndex = allTherapyAdmissions.findIndex((ta) => ta._id === currentAdmission._id);
        const currentValue = { icd10Codes: fieldValue };
        const previousValue = this.therapyAdmissionsSnapshot[foundIndex].documentation.evaluation[section][fieldKey];
        const changeInstance = {
            section,
            fieldName: '',
            fieldKey,
            currentValue: currentValue,
            previousValue,
        };
        this.dataForEdit.push(changeInstance);
        const existingMDDXIndex = this.dataForEdit.findIndex((x) => x.fieldKey === 'medicalDiagnosis');
        if (existingMDDXIndex !== -1) {
            if (Array.isArray(this.dataForEdit[existingMDDXIndex]?.currentValue.icd10Codes)) {
                const comparison = _compareArrays(
                    this.dataForEdit[existingMDDXIndex].currentValue.icd10Codes,
                    this.dataForEdit[existingMDDXIndex].previousValue.icd10Codes
                );
                if (
                    Object.keys(comparison).every(
                        (x) => comparison[x].description && comparison[x].isPrimary && comparison[x].clinicalCategory
                    )
                ) {
                    this.dataForEdit.splice(existingMDDXIndex, 1);
                }
            }
        }
    }

    editEvaluation(id, body, queryParams?: EvaluationQueryParams): Observable<EvaluationDocument> {
        if (
            queryParams?.facilityId !== undefined &&
            queryParams?.integratedPatientId !== undefined &&
            queryParams?.integratedOrganizationName !== undefined &&
            queryParams?.integratedOrganizationId !== undefined &&
            queryParams?.therapyDiscipline === undefined &&
            queryParams?.patientName === undefined
        )
            return this.http.patch(
                `${baseUrl}/evaluation/edit/${id}?facilityId=${queryParams.facilityId}&integratedPatientId=${queryParams.integratedPatientId}&integratedOrganizationName=${queryParams.integratedOrganizationName}&integratedOrganizationId=${queryParams.integratedOrganizationId}`,
                body
            ) as Observable<EvaluationDocument>;
        else if (queryParams?.therapyDiscipline !== undefined && queryParams?.patientName !== undefined)
            return this.http.patch(
                `${baseUrl}/evaluation/edit/${id}?facilityId=${queryParams.facilityId}&integratedPatientId=${queryParams.integratedPatientId}&integratedOrganizationName=${queryParams.integratedOrganizationName}&therapyDiscipline=${queryParams.therapyDiscipline}&patientName=${queryParams.patientName}`,
                body
            ) as Observable<EvaluationDocument>;
        else return this.http.patch(`${baseUrl}/evaluation/edit/${id}`, body) as Observable<EvaluationDocument>;
    }

    getEvaluationEditHistory(id: string): Observable<EvalChangeHistory[]> {
        return this.http.get(`${baseUrl}/evaluation/history/${id}`).pipe(
            map((x: any) => {
                return x.data;
            })
        ) as Observable<EvalChangeHistory[]>;
    }

    medicalDiagnosisUpdate(
        currentAdmission: TherapyAdmission,
        allTherapyAdmissions: TherapyAdmission[],
        facilityAdmissionData: FacilityAdmission
    ) {
        return new Promise((resolve, ) => {
            this.facilityAdmissionService
                .updateMedicalDiagnosis({
                    facilityAdmissionId: facilityAdmissionData.id,
                    icd10Codes: facilityAdmissionData.medicalDiagnosis?.icd10Codes,
                })
                .subscribe(() => {
                    // Once difference in MD DX detected update the medical diagnosis and update the state of T.A./F.A. with M.D. from F.A.
                    currentAdmission.id = currentAdmission._id ? currentAdmission._id : currentAdmission.id;
                    currentAdmission.icd10Codes = facilityAdmissionData.medicalDiagnosis?.icd10Codes;
                    const updatedFacilityAdmission = cloneDeep(facilityAdmissionData);
                    if (!updatedFacilityAdmission.medicalDiagnosis) {
                        updatedFacilityAdmission.medicalDiagnosis = {
                            icd10Codes: facilityAdmissionData.medicalDiagnosis?.icd10Codes,
                        };
                    } else {
                        updatedFacilityAdmission.medicalDiagnosis.icd10Codes =
                            facilityAdmissionData.medicalDiagnosis?.icd10Codes;
                    }
                    this.storeService.setFacilityAdmission(updatedFacilityAdmission);

                    const foundIndex = allTherapyAdmissions.findIndex((ta) => ta._id === currentAdmission.id);
                    const updatedCurrentAdmission = { ...currentAdmission };
                    updatedCurrentAdmission.documentation.evaluation.medicalReview.medicalDiagnosis['icd10Codes'] =
                        facilityAdmissionData.medicalDiagnosis?.icd10Codes;
                    allTherapyAdmissions[foundIndex] = updatedCurrentAdmission;
                    this.store.dispatch(
                        therapyadmissionChangedAction({
                            therapyadmission: allTherapyAdmissions,
                        })
                    );
                    resolve(true);
                });
        });
    }

    getEvaluationPDF(): any {
        return this.evaluationPDF;
    }

    setEvaluationPDF(value): any {
        this.evaluationPDF = value;
    }

    setEvalType(isEvalCode: boolean, therapyDiscipline): void {
        this.clinicalCategoriesService
            .cptCodesSearch('', therapyDiscipline, 1, isEvalCode, 100)
            .pipe(take(1))
            .subscribe((codes: any) => {
                if (codes.data) {
                    const evalTypes = codes.data.map((d) => `${d.cptCode} - ${d.description}`);
                    this.EvalTypeSubject.next(evalTypes);
                }
            });
    }
    delete(id: string) {
        return this.http.delete(`${baseUrl}/evaluation/delete/with_cleanup/${id}`, {
            withCredentials: true,
        });
    }
}
