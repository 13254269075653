// Author: T4professor

import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
    selector: 'app-pill-cell-renderer',
    templateUrl: './pill-cell-renderer.component.html',
    styleUrls: ['./pill-cell-renderer.component.scss'],
    providers: [],
})
export class PillCellRendererComponent implements ICellRendererAngularComp {
    params: any;
    agInit(params): void {
        this.params = params;
    }

    refresh(): boolean {
        return true;
    }
}
