import { Component, Input, Output, EventEmitter, OnChanges, OnDestroy } from '@angular/core';
import { TherapyAdmission, CptCodes } from '@app/interfaces';
import { ClinicalCategoriesService } from '@app/services';
import { RowClassRules, GridApi } from 'ag-grid-community';
import * as _ from 'lodash';

import { ActionsCellRendererComponent } from '@app/data-grid/cell-renderers/actions-cell-renderer/actions-cell-renderer.component';
import { GridActionsService } from '@app/data-grid/services/grid-actions.service';
import { GridRowAction } from '@app/data-grid/interfaces/grid-row-action';
import { Subject, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { cloneDeep } from 'lodash';
import { findTherapyCaseByParamId } from '@app/helpers/utils/therapy-case.utils';

@Component({
    selector: 'app-cpt-codes',
    templateUrl: './cpt-codes.component.html',
    styleUrls: ['./cpt-codes.component.scss'],
})
export class CptCodesComponent implements OnChanges, OnDestroy {
    @Input() cptCodesInput: CptCodes[];
    @Input() heading = 'Skilled Services(CPT codes)';
    @Input() isReadOnly = false;
    @Output() cptCodesEvent: EventEmitter<CptCodes[]> = new EventEmitter<CptCodes[]>();
    @Output() cptCodesSave: EventEmitter<boolean> = new EventEmitter();
    state: 'zero' | 'one' = 'zero';
    loading = false;
    isDone = false;
    columns = [
        { headerName: 'Code', field: 'cptCode', resizable: true, width: 10 },
        { headerName: 'Description', field: 'description', resizable: true },
        {
            headerName: 'Actions',
            field: 'actions',
            type: 'rightAligned',
            resizable: true,
            cellRenderer: ActionsCellRendererComponent,
            cellRendererParams: {
                delete: true,
                gridName: 'cptCodes',
            },
        },
    ];
    editColumns = [
        { headerName: 'Code', field: 'cptCode', resizable: true },
        { headerName: 'Description', field: 'description', resizable: true },
    ];

    data: any[] = [];
    // Ag Grid
    selectedData: any[] = [];
    // Row class rules to display
    rowClassRules: RowClassRules = {
        'bg-primary-50': function (params) {
            return this.selectedData.findIndex((object) => object.cptCode === params.data.cptCode) !== -1;
        }.bind(this),
        'text-secondary': function (params) {
            return this.selectedData.findIndex((object) => object.cptCode === params.data.cptCode) !== -1;
        }.bind(this),
    };
    gridApi: GridApi;
    private readonly onDestroy = new Subject<void>();
    currentAdmission: TherapyAdmission;
    cptCodesData = [];
    public therapyDiscipline;
    public note;
    therapyAdmissions$: any;

    private subscriptions: Subscription[] = [];

    constructor(
        private clinicalCategoryService: ClinicalCategoriesService,
        private gridActionService: GridActionsService,
        private store: Store<{ therapyAdmission: TherapyAdmission[] }>,
        private route: ActivatedRoute
    ) {
        gridActionService.action.subscribe((params) => {
            if (params.actionType === 'delete' && params.gridName === 'cptCodes') {
                this.deleteSelectedRowItem(params);
            }
        });
        this.route.queryParamMap.subscribe({
            next: (params) => {
                this.therapyDiscipline = params.get('discipline');
                this.note = params.get('note');
            },
        });
        this.therapyAdmissions$ = this.store.select('therapyAdmission');
        this.subscriptions.push(
            this.therapyAdmissions$.subscribe((data) => {
                const queryString = this.route.snapshot.queryParams;
                this.currentAdmission = findTherapyCaseByParamId(data, queryString['therapy-case']);
                this.therapyDiscipline ||= this.currentAdmission?.therapyDiscipline;
                this.therapyDiscipline ||= 'PT';
                this.currentAdmission = cloneDeep(this.currentAdmission);

                if (this.isReadOnly) {
                    this.setReadOnlyData();
                }
            })
        );
    }

    ngOnChanges(): void {
        if (this.cptCodesInput?.length > 0) {
            this.selectedData = this.cptCodesInput;
            this.data = this.cptCodesInput;
            this.isDone = true;
            this.state = 'one';
        }
        if (this.isReadOnly) {
            this.setReadOnlyData();
        }
    }
    setState(value: 'zero' | 'one') {
        this.state = value;
    }
    cptCodesSearch(text: string) {
        this.loading = true;
        this.clinicalCategoryService.cptCodesSearch(text, this.therapyDiscipline).subscribe(
            (data) => {
                this.loading = false;
                this.data = data.map((item) => ({ id: item.id, cptCode: item.cptCode, description: item.description }));
            },
            () => {
                this.loading = false;
                this.data = [];
            }
        );
    }
    // For Ag Grid
    // Working with clinical categories
    addSelectedItem(event: any) {
        const data = event.data;
        const index = this.selectedData.findIndex((object) => object.cptCode === data.cptCode);
        if (index == -1) {
            data.date = '';
            this.selectedData = [...this.selectedData, data];
        } else if (index >= 0) {
            this.selectedData = this.selectedData.filter((item) => item.cptCode !== data.cptCode);
        }
        this.cptCodesEvent.emit(this.selectedData);
    }

    removeSelectedItem(event: any) {
        this.selectedData = [...this.selectedData.filter((elem) => elem.cptCode !== event?.cptCode)];
        this.cptCodesEvent.emit(this.selectedData);

        // clear list ONLY when search 'x' is clicked
        if (!event) {
            this.data = [];
        }
    }

    setLoading(value: boolean) {
        this.loading = value;
    }
    toggleIsDone(): void {
        this.isDone = !this.isDone;
        if (this.isDone === true) {
            this.gridApi.setColumnDefs(this.columns);
        } else {
            this.gridApi.setColumnDefs(this.editColumns);
        }
        this.gridApi.sizeColumnsToFit();
        this.cptCodesEvent.emit(this.selectedData);
        if (this.isDone === true) this.cptCodesSave.emit(true);
    }
    // Grid API functions
    initGrid(event: any) {
        this.gridApi = event.api;
    }
    redrawRows() {
        this.gridApi.redrawRows();
    }
    // Grid renderer functions
    setRendererDate(param: any) {
        this.selectedData = this.selectedData.map((item) => {
            const deepItem = _.cloneDeep(item);
            if (deepItem.cptCode === param.data.cptCode) {
                deepItem.date = param.value;
            }
            return deepItem;
        });
        this.cptCodesEvent.emit(this.selectedData);
    }
    deleteSelectedRowItem(params: GridRowAction) {
        this.selectedData = this.selectedData.filter((item) => item.cptCode !== params.data.cptCode);
        this.cptCodesEvent.emit(this.selectedData);
        this.cptCodesSave.emit(true);
    }
    setReadOnlyData() {
        //this.medicalDiagnosisInput = this.medicalDiagnosis;
        if (this.cptCodesData?.length > 0) {
            this.isDone = true;
            this.state = 'one';
        }
    }
    ngOnDestroy(): void {
        this.onDestroy.next();
        this.subscriptions.map((sub) => sub.unsubscribe());
    }
}
