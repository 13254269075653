import { createReducer, on } from '@ngrx/store';
import { tabChangedAction, tabsResetAction } from './../actions/tabs.actions';

import { addPatientTabInitialState } from '@app/store/selectors';
export const tabsReducer = createReducer(
    addPatientTabInitialState,
    on(tabChangedAction, (state, { selectedTab }) => ({
        selectedTab: selectedTab,
    })),

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    on(tabsResetAction, (state) => ({
        ...addPatientTabInitialState,
    }))
);
