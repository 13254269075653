/* eslint-disable spellcheck/spell-checker */
import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { DIALOG_DATA } from '@app/helpers/dialog-tokens';
import { DialogRef } from '@app/services';

@Component({
    selector: 'app-contract-view-model',
    templateUrl: './contract-view-model.component.html',
    styleUrls: ['./contract-view-model.component.scss'],
})
export class ContractViewModelComponent implements OnInit {
    mppr: string;
    asstReduction: string;
    billableDischarge: string;
    controlsArray = [];
    keyValues = [];

    tableHeaders: string[][];
    constructor(@Inject(DIALOG_DATA) public data, private ref: DialogRef, private datePipe: DatePipe) {}

    ngOnInit(): void {
        if (this.data) {
            this.billableDischarge = this.data.billableDischarge ? 'Yes' : 'No';

            this.data.dateFrom =
                this.data.dateFrom && this.data.dateFrom != 'N/A'
                    ? this.transformDate(new Date(this.data.dateFrom))
                    : 'N/A';
            this.data.dateThrough =
                this.data.dateThrough && this.data.dateThrough != 'N/A'
                    ? this.transformDate(new Date(this.data.dateThrough))
                    : 'N/A';
            this.createControlsArray(this.data.controlsArray);
            const maxColumns = 4; // Set the maximum number of columns per row
            const headers = [];
            const values = [];
            const controls = this.controlsArray;
            if (this.data.contractType) {
                headers.push(['Contract Type']);
                values.push(Object.values([this.data.contractType]));
            }
            if (this.data.contractVariant) {
                headers.push(['Contract Variant']);
                values.push(Object.values([this.data.contractVariant]));
            }
            Object.keys(this.controlsArray).forEach(function (key) {
                headers.push(Object.keys(controls[key]));
                values.push(Object.values(controls[key]));
            });
            const rows = [];

            for (let i = 0; i < headers.length; i += maxColumns) {
                rows.push({ keys: headers.slice(i, i + maxColumns), values: values.slice(i, i + maxColumns) });
            }
            this.tableHeaders = rows;
            this.buildData();
        }
    }
    buildData() {
        if (this.data.contractType == 'Level Contract') {
            const keyValues = [];
            this.data.levels.map(function (level, index) {
                keyValues.push({ key: `Level ${index + 1}`, value: typeof level === 'object' ? level.level : level });
            });
            this.keyValues = [
                { key: 'Contract Type', value: this.data.contractType },
                { key: 'Contract Variant', value: this.data.contractVariant },
            ];
            this.keyValues.push(...keyValues);
        } else if (this.data.contractVariant == 'PDPM - Therapy Component Individual') {
            this.keyValues = [
                { key: 'Contract Type', value: this.data.contractType },
                { key: 'Contract Variant', value: this.data.contractVariant },
            ];
        } else {
            this.keyValues = this.tableHeaders.map((row) => {
                const keys: any = row['keys'];
                const values: any = row['values'];
                return keys.map((x, index) => {
                    if (x[0].includes('percentage Hipps Code')) {
                        return { key: 'Hipps Code', value: values[index][0] };
                    }
                    if (x[0].includes('Cmg Percentage')) {
                        return { key: x[0].split('Percentage')[0], value: values[index][0] };
                    }
                    return { key: x[0], value: values[index][0] };
                });
            });
            if (this.data.contractType == 'CPT Code Rates') {
                this.mppr = this.data.controlsArray[2]?.mpprReduction[0]?.isChecked ? 'Yes' : 'No';
                this.asstReduction = this.data.controlsArray[1]?.assistantReduction[0]?.isChecked ? 'Yes' : 'No';
                this.keyValues = [
                    { key: 'Contract Type', value: 'CPT Code' },
                    { key: 'CPT Code % contracted fee', value: this.data.controlsArray[0].cptCodeFee },
                ];
            }
        }

        this.keyValues = this.keyValues.flat();
    }
    mapKey(key: string) {
        if (key && key == 'alldisciplines') {
            return 'all disciplines';
        }
        if (key && key.startsWith('rate')) {
            return key.split('rate')[1];
        }
        return key;
    }
    private transformDate = (date) => {
        if (!date) {
            return '';
        }
        return this.datePipe.transform(new Date(date), 'MM/dd/yyyy');
    };

    createControlsArray(array: any) {
        let lastControlKey;
        array.forEach((control) => {
            for (const key in control) {
                if (Array.isArray(control[key])) {
                    control[key].forEach((value) => {
                        const newSet = {};
                        Object.keys(value).forEach((key) => {
                            const newKey = lastControlKey + '    ' + key.substring(0, 2);
                            newSet[newKey] = value[key];
                        });
                        this.controlsArray.push(newSet);
                    });
                } else {
                    lastControlKey = [key.split('-')[0]];
                    this.controlsArray.push({ [key.split('-')[0].replace(/([a-z])([A-Z])/g, '$1 $2')]: control[key] });
                }
            }
        });
    }

    getKey(obj: any): string {
        const key = Object.keys(obj)[0];
        return (key.match(/[a-zA-Z]+/g) || []).join('');
    }
    getOriginalKey(obj: any): string {
        return Object.keys(obj)[0];
    }
    get allKeys(): string[] {
        return this.controlsArray.reduce((keys, obj) => {
            return keys.concat(Object.keys(obj));
        }, []);
    }

    onClose() {
        this.ref.close();
    }
}
