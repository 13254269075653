import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { datadogRum } from '@datadog/browser-rum';

datadogRum.init({
    applicationId: environment.ddApplicationId,
    clientToken: environment.ddClientToken,
    site: environment.ddSite,
    service: environment.ddService,
    env: environment.ddEnv,
    sessionSampleRate: environment.ddSampleRate,
    sessionReplaySampleRate: environment.ddReplaySampleRate,
    trackUserInteractions: environment.ddTrackUserInteractions,
    trackResources: environment.ddTrackResources,
    trackLongTasks: environment.ddTrackLongTasks,
    allowedTracingUrls: environment.ddAllowedTracingUrls,
});

if (environment.production) {
    enableProdMode();
    if (window) {
        window.console.log =
            window.console.warn =
            window.console.info =
                function () {
                    // Don't log anything.
                };
    }
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
