import { Injectable } from '@angular/core';
import { CanDeactivate, UrlTree } from '@angular/router';
import { AddPatientLayoutComponent } from '@app/modules/add-patient/add-patient-layout/add-patient-layout.component';
import { AppService, ModalService } from '@app/services';
import { BackConfirmationDialogComponent } from '@app/shared/go-back/back-confirmation-dialog/back-confirmation-dialog.component';
import { PatientState } from '@app/store/store.interface';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ROUTER_UTILS } from '@app/helpers';

@Injectable({
    providedIn: 'root',
})
export class AdmissionFormGuard implements CanDeactivate<AddPatientLayoutComponent> {
    patient$: Observable<any>;
    editing: boolean;
    targetRoute: string;
    constructor(
        private store: Store<{
            patient: PatientState;
        }>,
        private modal: ModalService,
        private appService: AppService
    ) {
        this.appService.targetRoute.subscribe((val) => {
            this.targetRoute = val;
        });
        this.patient$ = this.store.pipe(select('patient'));
        this.patient$.subscribe((patient) => {
            if (patient.id) {
                this.editing = true;
            } else {
                this.editing = false;
            }
        });
    }
    canDeactivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (
            this.editing &&
            !this.targetRoute.includes(ROUTER_UTILS.config.therapyAdmission.show.root) &&
            !this.targetRoute.includes(ROUTER_UTILS.config.therapyAdmission.add.root) &&
            !this.targetRoute.includes(ROUTER_UTILS.config.patients.pcc.root) &&
            !this.targetRoute.includes(ROUTER_UTILS.config.payorInformation.insuranceCoverage.add.root) &&
            !this.targetRoute.includes('payor-information')
        ) {
            const dialogRef = this.modal.open(BackConfirmationDialogComponent, {
                data: { heading: 'Confirmation' },
                width: '520px',
            });
            return dialogRef.afterClosed();
        } else {
            return true;
        }
    }
}
