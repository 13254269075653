/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
    selector: 'app-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.scss'],
})
export class SelectComponent implements OnInit, ControlValueAccessor {
    @Input('options') selectOptions = [];
    @Input() label: string;
    @Input() placeholder = 'Choose one Option';
    @Input() backgroundClass: string;
    @Input() foregroundClass: string;
    @Input() class: string;
    @Input() labelClass = '';
    @Input() labelFont = '';
    @Input() notDisabled = '';
    @Input() inputClass = '';
    @Input() formGroupClass = '';
    @Input() formGroupStyle = '';
    @Input() selectStyle = '';
    @Input() labelLeft = false;
    @Input() selected = '';
    @Input() customSelect = false;
    @Input() small = false;
    @Input() selectWithContent = false;
    @Input() rightAlignToggle = false;
    @Input() required: boolean;
    @Input() isPlaceholderDisabled = true;
    @Input() selectedValue = '';
    @Input() contentInfo: {
        type: string;
        text?: string;
        options?: any[];
        selectedOption?: string;
    };
    @Input() disabled: boolean;
    @Output() optionChanged = new EventEmitter();
    @Output() selectWithContentValueChange = new EventEmitter();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    propagateChange = (_: any) => {};
    onTouched = () => {};

    value: any = '';
    constructor(public ngControl: NgControl) {
        ngControl.valueAccessor = this;
        if (this.backgroundClass === undefined) this.backgroundClass = '';
        if (this.foregroundClass === undefined) this.foregroundClass = '';
    }

    ngOnInit(): void {
        if (!this.value) {
            this.value = '';
        }
    }

    get isRequired(): boolean {
        const control: AbstractControl = this.ngControl.control;
        if (control?.validator === null) return false;
        return control?.validator(control)?.required ?? false;
    }

    get hasError(): boolean {
        const control: AbstractControl = this.ngControl.control;
        if (control && (control.dirty || control.touched) && control.invalid) return true;
        return false;
    }

    writeValue(obj: any): void {
        if (obj) this.value = obj;
        else this.value = '';
    }

    optionSelect(event: any) {
        let value: any;
        event.target ? (value = event.target.value) : (value = event);
        this.selected = value;
        this.value = value;
        this.propagateChange(value);
        this.optionChanged.emit(value);
    }
    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    contentValueChange() {
        this.selectWithContentValueChange.emit(this.contentInfo.selectedOption);
    }
}
