import { Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-functional-deficit-note',
  templateUrl: './functional-deficit-note.component.html',
  styleUrls: ['./functional-deficit-note.component.scss']
})
export class FunctionalDeficitNoteComponent implements ICellRendererAngularComp {
  params;
  tooltip = false;
  currentTooltip = null;

  @ViewChild('tooltipJustification') tooltipJustificationElement: ElementRef;

  agInit(params: ICellRendererParams): void {
    this.params = params;
}
refresh(params: any): boolean {
  this.params = params;
  return true;
}
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor(private renderer: Renderer2) { }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  ngOnInit(): void {}
  onMouseEnter(event) {
    this.currentTooltip = this.tooltipJustificationElement;
    this.tooltip = true;
    this.currentTooltip.nativeElement.style.top = event.pageY + 10 + 'px';
    this.currentTooltip.nativeElement.style.left = event.pageX - 250 + 'px';
    this.renderer.insertBefore(document.body, this.currentTooltip.nativeElement, document.body.firstChild);
    this.currentTooltip.nativeElement.style.display = 'block';
}

onMouseLeave() {
  
    this.currentTooltip = this.tooltipJustificationElement;
    this.tooltip = false;
    this.currentTooltip.nativeElement.style.display = 'none';
    this.renderer.removeChild(document.body, this.currentTooltip.nativeElement);
}
}
