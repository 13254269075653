import { Injectable } from '@angular/core';
import { TabItem } from '@app/interfaces';
import { USER_TYPES } from '@app/models';
import { ADD_USER_TABS, USER_TABS_INDEX } from '@app/modules/admin/user-management/constants';
import { cloneDeep } from 'lodash';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UserManagementService {
    userPersonalInfo = new BehaviorSubject({})
    userFacilityRoles = new BehaviorSubject([])
    isSameRoleForAllFacilities = new BehaviorSubject(false)
    userAdditionalInfo = new BehaviorSubject({})
    physicianAdditionalInfo = new BehaviorSubject({})
    userType = new BehaviorSubject(USER_TYPES.NONE)
    isConfirmedPhysician = new BehaviorSubject(false)
    addUserTabs = new BehaviorSubject(cloneDeep(ADD_USER_TABS))

    formInvalidStatuses = [true, true, true]
    formInvalidStatusesSub = new BehaviorSubject(this.formInvalidStatuses);
    isSubmitting = new BehaviorSubject(false)


    initializeAllFormStates() {
        this.userAdditionalInfo.next({})
        this.physicianAdditionalInfo.next({})
        this.userPersonalInfo.next({})
        this.userFacilityRoles.next([])
        this.isSameRoleForAllFacilities.next(false)
        this.userType.next(USER_TYPES.NONE)
        this.isConfirmedPhysician.next(false)
        this.addUserTabs.next(cloneDeep(ADD_USER_TABS))
        this.isSubmitting.next(false)

    }

    formStatusChanged(formIndex, isInValid) {
        const previousFormStatuses = this.formInvalidStatuses.join('')
        this.formInvalidStatuses[formIndex] = isInValid
        this.formInvalidStatusesSub.next(this.formInvalidStatuses)
        const currentFormStatuses = this.formInvalidStatuses.join('')
        if (previousFormStatuses !== currentFormStatuses) this.updateTabs()
    }

    updateTabs() {
        const currentTabs = cloneDeep(this.addUserTabs.value)

        if (this.formInvalidStatuses[USER_TABS_INDEX.PERSONAL_INFO]) {
            currentTabs[USER_TABS_INDEX.FACILITY_ROLES].unauthorized = true
            currentTabs[USER_TABS_INDEX.ADDITIONAL_DETAILS].unauthorized = true
        } else {
            currentTabs[USER_TABS_INDEX.FACILITY_ROLES].unauthorized = false
            currentTabs[USER_TABS_INDEX.ADDITIONAL_DETAILS].unauthorized = true
            this.updateDoneStatusOfTab(currentTabs, USER_TABS_INDEX.PERSONAL_INFO, true)
        }

        if (this.formInvalidStatuses[USER_TABS_INDEX.FACILITY_ROLES]) {
            currentTabs[USER_TABS_INDEX.ADDITIONAL_DETAILS].unauthorized = true
        } else {
            this.updateDoneStatusOfTab(currentTabs, USER_TABS_INDEX.FACILITY_ROLES, true)
            if ([USER_TYPES.THERAPIST, USER_TYPES.PHYSICIAN].includes(this.userType.value) && !this.formInvalidStatuses[USER_TABS_INDEX.PERSONAL_INFO]) currentTabs[USER_TABS_INDEX.ADDITIONAL_DETAILS].unauthorized = false
        }

        this.addUserTabs.next(currentTabs)

    }

    private updateDoneStatusOfTab(tabs: TabItem[], index, isDone) {
        tabs[index].isDone = isDone
        tabs[index].underline = isDone
    }



}
