import { Patient } from '@app/models';
import { MedicalDiagnosis, TherapyAdmission } from '.';

export interface FacilityAdmission {
    id: string;
    admitDate: string;
    admittedFrom: string;
    admissionType: string;
    lastAcute: string;
    MRN: string;
    admissionID: string;
    roomNumber: string;
    capOT: string;
    capPT: string;
    therapyTeam: string;
    patient?: string | Patient;
    therapyAdmissions?: TherapyAdmission[];
    _id?: string;
    filesAndDocuments?: FilesAndDocuments[];
    medicalDiagnosis?: { icd10Codes: MedicalDiagnosis[] };
    patientDischarge?: any;
    anticipatedDischarge?: any;
    facility?: any;
    facilityId?: string;
    mds?: any;
    isAnIntegratedPatient: boolean;
    attendingPhysician: AttendingPhysician;
    admissionNo?: string;
    createdAt?: Date;
    updatedAt?: Date;
}

export interface IPastFacilityAdmission extends FacilityAdmission {
    therapyAdmission: TherapyAdmission[];
    facilityAdmission_id: string;
    totalTherapyAdmissions?: number;
}

export interface FilesAndDocuments {
    _id?: string;
    id: string;
    file: string;
    description: string;
    date: string;
    facilityAdmission: string;
    therapyAdmission: string;
    therapyAdmissionId: string;
    documentType: string;
    documentDate: Date;
}

export interface AttendingPhysician {
    NPI: string;
    firstName: string;
    lastName: string;
    title: string;
}
