import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SHARED_COMPONENTS } from '../shared';
import { SHARED_DIRECTIVES } from '../Directives';
import { ToastModule } from './toaster/toaster.module';
import { IMaskModule } from 'angular-imask';
import { AgGridModule } from 'ag-grid-angular';
import { GoBackComponent } from './go-back/go-back.component';

import { DatePipe } from '@angular/common';
import { SHARED_PIPES } from '@app/pipes';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BackConfirmationDialogComponent } from './go-back/back-confirmation-dialog/back-confirmation-dialog.component';
import { DataGridModule } from '@app/data-grid/data-grid.module';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxEchartsModule } from 'ngx-echarts';
import { AddItemTableComponent } from './add-item-table/add-item-table.component';
import { GenericDeletionModalComponent } from './generic-deletion-modal/generic-deletion-modal.component';
import { PriorHospitalizationStaysComponent } from './prior-hospitalization-stays/prior-hospitalization-stays.component';
import { MinutesModalComponent } from './documentation/minutes-modal/minutes-modal.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { CodeViewModalComponent } from './code-view-modal/code-view-modal.component';
import { ContractViewModelComponent } from './contract-view-model/contract-view-model.component';
import { LegendGoalComponent } from './charts/goals-chart/legend/legend-goal/legend-goal.component';
import { LegendComponent } from './charts/goals-chart/legend/legend.component';
import { ParagraphModalComponent } from './paragraph-modal/paragraph-modal.component';
import { NInputsComponent } from './n-inputs/n-inputs.component';
import { CautionModalComponent } from './caution-modal/caution-modal.component';
import { ConflictModalComponent } from './conflict-modal/conflict-modal.component';
import { OBRAReportComponent } from './obra-report/obra-report.component';
import { FacilitySelectionCombinedTableComponent } from './facility-selection-combined-table/facility-selection-combined-table.component';
import { TabsComponent } from './tabs/tabs.component';
import { TherapyDisciplineBarCardModule } from './therapy-discipline-bar-card/therapy-discipline-bar-card.module';
import { WarningConfirmationDialogComponent } from './warning-confirmation-dialog/warning-confirmation-dialog.component';
import { ConfirmationModalComponent } from './modals/v2/confirmation-modal/confirmation-modal.component';
import { CustomTooltipComponent } from './custom-tooltip/custom-tooltip.component';

@NgModule({
    declarations: [
        SHARED_DIRECTIVES,
        SHARED_COMPONENTS,
        SHARED_PIPES,
        BackConfirmationDialogComponent,
        AddItemTableComponent,
        GenericDeletionModalComponent,
        PriorHospitalizationStaysComponent,
        MinutesModalComponent,
        CodeViewModalComponent,
        ContractViewModelComponent,
        LegendGoalComponent,
        LegendComponent,
        ParagraphModalComponent,
        NInputsComponent,
        CautionModalComponent,
        ConflictModalComponent,
        OBRAReportComponent,
        FacilitySelectionCombinedTableComponent,
        TabsComponent,
        WarningConfirmationDialogComponent,
        //PlanConfirmationModalComponent,
        ConfirmationModalComponent,
        CustomTooltipComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        ToastModule.forRoot(),
        IMaskModule,
        AgGridModule.withComponents([]),
        NgbModule,
        DataGridModule,
        NgbTooltipModule,
        NgxSkeletonLoaderModule,
        NgxEchartsModule.forRoot({
            echarts: () => import('echarts'),
        }),
        TherapyDisciplineBarCardModule,
    ],
    exports: [
        SHARED_COMPONENTS,
        SHARED_DIRECTIVES,
        SHARED_PIPES,
        NgbModule,
        DataGridModule,
        ReactiveFormsModule,
        NgbTooltipModule,
        TherapyDisciplineBarCardModule,
    ],
    providers: [GoBackComponent, DatePipe],
})
export class SharedModule {}
