// Author: T4professor

import { Component } from '@angular/core';
import { GridActionsService } from '@app/data-grid/services/grid-actions.service';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
    selector: 'app-select-cell-renderer',
    templateUrl: './select-cell-renderer.component.html',
    styleUrls: ['./select-cell-renderer.component.scss'],
    providers: [{ provide: NgbDropdown }],
})
export class SelectCellRendererComponent implements ICellRendererAngularComp {
    params: any;
    label: string;
    options = [];
    constructor(private gridActionService: GridActionsService) {}
    agInit(params): void {
        console.log('Params: ::::', params);
        this.params = params;
        this.options = params.values;
    }

    refresh(): boolean {
        return true;
    }
    dropdownSelect(option: any, event: any) {
        event.stopPropagation();
        this.params.value = option;
        const { data, rowIndex, gridName } = this.params;
        this.gridActionService.setSelectedAction(option, data, rowIndex, gridName ? gridName : '');
    }
}
