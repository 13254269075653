import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { _compareArrays, getNestedObjectValue } from '@app/helpers/utils';
import { DCChangeHistory, DischargeNote, TherapyAdmission } from '@app/interfaces';
import { therapyadmissionChangedAction } from '@app/store';
import { environment } from '@environments/environment';
import { Store } from '@ngrx/store';
import { Observable, Subject, map } from 'rxjs';
const baseUrl = `${environment.apiUrl}/dischargeNote`;

@Injectable({
    providedIn: 'root',
})
export class DischargeNoteService {
    dataForEdit = [];
    therapyAdmissionsSnapshot: TherapyAdmission[];
    documentEditedSuccessfully = false;
    isEditModeActive = false;
    dischargeNotePDF = '';
    public loadedDischargeNote: Subject<number> = new Subject<number>();

    constructor(
        private http: HttpClient,
        private store: Store<{
            therapyAdmission: TherapyAdmission[];
        }>
    ) {}

    prepareDataForEdit(
        section: string,
        fieldKey: string,
        fieldValue: string | object,
        type: string,
        allTherapyAdmissions: TherapyAdmission[],
        currentAdmission: TherapyAdmission
    ) {
        const foundIndex = allTherapyAdmissions.findIndex(
            (ta) => ta._id === currentAdmission._id
        );
        let currentValue;
        if (type == 'textBox') {
            currentValue = fieldValue;
        } else {
            let arr = '';
            if (section) arr = currentAdmission.documentation.dischargeNote[section][fieldKey];
            else arr = currentAdmission.documentation.dischargeNote[fieldKey];
            currentValue = arr;
        }
        let previousValue;
        let obj;
        const pathList = fieldKey.split('.');
        if (pathList && pathList.length > 1) {
            for (let i = 0; i < pathList.length; i++) {
                if (i === 0) {
                    if (section)
                        obj =
                            this.therapyAdmissionsSnapshot[foundIndex].documentation.dischargeNote[section][
                                pathList[i]
                            ];
                    else obj = this.therapyAdmissionsSnapshot[foundIndex].documentation.dischargeNote[pathList[i]];
                } else if (i === pathList.length - 1) {
                    previousValue = getNestedObjectValue(obj, pathList[i]);
                }
            }
        } else {
            if (section)
                previousValue =
                    this.therapyAdmissionsSnapshot[foundIndex].documentation.dischargeNote[section][fieldKey];
            else previousValue = this.therapyAdmissionsSnapshot[foundIndex].documentation.dischargeNote[fieldKey];
        }
        const changeInstance = {
            section,
            fieldName: '',
            fieldKey,
            currentValue: currentValue,
            previousValue,
        };
        const foundExisting = this.dataForEdit.findIndex((x) => x.fieldKey == fieldKey);
        if (foundExisting !== -1) {
            this.dataForEdit[foundExisting] = changeInstance;
            if (
                !Array.isArray(this.dataForEdit[foundExisting].currentValue) &&
                this.dataForEdit[foundExisting].currentValue === this.dataForEdit[foundExisting].previousValue
            ) {
                this.dataForEdit.splice(foundExisting, 1);
            } else if (Array.isArray(this.dataForEdit[foundExisting].currentValue)) {
                const comparison = _compareArrays(
                    this.dataForEdit[foundExisting].currentValue,
                    this.dataForEdit[foundExisting].previousValue
                );
                if (fieldKey === 'goalsStatus') {
                    if (
                        Object.keys(comparison).every(
                            (x) =>
                                comparison[x].clof.levelOfFunction &&
                                comparison[x].clof.assistiveDevice &&
                                comparison[x].status.description &&
                                Object.keys(comparison[x].stgs).every(
                                    (y) =>
                                        comparison[x].stgs[y].clof.levelOfFunction &&
                                        comparison[x].stgs[y].status.description
                                )
                        )
                    )
                        this.dataForEdit.splice(foundExisting, 1);
                } else if (fieldKey === 'functionalDeficits' || fieldKey === 'ggItems') {
                    if (
                        Object.keys(comparison).every(
                            (x) =>
                                comparison[x].clof.levelOfFunction &&
                                comparison[x].clof.ggScore &&
                                comparison[x].clof.assistiveDevice
                        )
                    )
                        this.dataForEdit.splice(foundExisting, 1);
                } else if (Object.keys(comparison).every((x) => comparison[x].description))
                    this.dataForEdit.splice(foundExisting, 1);
            }
        } else {
            if (fieldKey === 'goalsStatus') {
                const comparison = _compareArrays(currentValue, previousValue);
                if (
                    !Object.keys(comparison).every(
                        (x) =>
                            comparison[x].clof.levelOfFunction &&
                            comparison[x].clof.assistiveDevice &&
                            comparison[x].status.description &&
                            Object.keys(comparison[x].stgs).every(
                                (y) =>
                                    comparison[x].stgs[y].clof.levelOfFunction &&
                                    comparison[x].stgs[y].status.description
                            )
                    )
                )
                    this.dataForEdit.push(changeInstance);
            } else if (fieldKey === 'functionalDeficits' || fieldKey === 'ggItems') {
                const comparison = _compareArrays(currentValue, previousValue);
                if (
                    !Object.keys(comparison).every(
                        (x) =>
                            comparison[x].clof.levelOfFunction &&
                            comparison[x].clof.ggScore &&
                            comparison[x].clof.assistiveDevice
                    )
                )
                    this.dataForEdit.push(changeInstance);
            } else if (changeInstance.currentValue !== changeInstance.previousValue)
                this.dataForEdit.push(changeInstance);
        }
        allTherapyAdmissions[foundIndex] = currentAdmission;
        this.store.dispatch(
            therapyadmissionChangedAction({
                therapyadmission: allTherapyAdmissions,
            })
        );
        console.log('pushing the editor data...', this.dataForEdit);
    }

    editDC(id, body): Observable<DischargeNote> {
        return this.http.patch(`${baseUrl}/edit/${id}`, body) as Observable<DischargeNote>;
    }

    getDCEditHistory(id: string): Observable<DCChangeHistory[]> {
        return this.http.get(`${baseUrl}/history/${id}`).pipe(
            map((x: any) => {
                return x.data;
            })
        ) as Observable<DCChangeHistory[]>;
    }
    getDischargeNotePDF(): any {
        return this.dischargeNotePDF;
    }

    setDischargeNotePDF(value): any {
        this.dischargeNotePDF = value;
    }
    delete(id: string, queryParams: any) {
        return this.http.delete(
            `${baseUrl}/delete/with_cleanup/${id}?therapyAdmission=${queryParams.therapyAdmission}`,
            {
                withCredentials: true,
            }
        );
    }
}
