import { TherapyDiscipline, IDisciplineStatus } from '@app/interfaces';
import { EVAL_ONLY } from '@app/interfaces/documentation';

/**
 * This utility check if a discipline is present in payload
 * during drag and drop in planner calendar
 */

export const hasDiscipline = (data: any, discipline: TherapyDiscipline): boolean => {
    let flag = false;
    data?.forEach((item) => {
        if (item.discipline === discipline) {
            flag = true;
        }
        return;
    });
    return flag;
};

/**
 * This utility returns the status of present disciplines in a payload
 * that is dropped during drag drop event in planner calendar
 * Returns IDisciplineStatus object
 */

export const availableDisciplines = (payload: any): IDisciplineStatus => {
    const data: IDisciplineStatus = { pt: false, ot: false, st: false };
    payload?.forEach((item) => {
        if (item.discipline === 'PT') {
            data.pt = true;
        }
        if (item.discipline === 'OT') {
            data.ot = true;
        }
        if (item.discipline === 'ST') {
            data.st = true;
        }
    });
    return data;
};

/**
 * This utility is used to check if the dragged plan is droppable or not
 * Uses availableDisciplines(payload: any) utility
 */
// Document the flow while understanding how it works
// Tag: understand
export const isDropAblePlan = (previousData: any, nextData: any, previousIndex: number, currentIndex: number) => {
    // if (previousIndex !== currentIndex) {
    // return false;
    // }
    if (nextData[previousIndex].discipline === previousData[previousIndex].discipline) {
        return false;
    }
    if (
        nextData[currentIndex].discipline !== undefined ||
        previousData[previousIndex].discipline === nextData[currentIndex].discipline
    ) {
        return false;
    } else {
        return true;
    }
};

// Check if no patient was selected on drop in scheduler
export const noPatientSelected = (patients: any[]) => {
    return patients.every((patient) => patient.isSelected === false);
};

export const requiredLinksIdentifier = (data, therapyDiscipline, path) => {
    const linkData = data.filter((el) => el.therapyDiscipline == therapyDiscipline);
    if (path.length) {
        let link = linkData[0];
        for (let i = 0; i < path.length; i++) {
            const child = path[i] === EVAL_ONLY.EVAL_WITHOUT_POC ? link[EVAL_ONLY.EVALUATION_TYPE] : link[path[i]];
            link = child;
        }
        return link;
    }
};

// isEvaluationType flag is added to handle special case of eval only inside evaluationType
export const emptyValueValidator = (data, isEvaluationType = false) => {
    let status = true;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for (const [keys, value] of Object.entries(data)) {
        if (isEvaluationType && keys === 'evalOnlyReason') continue;
        if (keys !== 'temp' && (data[keys] === '' || data[keys]?.length === 0)) {
            status = false;
        }
        if (keys === 'medicalDiagnosis') {
            status = data[keys].some((item) => item.isPrimary);
        }
    }
    return status;
};

export const requiredLinksIdentifierOwnPaths = (data, type, therapyDiscipline, linksWithOwnPaths) => {
    const linkData =
        type === 'therapyAdmission' ? data.filter((el) => el.therapyDiscipline == therapyDiscipline) : data;
    //if (path.length) {
    let link = Array.isArray(linkData) ? linkData[0] : linkData;
    if (linksWithOwnPaths.ownPath.length > 1) {
        for (let i = 1; i < linksWithOwnPaths.ownPath.length; i++) {
            link = link[linksWithOwnPaths.ownPath[i]];
        }
    }
    return link;
    //}
};
export const validateTime = (pattern: RegExp, value) => {
    // const timePattern = pattern;

    const enteredTime = value.split(':');
    const hours = parseInt(enteredTime[0], 10);
    const minutes = parseInt(enteredTime[1], 10);

    if (hours > 24 || (hours === 24 && minutes > 0)) {
        return { invalidTime: 'Maximum allowed time is 24:00.' };
    }
    if (!pattern.test(value)) {
        return { invalidTime: 'Invalid time format. Use H:MM.' };
    }
    if (hours === 0 && minutes === 0) {
        return { invalidTime: 'Please enter valid time.' };
    }

    return null; // Validation passed
};
export const validateTimeWorkload = (pattern: RegExp, value, workload) => {
    // const timePattern = pattern;

    const enteredTime = value.split(':');
    const hours = parseInt(enteredTime[0], 10);
    const minutes = parseInt(enteredTime[1], 10);
    const timeMinutes = hours * 60 + minutes;

    if (hours > 24 || (hours === 24 && minutes > 0)) {
        return { invalidTime: 'Maximum allowed time is 24:00.' };
    }
    if (!pattern.test(value)) {
        return { invalidTime: 'Invalid time format. Use HH:mm.' };
    }
    if (timeMinutes != 0 && timeMinutes < workload) {
        return { invalidTime: 'Time cannot be less then workload.' };
    }

    return null; // Validation passed
};

export const validateTimeBlock = (pattern: RegExp, value, remainingTime, availabilityFormValue) => {
    const availabilityEntered = availabilityFormValue.split(':');
    const availabilityHours = parseInt(availabilityEntered, 10);
    const availabilityMinutes = parseInt(availabilityEntered, 10);
    const availabilityTimeMinutes = availabilityHours * 60 + availabilityMinutes;

    if (availabilityTimeMinutes == 0) return null;

    const enteredTime = value.split(':');
    const hours = parseInt(enteredTime[0], 10);
    const minutes = parseInt(enteredTime[1], 10);
    const timeMinutes = hours * 60 + minutes;

    if (!pattern.test(value)) {
        return { invalidTime: 'Invalid time format. Use HH:mm.' };
    }
    if (timeMinutes != 0 && remainingTime && timeMinutes > remainingTime) {
        return { invalidTime: 'Cannot Block more than remaining time.' };
    }
    if (hours === 0 && minutes === 0) {
        return { invalidTime: 'Please enter valid time.' };
    }

    return null;
};
