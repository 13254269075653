import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { OrganizationRoutingModule } from './organization-routing.module';

import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { OrganizationLayoutComponent } from './organization-layout/organization-layout.component';
import { OrganizationHeaderComponent } from './organization-header/organization-header.component';
import { OrganizationListComponent } from './organization-list/organization-list.component';
import { SharedModule } from '@app/shared/shared.module';
import { AddOrganizationComponent } from './add-organization/add-organization.component';
import { FacilityModule } from '../facility/facility.module';
import { ViewOrganizationComponent } from './view-organization/view-organization.component';
import { OrganizationNavBarComponent } from './organization-nav-bar/organization-nav-bar.component';
import { NonPatientCareComponent } from './non-patient-care/non-patient-care.component';
import { EditOrganization } from './edit-organization/edit-organization.component';

@NgModule({
    declarations: [
        OrganizationLayoutComponent,
        OrganizationHeaderComponent,
        OrganizationListComponent,
        AddOrganizationComponent,
        ViewOrganizationComponent,
        OrganizationNavBarComponent,
        NonPatientCareComponent,
        EditOrganization,
    ],
    imports: [
        CommonModule,
        OrganizationRoutingModule,
        SharedModule,
        NgxSkeletonLoaderModule,
        FacilityModule,
        FormsModule,
    ],
})
export class OrganizationModule {}
