<app-base-modal-card
    [showFooter]="false"
    [heading]="content.heading"
    [wrapperClass]="(content.heading === 'Approve Hours') ? 'modal-time' : 'modal-small'"
    (close)="closeAction(false)"
>
    <div body class="rounded text-center">
        <span *ngIf="content.icon" class="material-symbols-outlined icon-class" [class.filled]="content.iconFilled">
            {{ content.icon }}
        </span>
        <div class="p-3">
            <p
                class="d-flex flex-row justify-content-center body-medium-semibold text-secondary text-left body-text mt-1"
            >
                {{ content.bodyText }}
            </p>
            <p
                *ngIf="content.lowerBodyText"
                class="justify-content-center body-medium-semibold text-secondary text-left mt-4"
            >
                <span>{{ content.lowerBodyText }}:</span>
                <span class="time-stamp ml-1">{{ content.lowerBodyValue }}</span>
            </p>
            <div *ngIf="content.toDate" class="d-flex align-items-center dates mt-4" [formGroup]="dateForm">
                <app-date-picker
                    [placement]="'right-bottom'"
                    formControlName="fromDate"
                    [minDate]="content.fromDate"
                    [maxDate]="dateForm.controls.toDate.value"></app-date-picker>
                <span class="to form-group">to</span>
                <app-date-picker
                    [placement]="'left-bottom'"
                    formControlName="toDate"
                    [minDate]="dateForm.controls.fromDate.value"
                    [maxDate]="content.toDate"></app-date-picker>
            </div>
            <div class="d-flex justify-content-center mt-4">
                <button *ngIf="content.btn1Text === 'Yes, clock out'" class="btn innova-btn btn-outline-secondary btn-outlined" (click)="confirmAction('clockOutLogOut')">
                    {{ content.btn1Text }}
                </button>
                <button *ngIf="content.btn1Text !== 'Yes, clock out'" class="btn innova-btn btn-outline-secondary btn-outlined" (click)="confirmAction(false)">
                    {{ content.btn1Text }}
                </button>
                <button class="btn btn-secondary innova-btn ml-4 btn-outlined" (click)="confirmAction(true)">
                    {{ content.btn2Text }}
                </button>
            </div>
            <div *ngIf="content.showAgain !== undefined" class="show-again-layout text-left">
                <app-checkbox
                    [(ngModel)]="showAgain"
                    (optionChecked)="onCheckboxChange($event)"
                    [options]="[
                        {
                            text: 'Don\'t show this again',
                            isChecked: content.showAgain
                        }
                    ]"
                ></app-checkbox>
                <!-- <input type="checkbox" [(ngModel)]="showAgain" />
                <span class="text">Don’t show this again</span> -->
            </div>
        </div>
    </div>
</app-base-modal-card>
