import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ROUTER_UTILS } from '@app/helpers';
import { SocketEvents } from '@app/helpers/constants';
import { AuthPolicies } from '@app/helpers/utils';
import { filterFacilityItems } from '@app/helpers/utils/data.utils';
import { Layout } from '@app/interfaces/layout';
import { Account, Role, AdminPanelRole } from '@app/models';
import { FeedbackComponent } from '@app/modules/feedback/feedback.component';
import {
    AuthService,
    FacilityManagementService,
    ModalService,
    NotificationService,
    OrganizationManagementService,
    SocketService,
    StoreService,
    TimeTrackingService,
    ToasterService,
} from '@app/services';
import { PatientState } from '@app/store/store.interface';
import { Store } from '@ngrx/store';
import { finalize, first, firstValueFrom, Observable, Subscription, take } from 'rxjs';
import { isToday, isYesterday, millisecondsToMinutes } from 'date-fns';
import { TimeConfirmationDialogComponent } from '@app/shared/time-confirmation-dialog/time-confirmation-dialog.component';
import { millisecondsToHours } from 'date-fns';
import { InnovaCalendar } from '@app/helpers/lib';
import { cloneDeep } from 'lodash';
import { DeletionConfirmationDialogComponent } from '@app/shared/deletion-confirmation-dialog/deletion-confirmation-dialog.component';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
    @Input() layout: Layout;
    innovaCalendar2: InnovaCalendar = new InnovaCalendar();
    showMobileMenu = false;
    titles = []; //['Select Facility', 'Facility one', 'facility two', 'facility three', 'facility four'];
    selectedFacility = { id: 0, name: '' }; //'Select Facility';
    facilitySearchString = '';
    disabledRoutes = [
        ROUTER_UTILS.config.patients.pcc.root,
        ROUTER_UTILS.config.patients.pcc.tabs.documentation.root,
        ROUTER_UTILS.config.documentationViewMode.root,
        ROUTER_UTILS.config.addPatients.root,
        ROUTER_UTILS.config.admin.accounts.root + '/' + ROUTER_UTILS.config.admin.accounts.addUser,
        ROUTER_UTILS.config.admin.accounts.root + '/' + ROUTER_UTILS.config.admin.accounts.editUser,
    ];
    timeTrackingRoute = ROUTER_UTILS.config.timeTracking.root;
    Role = Role;
    adminRoles = AdminPanelRole;
    account: Account;
    expandMore: boolean;
    showAdminPanel: boolean;
    showManage: boolean;
    showOrganizations: boolean;
    showSettings: boolean;
    dropDownValue = '';
    currentUser$: Observable<Account>;
    helpUrl = ROUTER_UTILS.config.help.root;
    settingsUrl = ROUTER_UTILS.config.settings.root;
    adminUrl = ROUTER_UTILS.config.admin.root + '/' + ROUTER_UTILS.config.admin.accounts.root;
    profileUrl;
    currentFacility: any;
    loading: boolean;
    filteredFacilityList = [];
    facilityInput;
    filteredOrganizationList = [];
    organizationList = [];
    notifications = [];
    selectedOrganization = { id: 0, name: '', count: 0 };
    organizationSearchString = '';
    policies = AuthPolicies;
    totalNotifications = 0;
    unReadCount = 0;
    archivedCount = 0;
    markingReadLoading = false;
    clock: any = '0:00';
    timeInMilliseconds;
    currentDate: Date;
    intervalId: any;
    timeEvent: 'Start' | 'Stop' = 'Start';
    timeExist;
    timeConfirmationDialogRef: any;
    timeTrackData: Subscription;
    spentTimeInMinutes: Subscription;
    timeTrackingSubscription: Subscription;
    timerObj;
    goLiveEnabled: boolean;
    invoicePage = false;
    goLivePermission = false;
    goLive = false;
    //enum for Turn on and off
    goLiveStatusEnum = { ON: 'Turn on', OFF: 'Turn off' };
    goLiveOptions = [
        { label: this.goLiveStatusEnum.ON, disabled: false, color:'#4D8B63'},
        { label: this.goLiveStatusEnum.OFF, disabled: false, color: '#AC1616'},
      ];
    toggleHamburger(): void {
        this.showMobileMenu = !this.showMobileMenu;
    }

    public SKELETON_CSS = {
        CELL: {
            width: '96%',
            height: '3em',
            margin: '1.8em 35em 0.1em 2em',
        },
    };
    userId = '';

    constructor(
        private AuthService: AuthService,
        private socketService: SocketService,
        private notificationService: NotificationService,
        private store: Store<{ patient: PatientState; user: Account }>,
        private _facilityManagementService: FacilityManagementService,
        private storeService: StoreService,
        private router: Router,
        private toasterService: ToasterService,
        private _organizationManagementService: OrganizationManagementService,
        private modal: ModalService,
        private timeTrackingService: TimeTrackingService,
    ) {
        this.currentUser$ = this.store.select('user');
        this.AuthService.account.subscribe((x) => (this.account = x));
        this.expandMore = false;
        // for admin panel
        this.showAdminPanel = this.AuthService.applyPolicy(AuthPolicies.Users.View);
        this.showManage = this.AuthService.applyPolicy(this.policies.Users.View);
        this.showSettings = true; // * i don't think so we need to show permission here this.AuthService.applyPolicy(this.policies.Users.View);
        this.showOrganizations = this.AuthService.isSuperAdmin;
        if (this._facilityManagementService._currentFacility) {
            this.goLive = this._facilityManagementService._currentFacility.goLiveEnabled;
            this.goLiveOptionUpdate();
        }
        this.userId = this.AuthService.accountValue.user.id;
    }
    ngOnInit(): void {
        //this.getAllFacilities();
        // Subscribing to time-tracking socket and updating header clock

        // eslint-disable-next-line spellcheck/spell-checker
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                // eslint-disable-next-line spellcheck/spell-checker
                this.invoicePage =
                    window.location.pathname.includes('billing/invoice') ||
                    window.location.pathname.includes('eom-bill');
            }
        });
        this.timeTrackingSubscription = this.socketService.getEvent(SocketEvents.TIME_TRACKING).subscribe(() => {
            const timeTrackData = {
                therapistId: this.AuthService.accountValue?.user?.therapist?.id,
                clockOut: null,
            };
            this.timeTrackingService.searchAllSessions(timeTrackData).subscribe((res) => {
                if (res.length > 0) {
                    const storageData = { checkIn: res[0].clockIn, id: res[0]._id };
                    this.timeTrackingService.timeTrackData$.next(storageData);
                } else {
                    this.timeTrackingService.timeTrackData$.next(null);
                }
            });
        });
        this.timeTrackData = this.timeTrackingService.timeTrackData$.subscribe((data) => {
            if (data !== null) {
                localStorage.setItem('time-tracking', JSON.stringify(data));
                this.checkLoggedTime();
            } else {
                localStorage.removeItem('time-tracking');
            }
        });

        this.getOrganizations();
        this.socketService.getEvent(SocketEvents.NOTIFICATION).subscribe((message) => {
            this.notifications.unshift(message);
            this.unReadCount = this.unReadCount + 1;
            this.toasterService.show({
                title: message.data.title,
                body: message.data.message,
                type: 'notification',
                timestamp: this.formatNotificationTime(message.createdAt),
            });
            this.getNotifications();
        });
        this.socketService.getEvent(SocketEvents.GOLIVE).subscribe((message) => {
            if (message.facilityId == this._facilityManagementService._currentFacility.id) {
                this.goLive = message?.enableGoLive;
                this.goLiveOptionUpdate();
                this._facilityManagementService._currentFacility = {
                    ...this._facilityManagementService._currentFacility,
                    goLiveEnabled: this.goLive,
                };
                this.toasterService.show({
                    title: 'Facility Status',
                    body: this.goLive
                        ? 'Your facility is live now'
                        : 'Your Faculty is switched to transition phase',
                    type: 'success',
                });
            }
        });
        this.getNotifications();
        this.notificationService.dataChanged.subscribe((event) => {
            if (event == 'refresh-header') {
                this.getNotifications();
            }
        });
        this.currentDate = new Date();
        const time = JSON.parse(localStorage.getItem('time-tracking'));
        if (time) {
            this.checkLoggedTime();
        } else {
            this.startTimer();
        }
        this._organizationManagementService.selectedOrganization = this.selectedOrganization;
    }
    ngOnDestroy(): void {
        this.stopTimer();
        if (this.timeConfirmationDialogRef) {
            this.timeConfirmationDialogRef.close();
        }
        if (this.timeTrackData) {
            this.timeTrackData.unsubscribe();
        }
        if (this.timeTrackingSubscription) {
            this.timeTrackingSubscription.unsubscribe();
        }
    }

    // start timer using current settings (if it's not already running)
    startTimer() {
        if (!this.timerObj && this.AuthService?.isTherapist) {
            this.stopTimer();
            this.timerObj = setInterval(() => {
                const time = JSON.parse(localStorage.getItem('time-tracking'));
                const showTimeDialog = JSON.parse(localStorage.getItem('timeDialog'));
                if (!showTimeDialog && !time) {
                    this.trackTimeToggle('checkInReminder');
                }
            }, 300000);
        }
    }

    stopTimer() {
        if (this.timerObj) {
            clearInterval(this.timerObj);
            this.timerObj = null;
        }
    }

    openModal(data: any, clockAction: string, info?: any, organization: any = null) {
        this.timeConfirmationDialogRef = this.modal.open(TimeConfirmationDialogComponent, {
            data: data,
        });
        if (this.timeConfirmationDialogRef) {
            this.stopTimer();
        }
        this.timeConfirmationDialogRef.customAction().subscribe((action) => {
            // Refers to 'don't show this again checkbox'
            if (action.value) {
                localStorage.setItem('timeDialog', 'true');
            } else {
                localStorage.setItem('timeDialog', 'false');
            }
        });

        this.timeConfirmationDialogRef.afterClosed().subscribe(async (action) => {
            this.timeConfirmationDialogRef = false;
            const user = await firstValueFrom(this.currentUser$);
            let params: any;

            if (action) {
                if (clockAction === 'Start' || clockAction === 'checkInReminder') {
                    params = {
                        therapistId: user.user?.therapist?.id,
                        facilityId: this.selectedFacility.id,
                        clockIn: new Date().toISOString(),
                    };
                    this.submitTime(params, (clockAction = 'Start'));
                } else if (clockAction === 'Stop') {
                    const timeStorage = JSON.parse(localStorage.getItem('time-tracking'));
                    params = {
                        id: timeStorage.id,
                        clockOut: new Date().toISOString(),
                    };
                    await this.submitTime(params, clockAction, info);
                    if (info && info !== 'fromLogout') {
                        this.selectedFacility.id = info?.id;
                        this.selectedFacility.name = info?.name;
                        await this.saveDefaultFacility(info);
                    }
                    this.startTimer();
                    if (organization) {
                        const currentOrganization = this.organizationList.find((item) => item.id == organization.id);
                        this.selectedOrganization.id = organization.id;
                        this.selectedOrganization.name = organization.name;
                        this._organizationManagementService._currentOrganization = currentOrganization;
                    }
                } else if (action !== 'clockOutLogOut' && info === 'fromLogout' && clockAction === 'logout') {
                    this.stopTimer();
                    this.AuthService.logout();
                }
            } else if (action === undefined || action === false) {
                this.startTimer();
            }
            if (action === 'clockOutLogOut' && info === 'fromLogout') {
                const timeStorage = JSON.parse(localStorage.getItem('time-tracking'));
                params = {
                    id: timeStorage.id,
                    clockOut: new Date().toISOString(),
                };
                await this.submitTime(params, clockAction === 'logout' ? 'Stop' : clockAction, info);
                this.stopTimer();
                this.AuthService.logout();
            }
        });
    }

    async submitTime(params, clockAction, info?) {
        const response: any = await firstValueFrom(await this.timeTrackingService.trackTime(params, clockAction));
        if (!response.data.clockOut) {
            this.timeTrackingService.clockUpdated$.next(true);
            const timeParams = { checkIn: params.clockIn, id: response.data._id };
            localStorage.setItem('time-tracking', JSON.stringify(timeParams));
            this.stopTimer();
        } else {
            if (response.data.workedTime < 60) {
                await this.deleteSession(response.data._id);
            } else {
                this.timeTrackingService.clockUpdated$.next(true);
            }
            localStorage.removeItem('time-tracking');
            this.timeTrackingService.timeTrackData$.next(null);
            this.timeExist = false;
            this.startTimer();
        }
        if (info?.id && !info?.whileClockedIn) {
            this.getFullFacilityDetails(info.id);
        }
        this.checkLoggedTime();
        this.socketService.sendMessage(SocketEvents.TIME_TRACKING, this.AuthService.accountValue?.user?.id);
        this.toasterService.show({
            title: 'Success',
            body: response.message,
            type: 'success',
        });
        // }, (error) => {
        //     this.toasterService.show({
        //         title: 'Error',
        //         body: error,
        //         type: 'error',
        //     });
        // });
    }

    checkLoggedTime() {
        setInterval(() => {
            const time = JSON.parse(localStorage.getItem('time-tracking'));
            if (time) {
                this.timeExist = true;
                this.timeEvent = 'Stop';
                this.updateSpentTime(time);
            } else {
                this.timeExist = false;
                this.timeEvent = 'Start';
                this.clock = '0:00';
            }
        }, 1000);
    }

    updateSpentTime(time) {
        const spentTime = new Date().getTime() - new Date(time?.checkIn).getTime();
        this.timeInMilliseconds = spentTime;
        this.clock =
            String(millisecondsToHours(this.timeInMilliseconds)).padStart(2, '0') +
            ':' +
            (millisecondsToMinutes(this.timeInMilliseconds) % 60).toFixed(0).padStart(2, '0');
        // + ':' +
        // (millisecondsToSeconds(this.timeInMilliseconds) % 60).toFixed(0).padStart(2, '0');
        this.timeTrackingService.spentTimeInMinutes$.next(millisecondsToMinutes(this.timeInMilliseconds));
    }

    trackTimeToggle(e: string): void {
        let data = {};
        if (e === 'checkInReminder') {
            data = {
                heading: 'Clock-in',
                icon: 'schedule',
                btn1Text: 'No',
                btn2Text: 'Yes',
                showAgain: false,
                iconFilled: false,
                bodyText: `You are signed into ${this.selectedFacility.name}. Do you want to clock-in?`,
            };
        } else if (e === 'Start') {
            data = {
                heading: 'Clock-in',
                icon: 'schedule',
                btn1Text: 'No',
                btn2Text: 'Yes',
                iconFilled: false,
                bodyText: `You are signed into ${this.selectedFacility.name}. Do you want to clock-in?`,
            };
            // start the timer
        } else if (e === 'Stop') {
            data = {
                heading: 'Clock-out',
                icon: 'schedule',
                btn1Text: 'No',
                btn2Text: 'Yes',
                lowerBodyValue: this.clock,
                lowerBodyText: 'Total Time',
                iconFilled: true,
                bodyText: `Are you sure you want to clock-out from ${this.selectedFacility.name}`,
            };
            // stop the timer
        }
        if (!this.timeConfirmationDialogRef) {
            this.stopTimer();
            this.openModal(data, e);
        }
    }

    async deleteSession(id: string) {
        this.timeTrackingService.deleteSession(id).subscribe(() => this.timeTrackingService.clockUpdated$.next(true));
    }

    getNotifications() {
        this.notificationService.getNotifications(1, 'UnArchived').subscribe((notifications: any) => {
            this.notifications = notifications.data?.list.map((notification) => {
                notification.facility.id = notification.facility._id;
                return notification;
            });
            this.totalNotifications = notifications.data.total;
            this.unReadCount = notifications.data.unReadCount;
            this.archivedCount = notifications.data.archivedCount;
        });
    }
    getNumberOfUnreadNotifications() {
        return this.notifications.filter((notification) => !notification.isRead && !notification.isArchive).length;
    }
    formatNotificationTime(data: string) {
        const newDate = new Date(data);
        const dateString = isToday(newDate)
            ? 'Today at '
            : isYesterday(newDate)
            ? 'Yesterday at '
            : newDate.toDateString() + ' at ';
        return dateString + newDate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
    }
    notificationHandler(notification: any) {
        if (!notification.isRead) {
            notification.isRead = true;
            this.notificationService
                .updateNotification(notification._id, notification)
                .pipe(first())
                .subscribe({
                    next: () => {
                        this.getNotifications();
                        this.notificationService.dataChanged.next('refresh-notification-center');
                        if (notification.data.url) {
                            this._facilityManagementService._currentFacility = notification.facility;
                            this._facilityManagementService._Facilities.pipe(take(1)).subscribe((Fs) => {
                                this._facilityManagementService._Facilities = Fs;
                            });
                            this.navigate(notification);
                        }
                        // this.toasterService.show({
                        //     title: 'Success',
                        //     body: 'Request sent successfully',
                        //     type: 'success',
                        // });
                        // this.loading = false;
                    },
                    error: (error) => {
                        this.toasterService.show({
                            title: 'Error',
                            body: error,
                            type: 'error',
                        });
                        // this.loading = false;
                    },
                });
        } else {
            if (notification.data.url) {
                this._facilityManagementService._currentFacility = notification.facility;
                this._facilityManagementService._Facilities.pipe(take(1)).subscribe((Fs) => {
                    this._facilityManagementService._Facilities = Fs;
                });
                this.navigate(notification);
            }
        }
    }
    navigate(notification) {
        const isOnPCC = window.location.href.includes(ROUTER_UTILS.config.patients.pcc.root);
        if (notification.data.url.includes(ROUTER_UTILS.config.discharge.root)) {
            const searchParams = new URLSearchParams(notification.data.url.split('?')[1]);
            this.storeService
                .initializePatientData(searchParams.get('patient'), notification.facility.id)
                .pipe(
                    take(1),

                    finalize(() => {
                        this.storeService.setLoadingState(false);
                    })
                )
                .subscribe();
            this.router.navigateByUrl(notification.data.url);
        } else {
            this.router.navigateByUrl(notification.data.url);
        }
        if (notification.data.url && notification.data.url.includes(ROUTER_UTILS.config.patients.pcc.root) && isOnPCC) {
            const searchParams = new URLSearchParams(notification.data.url);
            if (searchParams.has('tab')) {
                this.notificationService.pccTabChanged.next(searchParams.get('tab'));
            }
        }
    }
    toggleExpandMore() {
        this.expandMore = !this.expandMore;
    }

    logout() {
        if (this.timeExist) {
            const data = {
                heading: 'Clock-out',
                icon: 'schedule',
                btn1Text: 'Yes, clock out',
                btn2Text: 'No, keep clock running',
                lowerBodyValue: this.clock,
                lowerBodyText: 'Total Time',
                iconFilled: true,
                bodyText: `Reminder, your clock is still running. Do you want to log out of ${this.selectedFacility.name}?`,
            };
            this.openModal(data, 'logout', 'fromLogout');
            return;
        }
        this.stopTimer();
        this.timeConfirmationDialogRef = false;
        this.AuthService.logout();
    }
    markAllAsRead(event) {
        if (this.unReadCount > 0) {
            event.preventDefault();
            event.stopPropagation();
            const Ids = this.notifications
                .filter((notification) => !notification.isRead)
                .map((notification) => notification._id);
            if (Ids.length > 0) {
                this.markingReadLoading = true;
                this.notificationService.markAllAsRead(Ids).subscribe({
                    next: () => {
                        this.toasterService.show({
                            title: 'Success',
                            body: Ids.length + ' marked as read successfully',
                            type: 'success',
                        });
                        this.getNotifications();
                        this.notificationService.dataChanged.next('refresh-notification-center');
                        this.markingReadLoading = false;
                    },
                    error: () => {
                        this.toasterService.show({
                            title: 'Error',
                            body: 'Something went wrong',
                            type: 'error',
                        });
                        this.markingReadLoading = false;
                    },
                });
            } else {
                this.toasterService.show({
                    title: 'Success',
                    body: 'No unread notifications',
                    type: 'success',
                });
            }
        }
    }
    getOrganizations() {
        this.storeService.setLoadingState(true);
        this.loading = true;
        this._organizationManagementService.__Organizations.subscribe({
            next: (res) => {
                if (res.length > 0) {
                    this.organizationList = res;
                    this.filteredOrganizationList = this._organizationManagementService.mapOrganizationsForDropDown();
                    // Sort organizations alphabetically by name
                    this.filteredOrganizationList.sort((a, b) => a.name.localeCompare(b.name));
                    this.selectedOrganization.id = this._organizationManagementService._currentOrganization?.id;
                    this.selectedOrganization.name = this._organizationManagementService._currentOrganization?.name;

                    if (this.filteredOrganizationList.length > 4) {
                        this.filteredOrganizationList = this.filteredOrganizationList.slice(0, 4);
                    }
                    this.getAllFacilities();
                }
            },
            error: () => {
                this.loading = false;
                this.storeService.setLoadingState(false);
            },
        });
    }
    getAllFacilities() {
        this.loading = true;
        this._facilityManagementService._Facilities.subscribe({
            next: (result) => {
                if (result.length > 0) {
                    this.titles = this.filteredFacilityList =
                        this._facilityManagementService.mapFacilitiesIntoDropdown();
                    // Sort organizations alphabetically by name
                    this.titles.sort((a, b) => {
                        if (!a.name) return 1;  
                        if (!b.name) return -1; 
                        return a.name.localeCompare(b.name);
                      });
                    cloneDeep(this.titles);
                    if (this.titles.length > 4) {
                        this.filteredFacilityList = this.titles.slice(0, 4);
                    }
                    // this.titles.unshift({ id: '-1', name: 'Select Facility' });

                    // Commented for now:
                    // If no functionality breaks, will remove it.
                    // // if (!this.selectedFacility.id && !this._facilityManagementService._currentFacility) {
                    // //     // if (this.titles.length > 0) this.getFullFacilityDetails(this.titles[0].id);
                    // // } else
                    if (this._facilityManagementService._currentFacility) {
                        this.selectedFacility.id = this._facilityManagementService._currentFacility.id;
                        this.selectedFacility.name = this._facilityManagementService._currentFacility.facilityName;
                        this.goLive = this._facilityManagementService._currentFacility.goLiveEnabled;
                        this.goLiveOptionUpdate();
                        this.goLivePermission = this.AuthService.applyPolicy(this.policies.GoLive.Access);
                    }
                    this.loading = false;
                }
                this.storeService.setLoadingState(false);
            },
            error: () => {
                this.loading = false;
                this.storeService.setLoadingState(false);
            },
        });
    }

    async facilityChanged(facility: any, organization: any = null) {
        if (this.timeExist) {
            const data = {
                heading: 'Clock-out',
                icon: 'schedule',
                btn1Text: 'No',
                btn2Text: 'Yes',
                lowerBodyValue: this.clock,
                lowerBodyText: 'Total Time',
                iconFilled: true,
                bodyText: `Do you want switch facility? Your time for ${this.selectedFacility.name} will be stopped and logged on your timecard.`,
            };
            facility.whileClockedIn = true;
            this.openModal(data, 'Stop', facility, organization);
            return;
        }
        this.selectedFacility.id = facility?.id ?? facility?._id;
        this.selectedFacility.name = facility.name;
        await this.saveDefaultFacility(facility);
    }

    async saveDefaultFacility(facility) {
        const user = await firstValueFrom(this.currentUser$);
        const obj = { facility: facility.id, id: user.user.id };
        this._facilityManagementService.markDefaultFacility(obj).subscribe(() => {
            this.getFullFacilityDetails(this.selectedFacility.id);
        });
    }

    facilitySearch(e) {
        if (typeof e !== 'string' && e?.target) {
            e = e.target.value;
        }
        this.facilitySearchString = e.toLowerCase();
        this.filteredFacilityList = filterFacilityItems(this.titles, this.facilitySearchString);
    }

    showAllFacilities() {
        this.filteredFacilityList = this.titles;
    }

    openFeedback() {
        // this.sideNavService.show(FeedbackComponent, {
        //     data: { name: 'Feedback Data'},
        //     position: 'right',
        // });
        this.modal.open(FeedbackComponent);
        // TSN.afterActionData.subscribe((data) => {
        //     this.schedulerService.unAssignTriggered.next(data);
        // });
    }

    showAllNotifications(event) {
        event.preventDefault();
        this.router.navigateByUrl(ROUTER_UTILS.config.notification.root);
    }
    openClose(open: boolean) {
        if (!open) {
            if (this.titles.length > 4 && this.facilitySearchString === '') {
                this.filteredFacilityList = this.titles.slice(0, 4);
            }
        }
    }

    getFullFacilityDetails(facilityId) {
        this._facilityManagementService.getFacilityById(facilityId).subscribe({
            next: (facility) => {
                if (typeof facility.data.mailingAddress.macId == 'object')
                    facility.data.mailingAddress.macId = facility.data.mailingAddress.macId._id;
                this._facilityManagementService._currentFacility = facility.data;
                this.selectedFacility.id = facility.data.id;
                this.selectedFacility.name = facility.data.facilityName;
                //this.storeService.resetPlannerTherapyAdmissions();
                // this.router.navigateByUrl('/');
                this.toasterService.show({
                    title: 'Facility Switched!',
                    body: 'Now you are experiencing data of facility "' + facility.data.facilityName + '"',
                    type: 'success',
                });
                setTimeout(() => {
                    window.location.href = '/';
                }, 1000);
                // this.storeService.setLoadingState(false);
            },
            error: () => {
                this.toasterService.show({
                    title: 'Try Again!',
                    body: 'Something went wrong',
                    type: 'error',
                });
                // this.storeService.setLoadingState(false);
            },
        });
    }

    checkRoutes() {
        let exists = false;
        this.disabledRoutes.forEach((route) => {
            //debugger;
            if (this.router.url.includes(route)) {
                exists = true;
            }
        });
        return exists;
    }

    organizationChanged(organization: any) {
        if (organization.facilityCount > 0) {
            const currentOrganization = this.organizationList.find((item) => item.id == organization.id);
            if (!this.timeExist) {
                this.selectedOrganization.id = organization.id;
                this.selectedOrganization.name = organization.name;
                const currentOrganization = this.organizationList.find((item) => item.id == organization.id);
                this._organizationManagementService._currentOrganization = currentOrganization;
            }
            const facility =
                currentOrganization.facility && currentOrganization.facility[0]
                    ? currentOrganization.facility[0]
                    : currentOrganization.facilities[0];

            this.facilityChanged(facility, organization);
        } else {
            this.toasterService.show({
                title: '',
                body: 'No Facility Associated with this organization',
                type: 'warning',
            });
        }
    }

    organizationSearch(e) {
        if (typeof e !== 'string' && e?.target) {
            e = e.target.value;
        }
        this.organizationSearchString = e.toLowerCase();
        this.filteredOrganizationList = filterFacilityItems(this.organizationList, this.organizationSearchString);
        this.filteredOrganizationList.map((item) => {
            item.facilityCount = item.facilities.length;
            return item;
        });
    }

    showAllOrganizations() {
        //this.filteredOrganizationList = this.organizationList;
        this.filteredOrganizationList = this._organizationManagementService.mapOrganizationsForDropDown();
    }
    handleGoLiveChange($event){
        const isActive = $event.label === this.goLiveStatusEnum.ON;
        const id = this._facilityManagementService._currentFacility.id;
        const dialogRef = this.modal.open(DeletionConfirmationDialogComponent, {
            data: {
                heading: 'Go Live Confirmation',
                resourceType: 'facilities',
                message: isActive
                    ? 'Turning this on would require evaluations of all the patients to be filled in as Regular Evals.'
                    : 'Turning this off would make evaluations of all the patients to be filled in as Partial Evals or JDoc Evals.',
                button1Text: 'Continue',
            },
        });
        dialogRef.afterClosed().subscribe((isContinue) => {
            if (isContinue) {
                this._facilityManagementService.toggleFacilityGoLive(id, isActive).subscribe((response) => {
                    response._id = id;
                        this._facilityManagementService._currentFacility = response;  
                        this.goLive = isActive;
                        this.goLiveOptionUpdate();
                    const facilities = this._facilityManagementService._Facilities.value
                    const index = facilities.findIndex(
                        (facility) => facility?._id == response.id || facility?.id == response.id
                    );
                    if (index > -1) {
                        facilities[index] = response;
                        this._facilityManagementService.updateFacilities(facilities);
                    }
                });
            } 
        });
    }
    goLiveOptionUpdate(){
        this.goLiveOptions[0].disabled = this.goLive
        this.goLiveOptions[1].disabled = !this.goLive
    }
}
