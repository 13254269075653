<div class="align-items-center d-flex">
    <div class="w-75">
        <input
            class="checkbox"
            type="checkbox"
            (click)="onChange($event)"
            [disabled]="params.disabled"
            [checked]="params.checked"
        />
        <span class="ml-3">{{ params.value }}</span>
    </div>
    <span class="badge p-2 px-3 rounded-pill bg-success-a10 text-success ml-8 font-weight-normal">{{
        params.pillText
    }}</span>
</div>
