<div ngbDropdown [container]="'body'">
    <span
        class="colored-select-layout badge rounded-pill p-1"
        *ngIf="params.isColored"
        ngbDropdownToggle
        (click)="$event.preventDefault(); $event.stopPropagation()"
    >
        <span class="colored-select-inner">
            <span class="text">{{ params.value }} </span>
            <svg class="arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 8" fill="none">
                <path d="M1.41 0.294998L6 4.875L10.59 0.294998L12 1.705L6 7.705L0 1.705L1.41 0.294998Z" />
            </svg>
        </span>
    </span>
    <span
        class="select-layout"
        *ngIf="!params.isColored"
        ngbDropdownToggle
        (click)="$event.preventDefault(); $event.stopPropagation()"
    >
        <span *ngIf="params.value == ''" class="select-text">Select Status</span>
        <span *ngIf="params.value" class="select-text">{{ params.value }}</span>

        <svg class="arrow" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g clip-path="url(#clip0_1279_4187)">
                <path d="M7.41 8.59L12 13.17L16.59 8.59L18 10L12 16L6 10L7.41 8.59Z" fill="#1C3775" />
            </g>
            <defs>
                <clipPath id="clip0_1279_4187">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    </span>
    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
        <ng-container *ngFor="let option of options">
            <button class="body-small option" ngbDropdownItem (click)="dropdownSelect(option, $event)">
                {{ option }}
            </button>
        </ng-container>
    </div>
</div>
