import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { Store } from '@ngrx/store';
import { PatientState } from '@app/store/store.interface';
import { TherapyDiscipline } from '@app/interfaces';

const baseUrl = `${environment.apiUrl}/clinical-categories`;
const baseUrlCPT = `${environment.apiUrl}/cpt-codes`;

@Injectable({ providedIn: 'root' })
export class ClinicalCategoriesService {
    tab$: Observable<any>;
    patient: PatientState;
    constructor(private router: Router, private http: HttpClient, private store: Store<{ patient: PatientState }>) {}

    categorySearch(searchText: string, discipline: string, macId = '', page = 1, comorbidity = false) {
        if (searchText.length >= 3) {
            let url = `${baseUrl}/search?page=${page}&searchText=${searchText}&discipline=${discipline}&macId=${macId}`;
            if (comorbidity) {
                url = url + `&comorbidity[exists]=${comorbidity}`;
            }
            return this.http
                .get<any>(`${url}`, {
                    withCredentials: true,
                })
                .pipe(
                    map((result) => {
                        return result;
                    })
                );
        } else {
            return of([]);
        }
    }

    cptCodesSearch(searchText: string, discipline: TherapyDiscipline, page = 1, isEvalCode?: boolean, limit = 10) {
        const currentFacility = JSON.parse(localStorage.getItem('current-facility'));
        let macId = '';
        if (currentFacility?.mailingAddress) {
            if (currentFacility.mailingAddress.macId?._id) {
                macId = currentFacility.mailingAddress.macId._id;
            } else if (currentFacility.mailingAddress.macId) {
                macId = currentFacility.mailingAddress.macId
            }
        }
        if (searchText.length >= 3) {
            return this.http
                .get<any>(
                    `${baseUrlCPT}/search?page=${page}&searchText=${searchText}&discipline=${discipline}&macId=${
                        macId ? macId : ''
                    }&limit=${limit}`,
                    {
                        withCredentials: true,
                    }
                )
                .pipe(
                    map((result) => {
                        return result;
                    })
                );
        } else if (isEvalCode !== undefined || isEvalCode !== null) {
            return this.http
                .get<any>(
                    `${baseUrlCPT}/search?page=${page}&isEvalCode=${isEvalCode}&discipline=${discipline}&macId=${
                        macId ? macId : ''
                    }&limit=${limit}`,
                    {
                        withCredentials: true,
                    }
                )
                .pipe(
                    map((result) => {
                        return result;
                    })
                );
        } else {
            return of([]);
        }
    }
}
