<ag-grid-angular
    #agGrid
    class="ag-theme-alpine"
    [defaultColDef]="defaultColDef"
    [rowHeight]="rowHeight"
    [tooltipShowDelay]="tooltipShowDelay"
    [tooltipHideDelay]="tooltipHideDelay"
    [rowMultiSelectWithClick]="rowMultiSelectWithClick"
    [rowData]="rowData"
    [columnDefs]="columnDefs"
    [pagination]="pagination"
    [paginationPageSize]="paginationPageSize"
    [rowClassRules]="rowClassRules"
    [rowClass]="rowClass"
    [context]="context"
    [enableBrowserTooltips]="true"
    [rowSelection]="rowSelection"
    [quickFilterText]="quickFilterText"
    [overlayLoadingTemplate]="overlayLoadingTemplate"
    [overlayNoRowsTemplate]="(loadingObs | async)?.isLoading ? overlayEmptyRowsTemplate : overlayNoRowsTemplate"
    [domLayout]="domLayout"
    (gridReady)="onGridReady($event)"
    (gridSizeChanged)="gridSizeChanged($event)"
    (firstDataRendered)="gridPopulated($event)"
    (rowClicked)="onRowClicked($event)"
    (sortChanged)="onSortChanged($event)"
    (filterChanged)="onFilterChanged($event)"
    (selectionChanged)="onSelectionChanged($event)"
    (cellValueChanged)="onCellValueChanged($event)"
    [singleClickEdit]="singleClickEdit"
    [suppressRowClickSelection]="!rowMultiSelectWithClick"
    [pinnedBottomRowData]="pinnedBottomRowData"
    [getRowStyle]="getRowStyle"
    [postSortRows]="postSort"
    [gridOptions]="gridOptions"
    [headerHeight]="headerHeight"
    [suppressDragLeaveHidesColumns]="suppressDragLeaveHidesColumns"
    (rowDataChanged)="onRowDataChanged($event)"
    (cellClicked)="onCellClicked($event)"
    (paginationChanged)="onPaginationChanged($event)"
    [cacheOverflowSize]="cacheOverflowSize"
    [maxConcurrentDatasourceRequests]="maxConcurrentDatasourceRequests"
    [infiniteInitialRowCount]="infiniteInitialRowCount"
    [maxBlocksInCache]="maxBlocksInCache"
    [paginationAutoPageSize]="paginationAutoPageSize"
    (rowSelected)="onRowSelected($event)"
>
</ag-grid-angular>
