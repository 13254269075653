/* eslint-disable spellcheck/spell-checker */
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NavigationExtras, Router } from '@angular/router';
import { FacilityManagementService, OrganizationManagementService, ToasterService } from '@app/services';
import { Button } from '@app/shared/toggle/toggle.component';
import { take } from 'rxjs';

@Component({
    selector: 'app-edit-organization',
    templateUrl: './edit-organization.component.html',
    styleUrls: ['./edit-organization.component.scss'],
})
export class EditOrganization implements OnInit {
    organizationId = '';
    isGeneralEditMode = false;
    organizationForm: FormGroup;
    editIcon = './../../../../../assets/icons/edit-icon.svg';
    crossIcon = './../../../../../assets/icons/add-icon-2.svg';
    buttons: Button[] = [
        {
            text: 'Facilities',
            isSelected: true,
        },
        {
            text: 'Other Services',
            isSelected: false,
        },
    ];
    units = ['Active', 'In Active'];
    facilityListData = [];
    public SKELETON_CSS = {
        CELL: {
            width: '96%',
            height: '3.4em',
            margin: '0 2em 0.1em 2em',
        },
    };

    TABS = {
        NON_PATIENT_CARE: 'Other Services',
        FACILITIES: 'facilities',
    };
    isFacilitiesTabSelected = true;
    isListLoading: boolean;
    customPattern = /^[0-9]{2}\/[0-9]{2}\/[0-9]{2}$/;
    NPCList = [];
    status = '';
    orgName = '';
    filteredStates = [];
    states = [];
    counties = [];
    mailingCounties: any[];
    address: any;

    constructor(
        private formBuilder: FormBuilder,
        private _organizationService: OrganizationManagementService,
        private facilityManagementService: FacilityManagementService,
        private router: Router,
        private toasterService: ToasterService
    ) {
        this.states = this.facilityManagementService.getUSAStates();
        this.filteredStates = this.states.map((item: any) => item.stateName);
        this.counties = this.facilityManagementService.getUSACounties();
    }

    ngOnInit(): void {
        const url = window.location.href;
        const segments = url.split('/');
        this.organizationId = segments.pop();
        this.organizationForm = this.formBuilder.group({
            companyCode: ['', [Validators.required]],
            status: [''],
            address: this.formBuilder.group({
                address: ['', [Validators.required]],
                county: ['', [Validators.required]],
                state: ['', [Validators.required]],
                zipCode: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(5)]],
                phone: ['', [Validators.required]],
                fax: [''],
            }),
        });
        this.isListLoading = true;

        this._organizationService.getOrganizationFacilitiesById(this.organizationId).subscribe((res) => {
            this.organizationForm.patchValue({
                companyCode: res?.data?.companyCode,
                status: res?.data?.status,
                address: res?.data?.address,
            });
            this.prepareData(res);
            this.isListLoading = false;
        });
    }

    prepareData(res) {
       
        this.orgName = res?.data?.name;
        this.status = res?.data?.status;
        this.facilityListData = res?.data?.facility;
        this.NPCList = res.data.npc;
        this.address = res.data.address;
        this.updateCounties(this.address?.state);
    }

    updateCounties(state: string, resetCounty = false) {
        // reseting county on every change of state
        if(resetCounty){
            this.organizationForm.patchValue({
                address: {
                  county: '',
                }
              });
        }
    
        const stateData = this.states?.filter((x) => x?.stateName === state);
        this.mailingCounties = this.counties
            ?.filter((x) => x?.stateCode === stateData[0]?.stateCode)
            ?.map((item) => item.countyName);
    }

    jumpToSection(section) {
        if (section.toLowerCase() === this.TABS.FACILITIES) {
            this.isFacilitiesTabSelected = true;
        } else {
            this.isFacilitiesTabSelected = false;
        }
    }
    updateEditMode() {
        this.isGeneralEditMode = !this.isGeneralEditMode;
    }
    formatInput(event: KeyboardEvent) {
        const input = event.key;
        const currentValue = (event.target as HTMLInputElement).value;
        const isAlphabet = /^[a-zA-Z]+$/.test(event.key) && input?.length === 1;
        const isSpecialKey = /^(ArrowLeft|ArrowRight|Backspace|Delete|Enter|Spacebar)$/.test(event.key);

        if ((currentValue.length === 2 || currentValue.length === 5) && !isSpecialKey) {
            // Add a slash after the second and fifth characters
            (event.target as HTMLInputElement).value = currentValue + '/';
        } else if ((isAlphabet || currentValue.length >= 8) && !isSpecialKey) {
            event.preventDefault();
        }
    }

    addNewFacility() {
        const navigationExtras: NavigationExtras = {
            state: {
                organizationId: this.organizationId,
            },
        };
        this.router.navigateByUrl('admin/facility/new', navigationExtras);
    }

    saveUpdateOrganization() {
        if(this.organizationForm.invalid){
            this.toasterService.show({ title: 'Failure', body: 'Please Fill all Fields', type: 'error' });
            return;
        }
        this._organizationService
            .updateOrganization(this.organizationId, this.organizationForm.getRawValue())
            .pipe(take(1))
            .subscribe(
                (result: any) => {
                    if (result.success) {
                        this.toasterService.show({ title: 'Success', body: result.message, type: 'success' });
                        this.isGeneralEditMode = false;
                    } else {
                        this.toasterService.show({ title: 'Failure', body: result.message, type: 'error' });
                    }
                },
                (err) => {
                    this.toasterService.show({ title: 'Failure', body: err, type: 'error' });
                }
            );
    }
}
