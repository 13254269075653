import { Component, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { FormControl } from '@angular/forms';
@Component({
    selector: 'app-date-cell-renderer',
    templateUrl: './date-cell-renderer.component.html',
    styleUrls: ['./date-cell-renderer.component.scss'],
})
export class DateCellRendererComponent implements ICellRendererAngularComp {
    dateStringControl = new FormControl('2020-09-28');
    params: ICellRendererParams;
    valueType: 'value' | 'valueAsDate' = 'value';

    constructor(private renderer: Renderer2, private elementRef: ElementRef) {}

    agInit(params: ICellRendererParams): void {
        this.params = params;
        this.dateStringControl = new FormControl(params.data.date);
    }

    refresh() {
        return false;
    }
    // Use this function in the template on date field change event
    invokeSetRendererDate(event: any) {
        // Create setRendererDate function in parent component and it will be called here on date changed
        this.params.context.componentParent.setRendererDate({ data: this.params.data, value: event.target.value });
    }
    // For date input
    @HostListener('blur', []) onTouched: any;
    @HostListener('input', ['$event']) onChange: any;

    registerOnTouched(fn: () => void) {
        this.onTouched = fn;
    }

    registerOnChange(fn: (value: any) => void) {
        this.onChange = (event: any) => fn(event.target[this.valueType]);
    }

    writeValue(value: Date | string) {
        this.valueType = typeof value === 'string' ? 'value' : 'valueAsDate';
        this.renderer.setProperty(this.elementRef.nativeElement, this.valueType, value);
    }
}
