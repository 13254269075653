import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { OrganizationManagementService } from './organizationManagement.service';
import { FacilityManagementService } from './facilityManagement.service';
import * as ExcelJS from 'exceljs';
import { ExcelService } from './excel.service';

const baseUrl = `${environment.apiUrl}`;

@Injectable({ providedIn: 'root' })
export class PayorService {
    private payorFilters$: BehaviorSubject<any> = new BehaviorSubject({
        type: 'All',
        active: true,
    });
    constructor(
        private http: HttpClient,
        private organizationService: OrganizationManagementService,
        private facilityService: FacilityManagementService,
        private _excelService: ExcelService
    ) {}

    set payorFilters(val: Observable<any>) {
        val.subscribe((data) => {
            this.payorFilters$.next(data);
        });
    }

    get payorFilters(): Observable<any> {
        return this.payorFilters$.asObservable();
    }

    getPayorsList() {
        return this.http
            .get<any>(`${baseUrl}/payor/list?organizationId=${this.organizationService._currentOrganization.id}`)
            .pipe(map((v) => v.data));
    }
    getCurrentFacilityPayorsList() {
        return this.http
            .get<any>(`${baseUrl}/payor/currentFacilityPayors?facilityId=${this.facilityService._currentFacility.id}`)
            .pipe(map((v) => v.data));
    }
    addPayor(data) {
        return this.http.post(
            `${baseUrl}/payor?organizationId=${this.organizationService._currentOrganization.id}`,
            data,
            {
                withCredentials: true,
            }
        );
        //   .pipe(
        //     tap((data: any) => {
        //       const tempF = {
        //         facilityName: data.data.facilityName,
        //         facilityNumber: data.data.facilityNumber,
        //         facilityType: data.data.facilityType,
        //         id: data.data.id,
        //         mailingAddress: data.data.mailingAddress,
        //         organization: data.data.organization,
        //         status: data.data.status,
        //       };
        //       this._currentFacility = data.data;
        //       this.allFacilities.push(tempF);
        //       this._Facilities = this.allFacilities;
        //     })
        //   );
    }

    exportList(selectedPayors: any[] = [], head = {}, fileName = ''): void {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet(fileName);

        // Add headers
        const headers = Object.keys(head);
        const headerRow = worksheet.addRow(headers);
        headerRow.eachCell({ includeEmpty: true }, (cell) => {
            cell.font = { bold: true };
        });

        // Add data rows
        selectedPayors.forEach((payor) => {
            const row: any[] = [];

            for (const key in head) {
                // eslint-disable-next-line no-prototype-builtins
                if (head.hasOwnProperty(key)) {
                    let value: any = '';
                    if (key === 'Progress Note Freq.') {
                        // value = this.getFrequencyAndDuration(payor);
                    } else if (key === 'Additional Requirements') {
                        // value = this.getAdditionalRequirement(payor, true);
                    } else {
                        value = payor[head[key]];
                    }
                    row.push(value);
                }
            }
            worksheet.addRow(row);
        });

        // Adjust column widths
        worksheet.columns.forEach((column) => {
            let maxLength = 0;
            column.eachCell({ includeEmpty: true }, (cell) => {
                const columnLength = cell.value ? cell.value.toString().length : 10;
                if (columnLength > maxLength) {
                    maxLength = columnLength;
                }
            });
            column.width = maxLength < 10 ? 10 : maxLength;
        });

        // Trigger the download
        this._excelService.downloadExcelSheet(workbook, 'Payors');
    }
    editPayor(data, payorId) {
        return this.http.put(
            `${baseUrl}/payor/${payorId}?organizationId=${this.organizationService._currentOrganization.id}`,
            data,
            {
                withCredentials: true,
            }
        );
    }

    getPayorDetails(id: string, queryParams: string = null) {
        return this.http.get(
            `${baseUrl}/payor/${id}?organizationId=${this.organizationService._currentOrganization.id}${
                queryParams ? '&' + queryParams : ''
            }`,
            {
                withCredentials: true,
            }
        );
    }

    getPatientCountPerPayor(facilityId): Observable<any> {
        return this.http.get<any>(
            `${baseUrl}/payor/patient-count?facilityId=${facilityId}&organizationId=${this.organizationService._currentOrganization.id}`,
            {
                withCredentials: true,
            }
        );
    }

    getIdByPayorName(name: string): Observable<any> {
        return this.http.get<any>(
            `${baseUrl}/payor/name/${name}?organizationId=${this.organizationService._currentOrganization.id}`,
            {
                withCredentials: true,
            }
        );
    }

    downloadPayors(selectedPayors: any[] = []) {
        const requestBody = {
            payors: selectedPayors,
            organizationName: this.organizationService._currentOrganization.name,
        };
        return this.http.post(`${baseUrl}/payor/downloadPDF`, requestBody, {
            withCredentials: true,
        });
    }
    printTable(data, filterType) {
        const printWindow = window.open('', '', 'height=600,width=800');
        if (!printWindow) return;
        const orgName = this.organizationService._currentOrganization.name;
        const status = data[0].status;
        // Render Html to the new Window
        this.renderTable(data, printWindow, orgName, status, filterType);

        // Close the document to trigger the print dialog
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
    }

    getAdditionalRequirement(payor, convertToString = false) {
        const additionalRequirements = [];
        if (payor.pdpm) {
            additionalRequirements.push('PDPM');
        }
        if (payor.mds) {
            additionalRequirements.push('MDS');
        }
        if (payor.assistantTreatment) {
            additionalRequirements.push('Assistant treatment');
        }
        if (payor.assistantReduction) {
            additionalRequirements.push('CO and CQ');
        }
        if (payor._95Modifier) {
            additionalRequirements.push('95 Modifier');
        }
        if (payor.requiresAuthorization) {
            additionalRequirements.push('Requires Auth');
        }
        if (payor.correctCodingInitiative) {
            additionalRequirements.push('CCI');
        }
        return convertToString ? additionalRequirements.join(', ') : additionalRequirements;
    }
    getFrequencyAndDuration(payor: any): string {
        return `${payor.frequency} x Every ${payor.duration} ${payor.frequencyType}`;
    }

    renderTable(data, printWindow, orgName, status, filterType) {
        printWindow.document.write('<html>');
        printWindow.document.write('<style>table { width: 100%; border-collapse: collapse; }');
        printWindow.document.write('th, td { border: 1px solid black; padding: 8px; text-align: left; }');
        printWindow.document.write('th { background-color: #f2f2f2; }');
        printWindow.document.write(
            'div.header { display: flex; justify-content: space-between; margin-bottom: 10px; }'
        );
        printWindow.document.write('div.header span { font-weight: normal; }'); // Set default font weight for values
        printWindow.document.write('div.header .heading { font-weight: bold; }'); // Set font weight for headings
        printWindow.document.write('</style></head><body >');

        // Table Header
        printWindow.document.write('<table>');
        printWindow.document.write(
            '<thead><tr><th>Name</th><th>Type</th><th>Prog. Note Frequency</th><th>No. of Facilities</th><th>Add. Requirements</th></tr></thead>'
        );
        printWindow.document.write('<tbody>');

        // Header
        printWindow.document.write('<div class="header">');
        printWindow.document.write('<div>');
        printWindow.document.write('<span class="heading">Org Name: </span><span>' + orgName + '</span>');
        printWindow.document.write('</div>');
        printWindow.document.write('<div>');
        printWindow.document.write('<span class="heading">Type: </span><span>' + filterType + '</span>');
        printWindow.document.write('</div>');
        printWindow.document.write('<div>');
        printWindow.document.write('<span class="heading">Status: </span><span>' + status + '</span>');
        printWindow.document.write('</div>');
        printWindow.document.write('</div>');

        // Table Body
        data.forEach((payor) => {
            const frequencyAndDuration =
                (this.getFrequencyAndDuration(payor) || '').length > 0 ? this.getFrequencyAndDuration(payor) : 'n/a';

            const additionalRequirement =
                (this.getAdditionalRequirement(payor) || '').length > 0 ? this.getAdditionalRequirement(payor) : 'n/a';

            printWindow.document.write(
                `<tr><td>${payor.name}</td><td>${payor.type}</td><td>${frequencyAndDuration}</td><td>${payor.facilitiesCount}</td><td>${additionalRequirement}</td></tr>`
            );
        });

        printWindow.document.write('</tbody></table>');
        printWindow.document.write('</body></html>');
    }
}
