import { Injectable } from '@angular/core';

import { environment } from '@environments/environment';
import { Store } from '@ngrx/store';
import { loadingSpinnerState, PatientState, PlannerState } from '@app/store/store.interface';
import {
    cachedFacilityAdmissionChangedAction,
    cachedTherapyadmissionChangedAction,
    caseloadCurrentDateAction,
    facilityAdmissionChangedAction,
    facilityAdmissionRemovedAction,
    pastFacilityAdmissionsChangedAction,
    pastFacilityAdmissionsRemovedAction,
    patientChangedAction,
    patientRemovedAction,
    payorInformationChangedAction,
    payorInformationRemovedAction,
    plannerPatientChangedAction,
    plannerPatientRemovedAction,
    plannerStateChangedAction,
    plannerStatesResetAction,
    plannerTherapyAdmissionsChangedAction,
    plannerTherapyAdmissionsRemovedAction,
    schedulerCalendarChangedAction,
    schedulerChangedAction,
    spinnerDocumentStateChangeAction,
    spinnerStateChangeAction,
    spinnerStateResetAction,
    spinnerTherapyStateChangeAction,
    tabChangedAction,
    therapyadmissionChangedAction,
    therapyadmissionRemovedAction,
} from '@app/store/actions/index';
import { FacilityAdmission } from '@app/models';
import { PayorInformation, Scheduler, TherapyAdmission } from '@app/interfaces';
import { FacilityAdmissionService, TherapyAdmissionService } from '.';
import { catchError, map, Observable, of, switchMap, take, throwError } from 'rxjs';
import { payorRoutingChangedAction, payorRoutingsResetAction } from '@app/store/actions/payor-routing.actions';
import { HttpClient } from '@angular/common/http';
import { PayorInformationService } from './payorInformation.service';

const baseUrl = `${environment.apiUrl}/patient`;

@Injectable({ providedIn: 'root' })
export class StoreService {
    constructor(
        private store: Store<{
            patient: PatientState;
            facilityAdmission: FacilityAdmission;
            therapyAdmission: TherapyAdmission[];
            plannerState: PlannerState;
            loadingSpinner: loadingSpinnerState;
            documentLoadingSpinner: loadingSpinnerState;
            therapyLoadingSpinner: loadingSpinnerState;
            plannerTherapyAdmissions: TherapyAdmission[];
        }>,
        private facilityAdmissionService: FacilityAdmissionService,
        private therapyAdmissionService: TherapyAdmissionService,
        private payorInformationService: PayorInformationService,
        private http: HttpClient
    ) {}
    setLoadingState(loading) {
        this.store.dispatch(
            spinnerStateChangeAction({
                selectedLoadingSpinnerState: { isLoading: loading },
            })
        );
    }
    getLoadingState() {
        return this.store.select('loadingSpinner');
    }
    setDocumentLoadingState(loading) {
        this.store.dispatch(
            spinnerDocumentStateChangeAction({
                selectedLoadingSpinnerState: { isLoading: loading },
            })
        );
    }
    getDocumentLoadingState() {
        return this.store.select('documentLoadingSpinner');
    }
    getTherapyAdmissionState() {
        return this.store.select('therapyLoadingSpinner');
    }
    setTherapyAdmissionState(loading) {
        this.store.dispatch(
            spinnerTherapyStateChangeAction({
                selectedLoadingSpinnerState: { isLoading: loading },
            })
        );
    }
    resetPatientState() {
        return this.store.dispatch(patientRemovedAction());
    }

    resetFacilityAdmissionState() {
        return this.store.dispatch(facilityAdmissionRemovedAction());
    }
    resetTherapyAdmissionState() {
        return this.store.dispatch(therapyadmissionRemovedAction());
    }
    resetPastFacilityAdmissionsState() {
        return this.store.dispatch(pastFacilityAdmissionsRemovedAction());
    }
    setFacilityAdmission(facilityAdmission: FacilityAdmission) {
        this.store.dispatch(facilityAdmissionChangedAction({ facilityAdmission: facilityAdmission }));
    }
    setPastFacilityAdmissions(facilityAdmissions: FacilityAdmission[]) {
        this.store.dispatch(pastFacilityAdmissionsChangedAction({ facilityAdmissions: facilityAdmissions }));
    }
    resetCachedFacilityAdmissionState() {
        return this.store.dispatch(facilityAdmissionRemovedAction());
    }
    setCachedFacilityAdmission(facilityAdmission: FacilityAdmission) {
        this.store.dispatch(cachedFacilityAdmissionChangedAction({ facilityAdmission: facilityAdmission }));
    }
    setCachedTherapyAdmission(therapyAdmissions: TherapyAdmission[]) {
        this.store.dispatch(cachedTherapyadmissionChangedAction({ therapyadmission: therapyAdmissions }));
    }
    setTherapyAdmission(therapyAdmission: TherapyAdmission[]) {
        this.store.dispatch(therapyadmissionChangedAction({ therapyadmission: therapyAdmission }));
    }

    setPayorInformation(payorInformation: PayorInformation) {
        this.store.dispatch(payorInformationChangedAction({ payorInformation: payorInformation }));
    }
    setPayorRouting(payorRouting: string) {
        this.store.dispatch(payorRoutingChangedAction({ selectedTab: payorRouting }));
    }
    setCaseloadDate(date: Date) {
        this.store.dispatch(caseloadCurrentDateAction({ selectedDate: date }));
    }

    set admissionStepper(state) {
        this.store.dispatch(tabChangedAction({ selectedTab: state }));
    }

    getPatientState() {
        return this.store.select('patient');
    }

    initializePatientData(
        patientId: string,
        facility: string,
        facilityAdmissionId = undefined,
        facilitiesWithoutRestriction = false
    ): Observable<any> {
        return this.getPatientState().pipe(
            take(1),
            switchMap((data) => {
                if (data.id) {
                    this.resetAllPatientData();
                }
                this.setLoadingState(true);
                return this.getPatientOnId(patientId).pipe(
                    take(1),
                    switchMap((patient) => {
                        if (patient) this.updatePatientState(patient);
                        return this.facilityAdmissionService
                            .getFacilityAdmission(
                                patientId,
                                facility,
                                facilityAdmissionId,
                                facilitiesWithoutRestriction
                            )
                            .pipe(
                                take(1),
                                catchError((err) => {
                                    this.setLoadingState(false);
                                    return throwError(err);
                                }),
                                switchMap((facility) => {
                                    if (facility?.id) {
                                        this.setFacilityAdmission(facility);
                                        return this.therapyAdmissionService.getAdmissionOnFacilities(facility.id).pipe(
                                            take(1),
                                            switchMap((admissions) => {
                                                // admissions.forEach((admission) => {
                                                //   admission.documentation = this.mapDocumentation(admission);
                                                // });
                                                this.setTherapyAdmission(admissions);
                                                this.setLoadingState(false);
                                                return this.payorInformationService.getAllInsurances(facility?.id).pipe(
                                                    take(1),
                                                    switchMap((payor) => {
                                                        this.setPayorInformation(
                                                            payor[0] == undefined
                                                                ? { payor: { name: 'N/A' } }
                                                                : payor[0]
                                                        );
                                                        this.setLoadingState(false);
                                                        return of(payor);
                                                    })
                                                );
                                            })
                                        );
                                    } else {
                                        this.setLoadingState(false);
                                        return of(facility);
                                    }
                                })
                            );
                    })
                );
            })
        );
    }
    mapDocumentation(admission) {
        try {
            if (!admission.documentation) {
                admission.documentation = {
                    evaluation: {
                        medicalReview: {
                            reasonForReferral: {
                                description: '',
                                required: true,
                            },
                            chiefComplaint: {
                                description: '',
                                required: true,
                            },
                            precautions: { description: [], required: true },
                            contraindications: { description: [], required: true },
                            medications: { description: [], required: true },
                            treatmentDiagnosis: { description: [], required: true },
                            medicalDiagnosis: { description: [], required: true },
                        },
                        backgroundAssessment: {
                            patientGoals: { descriptions: [], required: false },
                            priorLevelOfFunction: {
                                description: '',
                                required: true,
                            },
                            socialDeterminantsOfHealth: { descriptions: [], required: false },
                            environmentalFactors: { descriptions: [], required: false },
                            previousTherapy: {
                                description: '',
                                required: false,
                            },
                            careGiverEducation: {
                                description: '',
                                required: false,
                            },
                        },
                        medicalNecessity: {
                            therapyNecessity: {
                                description: '',
                                required: true,
                            },
                            interventionApproaches: {
                                description: '',
                                required: true,
                            },
                        },
                        patientAssessment: {
                            functionalDeficits: { required: true, description: [] as any },
                            impairments: { required: true, description: [] as any },
                            standardizedTests: { required: true, description: [] as any },
                            temp: {
                                description: '',
                                required: false,
                            },
                        },
                        planOfCare: {
                            goal: {
                                description: '',
                                required: true,
                            },
                            frequencyAndDuration: {
                                description: [] as any,
                                required: true,
                            },
                            skilledServices: {
                                description: [] as any,
                                required: true,
                            },
                            certification: {
                                description: {
                                    certificationFrom: admission.documentation.evaluation.planOfCare.fromDate || '',
                                    certificationThrough:
                                        admission.documentation.evaluation.planOfCare.throughDate || '',
                                },
                                required: true,
                            },
                            // certificationFrom: {
                            //   description: '',
                            //   required: true,
                            // },
                            // certificationThrough: {
                            //   description: '',
                            //   required: true,
                            // },
                            goalPurpose: {
                                description: '',
                                required: true,
                            },
                            anticipatedDischarge: {
                                description: {
                                    anticipatedDischargePlans: '',
                                    anticipatedDischargeDestination: '',
                                },
                                required: true,
                            },
                            // anticipatedDischargePlans: {
                            //   description: '',
                            //   required: true,
                            // },
                            // anticipatedDischargeDestination: {
                            //   description: '',
                            //   required: true,
                            // },
                            temp: {
                                description: '',
                                required: false,
                            },
                            goals: [],
                        },
                        evaluationType: {
                            description: { description: '', evalType: '' },
                            required: true,
                        },
                    },

                    dailyNote: {
                        objectiveAssessment: {
                            description: {
                                respiratoryRate: '',
                                temperature: '',
                                pulseRate: '',
                                bloodPressure: '',
                                saturation: '',
                            },
                            required: false,
                        },
                        subjectiveAssessment: {
                            description: '',
                            required: false,
                        },
                        caregiverEducation: {
                            description: '',
                            required: false,
                        },
                        assessment: {
                            description: { responseTreatment: '' },
                            required: false,
                        },
                        plan: {
                            description: '',
                            required: false,
                        },
                    },
                    progressNote: {
                        subjectiveAssessment: {
                            description: '',
                            required: false,
                        },
                        objectiveAssessment: {
                            description: '',
                            required: false,
                        },
                        plan: {
                            description: '',
                            required: false,
                        },
                    },
                    addendum: {
                        description: '',
                        required: false,
                    },
                    updatedPlanOfCareNote: {
                        subjectiveAssessment: {
                            description: '',
                            required: true,
                        },
                        objectiveAssessment: {
                            impairments: {
                                description: [],
                            },
                            functionalDeficits: {
                                description: [],
                            },
                            standardizedTests: {
                                description: [],
                            },
                        },
                    },
                    dischargeNote: {
                        subjectiveAssessment: {
                            description: '',
                            required: false,
                        },
                        assessment: {
                            description: { clinicalImpression: '', endOfCareDate: '', endOfCareReason: '' },
                            required: false,
                        },
                        assessmentDischarge: {
                            description: { dischargeInstructions: '' },
                            required: true,
                        },
                        dischargeDestination: {
                            description: '',
                            required: false,
                        },
                        dischargePlans: {
                            description: '',
                            required: false,
                        },
                    },
                    reCertNote: {
                        subjectiveAssessment: {
                            description: '',
                            required: false,
                        },
                        objectiveAssessment: {
                            impairments: [],
                            functionalDeficits: [],
                            standardizedTests: [],
                        },
                        assessment: {
                            description: {
                                clinicalImpression: '',
                                therapyNecessity: '',
                                prognosisAchievingRehabGoals: '',
                                reasonContinuingTreatment: '',
                                updatesToTreatment: '',
                                anticipatedDischargeDestination: '',
                                anticipatedDischargePlans: '',
                            },
                            required: false,
                        },
                    },
                };
                // this.updateDocumentation(this.currentTherapyAdmission);
            } else {
                admission.documentation = {
                    id: admission.documentation._id,
                    evaluation: {
                        id: admission.documentation.evaluation._id,
                        medicalReview: {
                            reasonForReferral: {
                                description: admission.documentation.evaluation.medicalReview.reasonForReferral || '',
                                required: true,
                            },
                            chiefComplaint: {
                                description: admission.documentation.evaluation.medicalReview.chiefComplaint || '',
                                required: true,
                            },
                            precautions: {
                                description: admission.documentation.evaluation.medicalReview.precautions || [],
                                required: true,
                            },
                            contraindications: {
                                description: admission.documentation.evaluation.medicalReview.contraindications || [],
                                required: true,
                            },
                            medications: {
                                description: admission.documentation.evaluation.medicalReview.medications,
                                required: true,
                            },
                            treatmentDiagnosis: {
                                description: admission.documentation.evaluation.medicalReview.treatmentDiagnosis,
                                required: true,
                            },
                            medicalDiagnosis: {
                                description: admission.documentation.evaluation.medicalReview.medicalDiagnosis,
                                required: true,
                            },
                            frequencyAndDuration: {
                                description: admission.documentation.evaluation.planOfCare.frequencyAndDuration || [],
                                required: true,
                            },
                            skilledServices: {
                                description: admission.documentation.evaluation.planOfCare.skilledServices || [],
                                required: true,
                            },
                            certification: {
                                description: {
                                    certificationFrom: admission.documentation.evaluation.planOfCare.fromDate || '',
                                    certificationThrough:
                                        admission.documentation.evaluation.planOfCare.throughDate || '',
                                },
                            },
                            // certificationFrom: {
                            //   description: admission.documentation.evaluation.planOfCare.fromDate || '',
                            //   required: true,
                            // },
                            // certificationThrough: {
                            //   description: admission.documentation.evaluation.planOfCare.throughDate || '',
                            //   required: true,
                            // },
                            goalPurpose: {
                                description: admission.documentation.evaluation.planOfCare.goalPurpose || '',
                                required: true,
                            },
                            anticipatedDischarge: {
                                description: {
                                    anticipatedDischargePlans:
                                        admission.documentation.evaluation.planOfCare.anticipatedDischarge
                                            .dischargePlans,
                                    anticipatedDischargeDestination:
                                        admission.documentation.evaluation.planOfCare.anticipatedDischarge
                                            .dischargeDestination || '',
                                },
                                required: true,
                            },
                            // anticipatedDischargePlans: {
                            //   description: admission.documentation.evaluation.planOfCare.anticipatedDischarge.dischargePlans || '',
                            //   required: true,
                            // },
                            // anticipatedDischargeDestination: {
                            //   description:
                            //     admission.documentation.evaluation.planOfCare.anticipatedDischarge.dischargeDestination || '',
                            //   required: true,
                            // },
                            temp: {
                                description: admission.documentation.evaluation.planOfCare.temp || '',
                                required: false,
                            },
                            goals: admission.documentation.evaluation.planOfCare.goals || [],
                        },
                        backgroundAssessment: {
                            patientGoals: {
                                description: admission.documentation.evaluation.backgroundAssessment.patientGoals || [],
                                required: false,
                            },
                            priorLevelOfFunction: {
                                description:
                                    admission.documentation.evaluation.backgroundAssessment.priorLevelOfFunction || '',
                                required: true,
                            },
                            socialDeterminantsOfHealth: {
                                description:
                                    admission.documentation.evaluation.backgroundAssessment
                                        .socialDeterminantsOfHealth || [],
                                required: false,
                            },
                            environmentalFactors: {
                                description:
                                    admission.documentation.evaluation.backgroundAssessment.environmentalFactors || [],
                                required: false,
                            },
                            previousTherapy: {
                                description:
                                    admission.documentation.evaluation.backgroundAssessment.previousTherapy || '',
                                required: false,
                            },
                            careGiverEducation: {
                                description:
                                    admission.documentation.evaluation.backgroundAssessment.careGiverEducation || '',
                                required: false,
                            },
                        },
                        medicalNecessity: {
                            therapyNecessity: {
                                description: admission.documentation.evaluation.medicalNecessity.therapyNecessity || '',
                                required: true,
                            },
                            interventionApproaches: {
                                description:
                                    admission.documentation.evaluation.medicalNecessity.interventionApproaches || '',
                                required: true,
                            },
                        },
                        patientAssessment: {
                            functionalDeficits: {
                                required: true,
                                description:
                                    admission.documentation.evaluation.patientAssessment?.functionalDeficits || [],
                            },
                            impairments: {
                                required: true,
                                description: admission.documentation.evaluation.patientAssessment?.impairments || [],
                            },
                            standardizedTests: {
                                required: true,
                                description:
                                    admission.documentation.evaluation.patientAssessment?.standardizedTests || [],
                            },
                        },
                        planOfCare: {
                            goal: {
                                description: admission.documentation.evaluation.planOfCare.goal || '',
                                required: true,
                            },
                            frequencyAndDuration: {
                                description: admission.documentation.evaluation.planOfCare.frequencyAndDuration || [],
                                required: true,
                            },
                            skilledServices: {
                                description: admission.documentation.evaluation.planOfCare.skilledServices || [],
                                required: true,
                            },
                            certification: {
                                description: {
                                    certificationFrom: admission.documentation.evaluation.planOfCare.fromDate || '',
                                    certificationThrough:
                                        admission.documentation.evaluation.planOfCare.throughDate || '',
                                },
                            },
                            // certificationFrom: {
                            //   description: admission.documentation.evaluation.planOfCare.fromDate || '',
                            //   required: true,
                            // },
                            // certificationThrough: {
                            //   description: admission.documentation.evaluation.planOfCare.throughDate || '',
                            //   required: true,
                            // },
                            goalPurpose: {
                                description: admission.documentation.evaluation.planOfCare.goalPurpose || '',
                                required: true,
                            },
                            anticipatedDischarge: {
                                description: {
                                    anticipatedDischargePlans:
                                        admission.documentation.evaluation.planOfCare.anticipatedDischarge
                                            .dischargePlans,
                                    anticipatedDischargeDestination:
                                        admission.documentation.evaluation.planOfCare.anticipatedDischarge
                                            .dischargeDestination || '',
                                },
                                required: true,
                            },
                            // anticipatedDischargePlans: {
                            //   description: admission.documentation.evaluation.planOfCare.anticipatedDischarge.dischargePlans || '',
                            //   required: true,
                            // },
                            // anticipatedDischargeDestination: {
                            //   description:
                            //     admission.documentation.evaluation.planOfCare.anticipatedDischarge.dischargeDestination || '',
                            //   required: true,
                            // },
                            temp: {
                                description: admission.documentation.evaluation.planOfCare.temp || '',
                                required: false,
                            },
                            goals: admission.documentation.evaluation.planOfCare.goals || [],
                        },
                        evaluationType: {
                            description: {
                                description: admission.documentation.evaluation.evaluationType.description || '',
                                evalType: admission.documentation.evaluation.evaluationType.evalType || '',
                            },
                            required: true,
                        },
                        // evaluationType: {
                        //   description: {
                        //     description: admission.documentation.evaluation.evaluationType.description || '',
                        //     required: true,
                        //   },
                        //   evalType: {
                        //     description: admission.documentation.evaluation.evaluationType.type || '',
                        //     required: true,
                        //   },
                        // },
                    },
                    dailyNote: {
                        id: admission.documentation.dailyNote._id,
                        objectiveAssessment: {
                            description: {
                                respiratoryRate: '',
                                temperature: '',
                                pulseRate: '',
                                bloodPressure: '',
                                saturation: '',
                            },
                            required: false,
                        },
                        subjectiveAssessment: {
                            description: '',
                            required: false,
                        },
                        caregiverEducation: {
                            description: '',
                            required: false,
                        },
                        assessment: {
                            description: { responseTreatment: '' },
                            required: false,
                        },
                        plan: {
                            description: '',
                            required: false,
                        },
                    },
                    progressNote: {
                        subjectiveAssessment: {
                            description: '',
                            required: false,
                        },
                        objectiveAssessment: {
                            description: '',
                            required: false,
                        },
                        plan: {
                            description: '',
                            required: false,
                        },
                    },
                    addendum: {
                        description: '',
                        required: false,
                    },
                    updatedPlanOfCareNote: {
                        subjectiveAssessment: {
                            description: '',
                            required: true,
                        },
                        objectiveAssessment: {
                            impairments: {
                                description: [],
                            },
                            functionalDeficits: {
                                description: [],
                            },
                            standardizedTests: {
                                description: [],
                            },
                        },
                    },
                    dischargeNote: {
                        subjectiveAssessment: {
                            description: '',
                            required: false,
                        },
                        assessment: {
                            description: { clinicalImpression: '', endOfCareDate: '', endOfCareReason: '' },
                            required: false,
                        },
                        assessmentDischarge: {
                            description: { dischargeInstructions: '' },
                            required: true,
                        },
                        dischargeDestination: {
                            description: '',
                            required: false,
                        },
                        dischargePlans: {
                            description: '',
                            required: false,
                        },
                    },
                    reCertNote: {
                        subjectiveAssessment: {
                            description: '',
                            required: false,
                        },
                        objectiveAssessment: {
                            impairments: [],
                            functionalDeficits: [],
                            standardizedTests: [],
                        },
                        assessment: {
                            description: {
                                clinicalImpression: '',
                                therapyNecessity: '',
                                prognosisAchievingRehabGoals: '',
                                reasonContinuingTreatment: '',
                                updatesToTreatment: '',
                                anticipatedDischargeDestination: '',
                                anticipatedDischargePlans: '',
                            },
                            required: false,
                        },
                    },
                };
                // this.updateDocumentation(this.currentTherapyAdmission);
            }
        } catch (error) {
            console.log(error);
        }

        return admission.documentation;
    }
    //to avoid circular dependency
    getPatientOnId(id: string) {
        return this.http.get<any>(`${baseUrl}/` + id).pipe(
            map((patient) => {
                return patient.data;
            })
        );
    }

    updatePatientState(data) {
        this.store.dispatch(patientChangedAction({ patient: data }));
    }

    updatePlannerState(state) {
        this.store.dispatch(plannerStateChangedAction({ selectedPlannerState: state }));
    }

    resetPlannerState() {
        this.store.dispatch(plannerStatesResetAction());
    }

    updatePlannerPatient(state) {
        this.store.dispatch(plannerPatientChangedAction({ plannerPatient: state }));
    }
    resetPlannerPatientState() {
        this.store.dispatch(plannerPatientRemovedAction());
    }

    updatePayorState(state) {
        this.store.dispatch(payorInformationChangedAction({ payorInformation: state }));
    }
    resetPayorInformation() {
        this.store.dispatch(payorInformationRemovedAction());
    }
    resetPayorRouting() {
        this.store.dispatch(payorRoutingsResetAction());
    }
    resetSpinnerState() {
        this.store.dispatch(spinnerStateResetAction());
    }
    setPlannerTherapyAdmissions(therapyAdmissions: TherapyAdmission[]) {
        this.store.dispatch(
            plannerTherapyAdmissionsChangedAction({
                therapyadmissions: therapyAdmissions,
            })
        );
    }
    setScheduler(scheduler: Scheduler) {
        this.store.dispatch(
            schedulerChangedAction({
                scheduler: scheduler,
            })
        );
    }
    setSchedulerCalendar(date: Date) {
        this.store.dispatch(schedulerCalendarChangedAction({ selectedDate: date }));
    }
    getPlannerTherapyAdmissions() {
        return this.store.select('plannerTherapyAdmissions');
    }
    resetPlannerTherapyAdmissions() {
        this.store.dispatch(plannerTherapyAdmissionsRemovedAction());
    }

    resetAllPatientData() {
        this.resetPatientState();
        this.resetFacilityAdmissionState();
        this.resetTherapyAdmissionState();
        this.resetPlannerPatientState();
        this.resetPayorInformation();
    }
}
