import { Component, OnDestroy } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {
    ContractService,
    FacilityManagementService,
    OrganizationManagementService,
    ToasterService,
} from '@app/services';
import { PayorService } from '@app/services/payor.service';
import { first } from 'rxjs';
import { DatePipe, Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { ColDef, GridReadyEvent } from 'ag-grid-community';
import * as _ from 'lodash';
import { cloneDeep } from 'lodash';
import { TIME } from '@app/helpers/constants';

@Component({
    selector: 'app-add-payors',
    templateUrl: './add-payors.component.html',
    styleUrls: ['./add-payors.component.scss'],
})
export class AddPayorsComponent implements OnDestroy {
    public SKELETON_CSS = {
        CELL: {
            width: '96%',
            height: '3.4em',
            margin: '0 2em 0.1em 2em',
        },
    };

    selectedPayors = [];
    configureIndividually = false;
    contractRequired = false;
    facilityName = '';

    addContractForm: FormGroup;
    payorsForm: FormGroup;

    contractTypeList = [];
    contractVariantList: any[][] = [];
    contractList = [];

    selectedContract: any = [];
    selectedVariant: string;
    currentContractIndex = 0;
    currentContractVariant: string;

    allFields: any[];
    contractTemplateList: any[];

    groupData: any[] = [{ name: 'Ohio West' }];

    controlsList: any; // Update with your list of controls
    currentFacilityId: string;

    payorsList: Array<any> = [];
    contractData: any;
    editMode = false;
    selectedPayorsDefs: any;
    importPayor = false;
    startMinDate: any[][] = [];
    activeContracts: any[] = [];
    payorMasterCopy: Array<any> = [];
    payorTypes: Array<any> = [];
    facilities: Array<any> = [];
    facilityCols: ColDef[] = [
        {
            field: 'name',
            headerName: 'Payor Name',
            sortable: true,
            sort: 'desc',
            sortingOrder: ['asc', 'desc'],
        },
    ];
    payorCols: ColDef[] = [
        {
            field: 'name',
            headerName: 'Payor Name',
            checkboxSelection: true,
            headerCheckboxSelection: true,
            sortable: true,
            sort: 'desc',
            sortingOrder: ['asc', 'desc'],
        },
    ];
    groupCols: ColDef[] = [
        {
            field: 'name',
            headerName: 'Payor Name',
            checkboxSelection: true,
            headerCheckboxSelection: true,
            sortable: true,
            sort: 'desc',
            sortingOrder: ['asc', 'desc'],
            tooltipField: 'name',
            headerTooltip: 'Name',
        },
        {
            field: 'type',
            headerName: 'Payor Type',
            sortable: true,
            sort: 'desc',
            sortingOrder: ['asc', 'desc'],
            tooltipField: 'type',
            headerTooltip: 'Payor Type',
        },
        {
            field: 'frequency',
            headerName: 'Progress Note Frequency',
            sortable: true,
            sort: 'desc',
            sortingOrder: ['asc', 'desc'],
            tooltipField: 'frequency',
            headerTooltip: 'Progress Note Frequency',
        },
        {
            field: 'additionalRequirements',
            headerName: 'Additional Requirements',
            sortable: true,
            sort: 'desc',
            sortingOrder: ['asc', 'desc'],
            tooltipField: 'additionalRequirements',
            headerTooltip: 'Additional Requirements',
        },
    ];
    facilityList = [];
    facilityMasterCopy = [];
    gridApi: any;
    gridApi2: any;
    formControlPath: any;
    payorProperties = [
        { name: 'pdpm' },
        { name: 'mds' },
        { name: 'assistantReduction' },
        { name: 'requiresAuthorization' },
        { name: 'concurrent' },
        { name: 'coTreatment' },
    ];
    isLoading = true;
    submitContract = false;
    selectedType = 'All';
    selectedFacility = 'All';
    searchTerm = '';
    isFilterApplied = false;
    selectedDataCount = 0;
    selectedTab = 'previous';
    public themeClass = 'ag-theme-quartz';
    tabs = [
        {
            url: 'selectFacilities',
            title: 'Select Payors',
            titleCount: 0,
            highlight: true,
            underline: true,
            isDone: false,
        },
        {
            url: 'setupContracts',
            title: 'Setup Contracts',
            titleCount: 0,
            highlight: false,
            underline: false,
            isDone: false,
        },
    ];
    addedContracts = [];

    constructor(
        private fb: FormBuilder,
        private payorService: PayorService,
        private contractService: ContractService,
        private toasterService: ToasterService,
        private location: Location,
        private route: ActivatedRoute,
        private _facilityManagementService: FacilityManagementService,
        private organizationManagement: OrganizationManagementService,
        private datePipe: DatePipe
    ) {}
    initGrid(event: any) {
        this.gridApi = event.api;
        this.gridApi.sizeColumnsToFit();
    }
    initGrid2(event: any) {
        this.gridApi2 = event.api;
    }
    cleanFilters() {
        this.selectedType = 'All';
        this.selectedFacility = 'All';
        this.searchTerm = '';
        this.handleFilterChange();
    }
    filterCheck() {
        return this.selectedType != 'All' || this.selectedFacility != 'All' || this.searchTerm != '';
    }
    handleSearch(searchTerm: any) {
        if (searchTerm.target) {
            searchTerm = '';
        }
        this.searchTerm = searchTerm;
        this.handleFilterChange();
    }
    onRowDataChanged(event) {
        event.api.forEachNode((node) => {
            if (node.data.checked) {
                node.setSelected(true);
            }
        });
    }
    rowSelection(event: Array<any>) {
        this.selectedPayors.length = 0;
        this.updateSelection(event);
        if (this.gridApi2) {
            this.gridApi2.setRowData(this.selectedPayors);
            this.gridApi2.redrawRows();
        }
    }

    ngOnDestroy(): void {
        this.selectedPayors = [];
        if (this.payorMasterCopy) {
            this.payorMasterCopy.map((payor) => (payor.checked = false));
        }
    }
    getSelectedPayors() {
        if (!this.isFilterApplied && this.selectedPayors.length > 0) {
            return this.selectedPayors;
        } else if (this.isFilterApplied && this.selectedDataCount > 0) {
            return this.payorMasterCopy.filter((item) => item.checked);
        } else {
            return [];
        }
    }
    updateSelection(selectedRows) {
        this.payorsList.map((f) => (f.checked = false));
        selectedRows.map((row) => {
            this.selectedPayors.push(cloneDeep(row));
            this.payorMasterCopy.map((fRow) => {
                if (row.id === fRow.id && (!this.importPayor || fRow.contractId == row.contractId)) {
                    fRow.checked = true;
                }
            });
        });
        this.selectedDataCount = this.payorMasterCopy.filter((item) => item.checked).length;
        this.selectedPayors = this.getSelectedPayors();
    }
    handleFilterChange() {
        this.selectedPayors = [];
        if (this.payorMasterCopy) {
            this.payorsList = this.payorMasterCopy.filter((cl) => {
                return (
                    (this.selectedType != 'All' ? cl.type?.includes(this.selectedType) : true) &&
                    (this.selectedFacility != 'All' && this.importPayor
                        ? cl.facilityName?.includes(this.selectedFacility)
                        : true) &&
                    (this.searchTerm
                        ? cl.name
                              .replace(/[^a-zA-Z0-9]/g, '')
                              .toLocaleLowerCase()
                              .includes(this.searchTerm.replace(/[^a-zA-Z0-9]/g, '').toLocaleLowerCase())
                        : true)
                );
            });
            this.payorMasterCopy.map((f) => (f.checked = false));
            this.selectedDataCount = this.payorMasterCopy.filter((item) => item.checked).length;
        }
        this.isFilterApplied = this.filterCheck();
    }

    validatingContracts(currentContractFrom, currentContractThru) {
        currentContractFrom = currentContractFrom ? new Date(currentContractFrom) : null;
        currentContractThru = currentContractThru ? new Date(currentContractThru) : null;
        // adding 6 hours to make the time zones same
        currentContractFrom = currentContractFrom
            ? currentContractFrom.setHours(currentContractFrom.getHours() + 6)
            : null;
        currentContractThru = currentContractThru
            ? currentContractThru.setHours(currentContractThru.getHours() + 6)
            : null;

        // Thru Date must be ahead of From Date
        if (currentContractThru && currentContractFrom > currentContractThru) {
            this.toasterService.show({
                title: 'Error',
                body: 'Through Date Must be Greater Than From Date',
                type: 'error',
            });
            return false;
        }

        for (let index = 0; index < this.addedContracts.length; index++) {
            const contract = this.addedContracts[index];
            //Checking weather same payor is already added with same contract
            const matchFound = this.selectedPayors.some(
                (payor) => payor?.id?.toLowerCase() === contract?.payor?._id?.toLowerCase() && !contract.contractEnabled
            );
            if (matchFound) {
                this.toasterService.show({
                    title: 'Error',
                    body: 'Payor Already Added',
                    type: 'error',
                });
                return false;
            }
        }

        if (this.addedContracts?.length) {
            for (let index = 0; index < this.addedContracts.length; index++) {
                const contract = this.addedContracts[index];
                //Checking weather same payor is already added with same contract
                const matchFound = this.selectedPayors.some(
                    (payor) => payor?.id?.toLowerCase() === contract?.payor?._id?.toLowerCase()
                );
                if (matchFound && contract?.contractStatus?.toLowerCase() === 'active') {
                    // if contract details are optional and adding a new contract then don't
                    // allow creation
                    if (
                        !this.contractRequired &&
                        this.contractData?._id?.toLowerCase() !== contract?._id?.toLowerCase() &&
                        !this.editMode
                    ) {
                        this.toasterService.show({
                            title: 'Error',
                            body: 'Contract with this payor already added',
                            type: 'error',
                        });
                        return false;
                    }

                    // if in edit mode we need to make sure that we are not comparing dates of the same contract we opened right now
                    if (!this.editMode || this.contractData?._id?.toLowerCase() !== contract?._id?.toLowerCase()) {
                        // Assuming contract.dateFrom and contract.dateThrough are date strings or can be converted to Date objects
                        const dateFrom = contract.dateFrom ? new Date(contract.dateFrom) : null;
                        const dateThrough = contract.dateThrough ? new Date(contract.dateThrough) : null;

                        // Extract hours from the Date objects, if they exist
                        const contractFrom = dateFrom ? dateFrom.getTime() : null;
                        const contractThrough = dateThrough ? dateThrough.getTime() : null;

                        if (!contractThrough) {
                            if (currentContractThru >= contractFrom || !currentContractThru) {
                                this.toasterService.show({
                                    title: 'Error',
                                    body: 'The effective dates overlap with another contract for the same payor and facility.',
                                    type: 'error',
                                });
                                return false;
                            } else {
                                if (currentContractThru < contractFrom) {
                                    return true;
                                }
                            }
                        }

                        if (
                            (currentContractFrom >= contractFrom && currentContractFrom <= contractThrough) ||
                            (currentContractThru >= contractFrom && currentContractThru <= contractThrough) ||
                            (!currentContractThru && currentContractFrom <= contractThrough)
                        ) {
                            this.toasterService.show({
                                title: 'Error',
                                body: 'Cannot Add Same Contract with Overlapping Dates',
                                type: 'error',
                            });
                            return false;
                        }

                        if (currentContractFrom <= contractFrom && currentContractThru >= contractFrom) {
                            this.toasterService.show({
                                title: 'Error',
                                body: 'Cannot Add Same Contract with Overlapping Dates',
                                type: 'error',
                            });
                            return false;
                        }
                    }
                }
            }
        }
        return true;
    }

    changeTab(tab: 'next' | 'previous' | 'previous-page') {
        if (tab == 'previous-page') {
            window.history.back();
        }
        this.selectedTab = tab;
        if (tab === 'next') {
            if (this.importPayor) {
                this.configureIndividually = true;
                this.selectedPayors.map((payor, index: any) => {
                    this.importPayors(payor, index);
                });
            }
            if (this.configureIndividually && !this.importPayor) {
                this.configureContractsIndividually();
            }
            this.tabs[this.tabs.length - 1].highlight = true;
            this.tabs[this.tabs.length - 1].underline = true;
            this.tabs[0].isDone = true;
        } else if (tab === 'previous') {
            this.tabs[this.tabs.length - 1].highlight = false;
            this.tabs[this.tabs.length - 1].underline = false;

            this.tabs[0].isDone = false;
        }
    }
    ngOnInit(): void {
        this.addContractForm = this.fb.group({
            contracts: this.fb.array([]),
        });
        this.addedContracts = this.contractService.addedPayors;
        this.route.queryParamMap.subscribe({
            next: (params) => {
                this.currentFacilityId = params.get('id');
                this.facilityName = params.get('name');
                this.editMode = params.get('edit') as unknown as boolean;
                this.contractData = JSON.parse(params.get('data'));
                this.importPayor = params.get('payor') as unknown as boolean;
                // getting contracts against facility id
                if (!this.addedContracts?.length) {
                    this.contractService
                        .getContractFacilityAssignment(this.currentFacilityId)
                        .subscribe((contracts: any) => {
                            this.addedContracts = contracts.data;
                        });
                }
                if (this.importPayor) {
                    this.groupCols = [
                        {
                            field: 'name',
                            headerName: 'Payor Name',
                            checkboxSelection: true,
                            headerCheckboxSelection: true,
                            sortable: true,
                            sort: 'desc',
                            sortingOrder: ['asc', 'desc'],
                            tooltipField: 'name',
                            headerTooltip: 'Name',
                        },
                        {
                            field: 'type',
                            headerName: 'Payor Type',
                            sortable: true,
                            sort: 'desc',
                            sortingOrder: ['asc', 'desc'],
                            tooltipField: 'type',
                            headerTooltip: 'Payor Type',
                        },
                        {
                            field: 'facilityName',
                            headerName: 'Facility',
                            sortable: true,
                            sort: 'desc',
                            sortingOrder: ['asc', 'desc'],
                            tooltipField: 'facilityName',
                            headerTooltip: 'Facility',
                        },

                        {
                            field: 'frequency',
                            headerName: 'Progress Note Frequency',
                            sortable: true,
                            sort: 'desc',
                            sortingOrder: ['asc', 'desc'],
                            tooltipField: 'frequency',
                            headerTooltip: 'Progress Note Frequency',
                        },
                        {
                            field: 'additionalRequirements',
                            headerName: 'Additional Requirements',
                            sortable: true,
                            sort: 'desc',
                            sortingOrder: ['asc', 'desc'],
                            tooltipField: 'additionalRequirements',
                            headerTooltip: 'Additional Requirements',
                        },
                    ];
                    this.tabs[1].title = 'Review Contracts';
                }
                this.selectedPayorsDefs = cloneDeep(this.groupCols);
                this.selectedPayorsDefs[0].checkboxSelection = false;
                this.selectedPayorsDefs[0].headerCheckboxSelection = false;
                if (!this.editMode) {
                    const contractForm = this.fb.group({
                        payor: this.contractData?.payor?._id,
                        facility: this.currentFacilityId,
                        contractEnabled: [true],
                        dateFrom: ['', Validators.required],
                        dateThrough: ['', Validators.required],
                        contractType: ['', Validators.required],
                        contractVariant: ['', Validators.required],
                        openEnded: [this.editMode ? this.contractData?.openEnded : true],
                        controlsArray: this.fb.array([]),
                        allDisciplines: [''],
                        billableDischarge: [false],
                        levels: this.fb.array([]),
                    });
                    this.contracts.push(contractForm);
                }
            },
        });

        this.formControlPath = this.addContractForm.controls.contracts['controls'];
        const queryParams$ = this.route.queryParamMap;
        const queryParamsData$ = this.route.queryParams;
        const contractSkeleton$ = this.contractService
            .getContractSkeleton()
            .toPromise()
            .catch(() => null);
        const allContracts$ = this.contractService
            .getAllContracts(this.organizationManagement._currentOrganization.id)
            .toPromise()
            .catch(() => null);
        const contractTemplate$ = this.contractService
            .getContractTemplate(this.organizationManagement._currentOrganization.id)
            .toPromise()
            .catch(() => null);
        const payorList$ = this.payorService
            .getPayorsList()
            .toPromise()
            .catch(() => null);

        Promise.all([queryParams$, queryParamsData$, contractSkeleton$, allContracts$, contractTemplate$, payorList$])
            .then((result) => {
                const contracts = result[2];
                const allContracts = result[3];
                const contractTemplate = result[4];
                const payorList = result[5];

                if (contracts) {
                    this.contractList = contracts.data;
                    this.contractTypeList = this.contractList.map((cont: any) => cont.label);
                }

                if (allContracts) {
                    this.activeContracts = allContracts.data;
                    if (this.importPayor) {
                        this.facilitySelectionChanged(allContracts.data);
                    }
                }

                if (contractTemplate) {
                    this.contractTemplateList = contractTemplate.data;
                }
                if (payorList && !this.importPayor) {
                    payorList.map((v) => {
                        this.payorsList.push({
                            value: v.name,
                            selected: false,
                            id: v._id,
                            type: v.type,
                            frequency: v.frequency?.replace(/x/g, '') + ' X Every ' + v.duration + ' Visits',
                            additionalRequirements: this.doesPayorHaveAdditionalProperties(v),
                        });
                    });
                    this.payorsList = this.payorsList.map((item) => {
                        return {
                            id: item.id,
                            name: item.value,
                            value: item.value,
                            type: item.type,
                            frequency: item.frequency,
                            additionalRequirements: item.additionalRequirements,
                        };
                    });
                    this.payorMasterCopy = [...this.payorsList];
                    this.payorTypes = _.uniq(this.payorMasterCopy.map((v) => v.type));

                    this.isLoading = false;
                    // if (this.importPayor) {
                    //     this.payorsList = [];
                    // }
                }

                if (this.contractData) {
                    this.selectPayor(this.contractData);
                    this.contractTemplateChanged(this.contractData, 0);
                }
                this.handleChange(this.contractData?.openEnded, 0);
            })
            .catch((error) => {
                // Handle error here
                console.error('Error occurred:', error);
                // Perform any additional error handling logic as needed
            });
    }
    doesPayorHaveAdditionalProperties(obj: any): any {
        const properties = [];
        for (const item of this.payorProperties) {
            // eslint-disable-next-line no-prototype-builtins
            if (obj.hasOwnProperty(item.name) && obj[item.name] === true) {
                properties.push(item.name);
            }
        }
        return properties;
    }
    importPayors(val, index) {
        this.contracts.clear();
        this.selectPayor(val);
        this.contractTemplateChanged(val, index);
    }
    getLevelsArray(index: any) {
        return this.formControlPath[index]?.controls['levels'] as FormArray;
    }

    selectPayor(val) {
        const payor = val?.payor ? val?.payor : val[0] ? val[0] : val?.data;
        payor.additionalRequirements = this.doesPayorHaveAdditionalProperties(payor);
        const indexToRemove = this.payorsList.findIndex(
            (p) =>
                p.value === val ||
                (p.value === payor.name &&
                    p.additionalRequirements.length === payor.additionalRequirements.length &&
                    p.additionalRequirements.every((value, index) => value === payor.additionalRequirements[index]))
        );
        const deletedPayor = this.payorsList[indexToRemove]?.id;
        const foundIndex = this.activeContracts.findIndex(
            (c) =>
                c?.facility?._id === this.currentFacilityId &&
                c?.payor?._id === deletedPayor &&
                c?.contractStatus === 'Active'
        );

        if (!this.activeContracts[foundIndex]?.openEnded || this.editMode) {
            if (!this.editMode && this.contractData) {
                this.payorMasterCopy[indexToRemove].checked = true;
                this.gridApi.redrawRows();
            }
            if (!this.importPayor) {
                this.selectedPayors.push(this.payorsList[indexToRemove]);
                this.payorsList.splice(indexToRemove, 1);
            }
            // commenting this code for now. I don't know the exact use of this
            // this.payorsList = this.payorsList.map((elem) => {
            //     if (elem.value === val || elem.value === val?.payor?.name) {
            //         return {
            //             ...elem,
            //             selected: true,
            //         };
            //     }
            //     return elem;
            // });
            this.currentContractIndex = this.selectedPayors.length - 1;

            if (this.importPayor) {
                this.configureIndividually = true;
            }
            this.selectedPayors.forEach((data, index) => {
                const id = data?.id ? data.id : payor._id;
                const contractForm = this.fb.group({
                    ['payor']: id,
                    ['facility']: this.currentFacilityId,
                    ['contractEnabled']: [true],
                    ['dateFrom']: ['', Validators.required],
                    ['dateThrough']: ['', Validators.required],
                    ['contractType']: [[], Validators.required],
                    ['contractVariant']: ['', this.activeContracts[foundIndex]?.openEnded ? '' : Validators.required],
                    ['openEnded']: [this.editMode || this.importPayor ? this.contractData?.openEnded : true],
                    ['billableDischarge']: [false],
                    ['controlsArray']: this.fb.array([]),
                    ['levels']: this.fb.array([]),
                });

                this.contracts.push(contractForm);
                if (this.importPayor) {
                    this.contractTemplateChanged(data, index);
                }
            });

            if (foundIndex > -1) {
                // Create a new Date object with the input date
                const date = new Date(this.activeContracts[foundIndex].dateThrough);
                // Add one day to the date
                date.setDate(date.getDate() + 1);
                this.startMinDate[this.currentContractIndex] = date as any;
            }
        } else {
            this.toasterService.show({
                title: 'Error',
                body: 'Already have Active Contract',
                type: 'error',
            });
        }
    }
    removeSelectedPayor(payor, index: number): void {
        this.selectedPayors.splice(index, 1);
        this.payorsList.push(payor);
        if (this.configureIndividually || this.selectedPayors.length === 0) {
            this.contracts.removeAt(index);
        }
        if (this.importPayor) {
            this.gridApi.setRowData(this.payorsList);
            this.contracts.removeAt(index);
        }
    }

    getEndDate(index: number) {
        return this.formControlPath[index]?.controls['dateFrom']?.value;
    }
    getControl(control: any) {
        const controlObject = [];
        controlObject.push({
            controlName: Object.keys(control)[0],
            label: Object.keys(control)[2],
            type: Object.keys(control)[1],
        });
        return controlObject[0];
    }
    controlsArray(index: any) {
        return this.formControlPath[index]?.controls['controlsArray'] as FormArray;
    }

    addControlsFromList(currentContractIndex: number) {
        this.allFields = this.controlsList?.fields ? this.controlsList.fields : this.controlsList;
        this.controlsArray(currentContractIndex).clear();
        this.allFields?.forEach((field, index) => {
            if (field.label != 'Level') {
                const controlGroup = this.fb.group({
                    [field.fieldName]: field.required
                        ? [this.addDefaultValue(field), Validators.required]
                        : this.addDefaultValue(field),
                    [field.fieldType]: { value: '', disabled: true },
                    [field.label]: { value: '', disabled: true },
                    ['nestedControlsArray']: this.fb.array([]),
                });
                this.controlsArray(currentContractIndex).push(controlGroup);
                if (field?.nestedFields?.length) {
                    field.nestedFields.forEach((nestedField, nestedIndex) => {
                        this.addNestedControl(currentContractIndex, index, nestedIndex, nestedField);
                    });
                }
            }
        });
    }

    // set Assistant Reduction Default Value
    addDefaultValue(field) {
        // only for CPT Contract
        if (field.fieldName === 'assistantReduction') {
            return field.default;
        }

        // for rest of contracts
        return '';
    }

    getNestedControlsArray(currentContractIndex: number, currentCategoryIndex: number) {
        const controlGroup = this.controlsArray(currentContractIndex)?.at(currentCategoryIndex) as FormGroup;
        return controlGroup?.get('nestedControlsArray') as FormArray;
    }
    getNestedControls(control: any) {
        const controlObject = [];
        controlObject.push({
            controlName: Object.keys(control)[0],
            label: Object.keys(control)[2],
            type: Object.keys(control)[1],
        });
        return controlObject[0];
    }

    addNestedControl(currentContractIndex: number, currentCategoryIndex: number, nestedIndex: any, field: any) {
        const nestedControlsArray = this.getNestedControlsArray(currentContractIndex, currentCategoryIndex);
        const validators = field.required ? [Validators.required, Validators.min(0.1)] : [Validators.min(0.1)];
        nestedControlsArray.push(
            this.fb.group({
                [field.fieldName + currentCategoryIndex + nestedIndex]: ['', validators],
                [field.fieldType]: { value: '', disabled: true },
                [field.label]: { value: '', disabled: true },
            })
        );
    }

    get contracts() {
        return this.addContractForm.controls['contracts'] as FormArray;
    }
    isAnyOverlappedContract(formValues: any) {
        for (let index = 0; index < this.selectedPayors.length; index++) {
            const foundIndex = this.activeContracts.findIndex(
                (c) =>
                    c?.facility?._id === this.currentFacilityId &&
                    c?.payor?._id === this.selectedPayors[index].id &&
                    c?.contractStatus === 'Active' &&
                    (!this.editMode || (this.editMode && c._id != this.contractData?._id))
            );
            if (foundIndex > -1) {
                if (!this.activeContracts[foundIndex].dateThrough) {
                    return true;
                }
                const dateThrough = new Date(this.activeContracts[foundIndex].dateThrough);
                const dateFrom = new Date(this.activeContracts[foundIndex].dateFrom);
                if (
                    (new Date(formValues.contracts[0].dateFrom) >= dateFrom &&
                        new Date(formValues.contracts[0].dateFrom) <= dateThrough) ||
                    (new Date(formValues.contracts[0].dateThrough) >= dateFrom &&
                        new Date(formValues.contracts[0].dateFrom) <= dateThrough)
                )
                    return true;
            }
        }
    }
    createContract(formValues) {
        // validating that contracts must not have overlapping dates
        if (!this.validatingContracts(formValues.contracts[0].dateFrom, formValues.contracts[0].dateThrough)) {
            return;
        }
        const isAnyLevelContract = formValues.contracts.find((x) => x.contractType == 'Level Contract');
        if (this.selectedContract == 'Level Contract' || isAnyLevelContract) {
            let levelAdded = true;
            (this.addContractForm.controls.contracts as FormArray)?.controls.map((x, index) => {
                const levels =
                    formValues.contracts[index].contractType == 'Level Contract'
                        ? ((x as FormGroup).controls.levels as FormArray).controls.map((y) => y.value?.level)
                        : [];
                formValues.contracts[index].levels = [].concat(...levels);
                const invalidLevels = levels && levels.length > 0 ? levels.filter((x) => !x || x == '' || x == 0) : [];
                if (
                    formValues.contracts[index].contractType == 'Level Contract' &&
                    (!levels || levels.length < 1 || invalidLevels.length > 0) &&
                    levelAdded == true
                ) {
                    levelAdded = false;
                } else if (formValues.contracts[index].contractType == 'Level Contract') {
                    this.getLevelsArray(index)?.controls.map((x) => {
                        x.setValidators(null);
                        x.updateValueAndValidity();
                    });
                }
            });
            if (!levelAdded) {
                this.addContractForm.markAllAsTouched();
                this.submitContract = false;
                this.toasterService.show({
                    title: 'Error',
                    body: 'Level is required.',
                    type: 'error',
                });
                return;
            }
        }
        if (this.addContractForm.valid || (!this.contractRequired && !this.editMode)) {
            (this.addContractForm.controls.contracts as FormArray)?.controls.map((x, index) => {
                if (this.contractRequired || this.editMode) {
                    if (!formValues.contracts[index].openEnded) {
                        formValues.contracts[index].dateThrough = new Date(
                            new Date(formValues.contracts[index].dateThrough).setHours(
                                TIME.hours,
                                TIME.minutes,
                                TIME.seconds
                            )
                        ).toISOString();
                    }
                    formValues.contracts[index].dateFrom = new Date(
                        new Date(formValues.contracts[index].dateFrom).setHours(TIME.hours, TIME.minutes, TIME.seconds)
                    ).toISOString();
                }
            });
            this.submitContract = true;
            if (this.selectedPayors.length > 1 && !this.configureIndividually) {
                const copyData = formValues.contracts[0];
                if (!this.contractRequired) {
                    copyData['contractEnabled'] = false;
                    copyData['dateFrom'] = '';
                    copyData['dateThrough'] = '';
                    copyData['contractType'] = '';
                    copyData['contractVariant'] = '';
                    copyData['controlsArray'] = '';
                    copyData['levels'] = '';
                }
                formValues.contracts = [];
                this.selectedPayors.forEach((facility) => {
                    const contractForm = {
                        ['payor']: facility.id,
                        ['facility']: this.currentFacilityId,
                        ['contractEnabled']: copyData['contractEnabled'],
                        ['dateFrom']: copyData['dateFrom'],
                        ['dateThrough']: copyData['dateThrough'],
                        ['contractType']: copyData['contractType'],
                        ['contractVariant']: copyData['contractVariant'],
                        ['openEnded']: copyData['openEnded'],
                        ['billableDischarge']: copyData['billableDischarge'],
                        ['controlsArray']: copyData['controlsArray'],
                        ['levels']: copyData['levels'],
                    };
                    formValues.contracts.push(contractForm);
                });
                // Commenting it for now , as i think its not required

                // if (this.isAnyOverlappedContract(formValues)) {
                //     this.toasterService.show({
                //         title: 'Error',
                //         body: 'Payor contract dates are overlapping, please configure it individually',
                //         type: 'error',
                //     });
                //     this.submitContract = false;
                //     return;
                // }
                this.contractService
                    .createContractFacilityAssignment(formValues)
                    .pipe(first())
                    .subscribe((result: any) => {
                        this.submitContract = false;
                        if (result) {
                            this.toasterService.show({
                                title: 'Success',
                                body: 'Facility Payor Assignments Successful',
                                type: 'success',
                            });
                            this.location.back();
                        }
                    });
            } else if (formValues.contracts.length > 0) {
                formValues.contracts[0].payor = this.selectedPayors[0]?.id
                    ? this.selectedPayors[0].id
                    : this.selectedPayors[0]._id;
                // Commenting it for now , as i think its not required

                // if (!this.configureIndividually && this.isAnyOverlappedContract(formValues)) {
                //     this.toasterService.show({
                //         title: 'Error',
                //         body: 'Payor contract dates are overlapping, please configure it individually',
                //         type: 'error',
                //     });
                //     this.submitContract = false;
                //     return;
                // }
                if (this.editMode) {
                    this.contractService
                        .updateContract(formValues, this.contractData?._id)
                        .pipe(first())
                        .subscribe((result: any) => {
                            this.submitContract = false;
                            if (result) {
                                this.toasterService.show({
                                    title: 'Success',
                                    body: 'Contract updated successfully.',
                                    type: 'success',
                                });
                                this.location.back();
                            }
                        });
                } else {
                    if (!this.contractRequired) {
                        formValues.contracts[0].contractEnabled = false;
                        formValues.contracts[0].contractType = '';
                        formValues.contracts[0].contractVariant = '';
                        formValues.contracts[0].dateFrom = '';
                        formValues.contracts[0].dateThrough = '';
                        formValues.contracts[0].controlsArray = '';
                        formValues.contracts[0].levels = '';
                    }
                    this.contractService
                        .createContractFacilityAssignment(formValues)
                        .pipe(first())
                        .subscribe((result: any) => {
                            this.submitContract = false;
                            if (result) {
                                this.toasterService.show({
                                    title: 'Success',
                                    body: 'Facility Payor Assignments Successful',
                                    type: 'success',
                                });
                                this.location.back();
                            }
                        });
                }
            } else {
                this.submitContract = false;
                this.toasterService.show({
                    title: 'Error',
                    body: 'Please Select Payor.',
                    type: 'error',
                });
            }
        } else {
            this.submitContract = false;
            this.toasterService.show({
                title: 'Error',
                body: 'All Fields are required.',
                type: 'error',
            });
        }
    }
    addLevel(contractIndex: number, value = ''): void {
        this.getLevelsArray(contractIndex)?.controls.push(
            this.fb.group({ level: [value, [Validators.required, Validators.min(0.1)]] })
        );
    }
    removeLevel(contractIndex: number, levelIndex: number): void {
        this.getLevelsArray(contractIndex).removeAt(levelIndex);
    }
    isLevelContract(index: number) {
        return (
            this.formControlPath[index]?.controls['contractType'] &&
            this.formControlPath[index]?.controls['contractType'].value &&
            this.formControlPath[index]?.controls['contractType'].value == 'Level Contract'
        );
    }
    contractTemplateChanged(contractTemplate: any, contractIndex: number) {
        contractTemplate = contractTemplate?.contractType ? contractTemplate : contractTemplate[0];
        // Set dates if editing a.importPayor
        if (this.editMode || this.importPayor) {
            this.formControlPath[contractIndex].controls['dateFrom'].setValue(
                this.datePipe.transform(new Date(contractTemplate.dateFrom), 'MM/dd/yyyy')
            );
            if (!contractTemplate.openEnded) {
                this.formControlPath[contractIndex].controls['dateThrough'].setValue(
                    this.datePipe.transform(new Date(contractTemplate.dateThrough), 'MM/dd/yyyy')
                );
            } else {
                this.formControlPath[contractIndex].controls['openEnded'].setValue(contractTemplate.openEnded);
                this.handleChange(true, contractIndex);
            }
            if (contractTemplate.levels && contractTemplate.levels.length > 0) {
                for (const level of contractTemplate.levels) {
                    this.addLevel(contractIndex, level);
                }
            }
        }
        if (!this.formControlPath[contractIndex]) {
            return;
        }
        this.formControlPath[contractIndex].controls['billableDischarge'].setValue(contractTemplate.billableDischarge);
        if (contractTemplate?.contractEnabled !== undefined) {
            this.formControlPath[contractIndex].controls['contractEnabled'].setValue(contractTemplate.contractEnabled);
        }
        this.updateContractVariantsList(contractTemplate.contractType, contractIndex);
        this.changeVariants(contractTemplate.contractVariant, contractIndex);
        const newControlsArray = [];
        const tempArray = [];
        tempArray.push(contractTemplate.controlsArray);
        tempArray[0]?.forEach((control) => {
            const updatedObj = {};

            Object.entries(control).forEach(([key, value]) => {
                key = key.includes('-') ? key : key;
                if (Array.isArray(value) && value.length > 0) {
                    const nestedControls = [];
                    value.forEach((nestedControl) => {
                        const nestedControlsArray = {};
                        Object.entries(nestedControl).forEach(([nestedKey, nestedValue]) => {
                            nestedKey = nestedKey.includes('-') ? nestedKey : nestedKey;
                            nestedControlsArray[nestedKey] = nestedValue;
                        });
                        nestedControls.push(nestedControlsArray);
                    });
                    updatedObj[key] = nestedControls;
                } else {
                    updatedObj[key] = value;
                }
            });

            newControlsArray.push(updatedObj);
        });
        this.formControlPath[contractIndex].controls['controlsArray'].patchValue(newControlsArray);
    }
    isContractRequired(contractRequired) {
        if (!this.editMode && contractRequired && !this.importPayor) {
            const dateThroughChange = this.formControlPath[0]?.controls['dateThrough'];
            dateThroughChange.patchValue('mm/dd/yyyy');
            dateThroughChange.setValidators([]);
            dateThroughChange.disable();
        }
    }
    configureContractsIndividually() {
        this.contractVariantList = [];
        this.contracts.clear();

        if (this.configureIndividually) {
            this.selectedPayors.forEach((payor, index) => {
                const foundIndex = this.activeContracts.findIndex(
                    (c) =>
                        c?.facility?._id === this.currentFacilityId &&
                        c?.payor?._id === payor.id &&
                        c?.contractStatus === 'Active'
                );
                if (foundIndex > -1) {
                    // Create a new Date object with the input date
                    const date = new Date(this.activeContracts[foundIndex].dateThrough);
                    // Add one day to the date
                    date.setDate(date.getDate() + 1);
                    this.startMinDate[index] = date as any;
                }
                const contractForm = this.fb.group({
                    ['payor']: payor.id,
                    ['facility']: this.currentFacilityId,
                    ['contractEnabled']: [true],
                    ['dateFrom']: ['', Validators.required],
                    ['dateThrough']: ['', Validators.required],
                    ['contractType']: ['', Validators.required],
                    ['contractVariant']: ['', Validators.required],
                    ['openEnded']: [this.editMode || this.importPayor ? this.contractData?.openEnded : true],
                    ['controlsArray']: this.fb.array([]),
                    ['levels']: this.fb.array([]),
                    ['billableDischarge']: [false],
                });

                this.contracts.push(contractForm);
                this.handleChange(true, index);
            });
        } else if (!this.configureIndividually && this.selectedPayors.length > 0) {
            const contractForm = this.fb.group({
                payor: this.selectedPayors[0].id,
                facility: this.currentFacilityId,
                contractEnabled: [true],
                dateFrom: ['', Validators.required],
                dateThrough: ['', Validators.required],
                contractType: ['', Validators.required],
                contractVariant: ['', Validators.required],
                openEnded: [true],
                controlsArray: this.fb.array([]),
                allDisciplines: [''],
                billableDischarge: [false],
                levels: this.fb.array([]),
            });
            this.contracts.push(contractForm);
            this.handleChange(true, 0);
        }
    }

    updateContractVariantsList(event: any, index: number) {
        this.contractVariantList[index] = [];
        this.controlsArray(index).clear();
        this.selectedContract = event?.target?.value ? event.target.value : event;
        this.controlsArray(index);
        const selectedVariantList = this.contractList.find((contract) => contract.label === this.selectedContract);
        this.formControlPath[index]?.controls['contractType'].patchValue(this.selectedContract);
        if (selectedVariantList) {
            for (const variant of selectedVariantList.variants) {
                this.contractVariantList[index].push(variant.label);
            }
        }
        if (!selectedVariantList?.variants.length) {
            this.controlsList = selectedVariantList?.fields ? selectedVariantList?.fields : selectedVariantList;
            this.addControlsFromList(index);
        }
        this.formControlPath[index]?.controls['contractVariant']?.reset();
        this.updateFormControls(index);
        if (this.selectedContract != 'Level Contract' && this.formControlPath[index]?.controls['levels']) {
            this.formControlPath[index].removeControl('levels');
        } else if (this.selectedContract == 'Level Contract' && !this.formControlPath[index]?.controls['levels']) {
            this.formControlPath[index].addControl('levels', this.fb.array([]));
        }
    }
    handleChange(event: any, index: number): void {
        const dateThroughChange = this.formControlPath[index]?.controls['dateThrough'];
        // Handle the change event
        if (event?.target?.checked || event === true) {
            dateThroughChange?.patchValue('mm/dd/yyyy');
            dateThroughChange?.setValidators([]);
            dateThroughChange?.disable();
        } else {
            dateThroughChange?.enable();
            dateThroughChange?.setValidators([Validators.required]);
        }
    }
    changeVariants(event: any, index: number) {
        this.selectedVariant = event?.target?.value ? event.target.value : event;
        this.formControlPath[index]?.controls['contractVariant']?.patchValue(this.selectedVariant);
        this.selectedContract = this.formControlPath[index]?.controls['contractType'].value;

        const selectedVariantList = this.contractList.find((contract) => contract.label === this.selectedContract);
        if (this.selectedVariant) {
            this.controlsList = selectedVariantList?.variants.find((variant) => variant.label === this.selectedVariant);
        }
        this.addControlsFromList(index);
    }

    groupSelectionChanged() {
        this.getAllFacilities();
    }

    getAllFacilities() {
        this._facilityManagementService._Facilities.subscribe((result) => {
            if (result.length > 0) {
                this.facilityList = this._facilityManagementService.mapFacilitiesIntoDropdown();
                this.facilityMasterCopy = [...this.facilityList];
            }
        });
    }
    payorSearch(text: string) {
        this.payorsList = this.payorsList.filter((payor) => payor.value.toLowerCase().includes(text.toLowerCase()));
    }

    redrawRows() {
        this.payorsList = [];
        this.facilityList = [];
        this.payorsList = [...this.payorMasterCopy];
        this.facilityList = [...this.facilityMasterCopy];
    }
    facilitySearch(text: string) {
        this.facilityList = this.facilityList.filter((payor) => payor.name.toLowerCase().includes(text.toLowerCase()));
    }
    onGridReady(params: GridReadyEvent) {
        this.gridApi = params.api;
    }
    async facilitySelectionChanged(contracts: any) {
        // await this.contractService.getAllContracts().subscribe((response: any) => {
        if (contracts) {
            this.contractVariantList = [];
            this.selectedPayors = [];
            this.contracts.clear();
            contracts = contracts
                .filter((x) => x.payor)
                .map((item) => {
                    return {
                        id: item.payor._id,
                        name: item.payor.name,
                        value: item.payor.name,
                        payor: item.payor,
                        facility: item.facility,
                        frequency:
                            item.payor.frequency?.replace(/x/g, '') + ' X Every ' + item.payor.duration + ' Visits',
                        additionalRequirements: this.doesPayorHaveAdditionalProperties(item.payor),
                        type: item.payor.type,
                        billableDischarge: item.billableDischarge,
                        contractEnabled: item.contractEnabled,
                        contractStatus: item.contractStatus,
                        contractType: item.contractType,
                        levels: item.levels,
                        contractVariant: item.contractVariant,
                        controlsArray: item.controlsArray,
                        dateFrom: item.dateFrom,
                        dateThrough: item.dateThrough,
                        openEnded: item.openEnded,
                        facilityName: item?.facility?.facilityName,
                        facilityId: item?.facility?._id,
                        contractId: item?._id,
                    };
                });
            contracts = contracts.filter((item) => item.contractStatus === 'Active');
            this.payorsList = [];
            this.payorMasterCopy = [...contracts];
            this.payorsList = [...this.payorMasterCopy];
            this.payorTypes = _.uniq(this.payorMasterCopy.map((v) => v.type));
            this.facilities = _.uniq(this.payorMasterCopy.map((v) => v.facilityName).filter((facility) => facility));

            this.isLoading = false;
        }
        // });
    }

    updateFormControls(contractIndex: number) {
        if (this.contractVariantList[contractIndex].length === 0) {
            this.removeFormControl('contractVariant', contractIndex);
        } else {
            this.addFormControl('contractVariant', contractIndex);
        }
    }
    removeFormControl(controlName: string, contractIndex: number) {
        this.formControlPath[contractIndex].removeControl(controlName);
    }

    addFormControl(controlName: string, contractIndex) {
        if (!this.formControlPath[contractIndex].contains(controlName)) {
            this.formControlPath[contractIndex].addControl(controlName, new FormControl());
        }
    }
    cancel() {
        history.go(-1);
    }
}
